import type { ReactNode } from "react";
import { useAtomValue } from "jotai";

import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import { recordingItemsWithPagingForRecordingGroupIdAtom } from "@sunrise/yallo-recordings-series";

import { RecordingsNetworkIndicator } from "@/features/recordings/recordings-network-indicator";

import { spinner } from "./details-series.css";

export type DetailsSeriesNetworkIndicatorProps = {
  recordingGroupId: RecordingGroupId;
  seriesAssetId: AssetId;
};

export function DetailsSeriesNetworkIndicator({
  recordingGroupId,
  seriesAssetId,
}: DetailsSeriesNetworkIndicatorProps): ReactNode {
  const { isLoadingNextPage } = useAtomValue(
    recordingItemsWithPagingForRecordingGroupIdAtom({
      seriesAssetId,
      recordingGroupId,
    }),
  );

  return (
    <RecordingsNetworkIndicator
      className={spinner}
      shouldShow={isLoadingNextPage}
    />
  );
}
