import { useCallback, useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import { useConfirmationDialog } from "@sunrise/dialogs";

import { cancelRecordingsMutationAtom } from "./query-atoms/cancel-recordings.mutation.atom";
import { deleteRecordingsMutationAtom } from "./query-atoms/delete-recordings.mutation.atom";
import { haveRecordingSchedulesAtom } from "./query-atoms/recording-schedules.query.atom";
import { recordingsMarkedForDeletionAtom } from "./recordings-marked-for-deletion.atom";

export type UseRecordSeriesProps = {
  recordingGroupId: RecordingGroupId;
  assetId: AssetId;
  deleteButtonFocusKey: string;
  onDeleteConfirm: () => void;
};

export function useRecordSeries({
  recordingGroupId,
  assetId,
  deleteButtonFocusKey,
  onDeleteConfirm,
}: UseRecordSeriesProps) {
  const { showDialog } = useConfirmationDialog();
  const [{ mutateAsync: mutateCancelRecordings }] = useAtom(
    cancelRecordingsMutationAtom,
  );
  const [{ mutateAsync: mutateDeleteRecordings }] = useAtom(
    deleteRecordingsMutationAtom,
  );
  const haveRecordingSchedules = useAtomValue(
    haveRecordingSchedulesAtom(assetId),
  );

  const cancelRecordings = useCallback(async (assetId: AssetId) => {
    await mutateCancelRecordings({
      assetId,
    });
  }, []);

  const deleteRecordings = useAtomCallback(
    useCallback(
      async (get, set, assetId: AssetId) => {
        void mutateDeleteRecordings({
          assetId,
        });

        const marked = get(recordingsMarkedForDeletionAtom);

        set(recordingsMarkedForDeletionAtom, {
          ...marked,
          [recordingGroupId]: true,
        });
      },
      [recordingGroupId],
    ),
  );

  const [cancelDisabled, setCancelDisabled] = useState(true); // start with button disabled until we know if there are recordings

  useEffect(() => {
    if (!haveRecordingSchedules.isPending) {
      setCancelDisabled(!haveRecordingSchedules.data);
    }
  }, [
    haveRecordingSchedules.isPending,
    setCancelDisabled,
    haveRecordingSchedules.data,
  ]);

  const onCancelRecordings = (): void => {
    setCancelDisabled(true);
    void cancelRecordings(assetId);
  };
  const onDeleteRecordings = (): void => {
    showDialog({
      title: { key: "dialog_delete_all_title" },
      description: { key: "dialog_delete_all_subtitle" },
      onConfirm: async () => {
        void deleteRecordings(assetId);
        onDeleteConfirm();
      },
      confirmationLabel: { key: "recordings_delete_all" },
      rejectionLabel: { key: "dialog_button_cancel" },
      lastFocusKey: deleteButtonFocusKey,
      focusReject: true,
      id: "delete-all-recordings",
    });
  };

  return {
    onCancelRecordings,
    haveRecordingSchedules: !cancelDisabled,
    onDeleteRecordings,
  };
}
