import type { MappedAsset } from "@sunrise/asset";
import { isDefined, type Nullable } from "@sunrise/utils";
import type { MappedChannel } from "@sunrise/yallo-channel-group";
import type { MappedEpg } from "@sunrise/yallo-epg";

import { MAX_GENRE_TAGS_NUMBER } from "../constants";
import { type DetailsAtomResponse } from "../details.types";
import { getPrefixAndSubtitle } from "./get-prefix-and-subtitle";

export function getAssetTags(
  genres: MappedAsset["genres"],
  productionYear: MappedAsset["productionYear"],
): string[] {
  return [
    ...genres.slice(0, MAX_GENRE_TAGS_NUMBER),
    productionYear?.toString(),
  ].filter(isDefined);
}

export function getDetailsForEpgAndAsset(
  epg: Nullable<MappedEpg>,
  asset: Nullable<MappedAsset>,
  channel: Nullable<Omit<MappedChannel, "order" | "locked">>,
): DetailsAtomResponse | null {
  if (!epg) {
    return null;
  }

  const genreLabels = asset?.genres?.slice(0, MAX_GENRE_TAGS_NUMBER) ?? [];
  const yearLabel = asset?.productionYear?.toString();
  const ageLabel = asset?.ageRecomendation;

  const start = new Date(epg.actualStart);
  const end = new Date(epg.actualEnd);

  const prefixAndSubtitle = getPrefixAndSubtitle(
    asset?.type,
    asset?.seasonNumber,
    asset?.episodeNumber,
    asset?.subtitle ?? epg.asset.subtitle ?? "",
  );

  // TODO: Would be nice if we could suppress the description if it's the same as the title.
  return {
    channelLogo: epg.channel.logo ?? channel?.logo ?? null,
    channelName: epg.channel.name ?? channel?.name ?? null,
    channelId: epg.channel.id,
    posterBlurHash: asset?.posterBlurHash ?? null,
    backgroundImage: asset?.posterPath ?? null,
    title: asset?.title ?? epg.asset.title,
    subtitle: prefixAndSubtitle ?? null,
    tags: {
      year: yearLabel ?? null,
      age: ageLabel ?? null,
      genres: genreLabels,
    },
    description: asset?.plot ?? null,
    time: { start, end },
  };
}
