import type {
  ExtractAtomArgs,
  ExtractAtomResult,
  ExtractAtomValue,
} from "jotai";
import { atom } from "jotai";
import { unwrap } from "jotai/utils";
import { atomEffect } from "jotai-effect";

import { isNil } from "@sunrise/utils";
import { activeChannelIdAtom } from "@sunrise/yallo-active-channel";
import { currentChannelGroupAtom } from "@sunrise/yallo-channel-group";

import { activeChannelIndexAtom } from "../active-channel-index.atom";
import { channelsForChannelListAtom } from "../channels-for-channel-list.atom";
import { scrollPositionReducerAtom } from "./scroll-position-reducer.atom";

const setChannelGroupEffect = atomEffect((get, set) => {
  const channelGroup = get(unwrap(currentChannelGroupAtom));
  if (isNil(channelGroup)) {
    return;
  }

  set(scrollPositionReducerAtom, {
    type: "set-channel-group-id",
    payload: { channelGroupId: channelGroup.id },
  });
});

/**
 * Whenever the active channel changes we need to update the scroll position atom to reflect the new active channel.
 * The reducer will then take care of auto-scrolling to the new channel if needed.
 */
const setActiveChannelEffect = atomEffect((get, set) => {
  const activeChannel = get(unwrap(activeChannelIdAtom));
  const activeChannelIndex = get(unwrap(activeChannelIndexAtom));

  if (isNil(activeChannel) || isNil(activeChannelIndex)) {
    if (activeChannelIndex === -1) {
      set(scrollPositionReducerAtom, {
        type: "clear-active-channel",
      });
    }
    return;
  }

  const current = get(scrollPositionReducerAtom);
  if (current.activeChannel?.id === activeChannel) {
    return;
  }

  set(scrollPositionReducerAtom, {
    type: "set-active-channel",
    payload: { channelId: activeChannel, index: activeChannelIndex },
  });
});

const setMountedEffect = atomEffect((_, set) => {
  set(scrollPositionReducerAtom, { type: "mounted" });

  return () => {
    set(scrollPositionReducerAtom, { type: "unmounted" });
  };
});

const setChannelsLoadedEffect = atomEffect((get, set) => {
  const current = get(scrollPositionReducerAtom);
  const { channels } = get(unwrap(channelsForChannelListAtom)) ?? {};
  const hasChannels = channels ? channels.length > 0 : false;

  if (current.loading && hasChannels) {
    set(scrollPositionReducerAtom, { type: "channels-loaded" });
    return;
  } else if (!current.loading && !hasChannels) {
    set(scrollPositionReducerAtom, { type: "channels-loading" });
    return;
  }
});

export const scrollPositionAtom = atom<
  ExtractAtomValue<typeof scrollPositionReducerAtom>,
  ExtractAtomArgs<typeof scrollPositionReducerAtom>,
  ExtractAtomResult<typeof scrollPositionReducerAtom>
>(
  (get) => {
    get(setChannelGroupEffect);
    get(setActiveChannelEffect);
    get(setMountedEffect);
    get(setChannelsLoadedEffect);
    return get(scrollPositionReducerAtom);
  },
  (_, set, update) => {
    set(scrollPositionReducerAtom, update);
  },
);
