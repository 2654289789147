import { type QueryClient } from "@tanstack/query-core";
import { isPast } from "date-fns";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { endpoints, type PinResponse } from "@sunrise/backend-types";
import { hostsAtom, publicApi } from "@sunrise/http-client";

export const pinAtomQuery = atomWithSuspenseQuery<PinResponse>((get) => ({
  queryKey: ["pin"],
  refetchOnWindowFocus: false,
  queryFn: async () => {
    const hosts = get(hostsAtom);
    const host = hosts.api;
    if (isNil(host)) throw new Error("api host is not defined");

    const { data } = await publicApi.post<PinResponse>(
      endpoints.requestPin(host),
      {
        device_type_name: "tizen",
        // pretend we are the webapp.
        // device_type_name: "webclient",
      },
    );

    return data;
  },
}));

export async function checkIfApproved(
  url: string,
  client: QueryClient,
  onApprove: (data: PinResponse) => void,
): Promise<void> {
  try {
    const response = await publicApi.get<PinResponse>(url);

    const data = response.data;

    if (data.approved) {
      onApprove(data);
      return;
    }

    const expiryDate = new Date(data.expires_at);

    // pin not valid anymore, need to get a new one
    if (isPast(expiryDate)) {
      void client.refetchQueries({
        queryKey: ["pin"],
        exact: true,
      });
    }
  } catch {
    void client.refetchQueries({
      queryKey: ["pin"],
      exact: true,
    });
  }
}
