import { atom } from "jotai";

import { playerControlsVisibleAtom } from "@sunrise/yallo-player-controls";

import { isChannelListHiddenAtom } from "@/features/channel-list";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";

export const shouldPullFocusToPlayerControlsAtom = atom<boolean>((get) => {
  // We should pull focus
  return (
    // When the player controls are visible,
    get(playerControlsVisibleAtom) &&
    // And when no menu is open or modal is open,
    get(canPullFocusAtom) &&
    // And when channel list is not expanded,
    get(isChannelListHiddenAtom)
  );
});
