import { atom } from "jotai";

import { selectedChannelGroupWithAllChannels } from "@sunrise/yallo-channel-group";

import type { ChannelsListOutput } from "./channels-for-channel-list.atom";

export const channelsForChannelListLegacyAtom = atom<
  Promise<ChannelsListOutput>
>(async (get) => {
  const allChannelsForChannelGroup = await get(
    selectedChannelGroupWithAllChannels,
  );

  return {
    channels: allChannelsForChannelGroup.map((channel) => ({
      type: "channel",
      data: {
        id: channel.id,
        channelNumber: channel.order,
        channelLogo: channel.logo,
        channelName: channel.name,
        stream: null,
      },
    })),
  };
});
