import { type ReactNode, useCallback, useEffect } from "react";

import type { ActionDialogAction } from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";
import { useIdle } from "@sunrise/utils";

import { FocusButton } from "@/components";

import * as styles from "./side-bar-actions-dialog.css";

export type SideBarActionsButtonProps = ActionDialogAction & {
  doClose: () => void;
  index: number;
};

export function SideBarActionsButton(
  props: SideBarActionsButtonProps,
): ReactNode {
  const { label, index, closeDisabled, execAfterTimeout, doClose } = props;
  const t = useTranslatable();
  const shouldExec = useIdle(execAfterTimeout);

  const action = "action" in props ? props.action : undefined;
  const url = "url" in props ? props.url : undefined;

  const performAction = useCallback(() => {
    if (action) {
      action();
    }

    if (url) {
      window.open(url);
    }

    if (!closeDisabled) {
      doClose();
    }
  }, [doClose, closeDisabled, action, url]);

  useEffect(() => {
    if (execAfterTimeout && shouldExec) {
      performAction();
    }
  }, [shouldExec, performAction, execAfterTimeout]);

  return (
    <FocusButton
      className={styles.button}
      focusKey={`side-bar-actions-dialog.button.${index}`}
      text={t(label) ?? `button ${index}`}
      onEnterPress={performAction}
    />
  );
}
