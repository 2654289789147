import { atom } from "jotai";

import type { AscDescSort, RecordingsSort } from "@sunrise/backend-types";
import type { Translatable } from "@sunrise/i18n";
import type { SortedListOptions } from "@sunrise/types";

import { recordingsSortOverviewLegacyAtom } from "./recordings-sort-overview.legacy.atom";

const sortOptions: {
  value: RecordingsSort;
  label: Translatable;
  sort: AscDescSort;
}[] = [
  {
    value: "dateDesc",
    sort: "desc",
    label: { key: "recording_sort_newest_aired" },
  },
  {
    value: "dateAsc",
    sort: "asc",
    label: { key: "recording_sort_oldest_aired" },
  },
  {
    value: "titleAsc",
    sort: "asc",
    label: { key: "recording_sort_title_asc" },
  },
  {
    value: "titleDesc",
    sort: "desc",
    label: { key: "recording_sort_title_desc" },
  },
];

const LEGACY_OPTIONS = sortOptions.map((option) => ({
  label: option.label,
  value: option.value,
}));

export const recordingsOverviewSortLegacyAtom = atom<
  SortedListOptions<RecordingsSort>,
  [RecordingsSort],
  unknown
>(
  (get) => {
    const value = get(recordingsSortOverviewLegacyAtom);

    return {
      options: LEGACY_OPTIONS,
      current: LEGACY_OPTIONS.find((item) => item.value === value) ?? null,
    };
  },
  (_, set, sort) => {
    set(recordingsSortOverviewLegacyAtom, sort);
  },
);
