import { useCallback } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { areVideoAdsPlayingAtom } from "@sunrise/ads";
import type { ChannelId } from "@sunrise/backend-types-core";
import { useConfirmation } from "@sunrise/dialogs";
import {
  playerCurrentEpgItemAtom,
  selectPlayerCurrentPlayRequest,
  selectPlayerIsStopped,
} from "@sunrise/player";
import { nowSecondAtom } from "@sunrise/time";
import { isDefined, type Nullable } from "@sunrise/utils";
import { playableChannelsInCurrentChannelGroupAtom } from "@sunrise/yallo-channel-group";
import {
  actionPlayerManagerPlayLiveChannelId,
  currentlyRequestedPlayRequestAtom,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";

import type { ZappingDirection } from "@/modules/zapping/zapping.types";
import {
  actionZappingChannelShow,
  zappingControlAtom,
} from "@/modules/zapping/zapping-control.atom";

import { confirmZappingAwayAtom } from "../confirm-zapping-away.atom";

type ZappingOrigin = "arrow-up-down" | "channel-up-down";

type UseZappingReturn = {
  zap: (direction: ZappingDirection, origin: ZappingOrigin) => Promise<void>;
};

export function useZapping(): UseZappingReturn {
  const dispatchZappingChannel = useSetAtom(zappingControlAtom);

  const showSelf = useCallback(() => {
    dispatchZappingChannel(actionZappingChannelShow());
  }, [dispatchZappingChannel]);

  const epg = useAtomValue(playerCurrentEpgItemAtom);

  /**
   * NOTE: This should not be async. We do not want to have a delay when we need to show the confirmation.
   */
  const shouldItShowZappingConfirmation = useAtomCallback(
    useCallback(
      (get): boolean => {
        if (!get(confirmZappingAwayAtom)) {
          return false;
        }

        // When ads are playing, do not ask.
        const playingAds = get(areVideoAdsPlayingAtom);
        if (playingAds) {
          return false;
        }

        // We should probably use the player's current play request.
        const playRequest = get(selectPlayerCurrentPlayRequest);
        // When the player is stopped, we should not ask. Since the user is not playing out anything anyway.
        if (!playRequest || get(selectPlayerIsStopped)) {
          return false;
        }

        switch (playRequest.type) {
          case "live":
            return false;
          case "recording":
            return true;
          case "replay": {
            // When we do not have an epg item (highly unlikely) let's not ask.
            if (!epg) {
              return false;
            }

            // When the epg item is fully in the past, we should ask.
            const currentTime = get(nowSecondAtom);
            return new Date(epg.actualEnd) < currentTime;
          }
        }
      },
      [epg],
    ),
  );

  const { confirm } = useConfirmation<boolean>();

  const zap = useAtomCallback(
    useCallback(
      async (get, set, direction: ZappingDirection, origin: ZappingOrigin) => {
        // Ensure we only loop over zappable channels
        const channels = await get(playableChannelsInCurrentChannelGroupAtom);

        // Figure out if we used the arrow keys or if we used channel up/down.
        // When arrow keys we need to ask for confirmation. Else, never.
        const shouldConfirm =
          origin === "channel-up-down"
            ? false
            : shouldItShowZappingConfirmation();

        const confirmed = shouldConfirm
          ? await confirm({
              title: { key: "confirm_zapping_title" },
              options: [
                {
                  label: { key: "button_yes" },
                  value: true,
                },
                {
                  label: { key: "button_no" },
                  value: false,
                  initialFocus: true,
                  execAfterTimeout: 15_000,
                },
              ],
            })
          : true;

        if (!confirmed) {
          return;
        }

        // get current channel
        const currentChannelId = get(
          currentlyRequestedPlayRequestAtom,
        )?.channelId;
        const channelIdx = channels.findIndex(
          (it) => it.id === currentChannelId,
        );

        let id: Nullable<ChannelId> = null;

        if (channelIdx === -1) {
          // When there's no channel found, we just pick the first channel. (if any)
          id = channels[0]?.id ?? null;
        } else {
          if (direction === "down") {
            // When we go down, we pick the next channel. If there's no next channel, we pick the first channel.
            id = channels[channelIdx + 1]?.id ?? channels[0]?.id;
          } else {
            // When we go up, we pick the previous channel. If there's no previous channel, we pick the last channel.
            id = channels[channelIdx - 1]?.id ?? channels.at(-1)?.id;
          }
        }

        if (isDefined(id)) {
          set(playerManagerAtom, actionPlayerManagerPlayLiveChannelId(id));
          showSelf();
        }
      },
      [showSelf, confirm, shouldItShowZappingConfirmation],
    ),
  );

  return {
    zap,
  };
}
