import type { RecordingStatusUpdate } from "@sunrise/backend-types";
import type {
  GenericRecording,
  MarkedRecordings,
} from "@sunrise/yallo-recordings";

export function removeUnwantedItemsFromList(
  items: GenericRecording[],
  statuses: RecordingStatusUpdate[],
  marked: MarkedRecordings,
): GenericRecording[] {
  return items.filter((item) => {
    const isMarkedForDeletion = !!marked[item.id];
    if (isMarkedForDeletion) {
      return false;
    }

    return !statuses.some(
      (s) => s.recordingId === item.id && s.status === "deleted",
    );
  });
}
