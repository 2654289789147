import { atom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";
import { recordingByRecordingIdAtom } from "@sunrise/yallo-recordings";

import { selectPlayerCurrentPlayRequest } from "./player.atom";
import { type PlayerCurrentContent } from "./types";

const EMPTY: Readonly<PlayerCurrentContent> = {
  epgId: null,
  channelId: null,
  recordingId: null,
  title: null,
  subtitle: null,
  channelLogo: null,
  channelName: null,
  schedule: null,
};

/**
 * This assumes whatever is playing is a recording. And that for a recording, whatever is playing can't change.
 * So it just does a lookup on the recording details through the recordingId and exposes those details as the current content.
 */
export const playerCurrentContentRecordingAtom = atom<
  Promise<PlayerCurrentContent>
>(async (get) => {
  const playRequest = get(selectPlayerCurrentPlayRequest);

  if (!playRequest || playRequest.type !== "recording") {
    return EMPTY;
  }

  const isLegacy = get(isLegacyBackendAtom);
  const [channel, recordingData] = await Promise.all([
    isLegacy && playRequest.channelId
      ? get(channelByIdAtom(playRequest.channelId))
      : Promise.resolve(null),
    get(recordingByRecordingIdAtom(playRequest.recordingId)),
  ]);

  if (!recordingData || recordingData.type === "group") {
    return EMPTY;
  }

  return {
    epgId: recordingData.epgEntryId,
    channelId: recordingData.channelId ?? channel?.id,
    channelName:
      "channelName" in recordingData
        ? recordingData.channelName
        : channel?.name,
    channelLogo:
      "channelLogo" in recordingData
        ? recordingData.channelLogo
        : channel?.logo,
    recordingId: recordingData.id,
    title: recordingData.title,
    subtitle: recordingData.subtitle,
    schedule: {
      endTime: recordingData.epg_end,
      startTime: recordingData.epg_start,
    },
  };
});
