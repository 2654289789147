import type { PageWithItems } from "@sunrise/backend-ng-core";
import type { PagedResponse } from "@sunrise/backend-types";

/**
 * Helper function to convert the legacy page format to the NG format.
 * Ideally, all paged legacy queries should be converted to the NG format so it is easier to work with the data.
 */
export function convertLegacyPageFormatToNgPageFormat<T>(
  input: PagedResponse<T>,
): PageWithItems<T> {
  return {
    items: input.result,
    page: input.page,
    pages: input.pages,
    size: input.pagesize,
    total: input.records,
  };
}
