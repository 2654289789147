import { type ReactNode } from "react";
import clsx from "clsx";

import * as styles from "./qr-code.css";

export type QrCodeProps = CommonProps & {
  qrCodeUrl: string;
  link: string;
  size?: number;
  textColor?: "white" | "yellow";
};

export function QrCode({
  qrCodeUrl,
  link,
  size = 300,
  className,
  textColor = "white",
  "data-testid": dataTestId = "qrCode",
}: QrCodeProps): ReactNode {
  if (qrCodeUrl.length === 0) return null;

  return (
    <div className={clsx([styles.qrCode, className])} data-testid={dataTestId}>
      <div
        data-testid={`${dataTestId}.image-container`}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <img
          alt={link}
          className={styles.image}
          data-testid={`${dataTestId}.image`}
          src={qrCodeUrl}
        />
      </div>

      <span
        className={clsx([styles.link, styles[textColor]])}
        data-testid={`${dataTestId}.link`}
      >
        {link}
      </span>
    </div>
  );
}
