import type { ReactNode } from "react";
import { useMemo } from "react";
import clsx from "clsx";
import { useAtomValue } from "jotai";

import type { AssetId } from "@sunrise/backend-types-core";
import type { TimeInfo } from "@sunrise/details";
import { nowAtom } from "@sunrise/time";
import { useTranslator } from "@sunrise/translator";
import type { Nullable } from "@sunrise/utils";
import { useReadableEpgSchedulingInfo } from "@sunrise/yallo-epg";

import { ChannelLogo } from "@/components/channel-logo/channel-logo";
import { typography } from "@/styles/typography.css";

import { DetailsCastDirector } from "../details-cast-director";
import * as styles from "./details-info.css";

export type DetailsInfoProps = {
  title: Nullable<string>;
  subtitle?: Nullable<string>;
  description: Nullable<string>;
  channelLogo?: Nullable<string>;
  time?: Nullable<TimeInfo>;
  assetId: AssetId;
  showCastDirector?: boolean;
} & CommonProps;

export function DetailsInfo({
  title,
  time,
  channelLogo,
  description,
  subtitle,
  assetId,
  showCastDirector,
  "data-testid": testId,
}: DetailsInfoProps): ReactNode {
  const t = useTranslator();

  const timings = useMemo(
    () => (time ? { startTime: time.start, endTime: time.end } : null),
    [time],
  );
  const now = useAtomValue(nowAtom);
  const when = useReadableEpgSchedulingInfo(timings, t, now);
  const hasProgrammation = !!channelLogo || !!when.status;

  return (
    <>
      {!title ? null : (
        <h1
          className={clsx(styles.title, typography.h1.bold)}
          data-testid={`${testId}.title`}
        >
          {title}
        </h1>
      )}
      {!subtitle ? null : (
        <h2
          className={clsx(styles.subtitle, typography.h6.bold)}
          data-testid={`${testId}.subtitle`}
        >
          {subtitle}
        </h2>
      )}
      {!description ? null : (
        <p
          className={clsx(typography.h6.regular, styles.description)}
          data-testid={`${testId}.description`}
        >
          {description}
        </p>
      )}
      {showCastDirector ? <DetailsCastDirector assetId={assetId} /> : null}
      {hasProgrammation ? (
        <div className={styles.programmation}>
          {channelLogo ? (
            <ChannelLogo
              className={styles.channelLogo}
              data-testid="channel-logo"
              url={channelLogo}
            />
          ) : null}
          {when.status ? (
            <div className={typography.h6.bold} data-testid="timing">
              {[when.status, when.schedule].filter(Boolean).join(", ")}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
