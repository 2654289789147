import { useCallback, useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import type { Nullable } from "@sunrise/utils";

import {
  actionSeekBackward,
  actionSeekForward,
  actionSeekReset,
  seekFactorAtom,
  seekSpeedAtom,
} from "./seek-speed.atom";
import { speedupSeekEnabledAtom } from "./speedup-seek-enabled.atom";

type UseSpeedupSeekProps = {
  enabled: boolean;
};

/**
 * Speedup seek when holding down arrow keys.
 * Optimized for Philips TV remotes
 */
export const useSpeedupSeek = ({
  enabled,
}: UseSpeedupSeekProps): {
  seekFactorForward: Nullable<number>;
  seekFactorBackward: Nullable<number>;
  onSeekForward: Nullable<() => void>;
  onSeekBackward: Nullable<() => void>;
} => {
  const isSpeedupSeekEnabled = useAtomValue(speedupSeekEnabledAtom);
  const dispatchSeekSpeed = useSetAtom(seekSpeedAtom);
  const isEnabled = enabled && isSpeedupSeekEnabled;

  const onSeekForward = useCallback(() => {
    dispatchSeekSpeed(actionSeekForward());
  }, [dispatchSeekSpeed]);

  const onSeekBackward = useCallback(() => {
    dispatchSeekSpeed(actionSeekBackward());
  }, [dispatchSeekSpeed]);

  useEffect(() => {
    return () => {
      dispatchSeekSpeed(actionSeekReset());
    };
  }, [dispatchSeekSpeed]);

  return {
    seekFactorBackward: useAtomValue(seekFactorAtom("forward")),
    seekFactorForward: useAtomValue(seekFactorAtom("backward")),
    onSeekForward: isEnabled ? onSeekForward : null,
    onSeekBackward: isEnabled ? onSeekBackward : null,
  };
};
