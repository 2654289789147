import { atom } from "jotai";

import { adControlsVisible, areVideoAdsPlayingAtom } from "@sunrise/ads";

import { tvIsFocusedAtom } from "@/modules/tv/tv-is-focused.atom";

import { zappingAllowedAtom } from "./zapping-allowed.atom";

export const zappingThroughChannelKeysAllowedAtom = atom(
  (get) =>
    get(zappingAllowedAtom) &&
    (get(tvIsFocusedAtom) ||
      get(adControlsVisible) ||
      get(areVideoAdsPlayingAtom)),
);
