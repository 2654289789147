type WebOSRemoteKey =
  | "Back"
  | "ColorF0Red"
  | "ColorF1Green"
  | "ColorF2Yellow"
  | "ColorF3Blue"
  | "MediaFastForward"
  | "MediaPause"
  | "MediaPlay"
  | "MediaRewind"
  | "MediaStop";

type WebOSRemoteKeyIdKeyCodes = Record<WebOSRemoteKey, string>;

export const webosRemoteKeyIdToKeyboardEventKeyDict = {
  Back: "461",
  ColorF0Red: "403",
  ColorF1Green: "404",
  ColorF2Yellow: "405",
  ColorF3Blue: "406",
  MediaPause: "19",
  MediaPlay: "415",
  MediaStop: "413",
  MediaRewind: "412",
  MediaFastForward: "417",
} satisfies WebOSRemoteKeyIdKeyCodes;

export function initTvRemote(o: {
  registerKeys: (r: WebOSRemoteKeyIdKeyCodes) => void;
}): void {
  o.registerKeys(webosRemoteKeyIdToKeyboardEventKeyDict);
}
