import { atomWithCompare } from "@sunrise/utils";

type VisibleData = {
  /**
   * What is the earliest visible timeblock in the grid.
   */
  startTime: Date;
  /**
   * What is the latest visible timeblock in the grid.
   */
  endTime: Date;
};

/**
 * A 'stable' atom which will only propagate changes when the dates in the object have changed.
 */
export const visibleTimingsAtom = atomWithCompare<VisibleData | null>(null);

type VisibleChannels = {
  start: number;
  end: number;
};

/**
 * A 'stable' atom which will only propagate when the actual contents have changed.
 *
 * It'll contain the indexes of the channels that are visible on screen.
 * If there's a form of overscan through the virtualizer it'll be included in this value.
 */
export const visibleChannelsAtom = atomWithCompare<VisibleChannels | null>(
  null,
);
