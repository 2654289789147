import { forwardRef } from "react";
import clsx from "clsx";

import * as styles from "../program-box.css";
import { ProgramCover } from "../program-cover/program-cover";
import type { BoxProps } from "../types";

type ChannelBoxProps = BoxProps & {
  "data-position"?: number;
};

export const ChannelBox = forwardRef<HTMLDivElement, ChannelBoxProps>(
  function ForwardRefProgramBox(
    {
      "data-testid": dataTestId = "channel-box",
      "data-focused": dataFocused,
      "data-position": dataPosition,
      focused,
      logoUrl,
      coverImageUrl,
      className,
      children,
    },
    ref,
  ) {
    return (
      <div
        ref={ref}
        className={clsx([
          styles.channelBox,
          focused && styles.channelBoxFocused,
        ])}
        data-focused={dataFocused}
        data-position={dataPosition}
        data-testid={dataTestId}
      >
        {children ? <div className={styles.lock}>{children}</div> : null}
        <ProgramCover
          ref={ref}
          className={className}
          coverImageUrl={coverImageUrl}
          data-focused={focused}
          data-testid={`${dataTestId}-cover`}
          focused={focused}
          kind="channel"
          logoUrl={logoUrl}
        />
      </div>
    );
  },
);
