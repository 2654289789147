import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { queryKeys, type SearchHistoryResult } from "@sunrise/backend-types";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import { searchHistory } from "./search-history-service";

const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * ONE_MINUTE;

/**
 * Contains the most up to date search history.
 * This should be cleared whenever we add new search history.
 * We will attempt to refresh the search history while your search is open.
 */
export const searchHistoryQueryAtom = atomWithSuspenseQuery<
  SearchHistoryResult,
  unknown,
  SearchHistoryResult,
  []
>((get) => {
  const hosts = get(hostsAtom);
  const host = hosts.api;
  if (isNil(host)) throw new Error("api host is not defined");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  return {
    queryKey: queryKeys.searchHistory(get(selectJwtUserToken)),
    queryFn: async () => {
      return searchHistory(host, privateApi);
    },
    staleTime: ONE_HOUR,
    refetchInterval: ONE_MINUTE,
    networkMode: "offlineFirst",
  };
});
