import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";

import type { SearchHistoryResult } from "@sunrise/backend-types";
import { queryKeys } from "@sunrise/backend-types";
import type { UserId } from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectCurrentUserId, selectJwtUserToken } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import { searchHistoryFeatureAtom } from "./search-history-feature.atom";
import { deleteSearchHistory } from "./search-history-service";

export const clearSearchHistoryMutationAtom = atomWithMutation<
  SearchHistoryResult,
  string
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);
  const at = get(selectJwtUserToken);
  const queryKey = queryKeys.searchHistory(at);

  const enabled = get(searchHistoryFeatureAtom);
  const userId = get(selectCurrentUserId);
  if (!userId) throw new Error("User id is not set");

  return {
    mutationKey: ["clear-search-history"],
    mutationFn: () => {
      if (!enabled) {
        return Promise.resolve({
          queries: [],
          user_id: userId as UserId,
        } satisfies SearchHistoryResult);
      }

      return deleteSearchHistory(host, privateApi);
    },
    onSettled: function (data) {
      // We make sure to override the regular search history query's data with what we receive from the backend here.
      // That way we need no additional call to the history.
      queryClient.setQueryData(queryKey, data);
    },
  };
});
