import type { ReactNode, Ref } from "react";
import { forwardRef } from "react";
import clsx from "clsx";

import { type Nullable } from "@sunrise/utils";

import { typography } from "@/styles/typography.css";

import * as styles from "./progres-bar.css";

export type ProgramBreak = {
  start: number;
  length?: number;
  isRecording?: boolean;
};

type ProgressBarProps = CommonProps & {
  height?: number;
  radius?: number;
  liveProgress: Nullable<number>;
  replayProgress?: Nullable<number>;
  breaks?: ProgramBreak[];
};
export const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
  function ProgressBarContent(
    {
      className,
      height,
      radius,
      liveProgress,
      replayProgress,
      breaks,
      "data-testid": dataTestId = "progressBar",
    }: ProgressBarProps,
    ref: Ref<HTMLDivElement>,
  ): ReactNode {
    return (
      <div
        ref={ref}
        className={clsx([styles.defaultStyle, typography.h7.bold, className])}
        data-testid={dataTestId}
        style={{ height, borderRadius: radius }}
      >
        <div
          className={styles.liveProgressStyle}
          data-progress={liveProgress}
          data-testid={`${dataTestId}.liveProgress`}
          style={{ width: `${liveProgress}%`, height, borderRadius: radius }}
        />
        {!!replayProgress && (
          <div
            className={styles.replayProgressStyle}
            data-progress={replayProgress}
            data-testid={`${dataTestId}.replayProgress`}
            style={{
              width: `${replayProgress}%`,
              height,
              borderRadius: radius,
            }}
          />
        )}
        {breaks?.map((br, idx) => (
          <div
            key={`${idx}-${br.start}-${br.length}`}
            className={clsx([
              styles.breakStyle,
              br.isRecording && styles.recordingBreakStyle,
            ])}
            data-testid={`${dataTestId}.break.${idx}`}
            style={{
              width: !br.isRecording ? `${br.length ?? 2}%` : height,
              left: `${br.start}%`,
              height,
            }}
          />
        ))}
      </div>
    );
  },
);
