import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import type { Nullable } from "@sunrise/utils";

import {
  getTizenProductInfo,
  type ProductInfo,
} from "@/modules/tizen/system.service";

import { queryKeys } from "./query-keys";

export const tizenProductInfoAtom = atomWithSuspenseQuery<
  Nullable<ProductInfo>
>(() => {
  return {
    queryKey: queryKeys.productInfo,
    queryFn: async () => {
      try {
        return await getTizenProductInfo();
      } catch {
        return null;
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    keepPreviousData: true,
  };
});
tizenProductInfoAtom.debugLabel = "tizenProductInfoAtom";
