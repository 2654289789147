import type { ReactNode } from "react";
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useVirtualizer } from "@tanstack/react-virtual";
import { addMinutes, isWithinInterval } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { logButtonClickAtom } from "@sunrise/analytics";
import type { ChannelId, EPGEntryId } from "@sunrise/backend-types-core";
import {
  MouseNavigationContext,
  type NavigationId,
  useKeyboardNavigation,
} from "@sunrise/bigscreen";
import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { playerCurrentEpgItemAtom } from "@sunrise/player";
import { nowAtom } from "@sunrise/time";
import { isDefined, isNil } from "@sunrise/utils";
import { programIsPlayingAtTime } from "@sunrise/yallo-epg";
import {
  actionGuideSetGridFocused,
  getProgramsStartingAtTime,
  gridStateAtom,
  guideDataAtom,
  guideWindowAtom,
  pixelsToTime,
  selectorGridCoordinates,
  selectorGuideSelection,
  selectorIsGridFocused,
  timeToPixels,
  useGridVisibleDataChangedChannels,
  useGridVisibleDataChangedTime,
} from "@sunrise/yallo-guide";

import { route } from "@/config/route";
import { SCREEN_HEIGHT_IN_PX } from "@/core/constants";
import { GuideChannels } from "@/features/guide/guide-channels";
import { GuideNowBar } from "@/features/guide/guide-now-bar";
import { TimeBlocksBar } from "@/features/guide/time-blocks-bar";
import { useGridAutoSelectAndScrollAndNavigate } from "@/modules/guide/hooks/use-grid-auto-select-and-scroll-and-navigate";
import { mergeRefs } from "@/utils/merge-refs";
import { isArrowLeftKey, isArrowUpKey } from "@/utils/navigation";

import type { OnBlurRequestFunction } from "../settings/types";
import {
  GUIDE_GRID_CHANNEL_HEIGHT_IN_PX,
  GUIDE_GRID_CHANNELS_COLUMN_WIDTH_IN_PX,
  GUIDE_GRID_TIME_INDICATOR_BAR_HEIGHT_IN_PX,
  GUIDE_PROGRAM_PREVIEW_HEIGHT_IN_PX,
  GUIDE_THROTTLE_ON_LONG_PRESS_IN_MS,
  NOW_BAR_WIDTH_IN_PX,
} from "./constants";
import * as styles from "./guide-grid.css";
import { GuideProgramItem } from "./guide-program-item";

const CHANNEL_COUNT_VISIBLE =
  (SCREEN_HEIGHT_IN_PX -
    GUIDE_PROGRAM_PREVIEW_HEIGHT_IN_PX -
    GUIDE_GRID_TIME_INDICATOR_BAR_HEIGHT_IN_PX) /
  GUIDE_GRID_CHANNEL_HEIGHT_IN_PX;

const TIME_BLOCKS_VISIBLE = 5;
const TIME_BLOCK_SIZE_IN_MINUTES = 30;
const CHANNEL_TIME_FRAME_SIZE_IN_MINUTES = 24 * 60;
const TIME_FRAME_BUFFER = 0.5;
// This means, we want 12 hour of buffer.
// So if the first block in the grid starts at 10:00 I am 2 hours under the buffer for the start.
// That means we need to load in the 24 hours from the day before as well.
const TIME_FRAME_BUFFER_IN_MINUTES =
  CHANNEL_TIME_FRAME_SIZE_IN_MINUTES * TIME_FRAME_BUFFER;

export type GuideGridProps = {
  disableAnimations?: boolean;
  focusKey?: string;
  height?: number;
  onBlurRequest?: OnBlurRequestFunction;
  testId?: string;
  width?: number;
};

export type TimeBlock = {
  id: number;
  date: Date;
};

/**
 * This is purely the grid section of the TV-Guide feature.
 * It should always be kept as a pure component and have nothing to do with state management.
 * All data should be passed to it. All state change requests should be emitted as callbacks.
 *
 * Everything can be wired to the state manager at a higher level.
 *
 * NOTE: The width & height needs to be consistent. As soon as the width / height of the element changes, the virtualizers will get confused.
 */
export function GuideGrid({
  disableAnimations = false,
  focusKey,
  height,
  onBlurRequest,
  testId = "guide-grid",
  width,
}: GuideGridProps): ReactNode {
  const offset = useAtomValue(selectorGridCoordinates);
  const { data } = useAtomValue(guideDataAtom);
  const { startTime, endTime } = useAtomValue(guideWindowAtom);
  const gridRef = useRef<HTMLDivElement>(null);
  const [started, setStarted] = useState(false);
  const dispatchGridAction = useSetAtom(gridStateAtom);
  const selection = useAtomValue(selectorGuideSelection);
  const playingEpgItem = useAtomValue(playerCurrentEpgItemAtom);

  const isProgramFocused = useAtomValue(selectorIsGridFocused);

  const gridHeightInPx = height ?? gridRef.current?.offsetHeight ?? 0;
  const gridWidthInPx = width ?? gridRef.current?.offsetWidth ?? 0;

  const programsAvailableWidth =
    gridWidthInPx - GUIDE_GRID_CHANNELS_COLUMN_WIDTH_IN_PX;
  const timeBlockWidthInPx = Math.floor(
    programsAvailableWidth / TIME_BLOCKS_VISIBLE,
  );

  const channelHeightInPx =
    (gridHeightInPx - GUIDE_GRID_TIME_INDICATOR_BAR_HEIGHT_IN_PX) /
    CHANNEL_COUNT_VISIBLE;

  // This needs to be consistent .... if it is not we somehow don't render the correct width for the programs anymore.
  // NOTE: I attempted to add different count on the virtualizers as long as the width was not stable.
  //       This sometimes worked. But not always. It's best to pass in a fixed height / width into the component.
  const oneMinuteWidthInPx = timeBlockWidthInPx / TIME_BLOCK_SIZE_IN_MINUTES;

  /**
   * Generates an array of timeblocks based on the start and end time and the TIMEBLOCK_SIZE_IN_MINUTES.
   */
  const timeBlocks = useMemo<TimeBlock[]>(() => {
    const rangeInMs =
      new Date(endTime).getTime() - new Date(startTime).getTime();
    const rangeInMinutes = rangeInMs / 1000 / 60;
    const rangeInTimeBlocks = rangeInMinutes / TIME_BLOCK_SIZE_IN_MINUTES;
    const count = Math.floor(rangeInTimeBlocks);

    return new Array(count).fill(null).map((_, index) => {
      return {
        id: index,
        date: addMinutes(startTime, index * TIME_BLOCK_SIZE_IN_MINUTES),
      };
    });
  }, [startTime, endTime]);

  const timeBlockVirtualizer = useVirtualizer({
    count: timeBlocks.length,
    estimateSize: () => timeBlockWidthInPx,
    getScrollElement: () => gridRef.current,
    horizontal: true,
  });

  const channelsVirtualizer = useVirtualizer({
    count: data.length,
    estimateSize: () => channelHeightInPx,
    getScrollElement: () => gridRef.current,
    overscan: 2,
  });

  const virtualChannels = channelsVirtualizer.getVirtualItems();
  const virtualTimeBlocks = timeBlockVirtualizer.getVirtualItems();

  // Whenever the size of the container changes, we need to measure the virtualizers again.
  // Else we would keep the calculations and the grid would look really funny.
  useEffect(() => {
    if (gridHeightInPx > 0) {
      channelsVirtualizer.measure();
    }
  }, [channelsVirtualizer, gridHeightInPx]);
  useEffect(() => {
    if (gridWidthInPx > 0) {
      timeBlockVirtualizer.measure();
    }
  }, [timeBlockVirtualizer, gridWidthInPx]);

  useEffect(() => {
    if (!offset) {
      return;
    }
    // NOTE: Absolutely no idea why this needs to be delayed.
    //       Checked to also do it after the guideRef and timeBlocksRef exist. No difference.
    //       Tried useLayoutEffect. No difference.
    const timeout = setTimeout(() => {
      if (!gridRef.current) {
        return;
      }

      if (offset.x > 0 || offset.y > 0) {
        gridRef.current.scroll({
          left: offset.x,
          top: offset.y,
          behavior: "auto",
        });
        // Only mark started after we have scrolled to an offset.
        setStarted(true);
      }
    }, 10);

    return () => clearTimeout(timeout);
  }, [offset]);

  const channelBarCutoff = offset ? offset.x : undefined;

  // Always disable animations on initial render.
  const shouldDisableAnimations = started ? disableAnimations : true;

  const now = useAtomValue(nowAtom);

  const fullWidth = timeBlockVirtualizer.getTotalSize();

  const {
    focused,
    ref: gridFocusRef,
    focusSelf,
  } = useFocusable({
    focusKey,
    onArrowPress: function handleArrowPress() {
      // Important we return false here.
      // We don't want to suddenly lose focus from the grid in case a navigation didn't work as expected.
      return false;
    },
    onBlur() {
      navigation.flush();
      dispatchGridAction(actionGuideSetGridFocused(false));
    },
    onFocus() {
      dispatchGridAction(actionGuideSetGridFocused(true));
    },
    // It's a boundary because we will be managing the focus manually inside the grid.
    isFocusBoundary: true,
  });

  const dispatchLocation = useSetAtom(locationAtom);
  const onEnter = useAtomCallback(
    useCallback(
      async (get) => {
        const selectionInternal = get(selectorGuideSelection);
        if (!selectionInternal) {
          return;
        }

        const log = get(logButtonClickAtom);

        await log.invoke({
          type: "to_epg_item",
          epgId: selectionInternal.epgId,
        });

        dispatchLocation(
          actionLocationNavigate(
            route.details.root({
              epgId: selectionInternal.epgId,
              assetId: selectionInternal.assetId,
            }),
          ),
        );
      },
      [dispatchLocation],
    ),
  );

  useKeyboardNavigation({
    onBack: () => onBlurRequest?.("back"),
    onEnter,
    onArrow: (direction) => {
      // This is dangerous, I know. But the onArrowPress directions are only up/right/down/left. Which are the NavigationDirections.
      // The idea here is that if we were able to navigate in the grid, we stop all other navigation. Else, we let the normal navigation continue.
      // That means if we can no longer go up because we are at the top channel, we would direct to the onBlurRequest('up').
      if (navigation.navigate(direction as NavigationDirection)) {
        return;
      }

      if (isArrowUpKey(direction) && onBlurRequest) {
        onBlurRequest("up");
        return;
      }

      if (isArrowLeftKey(direction) && onBlurRequest) {
        onBlurRequest("left");
      }
    },
    isEnabled: focused,
    repeatThrottle: GUIDE_THROTTLE_ON_LONG_PRESS_IN_MS,
  });

  const channelToPixels = useCallback(
    (channelId: ChannelId) => {
      const idx = data.findIndex((c) => "id" in c && c.id === channelId);
      if (idx === -1) {
        return 0;
      }

      // NOTE: I tried using the virtualizer to get the virtual item and then the start of it.
      //       But when scrolling fast this would just not be accurate. The virtual item would not be known yet and so it would return 0.
      return channelHeightInPx * idx;
    },
    [channelHeightInPx, data],
  );

  const timeToPixelsFn = useCallback(
    (time: Date) => {
      return timeToPixels(
        time,
        new Date(startTime),
        new Date(endTime),
        fullWidth,
      );
    },
    [startTime, endTime, fullWidth],
  );

  const pixelsToTimeFn = useCallback(
    (pixels: number) => {
      return pixelsToTime(
        pixels,
        new Date(startTime),
        new Date(endTime),
        fullWidth,
      );
    },
    [startTime, endTime, fullWidth],
  );

  const navigation = useGridAutoSelectAndScrollAndNavigate({
    channelHeightInPx,
    data: data.filter((c) => "id" in c),
    height: gridHeightInPx,
    width: gridWidthInPx - GUIDE_GRID_CHANNELS_COLUMN_WIDTH_IN_PX,
    channelToPixels,
    timeToPixels: timeToPixelsFn,
    pixelsToTime: pixelsToTimeFn,
    isWithinGuideRange: useCallback(
      (time) => isWithinInterval(time, { start: startTime, end: endTime }),
      [startTime, endTime],
    ),
  });

  const mouseNavigation = useMemo(
    () => ({
      focusElement: (id: NavigationId) => {
        focusSelf();
        navigation.navigateTo(id as EPGEntryId);
      },
      enterElement: () => {
        onEnter();
      },
    }),
    [navigation, focusSelf, onEnter],
  );

  const nowOffset = useMemo(() => {
    const pixels = timeToPixelsFn(now);

    return (
      // TODO: Is there a way to fix the nowBar styling so that we can place it in the center of its marginLeft?
      pixels - NOW_BAR_WIDTH_IN_PX / 2
    );
  }, [now, timeToPixelsFn]);

  const firstTimeVisible =
    timeBlocks[virtualTimeBlocks[0]?.index ?? -1]?.date.toISOString() ?? null;
  const lastVirtualTimeBlock = virtualTimeBlocks[virtualTimeBlocks.length - 1];
  const lastBlock = isDefined(lastVirtualTimeBlock)
    ? (timeBlocks[lastVirtualTimeBlock.index] ?? null)
    : null;
  const lastTimeVisible = lastBlock?.date.toISOString() ?? null;

  const firstChannelIndexVisible = virtualChannels[0]?.index ?? null;
  const lastChannelIndexVisible =
    virtualChannels[virtualChannels.length - 1]?.index ?? null;

  useGridVisibleDataChangedTime({
    firstTimeVisible,
    lastTimeVisible,
    bufferInMinutes: TIME_FRAME_BUFFER_IN_MINUTES,
  });

  useGridVisibleDataChangedChannels({
    firstChannelIndexVisible,
    lastChannelIndexVisible,
  });

  if (data.length === 0) {
    return null;
  }

  // We wire up the virtualizers to the x/y axis of this container.
  // This container's offset will be constantly changed when navigating.
  return (
    <div
      ref={mergeRefs(gridRef, gridFocusRef)}
      className={styles.scrollContainer}
      data-focused={focused}
      data-testid={testId}
      style={{
        scrollBehavior: shouldDisableAnimations ? "unset" : "smooth",
      }}
    >
      <TimeBlocksBar
        nowOffset={nowOffset}
        style={{
          width: fullWidth,
        }}
        timeBlocks={timeBlocks}
        virtualTimeBlocks={virtualTimeBlocks}
      />

      {/* under the hours bar we render a div that covers the full width and height of the virtualizers.
      So we can already scroll to any position we like. It also contains the sticky channelbar.*/}
      <div
        className={styles.scrollContainerInner}
        style={{
          height: channelsVirtualizer.getTotalSize(),
          width: fullWidth,
        }}
      >
        <Suspense>
          <GuideChannels
            channelHeightInPx={channelHeightInPx}
            channels={data}
            items={virtualChannels}
            size={channelsVirtualizer.getTotalSize()}
          />
        </Suspense>

        <GuideNowBar
          nowOffset={nowOffset}
          size={channelsVirtualizer.getTotalSize()}
        />

        <MouseNavigationContext.Provider value={mouseNavigation}>
          {/* this is a virtualized row per channel */}
          {virtualChannels.map((virtualItem) => {
            const channel = data[virtualItem.index];

            if (isNil(channel)) {
              return null;
            }

            const isChannelSelected =
              !!selection &&
              "id" in channel &&
              channel.id === selection.channelId;

            return (
              <div
                key={virtualItem.key}
                className={styles.channelRow}
                data-testid={`${testId}-channel-${"id" in channel ? channel.id : virtualItem.key}`}
                style={{
                  height: channelHeightInPx,
                  // We need to make sure the width of the channelbar is also the width of the hours.
                  width: fullWidth,
                  transform: `translateY(${virtualItem.start}px)`,
                  willChange: "transform",
                }}
              >
                {/* in every channel row we again loop over the hours virtualizer to
              render the programs associated with that hour for the current channel.
              But they will have an offset releted to the hour. */}
                {virtualTimeBlocks.map((virtualTimeBlockForChannel, i) => {
                  const timeBlock =
                    timeBlocks[virtualTimeBlockForChannel.index];

                  if (isNil(timeBlock) || !("items" in channel)) {
                    return null;
                  }

                  const programs = getProgramsStartingAtTime(
                    timeBlock.date,
                    TIME_BLOCK_SIZE_IN_MINUTES,
                    channel.items,
                    i === 0,
                  );

                  const offsetForHour = virtualTimeBlockForChannel.start;

                  return programs.map((program) => {
                    const isLive = programIsPlayingAtTime(program, now);
                    const isFocused =
                      isProgramFocused &&
                      isChannelSelected &&
                      program.id === selection.epgId;

                    return (
                      <GuideProgramItem
                        key={program.id}
                        channelBarCutoffInPx={channelBarCutoff}
                        channelHeightInPx={channelHeightInPx}
                        // NOTE: In the context of GuideProgramItem, selected means isPlaying.
                        //       Not that it is selected by the user in the grid.
                        disableAnimations={shouldDisableAnimations}
                        fullGuideWidth={fullWidth}
                        isActive={playingEpgItem?.id === program.id}
                        isFocused={isFocused}
                        isLive={isLive}
                        navId={program.id}
                        offset={offsetForHour}
                        oneMinuteWidthInPx={oneMinuteWidthInPx}
                        program={program}
                        timeOffset={timeBlock.date}
                      />
                    );
                  });
                })}
              </div>
            );
          })}
        </MouseNavigationContext.Provider>
      </div>
    </div>
  );
}
