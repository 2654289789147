import {
  endpoints,
  type RecordingBulkDelete,
  type RecordingItemsSearchParams,
  type RecordingsResponse,
} from "@sunrise/backend-types";
import type { PrivateApiClient } from "@sunrise/http-client";

export async function fetchRecordings(
  host: string,
  httpClient: PrivateApiClient,
  searchParam: RecordingItemsSearchParams,
): Promise<RecordingsResponse> {
  return httpClient.get(endpoints.recordingItems(host, searchParam));
}

export async function bulkDeleteRecordings(
  host: string,
  httpClient: PrivateApiClient,
  recordingsToBeDeleted: RecordingBulkDelete[],
): Promise<void> {
  await httpClient.delete<RecordingBulkDelete[]>(
    endpoints.recordingsBulkDelete(host),
    { data: JSON.stringify(recordingsToBeDeleted) },
  );
}
