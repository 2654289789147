import { atom } from "jotai";
import { atomFamily, atomWithReducer } from "jotai/utils";

type ActionSeekForward = {
  type: "seek-forward";
};

type ActionSeekBackward = {
  type: "seek-backward";
};

type ActionSeekReset = {
  type: "reset";
};

export type SeekSpeedAtomState = {
  sequentialSeeks: number;
  direction?: "forward" | "backward";
  lastSeek?: Date;
};

const INITIAL_STATE: SeekSpeedAtomState = {
  sequentialSeeks: 0,
};

const DEFAULT_SPEEDUP_FACTOR = 4;
const MIN_SEQUENTIAL_SEEKS = 10;
const RESET_SEQUENTIAL_SEEKS_AFTER_MS = 1_000;

type SeekSpeedAction = ActionSeekForward | ActionSeekBackward | ActionSeekReset;

function getSequentialSeeks(
  state: SeekSpeedAtomState,
  direction: SeekSpeedAtomState["direction"],
): number {
  const shouldReset =
    state.direction !== direction ||
    Date.now() - (state.lastSeek?.getTime() ?? 0) >
      RESET_SEQUENTIAL_SEEKS_AFTER_MS;
  return shouldReset ? 1 : state.sequentialSeeks + 1;
}

export const seekSpeedAtom = atomWithReducer<
  SeekSpeedAtomState,
  SeekSpeedAction
>(INITIAL_STATE, (state: SeekSpeedAtomState, action: SeekSpeedAction) => {
  switch (action?.type) {
    case "seek-forward": {
      const sequentialSeeks = getSequentialSeeks(state, "forward");
      return {
        direction: "forward",
        sequentialSeeks: sequentialSeeks,
        lastSeek: new Date(),
      };
    }
    case "seek-backward": {
      const sequentialSeeks = getSequentialSeeks(state, "backward");
      return {
        direction: "backward",
        sequentialSeeks: sequentialSeeks,
        lastSeek: new Date(),
      };
    }
    case "reset":
      return INITIAL_STATE;
    default:
      return state;
  }
});

const shouldSpeedUp = (
  state: SeekSpeedAtomState,
  direction: SeekSpeedAtomState["direction"],
) => {
  return state.direction === direction &&
    state.sequentialSeeks >= MIN_SEQUENTIAL_SEEKS
    ? DEFAULT_SPEEDUP_FACTOR
    : null;
};

export const seekFactorAtom = atomFamily(
  (direction: SeekSpeedAtomState["direction"]) =>
    atom((get) => {
      const state = get(seekSpeedAtom);
      return shouldSpeedUp(state, direction);
    }),
);

export const seekFactorForwardAtom = atom((get) => {
  const state = get(seekSpeedAtom);
  return shouldSpeedUp(state, "forward");
});

export const seekFactorBackwardAtom = atom((get) => {
  const state = get(seekSpeedAtom);
  return shouldSpeedUp(state, "backward");
});

export function actionSeekForward(): ActionSeekForward {
  return {
    type: "seek-forward",
  };
}

export function actionSeekBackward(): ActionSeekBackward {
  return {
    type: "seek-backward",
  };
}

export function actionSeekReset(): ActionSeekReset {
  return {
    type: "reset",
  };
}
