import { logEvent as logEventFn } from "firebase/analytics";

import { firebaseAnalytics } from "./firebase-analytics";

export const logEvent = (event: string, params: Record<string, unknown>) => {
  if (!firebaseAnalytics) {
    return;
  }

  logEventFn(firebaseAnalytics, event, {
    ...params,
    client_timestamp: new Date().getTime(),
  });
};
