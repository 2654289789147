import deepEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";

import { recordingGroupQueryAtom } from "./legacy/queries/recording-group.query.atom";

export type SeasonFilter = RecordingGroupId | "all";

/**
 * Ensures that the last selected season asset is still available in the recording group.
 * You can write the preferred season asset id to this atom and it will be checked if it is still available in the recording group.
 * If it is not available we fall back to "all".
 * Else we of course return the preferred season asset id.
 */
export const lastSelectedSeasonAssetBySeriesAssetIdAtom = atomFamily(
  ({
    seriesAssetId,
    recordingGroupId,
  }: {
    seriesAssetId: AssetId;
    recordingGroupId: RecordingGroupId;
  }) => {
    const preferred = atom<SeasonFilter>("all");

    preferred.debugLabel = `selectedSeasonAssetBySeriesAssetIdAtom$preferred(seriesId:${seriesAssetId},recordingGroupId:${recordingGroupId})`;

    const inner = atom(
      async (get) => {
        const current = get(preferred);
        if (current === "all") {
          return current;
        }

        const { data } = await get(recordingGroupQueryAtom(recordingGroupId));

        const hasItem = data.child_groups.some(
          (season) => season.id === current,
        );

        if (!hasItem) {
          return "all";
        }

        return current;
      },
      (_, set, newValue: SeasonFilter) => {
        set(preferred, newValue);
      },
    );

    inner.debugLabel = `selectedSeasonAssetBySeriesAssetIdAtom(seriesId:${seriesAssetId},recordingGroupId:${recordingGroupId})`;

    return inner;
  },
  deepEqual,
);
