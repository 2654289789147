import { atomFamily } from "jotai/utils";

import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";

import { assetAvailableFiltersByAssetIdAtom } from "./asset-available-filters-by-asset-id.atom";
import { assetAvailableFiltersByRecordingGroupIdAtom } from "./asset-available-filters-by-recording-group-id.atom";

type assetAvailableFiltersByIdAtomProps =
  | { assetId: AssetId }
  | { recordingGroupId: RecordingGroupId };

/**
 * Available channels, seasons, availability with count for a specific asset or recording group
 */
export const assetAvailableFiltersByIdAtom = atomFamily(
  (props: assetAvailableFiltersByIdAtomProps) => {
    if ("assetId" in props) {
      return assetAvailableFiltersByAssetIdAtom(props.assetId);
    }
    return assetAvailableFiltersByRecordingGroupIdAtom(props.recordingGroupId);
  },
);
