import { useAtomValue } from "jotai";

import { recordingItemsWithPagingAtom } from "@sunrise/yallo-recordings-overview";

import { RecordingsNetworkIndicator } from "./recordings-network-indicator";

/**
 * A wrapper for the Network Indicator so we do not subscribe to the entire data source at the top of the recordings page.
 */
export function RecordingsOverviewNetworkIndicator() {
  const { isLoadingNextPage } = useAtomValue(recordingItemsWithPagingAtom);

  return <RecordingsNetworkIndicator shouldShow={isLoadingNextPage} />;
}
