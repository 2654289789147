import {
  addDays,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
  subDays,
} from "date-fns";

import {
  GUIDE_WINDOW_DAYS_IN_FUTURE,
  GUIDE_WINDOW_DAYS_IN_PAST,
} from "../guide.constants";

export function isInGuideWindow(
  startTime: Date,
  endTime: Date,
  now: Date,
  daysInPast: number = GUIDE_WINDOW_DAYS_IN_PAST,
  daysInFuture: number = GUIDE_WINDOW_DAYS_IN_FUTURE,
): boolean {
  const startWindow = startOfDay(subDays(now, daysInPast));
  const endWindow = startOfDay(addDays(now, daysInFuture + 1));

  return (
    (isAfter(startTime, startWindow) || isEqual(startTime, startWindow)) &&
    (isBefore(endTime, endWindow) || isEqual(endTime, endWindow))
  );
}
