import { type JSX, useEffect } from "react";
import {
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";
import backgroundImage from "static/background/login.webp";
import logoImage from "static/icons/logo-extended.svg";

import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { useTranslator } from "@sunrise/translator";

import { FocusButton } from "@/components/buttons/focus-button/focus-button";
import { DebugVersion } from "@/components/debug/debug-version";
import { globalFocusKey } from "@/config/focus-key";
import { route } from "@/config/route";
import { useMenu } from "@/features/menu/use-menu";
import { useCloseApp } from "@/features/routing/use-close-app";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";

import * as styles from "./landing.css";

const localWidgetFocusKey = {
  page: globalFocusKey.activePage,
  login: "landing.button.login",
};

export function Landing(): JSX.Element {
  useMenu({ hidden: true });

  const dispatchLocation = useSetAtom(locationAtom);
  const { ref, focused } = useFocusable({
    focusKey: localWidgetFocusKey.page,
    isFocusBoundary: true,
    forceFocus: true,
    preferredChildFocusKey: localWidgetFocusKey.login,
    autoRestoreFocus: true,
    trackChildren: true,
  });

  useCloseApp({ isEnabled: true, onClose: (_, forceClose) => forceClose() });

  const t = useTranslator();

  const canPullFocus = useAtomValue(canPullFocusAtom);
  useEffect(() => {
    if (!canPullFocus) return;
    setFocus(localWidgetFocusKey.login);
  }, [focused, canPullFocus]);

  return (
    <main ref={ref} className={styles.root} data-testid="landing">
      <img className={styles.background} src={backgroundImage} />
      <div className={styles.wrapper}>
        <img className={styles.logo} src={logoImage} />

        <h1 className={clsx(styles.header, styles.headerText)}>
          {t("v2_pin_landing_title")}
        </h1>

        <p className={clsx(styles.paragraph, styles.paragraphText)}>
          {t("v2_pin_landing_description")}
        </p>

        <div className={styles.buttonList}>
          <FocusButton
            className={styles.button}
            data-testid="landing.button.login"
            focusKey={localWidgetFocusKey.login}
            text={t("v2_pin_landing_login_button")}
            onEnterPress={() =>
              dispatchLocation(actionLocationNavigate(route.login.root()))
            }
          />
          <FocusButton
            className={styles.button}
            data-testid="landing.button.register"
            text={t("v2_pin_landing_no_subscription_button")}
            onEnterPress={() =>
              dispatchLocation(actionLocationNavigate(route.register.root()))
            }
          />
        </div>
      </div>
      <DebugVersion />
    </main>
  );
}
