import type { InfiniteData } from "@tanstack/query-core";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { HTTPValidationError } from "@sunrise/backend-ng-channel";
import type { PageAnnotatedUnionChannelSearchResultSchemaEpgEntrySearchResultSchemaRecordingSearchResultSchemaFieldInfoAnnotationNoneTypeRequiredTrueDiscriminatorType as SearchResults } from "@sunrise/backend-ng-search";
import { ngSearchApiAtom, SearchFilters } from "@sunrise/backend-ng-search";
import { queryKeys } from "@sunrise/backend-types";
import { logEvent } from "@sunrise/firebase";

import { mapBackendSearchResults } from "./helpers/map-backend-search-result";

export type MappedNgSearchResult = Omit<SearchResults, "items"> & {
  items: ReturnType<typeof mapBackendSearchResults>[];
};

export const searchFilterAtom = atom<SearchFilters>(SearchFilters.All);

// TODO: This should be rewritten to pull the searchTerm from a shared atom. It should not be an atomFamily.
export const ngSearchByTermAtom = atomFamily((searchTerm: string) => {
  return atomWithInfiniteQuery<
    MappedNgSearchResult,
    HTTPValidationError,
    InfiniteData<MappedNgSearchResult>,
    ReturnType<typeof queryKeys.search>,
    number
  >((get) => {
    const api = get(ngSearchApiAtom);
    const filter = get(searchFilterAtom);

    return {
      initialPageParam: 1,
      queryKey: queryKeys.search(searchTerm, filter),
      queryFn: async ({ pageParam = 1 }) => {
        const { data } = await api.search.searchSearchV1SearchGet({
          query: searchTerm,
          page: pageParam,
          size: 20,
          search_filter: filter,
        });

        logEvent("search", {
          search_term: searchTerm,
          search_page: pageParam,
          search_filter: filter,
        });

        return {
          ...data,
          items: data.items.map(mapBackendSearchResults),
        };
      },
      getNextPageParam: (lastPage) => {
        if (isNil(lastPage) || !lastPage.pages || !lastPage.page)
          return undefined;

        const { page, pages } = lastPage;
        return page < pages ? page + 1 : undefined;
      },
    };
  });
});
