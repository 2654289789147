import { atom } from "jotai";

import type { ChannelId } from "@sunrise/backend-types-core";
import { selectPlayerCurrentChannelId } from "@sunrise/player";
import { startupChannelIdAtom } from "@sunrise/yallo-startup-channel";

export const activeChannelIdAtom = atom<Promise<ChannelId | null>>(
  async (get) =>
    get(selectPlayerCurrentChannelId) ??
    (await get(startupChannelIdAtom)) ??
    null,
);
