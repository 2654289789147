import { useCallback } from "react";
import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import { useAtom, useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { actionDialogOpen, dialogAtom } from "@sunrise/dialogs";
import { useTranslator } from "@sunrise/translator";
import {
  bulkDeletionEnabledAtom,
  hasRecordingsOnOverviewAtom,
  isAllSelectedAtom,
  isBulkDeletionModeAtom,
  recordingsMarkedForBulkDeletionAtom,
  recordingsMarkedForBulkDeletionCountAtom,
  selectedRecordingReferenceAtom,
  useSortRecordingsOverview,
} from "@sunrise/yallo-recordings-overview";

import { FocusButton } from "@/components";
import { localWidgetFocusKey } from "@/routes/recordings/recordings";

import * as styles from "./recordings.css";

const DATA_TEST_ID = "recordings";

export function RecordingsRightMenu() {
  const t = useTranslator();
  const [markedForBulkDeletionRecordings, writeMarked] = useAtom(
    recordingsMarkedForBulkDeletionAtom,
  );
  const hasRecordings = useAtomValue(hasRecordingsOnOverviewAtom);
  const [isBulkDeletionMode, setBulkDeletionMode] = useAtom(
    isBulkDeletionModeAtom,
  );
  const areAllSelected = useAtomValue(isAllSelectedAtom);
  const bulkDeleteRecordings = useAtomCallback(
    useCallback(
      async (get, set) => {
        const count = get(recordingsMarkedForBulkDeletionCountAtom);

        set(
          dialogAtom,
          actionDialogOpen({
            lastFocusKey: "",
            type: "actions",
            title: { key: "confirm_bulk_delete_title" },
            description: {
              key: "confirm_bulk_delete_message",
              params: [count],
              isPlural: count > 1,
            },
            actions: [
              {
                label: { key: "confirm_bulk_delete_button_confirm" },
                action: async () => {
                  await writeMarked({ type: "confirm" });
                },
                key: "confirm",
              },
              {
                label: { key: "button_cancel" },
                action: () => {
                  /* noop */
                },
                key: "cancel",
                initialFocus: true,
              },
            ],
            id: "confirm-bulk-deletion",
          }),
        );
      },
      [writeMarked],
    ),
  );
  const selectAllRecordings = useAtomCallback(
    useCallback(async () => {
      await writeMarked({ type: "select-all" });
    }, [writeMarked]),
  );
  const closeSort = useAtomCallback(
    useCallback((_, set) => {
      // NOTE: after sorting, reset previously focused item (it could be out of screen) and focus list
      set(selectedRecordingReferenceAtom, null);
      setFocus(localWidgetFocusKey.recordings);
    }, []),
  );

  const bulkDeletionEnabled = useAtomValue(bulkDeletionEnabledAtom);
  const deselectAllRecordings = useAtomCallback(
    useCallback(async () => {
      await writeMarked({ type: "deselect-all" });
    }, [writeMarked]),
  );

  const { openSortDialog } = useSortRecordingsOverview({
    onClose: closeSort,
  });

  const shouldShowSelectButton = bulkDeletionEnabled && hasRecordings;

  if (isBulkDeletionMode) {
    return (
      <div className={styles.buttonsContainer}>
        <div className={styles.button}>
          <FocusButton
            active={false}
            data-testid={
              DATA_TEST_ID +
              (areAllSelected
                ? ".deselect-all-bulk-deletion"
                : ".select-all-bulk-deletion")
            }
            focusKey={localWidgetFocusKey.selectAllButton}
            text={t(
              areAllSelected
                ? "recordings_button_deselect_all"
                : "recordings_button_select_all",
            )}
            onEnterPress={
              areAllSelected ? deselectAllRecordings : selectAllRecordings
            }
          />
        </div>
        <div className={styles.button}>
          <FocusButton
            active={false}
            data-testid={DATA_TEST_ID + ".delete-bulk-deletion"}
            disabled={markedForBulkDeletionRecordings.length === 0} // NOTE: if there are no recordings selected or all are deselected
            focusKey={localWidgetFocusKey.deleteButton}
            iconLeft="remove"
            text={t("button_delete")}
            onEnterPress={bulkDeleteRecordings}
          />
        </div>
        <div className={styles.button}>
          <FocusButton
            active={false}
            data-testid={DATA_TEST_ID + ".cancel-bulk-deletion"}
            focusKey={localWidgetFocusKey.cancelButton}
            text={t("button_cancel")}
            onEnterPress={() => setBulkDeletionMode(false)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.buttonsContainer}>
      {hasRecordings && (
        <FocusButton
          data-testid={DATA_TEST_ID + ".change-sort"}
          focusKey={localWidgetFocusKey.sortButton}
          iconLeft="sort"
          text={t("recording_sort")}
          onEnterPress={() => openSortDialog()}
        />
      )}
      {shouldShowSelectButton && (
        <FocusButton
          className={styles.button}
          data-testid={DATA_TEST_ID + ".select-bulk-deletion"}
          focusKey={localWidgetFocusKey.selectButton}
          iconLeft="select"
          text={t("recordings_button_start_selection")}
          onEnterPress={() => setBulkDeletionMode(true)}
        />
      )}
    </div>
  );
}
