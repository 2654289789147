import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";

import { actionDialogClose, dialogAtom, useListDialog } from "@sunrise/dialogs";

import { recordingsOverviewSortableAtom } from "./recordings-overview-sortable.atom";

type UseSortRecordingsProps = {
  onClose?: () => void;
};

type UseSortRecordingsReturn = {
  openSortDialog: () => void;
};

const SORT_RECORDINGS_ID = "sort-recordings";

/**
 * Can be used for the sorting of the recordings overview page.
 */
export function useSortRecordingsOverview({
  onClose,
}: UseSortRecordingsProps): UseSortRecordingsReturn {
  const { showDialog } = useListDialog();

  const openSortDialog = useAtomCallback(
    useCallback(
      async (get, set) => {
        const { options, current } = await get(recordingsOverviewSortableAtom);

        showDialog({
          id: SORT_RECORDINGS_ID,
          title: { key: "recording_sort_title" },
          sections: [
            {
              options,
            },
          ],
          activeOptions: [current?.value],
          buttonTextAlign: "left",
          radioButtons: true,
          actionButtonLabel: { key: "recordings_done_button" },
          onButtonClick: (value) => {
            if (!value) {
              return;
            }
            set(recordingsOverviewSortableAtom, value);

            set(dialogAtom, actionDialogClose({ id: SORT_RECORDINGS_ID }));
            onClose?.();
          },
        });
      },
      [onClose, showDialog],
    ),
  );

  return {
    openSortDialog,
  };
}
