import { useMemo } from "react";
import { useSetAtom } from "jotai";

import { actionLocationNavigate, locationAtom } from "@sunrise/location";

import type { routeParam } from "@/config/route";
import { route } from "@/config/route";

const MINIMUM_HISTORY_LENGTH = 2;

// TODO: Fix the return type when this becomes a response generated from the `route` object.

export function useRoutes() {
  // TODO: Implement history for location state
  const dispatchLocation = useSetAtom(locationAtom);

  // TODO: reduce the route object and its children and make it inherit the types.
  //       For now it's not a generic thing we should first verify that the API is nice or not.
  return useMemo(
    () => ({
      details: {
        root: (params: typeof routeParam.details) => {
          dispatchLocation(actionLocationNavigate(route.details.root(params)));
        },
      },
      detailsSeries: {
        root: (params: typeof routeParam.detailsSeries) => {
          dispatchLocation(
            actionLocationNavigate(route.detailsSeries.root(params)),
          );
        },
      },
      tv: {
        root: () => {
          dispatchLocation(actionLocationNavigate(route.tv.root()));
        },
      },
      landing: {
        root: () => {
          dispatchLocation(actionLocationNavigate(route.landing.root()));
        },
      },
      back: () => {
        if (globalThis.history.length <= MINIMUM_HISTORY_LENGTH) {
          dispatchLocation(actionLocationNavigate("/"));
          return;
        }

        globalThis.history.back();
      },
      recordings: {
        root: () => {
          dispatchLocation(actionLocationNavigate(route.recordings.root()));
        },
      },
      featureFlag: {
        id: (id: string) => {
          dispatchLocation(
            actionLocationNavigate(route.settings.featureFlagsDetails({ id })),
          );
        },
      },
    }),
    [dispatchLocation],
  );
}
