import type { ReactNode } from "react";

import type { Translatable } from "@sunrise/i18n";
import { useTranslatable } from "@sunrise/translator";

import { eventStyle } from "./error-dialog-description.css";

type ErrorDialogDescriptionProps = {
  eventId: string | undefined;
  description: Translatable;
};

export function ErrorDialogDescription({
  eventId,
  description,
}: ErrorDialogDescriptionProps): ReactNode {
  const t = useTranslatable();
  if (!eventId) {
    return t(description);
  }

  return (
    <div>
      <div>
        <span>{t(description)}</span>
      </div>
      <p className={eventStyle}>eventId: {eventId}</p>
    </div>
  );
}
