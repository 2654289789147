import { type ReactNode, useCallback, useEffect } from "react";
import { useAtom } from "jotai";

import { useKeyboardNavigation } from "@sunrise/bigscreen";

import { ChannelList } from "@/features/channel-list/channel-list/channel-list";

import { ChannelGroupsContainer } from "./channel-groups/channel-groups-container";
import { container } from "./channel-list.css";
import {
  actionChannelListClose,
  actionChannelListExpandChannels,
  actionChannelListExpandChannelsAndGroups,
  channelListAtom,
} from "./channel-list.store";
import { channelListSeparator } from "./channel-selector.css";

/**
 * This wraps the ChannelList and the ChannelGroups components. We either show just the ChannelList or both ChannelList and ChannelGroups.
 * We do not want to have the navigator get confused while the groups are open. So we disable navigation on the ChannelList when the groups are open.
 */
export function ChannelSelector({
  "data-testid": dataTestId,
}: {
  ["data-testid"]?: string;
}): ReactNode {
  const [channelList, dispatchChannelList] = useAtom(channelListAtom);
  const showGroups = channelList.expandLevel === "channels-and-groups";

  // TODO: simplify this. It's doing the same thing 3 times.
  useKeyboardNavigation({
    onBack: useCallback(() => {
      dispatchChannelList(
        showGroups
          ? actionChannelListExpandChannels()
          : actionChannelListClose(),
      );
    }, [dispatchChannelList, showGroups]),
    isEnabled: channelList.expandLevel !== "hidden",
  });

  const showHideMore = useCallback(() => {
    dispatchChannelList(
      showGroups
        ? actionChannelListExpandChannels()
        : actionChannelListExpandChannelsAndGroups(),
    );
  }, [dispatchChannelList, showGroups]);

  // We need to make sure to collapse the channel list when it is unmounted.
  // Else it will automatically pop open when it is remounted which is undesired. The focus will be off.
  useEffect(() => {
    return () => {
      dispatchChannelList(actionChannelListClose());
    };
  }, [dispatchChannelList]);

  if (channelList.expandLevel === "hidden") {
    return null;
  }

  // TODO: We can pass ChannelGroups a prop to close itself. Is that a better API?
  return (
    <div className={container} data-testid={dataTestId}>
      <ChannelList data-testid={dataTestId} />
      <div
        className={channelListSeparator}
        data-testid={`${dataTestId}.separator`}
        onClick={showHideMore}
      >
        ...
      </div>
      {showGroups && <ChannelGroupsContainer />}
    </div>
  );
}
