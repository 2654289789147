import { atom } from "jotai";

import { selectPlayerIsLoading } from "@sunrise/player";
import { selectPlayerManagerIsLoading } from "@sunrise/yallo-common-player-manager";

export const shouldShowPlayerLoadingAtom = atom((get) => {
  const isPlayerLoading = get(selectPlayerIsLoading);
  const isPlayerManagerLoading = get(selectPlayerManagerIsLoading);

  return isPlayerLoading || isPlayerManagerLoading;
});
