import { useCallback, useEffect } from "react";
import { useAtom, useAtomValue } from "jotai";

import { selectedChannelGroupIdAtom } from "@sunrise/yallo-channel-group";

import {
  actionChannelListClose,
  actionChannelListExpandChannelsAndGroups,
  channelListAtom,
} from "@/features/channel-list/channel-list.store";
import { InnerChannelList } from "@/features/channel-list/channel-list/inner-channel-list";
import { isChannelListAllowedAtom } from "@/modules/channel-group";

type ChannelListContainerProps = {
  "data-testid"?: string;
};

export function ChannelList({
  "data-testid": dataTestId,
}: ChannelListContainerProps) {
  const [channelList, dispatchChannelList] = useAtom(channelListAtom);
  const channelGroup = useAtomValue(selectedChannelGroupIdAtom);

  const doClose = useCallback(
    () => dispatchChannelList(actionChannelListClose()),
    [dispatchChannelList],
  );

  // Hide itself when we are not allowed to have the channel list open.
  const isAllowed = useAtomValue(isChannelListAllowedAtom);
  useEffect(() => {
    if (!isAllowed) {
      doClose();
    }
  }, [isAllowed, doClose]);

  const doShowMore = useCallback((): boolean => {
    dispatchChannelList(actionChannelListExpandChannelsAndGroups());
    return true;
  }, [dispatchChannelList]);

  return (
    <InnerChannelList
      key={channelGroup} // have a key that is based on the selected channel group, so that the list gets re-rendered when the group changes.
      data-testid={`${dataTestId}.list`}
      doClose={doClose}
      doShowMore={doShowMore}
      shouldFocus={channelList.expandLevel === "channels"}
    />
  );
}
