import type { ComponentProps, JSX } from "react";
import {
  type ArrowPressHandler,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue } from "jotai";

import type { Button } from "@/components";
import { RadioButton } from "@/components";
import { isCursorVisibleAtom } from "@/modules/webos/cursor-visibility.atom";
import { useScrollOnFocus } from "@/utils/use-scoll-on-focus";

type FocusRadioButtonProps = ComponentProps<typeof Button> & {
  onEnterPress: () => void;
  onArrowPress?: ArrowPressHandler;
  scrollOnFocus: boolean;
  focusKey?: string;
  checked?: boolean;
};

export function FocusListRadioButton({
  onEnterPress,
  focusKey,
  scrollOnFocus,
  onArrowPress,
  "data-testid": dataTestId = "focus-list-radio-button",
  active = false,
  ...rest
}: FocusRadioButtonProps): JSX.Element {
  const isCursorVisible = useAtomValue(isCursorVisibleAtom);

  const { ref, focused, focusSelf } = useFocusable({
    onEnterPress,
    focusKey,
    onArrowPress,
  });

  useScrollOnFocus({
    enabled: scrollOnFocus && !isCursorVisible,
    focused,
    ref,
  });

  return (
    <RadioButton
      {...rest}
      ref={ref}
      active={active}
      checked={active}
      data-testid={`${dataTestId}.radio-button`}
      focused={focused}
      onMouseClick={onEnterPress}
      onMouseEnter={isCursorVisible ? focusSelf : undefined}
    />
  );
}
