import { isNil } from "lodash";
import type { DeviceInfo } from "webostvjs";

import { isWebOS, promiseWithTimeout } from "@sunrise/utils";

import { loadWebOSApi } from "./load-webos-api";

async function loadApi() {
  if (!isWebOS()) return false;
  if (isNil(window.webOS)) {
    try {
      await loadWebOSApi();
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  return true;
}

export function closeWebOSApp(): void {
  if (!isWebOS()) return;
  // window.webOS.platformBack(); // this would be a soft exit, it asks if the user wants to close the app
  window.close(); // this is a forced exit
}

export async function getWebOSDeviceInfo(): Promise<DeviceInfo | null> {
  if (!(await loadApi())) {
    return null;
  }

  try {
    return await promiseWithTimeout(
      new Promise((resolve) => {
        window.webOS.deviceInfo((info: DeviceInfo) => resolve(info));
      }),
      2000,
    );
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export async function getWebOSDeviceId(): Promise<string | undefined> {
  if (!(await loadApi())) {
    return;
  }

  return new Promise<string | undefined>((resolve) => {
    window.webOSDev.LGUDID({
      onSuccess: (res) => resolve(res.id),
      onFailure: (error) => {
        console.error(error);
        resolve(undefined);
      },
    });
  });
}

export async function isWebOSSimulator(): Promise<boolean> {
  const deviceInfo = await getWebOSDeviceInfo();
  return deviceInfo?.modelName.includes("_SIMULATOR") ?? false;
}

export async function getWebOSNetwork(): Promise<
  typeof window.webOSDev.connection | undefined
> {
  if (!(await loadApi())) {
    return;
  }

  if (await isWebOSSimulator()) {
    return;
  }

  return window.webOSDev.connection;
}
