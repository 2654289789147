import { atom } from "jotai";

import { selectPlayerCurrentError } from "@sunrise/player";

import { canShowErrorInPlayerAtom } from "./can-show-error-in-player.atom";
import { shouldHideErrorDueToRecoveryFnAtom } from "./should-hide-error-due-to-recovery-fn.atom";

const _playerErrorToShowInPlayerAtom = atom<{ error: Error | null }>((get) => {
  const canShowErrorInPlayer = get(canShowErrorInPlayerAtom);

  if (!canShowErrorInPlayer) return { error: null };

  const error = get(selectPlayerCurrentError);
  if (!error) return { error: null };

  return { error };
});

/**
 * This atom is used to store the error that should be shown in the player.
 * At the moment we only show actual errors set on the player state.
 *
 * This can only happen when the client opts in to show errors in the player through the `canShowErrorInPlayerAtom`.
 */
export const errorToShowInPlayerAtom = atom<{
  error: Error | null;
  hidden: boolean;
}>((get) => {
  const { error } = get(_playerErrorToShowInPlayerAtom);

  if (!error) return { error: null, hidden: false };

  const shouldHideErrorDueToRecovery = get(shouldHideErrorDueToRecoveryFnAtom);
  if (shouldHideErrorDueToRecovery(error)) {
    return { error, hidden: true };
  }

  return { error, hidden: false };
});
