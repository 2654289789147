import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { AssetId } from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";
import { isDefined } from "@sunrise/utils";
import { recordingByIdNgAtom } from "@sunrise/yallo-recordings";

import { MAX_GENRE_TAGS_NUMBER } from "./constants";
import { assetDetailsByIdNgAtom } from "./ng/asset-details-by-id.ng.atom";
import type { EpgOrRecordingId } from "./recording-status.atom";

type AssetTagsByIdAtomAgs = {
  assetId: AssetId;
  epgOrRecordingId: EpgOrRecordingId;
};

type AssetTagsByIdAtomReturn = {
  productionYear: Nullable<string>;
  parentalGuidance: Nullable<string>;
  genres: string[];
};

export const assetTagsByIdAtom = atomFamily((args: AssetTagsByIdAtomAgs) => {
  const innerAtom = atom(async (get): Promise<AssetTagsByIdAtomReturn> => {
    const id = args.epgOrRecordingId;
    const recordingId =
      "recordingId" in id
        ? id.recordingId
        : "recordingGroupId" in id
          ? id.recordingGroupId
          : null;
    if (recordingId) {
      const details = await get(recordingByIdNgAtom(recordingId));
      return {
        genres:
          details.data?.asset.genres
            ?.slice(0, MAX_GENRE_TAGS_NUMBER)
            .map((genre) => genre.name)
            .filter(isDefined) ?? [],
        productionYear: details.data?.asset?.production_year?.toString(),
        parentalGuidance: details.data?.asset.age_recommendation
          ? `${details.data?.asset?.age_recommendation?.toString()}+`
          : null,
      };
    }

    const details = await get(assetDetailsByIdNgAtom(args.assetId));

    return {
      genres: details.data?.genres?.slice(0, MAX_GENRE_TAGS_NUMBER) ?? [],
      productionYear: details.data?.productionYear?.toString(),
      parentalGuidance: details.data?.ageRecomendation,
    };
  });

  innerAtom.debugLabel = `assetStaffByIdAtom(${
    "recordingId" in args ? args.recordingId : args.assetId
  })`;

  return innerAtom;
}, areEqual);
