import { useAtomValue } from "jotai";

import { disableAnimationsAtom } from "@sunrise/yallo-settings";

type UseAnimationReturn = null | (() => void);

export const useListScrolling = (
  element: HTMLElement | undefined,
  scrollRef: React.RefObject<HTMLElement>,
  elHeight: number,
  scrollAfterElWithIdx: number,
): UseAnimationReturn => {
  // Calculate if we should animate the scroll.
  // Even if we are allowed to animate, it's not always a good idea.
  // When the item is completely out of view we just want to jump to it.

  const disableAnimations = useAtomValue(disableAnimationsAtom);
  if (!element) return null;

  const shouldAnimate =
    !disableAnimations &&
    scrollRef.current &&
    Math.abs(scrollRef.current.scrollTop - element.offsetTop) <
      elHeight * scrollAfterElWithIdx;

  return () => {
    element.scrollIntoView({
      behavior: shouldAnimate ? "smooth" : "instant",
      block: "center",
      inline: "center",
    });
  };
};
