import { useCallback, useContext } from "react";
import { useAtomValue } from "jotai";

import { isCursorVisibleAtom } from "../cursor-visibility.atom";
import type { NavigationId } from "../types";
import { MouseNavigationContext } from "./mouse-navigation.context";

export type UseMouseNavigationArgs = {
  navId?: NavigationId;
  onMouseClick?: () => void;
  onMouseEnter?: () => void;
};

export const useMouseNavigation = ({
  navId,
  onMouseClick,
  onMouseEnter,
}: UseMouseNavigationArgs) => {
  const isCursorVisible = useAtomValue(isCursorVisibleAtom);
  const mouseNavigation = useContext(MouseNavigationContext);

  const onClick = useCallback(() => {
    if (!isCursorVisible) return;

    if (onMouseClick) {
      onMouseClick();
    } else {
      mouseNavigation.enterElement(navId);
    }
  }, [isCursorVisible, onMouseClick, mouseNavigation, navId]);

  const onEnter = useCallback(() => {
    if (!isCursorVisible) return;

    if (onMouseEnter) {
      onMouseEnter();
    } else {
      mouseNavigation.focusElement(navId);
    }
  }, [isCursorVisible, onMouseEnter, mouseNavigation, navId]);

  return {
    onClick,
    onEnter,
  };
};
