import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";
import { isNil } from "lodash";

import { endpoints, type PinResponse } from "@sunrise/backend-types";
import { hostsAtom } from "@sunrise/http-client";
import { actionJWTSetTokens, jwtAtom, selectIsLoggedIn } from "@sunrise/jwt";

import { checkIfApproved, pinAtomQuery } from "./qr-code-login.service";

export type UseLoginReturn = {
  pin: PinResponse;
};

export function useQrCodeLogin(
  checkForApprovalMsInterval = 5000,
): UseLoginReturn {
  const queryClient = useQueryClient();
  const dispatchJWT = useSetAtom(jwtAtom);
  const isLoggedIn = useAtomValue(selectIsLoggedIn);
  const pinResponse = useAtomValue(pinAtomQuery);
  const hosts = useAtomValue(hostsAtom);
  const host = hosts.api;

  if (isNil(host)) throw new Error("Missing host");

  useEffect(() => {
    if (isLoggedIn) return;

    const onApprove = (resp: PinResponse): void => {
      window.clearInterval(id);

      if (!resp.access_token) throw new Error("Missing access token");
      if (!resp.refresh_token) throw new Error("Missing refresh token");

      dispatchJWT(
        actionJWTSetTokens({
          accessToken: resp.access_token,
          refreshToken: resp.refresh_token,
          // TODO: Figure out how QR login would work in v2. Only a problem for NG tizen websocket connections.
          wsToken: null,
        }),
      );
    };

    const id = window.setInterval(() => {
      void checkIfApproved(
        endpoints.postPin(host, pinResponse.data.id),
        queryClient,
        onApprove,
      );
    }, checkForApprovalMsInterval);

    return () => {
      window.clearInterval(id);
    };
  }, [dispatchJWT, isLoggedIn, pinResponse, queryClient]);

  return { pin: pinResponse.data };
}
