import { BaseError } from "@sunrise/error";
import { type Translatable } from "@sunrise/i18n";
import type { Store } from "@sunrise/store";
import { type Nullable } from "@sunrise/utils";

import { DIALOG_BUTTONS } from "./dialog-buttons";
import { ERROR_MAPPING } from "./error-mapping";
import type { DialogButtonType, ErrorConfiguration } from "./types";

export type ErrorDialogConfiguration = {
  title: Translatable;
  backBehaviour?: "close-modal" | "kill-app" | "blocked";
  description: Translatable;
  confirmationButton?: DialogButtonType;
  confirmationLabel?: Translatable;
  onConfirm?: () => void;
  rejectionButton?: DialogButtonType;
  rejectionLabel?: Translatable;
  onReject?: () => void;
  focusReject?: boolean;
  id: string;
  isGeneralError: boolean;
  technicalErrorName: string;
};

export type GetErrorButtonFn = (code?: DialogButtonType) => () => void;

export async function getErrorDialogConfiguration(
  error: Error,
  getButtonFn: GetErrorButtonFn,
  store: Store,
  options: {
    /**
     * When this is set to true, we will always return a dialog output and we will never return null.
     */
    forceResult?: boolean;
  } = { forceResult: false },
): Promise<Nullable<ErrorDialogConfiguration>> {
  let errorCode: Nullable<string>;
  if (error instanceof BaseError) {
    errorCode = error.errorCode;
  } else {
    errorCode = null;
  }

  // When there is no errorCode it means it is not one of our known errors.
  // And we should not even attempt to find a dialog mapping for it. We will need to let this error pass through.
  if (!errorCode && !options.forceResult) {
    return null;
  }

  let configuration =
    errorCode && errorCode in ERROR_MAPPING
      ? (ERROR_MAPPING[errorCode] as Nullable<
          ErrorConfiguration | ((store: Store) => Promise<ErrorConfiguration>)
        >)
      : null;

  if (!configuration) {
    configuration = ERROR_MAPPING.GENERAL_ERROR;
  }

  const isGeneralError = configuration === ERROR_MAPPING.GENERAL_ERROR;
  const createConfigurationValue = (
    config: ErrorConfiguration,
  ): ErrorDialogConfiguration => {
    const description = config.description.map((desc) => ({
      key: desc.key,
      params: desc.dynamicParams,
    }));

    const hasConfirmation = config.type !== "blocking";

    return {
      title: { key: config.title },
      description:
        description.length === 1 && description[0]
          ? description[0]
          : description,
      confirmationButton: hasConfirmation
        ? config.confirmationButton
        : undefined,
      confirmationLabel: hasConfirmation
        ? {
            key: DIALOG_BUTTONS[config.confirmationButton].label,
          }
        : undefined,
      onConfirm: hasConfirmation
        ? getButtonFn(config.confirmationButton)
        : undefined,
      rejectionButton: config.rejectionButton,
      rejectionLabel: config.rejectionButton
        ? { key: DIALOG_BUTTONS[config.rejectionButton].label }
        : undefined,
      onReject: config.rejectionButton
        ? getButtonFn(config.rejectionButton)
        : undefined,
      focusReject: config.focusReject,
      backBehaviour: config.type === "blocking" ? "kill-app" : "close-modal",
      id: errorCode ?? "general-error",
      isGeneralError,
      technicalErrorName: config.technicalErrorName,
    };
  };

  if (typeof configuration === "function") {
    const dynamicConfig = await configuration(store);

    return createConfigurationValue(dynamicConfig);
  }

  return createConfigurationValue(configuration);
}
