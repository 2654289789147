import { useEffect } from "react";
import { useAtomValue } from "jotai";

import { usePlayStartupChannel } from "@sunrise/yallo-startup-channel";

import { shouldPlayerAutostartAtom } from "./should-player-autostart.atom";

export function useAutostart(options?: {
  /**
   * Whether we are allowed to autostart.
   * This is used to prevent autostarting before the authentication is complete for example.
   */
  allowedToAutostart: boolean;
}): void {
  const startupChannelPlay = usePlayStartupChannel();
  const shouldAutoStart = useAtomValue(shouldPlayerAutostartAtom);
  const allowedToAutostart = options?.allowedToAutostart ?? true;

  useEffect(
    function setStreamOnLoad() {
      if (shouldAutoStart && allowedToAutostart) {
        startupChannelPlay("autostart");
      }
    },
    [shouldAutoStart, startupChannelPlay, allowedToAutostart],
  );
}
