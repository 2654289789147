import { set } from "date-fns";
import type { Atom } from "jotai";
import { selectAtom } from "jotai/utils";

export const selectAtomPerMinute = <T>(atom: Atom<T>) => {
  return selectAtom(
    atom,
    (state) =>
      state instanceof Date
        ? set(state, { seconds: 0, milliseconds: 0 })
        : state,
    (a, b) =>
      (a instanceof Date && a?.getTime()) ===
      (b instanceof Date && b?.getTime()),
  );
};
