import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { addDays, startOfDay, subDays } from "date-fns";
import { atom, useAtomValue, useStore } from "jotai";
import { isEqual, uniqBy } from "lodash";

import type { ChannelId } from "@sunrise/backend-types-core";
import { type Store } from "@sunrise/store";
import { dateToTimeDay } from "@sunrise/time";
import type { Nullable } from "@sunrise/utils";
import {
  type MappedChannel,
  selectedChannelGroupWithAllChannels,
} from "@sunrise/yallo-channel-group";
import type { MappedEpg } from "@sunrise/yallo-epg";
import { selectEpgCollectionPerDayAtom } from "@sunrise/yallo-epg";

import {
  GUIDE_WINDOW_DAYS_IN_FUTURE,
  GUIDE_WINDOW_DAYS_IN_PAST,
} from "../guide.constants";
import { type GuideChannel } from "../guide.types";
import { epgEntryToGuideProgram } from "../utils/epg-entry-to-guide-program";

export type UseGuideDataOnVisibleDataChangedCallback = (
  channelIds: ChannelId[],
  startTime: Date,
  endTime: Date,
) => void;

type VisibleData = {
  /**
   * When channelIds is a number we need to load the first X channels. Else, just load the channelIds passed in.
   */
  channelIds: ChannelId[] | number;
  startTime: Date;
  endTime: Date;
};

/**
 * Hook that is responsible for loading in the correct data and returning it.
 * It will always return all the channels when we have it.
 * The GuidePrograms may not necessarily be on every channel if the data is not visible.
 *
 * @returns
 */
export function useGuideData({
  daysInPast = GUIDE_WINDOW_DAYS_IN_PAST,
  daysInFuture = GUIDE_WINDOW_DAYS_IN_FUTURE,
  enabled = true,
  initialVisibleData,
}: {
  daysInPast: number;
  daysInFuture: number;
  enabled?: boolean;
  initialVisibleData?: VisibleData;
}): {
  data: GuideChannel[];
  endTime: Date;
  onVisibleDataChanged: UseGuideDataOnVisibleDataChangedCallback;
  startTime: Date;
  initialLoading: boolean;
} {
  const startTime = useMemo(() => {
    return startOfDay(subDays(new Date(), daysInPast));
  }, [daysInPast]);

  const endTime = useMemo(() => {
    return startOfDay(addDays(new Date(), daysInFuture + 1));
  }, [daysInFuture]);

  const [visibleData, setVisibleData] = useState<VisibleData | undefined>(
    initialVisibleData,
  );

  const backendData = useGuideBackendData({
    enabled: enabled,
    visible: visibleData,
  });

  return {
    startTime,
    endTime,
    initialLoading: backendData.initialLoad,
    data: enabled ? backendData.data : [],
    /**
     * Call this to notify the hook that you are interested in this data.
     * The hook will then attempt to provide said data.
     */
    onVisibleDataChanged: useCallback(
      (newChannelIds, newStartTime, newEndTime) => {
        const next = {
          channelIds: newChannelIds,
          startTime: newStartTime,
          endTime: newEndTime,
        };

        setVisibleData((prev) => {
          // Needed to prevent looping.
          if (isEqual(prev, next)) {
            return prev;
          }

          return next;
        });
      },
      [],
    ),
  };
}

const EMPTY_CHANNEL_GROUP: MappedChannel[] = [];
// NOTE: Doing this so we do not call to ask for the channels when the hook is not enabled.
const emptyChannelsAtom = atom(() => EMPTY_CHANNEL_GROUP);

function useGuideBackendData({
  enabled,
  visible,
}: {
  enabled: boolean;
  visible?: VisibleData;
  requiredTimes?: Date[];
}): { data: GuideChannel[]; initialLoad: boolean } {
  const [data, setData] = useState<GuideChannel[]>([]);

  const store = useStore();

  // TODO: Refactor useGuideData to page through the channels as needed.
  const channels =
    useAtomValue(
      enabled ? selectedChannelGroupWithAllChannels : emptyChannelsAtom,
    ) ?? EMPTY_CHANNEL_GROUP;

  const [initialLoad, setInitialLoad] = useState(true);

  const emptyChannels: Readonly<GuideChannel>[] = useMemo(() => {
    if (!enabled) {
      return [];
    }

    return channels.map((channel) => channelToEmptyGuideChannel(channel));
  }, [channels, enabled]);

  const reqCount = useRef(0);

  useEffect((): void => {
    if (!enabled) {
      return;
    }

    const { startTime, endTime, channelIds } = visible ?? {
      channelIds: channels.map((channel) => channel.id),
    };

    // When we have no time indication yet, we should just return the channels with no events yet.
    // Then the grid can figure out what to timings to show.
    if (!startTime || !endTime) {
      setData(emptyChannels);
      return;
    }

    reqCount.current += 1;
    const reqNr = reqCount.current;

    const needsChannel = (channelId: ChannelId, index: number): boolean =>
      needsChannelData(channelIds, index, channelId);

    const promise = mapTimeframeResponses(
      emptyChannels,
      needsChannel,
      startTime,
      endTime,
      store,
    );

    promise
      .then((channelData): void => {
        // NOTE: If it takes longer to load the data we may end up showing old data.
        //       Ideally we do work with a query of some sorts here where we can only set the data that matches the most recent request.
        //       Since we mock the API with MSW we can't control the timings. So we can't really test this.
        if (reqCount.current === reqNr) {
          setData(channelData);
        }
      })
      .catch((): void => {
        /* we are ignoring any load issues here.
           It's actually not possible for this promise to reject.
           Since rejections are already resolved to empty arrays here. (see above) */
      })
      .finally(() => {
        setInitialLoad(false);
      });
  }, [enabled, channels, visible, emptyChannels, store]);

  return {
    data: data.length === 0 ? emptyChannels : data,
    initialLoad,
  };
}

function mapTimeframeResponses(
  channels: GuideChannel[],
  needsChannel: (channelId: ChannelId, index: number) => boolean,
  startTime: Date,
  endTime: Date,
  store: Store,
): Promise<GuideChannel[]> {
  const promisedChannelData: Promise<GuideChannel>[] = channels.map(
    async (channel, i) => {
      const channelNeedsData = needsChannel(channel.id, i);
      if (!channelNeedsData) {
        return channel;
      }

      let current = startTime;
      const epgPromises: Nullable<MappedEpg[]>[] = [];
      do {
        const day = dateToTimeDay(current);

        // Build or fetch the atom for this day + channel.
        const atom = selectEpgCollectionPerDayAtom({
          channelId: channel.id,
          day,
        });

        // Grab all the EpgEntries for this day. If it fails, so be it. Resolve to an empty array.
        epgPromises.push((await store.get(atom)).data);

        current = addDays(current, 1);
      } while (current < endTime);

      const resolvedPromises = await Promise.all(epgPromises);

      return {
        ...channel,
        // NOTE: days can have overlapping epg entries, so all promises combined would contain some items twice.
        // This causes the guide to render the same item multiple times and thus breaking the navigation
        items: uniqBy(
          resolvedPromises.flatMap(
            (entries) =>
              entries?.filter((e) => !!e).map(epgEntryToGuideProgram) ?? [],
          ),
          (program) => program.id,
        ),
      };
    },
  );

  return Promise.all(promisedChannelData);
}

function needsChannelData(
  channelIds: VisibleData["channelIds"],
  index: number,
  channelId: ChannelId,
): boolean {
  return typeof channelIds === "number"
    ? index <= channelIds
    : channelIds.includes(channelId);
}

function channelToEmptyGuideChannel(
  channel: MappedChannel,
): Readonly<GuideChannel> {
  return {
    id: channel.id,
    name: channel.name,
    logo: channel.logo,
    channelNumber: channel.order,
    items: [],
  };
}
