import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { logButtonClickAtom } from "@sunrise/analytics";
import type { ChannelSearchEntry } from "@sunrise/backend-types";
import type { ChannelId } from "@sunrise/backend-types-core";
import { MouseNavigationContext, type NavigationId } from "@sunrise/bigscreen";
import { searchChannelQueryAtom } from "@sunrise/search";
import {
  actionPlayerManagerPlayLiveChannelId,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";

import { useRoutes } from "@/features/routing/use-routes";
import { isArrowLeftKey, isArrowRightKey } from "@/utils/navigation";
import { useScrollOnFocus } from "@/utils/use-scroll-on-focus";

import { ChannelResult } from "./channel-result";
import * as styles from "./channel-search-results.css";

export const ChannelSearchResults = ({
  exitLeft,
  focusKey,
}: {
  exitLeft: () => void;
  focusKey: string;
}) => {
  const channelsQuery = useAtomValue(searchChannelQueryAtom);
  const channels = channelsQuery.isSuccess ? channelsQuery.data : undefined;

  if (!channels || !channels.result.length) {
    return null;
  }

  return (
    <ChannelSearchResultsInner
      channels={channels.result}
      exitLeft={exitLeft}
      focusKey={focusKey}
    />
  );
};

const ChannelSearchResultsInner = ({
  exitLeft,
  focusKey,
  channels,
}: {
  exitLeft: () => void;
  focusKey: string;
  channels: ChannelSearchEntry[];
}) => {
  // No need for this to be in jotai. When you come back to search it's ok to reset the channel carousel.
  // TODO: remember last selected channel id. And scroll to it when we re-visit the page and we still know the last selected id.
  const [focusedChannelId, setFocusedChannelId] = useState<ChannelId | null>(
    null,
  );
  useEffect(() => {
    if (
      !focusedChannelId ||
      !channels.some((channel) => channel.id === focusedChannelId)
    ) {
      setFocusedChannelId(channels[0].id);
    }
  }, [channels, focusedChannelId]);
  const isFirstChannelSelected = focusedChannelId === channels[0]?.id;
  const routes = useRoutes();
  const onEnterPress = useAtomCallback(
    useCallback(
      async (get, set) => {
        if (!focusedChannelId) {
          return;
        }

        const log = get(logButtonClickAtom);
        await log.invoke({
          type: "to_channel_item",
          channelId: focusedChannelId,
        });

        set(
          playerManagerAtom,
          actionPlayerManagerPlayLiveChannelId(focusedChannelId),
        );
        // NOTE: We should not redirect ourselves. The player manager should do that. But there's perhaps an issue with the player manager.
        //       It does a retry on some errors and it will not switch to the player page before that. It intends to show the error message on the page where you are.
        //       The problem is that our retries are probably too long.
        //       So we should fix the player manager to handle this properly. And for now we do an instant redirect.
        routes.tv.root();
      },
      [focusedChannelId, routes.tv],
    ),
  );

  const focus = useFocusable({
    focusKey,
    onArrowPress: (direction) => {
      if (isFirstChannelSelected && isArrowLeftKey(direction)) {
        exitLeft();
        return false;
      }

      if (isArrowLeftKey(direction)) {
        // Select the previously selected channel.
        setFocusedChannelId((prev) => {
          const index = channels.findIndex((channel) => channel.id === prev);
          return channels[index - 1]?.id ?? prev;
        });
        return false;
      }

      if (isArrowRightKey(direction)) {
        // Select the next channel.
        setFocusedChannelId((prev) => {
          const index = channels.findIndex((channel) => channel.id === prev);
          return channels[index + 1]?.id ?? prev;
        });
        return false;
      }

      return true;
    },
    onEnterPress,
  });
  const focusElement = useCallback(
    (channelId: ChannelId) => {
      setFocus(focusKey);
      setFocusedChannelId(channelId);
    },
    [focusKey],
  );
  const navigation = useMemo(
    () => ({
      focusElement: (id: NavigationId) => focusElement(id as ChannelId),
      enterElement: onEnterPress,
    }),
    [focusElement, onEnterPress],
  );

  // TODO: add virtualizer for the channels? We will probably never have enough results to make it worthwhile to virtualize.
  return (
    <div className={styles.outer} data-testid="channel-search-results">
      <MouseNavigationContext.Provider value={navigation}>
        <div ref={focus.ref} className={styles.inner}>
          {channels.map((channel, index) => (
            <ChannelResultItem
              key={index}
              focused={focus.focused && channel.id === focusedChannelId}
              item={channel}
              position={index + 1}
            />
          ))}
        </div>
      </MouseNavigationContext.Provider>
    </div>
  );
};

const ChannelResultItem = ({
  focused,
  item,
  position,
}: {
  focused: boolean;
  item: ChannelSearchEntry;
  position: number;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useScrollOnFocus({
    enabled: true,
    focused,
    ref,
  });

  return (
    <ChannelResult
      ref={ref}
      channel={item}
      data-position={position}
      focused={focused}
    />
  );
};
