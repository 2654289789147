import { useEffect } from "react";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue } from "jotai";

import { isBulkDeletionModeAtom } from "@sunrise/yallo-recordings-overview";

import { useMenu } from "@/features/menu/use-menu";
import { useRoutes } from "@/features/routing/use-routes";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";
import { localWidgetFocusKey } from "@/routes/recordings/recordings";
import { isArrowLeftKey } from "@/utils/navigation";

export function useRecordings() {
  const menu = useMenu();
  const routes = useRoutes();
  const isBulkDeletionMode = useAtomValue(isBulkDeletionModeAtom);

  const { ref, focusKey, focusSelf } = useFocusable({
    focusKey: localWidgetFocusKey.page,
    focusable: true,
    isFocusBoundary: true,
    /**
     * This makes sure that if focusables disappear inside our ref, this focusable gets focused again.
     */
    autoRestoreFocus: true,
    preferredChildFocusKey: isBulkDeletionMode
      ? localWidgetFocusKey.cancelButton
      : localWidgetFocusKey.recordings + ".list",
    onArrowPress: function handleArrowPress(direction) {
      // NOTE: only gets called if there is no RecordingsList rendered
      if (isArrowLeftKey(direction) && isBulkDeletionMode) {
        routes.recordings.root();
      } else {
        menu.expand();
      }
      return false;
    },
    forceFocus: true,
  });

  // Needs to refocus the page whenever we can focus on ourselves again.
  // This is because the modals are not inside the focus tree but outside of it.
  const canFocusSelf = useAtomValue(canPullFocusAtom);
  useEffect(() => {
    if (canFocusSelf) {
      focusSelf();
    }
  }, [canFocusSelf, focusSelf]);

  return {
    focusKey,
    ref,
    focusSelf,
  };
}
