import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import type { RecordingBulkDelete } from "@sunrise/backend-types";
import { recordingsMarkedForDeletionAtom } from "@sunrise/yallo-recordings";

import { bulkDeleteRecordingsMutationAtom } from "../legacy/queries/bulk-delete-recordings.mutation.atom";
import { recordingsLegacyQueryAtom } from "../legacy/queries/recordings.legacy.query.atom";
import { isBulkDeletionModeAtom } from "./is-bulk-deletion-mode.atom";
import {
  canDeselectAllAtom,
  isAllSelectedAtom,
  selectAllModeAtom,
} from "./select-all-to-bulk-delete.atom";

const _recordingsMarkedForBulkDeletionAtom = atom<RecordingBulkDelete[]>([]);

/**
 * An array of recordings that are marked to be deleted.
 *
 * Write to the atom to add items OR to send the deletion to the backend.
 * The atom will take care of synchronising the state as needed.
 */
export const recordingsMarkedForBulkDeletionAtom = atom(
  (get) => {
    get(resetMarkedWhenJumpingOutOfBulkDeleteEffect);
    get(selectAllEffect);
    get(deselectAllEffect);

    return get(_recordingsMarkedForBulkDeletionAtom);
  },
  async (
    get,
    set,
    update:
      | { type: "confirm" }
      | { type: "toggle"; value: RecordingBulkDelete }
      | { type: "select-all" }
      | { type: "deselect-all" },
  ) => {
    switch (update.type) {
      case "toggle": {
        const marked = get(_recordingsMarkedForBulkDeletionAtom);
        const alreadyExists = marked.some(
          (item) => item.id === update.value.id,
        );

        // When it already exists, we need to filter it out.
        if (alreadyExists) {
          set(
            _recordingsMarkedForBulkDeletionAtom,
            marked.filter((v) => v.id !== update.value.id),
          );
          set(selectAllModeAtom, false);
          return;
        }

        set(_recordingsMarkedForBulkDeletionAtom, [...marked, update.value]);
        break;
      }
      case "confirm": {
        const marked = get(_recordingsMarkedForBulkDeletionAtom);

        // Add items to marked as deleted.
        const deleted = { ...get(recordingsMarkedForDeletionAtom) };
        marked.forEach((item) => {
          const { id } = item;
          deleted[id] = true;
        });
        set(recordingsMarkedForDeletionAtom, deleted);

        // Jump out of bulk deletion mode
        set(isBulkDeletionModeAtom, false);

        const deleteAtom = get(bulkDeleteRecordingsMutationAtom);

        // Send items to delete to the backend.
        await deleteAtom.mutateAsync({
          recordings: marked,
        });
        break;

        // TODO: Handle if failure?
      }
      case "select-all": {
        set(selectAllModeAtom, true);
        break;
      }
      case "deselect-all": {
        set(canDeselectAllAtom, true);
        set(selectAllModeAtom, false);
        break;
      }
    }
  },
);

const resetMarkedWhenJumpingOutOfBulkDeleteEffect = atomEffect((get, set) => {
  const inBulkMode = get(isBulkDeletionModeAtom);

  if (!inBulkMode) {
    set(_recordingsMarkedForBulkDeletionAtom, []);
    set(selectAllModeAtom, false);
  }
});

export const recordingsMarkedForBulkDeletionCountAtom = atom<number>((get) => {
  const recordings = get(recordingsLegacyQueryAtom);
  const recordingsIdsMarkedForBulkDeletion = get(
    recordingsMarkedForBulkDeletionAtom,
  ).map((recording) => recording.id);

  const recordingsMarkedForBulkDeletionCount =
    recordings.data?.pages
      .flatMap((page) => page?.items || [])
      .reduce((markedRecordingsCount, recording) => {
        if (recordingsIdsMarkedForBulkDeletion.includes(recording.id)) {
          return (
            markedRecordingsCount +
            ("type" in recording && recording.type === "recordinggroup"
              ? recording.episode_count
              : 1)
          );
        }
        return markedRecordingsCount;
      }, 0) || 0;

  return recordingsMarkedForBulkDeletionCount;
});

/**
 * An effect to fetch all recordings (all pages) and select each of them for deletion.
 */
const selectAllEffect = atomEffect((get, set) => {
  if (get(selectAllModeAtom)) {
    const recordings = get(recordingsLegacyQueryAtom);

    if (recordings.hasNextPage && !recordings.isFetchingNextPage) {
      recordings.fetchNextPage();
    }

    const allRecordingsIds =
      recordings.data?.pages
        .flatMap((page) => page?.items || [])
        .filter((recording) => "type" in recording)
        .map((recording) => ({
          id: recording.id,
          type: recording.type,
        })) || [];

    set(_recordingsMarkedForBulkDeletionAtom, allRecordingsIds);
  }
});

/**
 * An effect to deselect all of the marked recordings.
 * Available only when in select all mode and all recordings are selected.
 */
const deselectAllEffect = atomEffect((get, set) => {
  if (get(selectAllModeAtom)) return;
  if (get(isAllSelectedAtom) && get(canDeselectAllAtom)) {
    set(_recordingsMarkedForBulkDeletionAtom, []);
    set.recurse(canDeselectAllAtom, false);
  }
});
