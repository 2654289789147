import { type ReactNode } from "react";
import clsx from "clsx";
import { format } from "date-fns";
import { atom, useAtomValue } from "jotai";

import { nowAtom } from "@sunrise/time";
import { useTranslator } from "@sunrise/translator";
import { makeRelativeDayOfWeekTranslation } from "@sunrise/yallo-epg";
import {
  selectorGuideSelection,
  selectorIsGridFocused,
} from "@sunrise/yallo-guide";
import { recordingStatusByEpgIdAtom } from "@sunrise/yallo-recordings";

import { RowProgramBox } from "@/components/boxes";
import { GUIDE_THROTTLE_ON_LONG_PRESS_IN_MS } from "@/features/guide/constants";

import * as styles from "./selected-program.css";

const NULL_ATOM = atom(null);

type SelectedProgramProps = CommonProps;

/**
 * Dummy implementation.
 * But it's enough to make the test pass and to give people an idea what is selected and how the full version will work.
 */
export function SelectedProgram({
  "data-testid": dataTestId,
  className,
}: SelectedProgramProps): ReactNode {
  const selection = useAtomValue(selectorGuideSelection);
  const isFocused = useAtomValue(selectorIsGridFocused);
  const now = useAtomValue(nowAtom);
  const t = useTranslator();

  const recState = useAtomValue(
    selection ? recordingStatusByEpgIdAtom(selection.epgId) : NULL_ATOM,
  );

  if (!selection || !isFocused) {
    return null;
  }

  const relativeDay = makeRelativeDayOfWeekTranslation(
    selection.startTime,
    selection.endTime,
    now,
    t,
  );

  const start = format(selection.startTime, "HH:mm");
  const end = format(selection.endTime, "HH:mm");

  return (
    <div
      className={clsx([className, styles.container])}
      data-channel-id={selection.channelId}
      data-end-date={selection.endTime}
      data-epg-id={selection.epgId}
      data-start-date={selection.startTime}
      data-testid={`${dataTestId}.selected-program`}
    >
      <RowProgramBox
        airTime={relativeDay ?? ""}
        coverImageUrl={selection.image}
        description={selection.plot ?? ""}
        durationInMinutes={selection.durationInMinutes}
        end={end}
        logoUrl={""}
        recordingState={recState?.status}
        start={start}
        subtitle={selection.subtitle ?? ""}
        throttleForPreviewInMs={GUIDE_THROTTLE_ON_LONG_PRESS_IN_MS}
        title={selection.title ?? ""}
      />
    </div>
  );
}
