import { atom } from "jotai";

import type { ChannelListItem } from "@sunrise/backend-types";
import { selectedChannelGroupWithPaginatedChannels } from "@sunrise/yallo-channel-group";

/**
 * This atom represents the user's channels in their selected channel group.
 * This list should be shown on the TV page and the Guide page.
 *
 * It's pre-sorted on the backend's order attribute.
 *
 * @deprecated
 *   Use `channelsForChannelListAtom`.
 *
 *   With ng, we have a new atom which will load the channels in a different way.
 *   We will ask the page on which the active channel id is displayed. Then, we get back that page as the first page.
 *   After that, we will position the view so that the active channel is in the center of the screen.
 *   And a virtualizer will ensure we load the necessary pages when we scroll around.
 */
export const channelsOfCurrentSelectedGroupLegacyAtom = atom<
  Promise<{ channels: ChannelListItem[]; fetchNextPage?: () => void }>
>(async (get) => {
  const channels =
    (await get(selectedChannelGroupWithPaginatedChannels))?.channels.map(
      (channel) => ({
        id: channel.id,
        stream: channel.stream,
        channelNumber: channel.order,
        channelLogo: channel.logo,
        channelName: channel.name,
      }),
    ) ?? [];

  return {
    channels,
    fetchNextPage: (await get(selectedChannelGroupWithPaginatedChannels))
      ?.fetchNextPage,
  };
});
channelsOfCurrentSelectedGroupLegacyAtom.debugLabel =
  "channelsOfCurrentSelectedGroupLegacyAtom";
