import type {
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { isNil } from "lodash";

import type { PageWithItems } from "@sunrise/backend-ng-core";

/*
 * Generic selectors for paged endpoints.
 * TODO: Maybe move this to a different package that is more generic for lists. Not specific to recordings.
 */

type InfinitePage<T> = InfiniteQueryObserverResult<
  InfiniteData<PageWithItems<T>>,
  unknown
>;

export function selectHasRecordingsQueryFetchedFirstPage(
  value: InfinitePage<unknown>,
): boolean {
  const pages = value.data?.pages;
  if (isNil(pages)) return false;

  const pagesCount = pages.length ?? 0;
  const entriesCount =
    pages
      .flatMap((it) => it?.items.length ?? 0)
      .reduce((sum, val) => sum + val, 0) ?? 0;

  return pagesCount > 0 && entriesCount > 0;
}

export function selectRecordingsData<T>(value: InfinitePage<T>): T[] {
  return value.data?.pages.flatMap((p) => p?.items ?? []) ?? [];
}

export function selectRecordingsIsFetchingInitialData(
  value: InfinitePage<unknown>,
): boolean {
  const pagesCount = value.data?.pages.length ?? 0;
  const isFetching = value.isFetching;
  return isFetching && pagesCount === 0;
}
