import { forwardRef, memo, type ReactNode, useMemo } from "react";
import areEqual from "fast-deep-equal";
import { atom, useAtomValue } from "jotai";

import type { AssetId } from "@sunrise/backend-types-core";
import { seasonAndEpisodeByAssetAndSeriesAssetIdAtom } from "@sunrise/details";
import { type Nullable } from "@sunrise/utils";
import { useChannelDetails } from "@sunrise/yallo-channel";
import {
  continueWatchingDataByEpgIdAtom,
  getContinueWatchingProgress,
} from "@sunrise/yallo-continue-watching";
import { useEpgRelativeTime } from "@sunrise/yallo-epg";
import type { GenericSingleRecording } from "@sunrise/yallo-recordings";
import {
  isBulkDeletionModeAtom,
  isRecordingMarkedForBulkDeletionAtom,
} from "@sunrise/yallo-recordings-overview";

import { RecordingProgramBox } from "@/components/boxes";
import { programBoxSize } from "@/config/size";
import { makePosterImgUrl } from "@/utils/image";

const NULL_ATOM = atom(null);

export type RecordingItemProps = CommonProps & {
  seriesAssetId?: Nullable<AssetId>;
  focused: boolean;
  recording: GenericSingleRecording;
  active?: boolean;
  index?: number;
};

/**
 * When we show Continue-Watching and we favour playout of replay over recordings ...
 * then the CW data should come from the replay item and not from the recordings item.
 */
export const RecordingItemSingle = memo(
  forwardRef<HTMLDivElement, RecordingItemProps>(function RecordingItem(
    {
      "data-testid": dataTestId = "RecordingItem",
      className,
      recording,
      seriesAssetId,
      ...props
    },
    ref,
  ): ReactNode {
    const { channelLogo } = useChannelDetails({
      channelId: recording.channelId,
      logo: "channelLogo" in recording ? recording.channelLogo : null,
      name: "channelName" in recording ? recording.channelName : null,
    });

    const { formattedStart, formattedEnd, airTime, liveProgress, expiresIn } =
      useEpgRelativeTime({
        startDate: recording.epg_start,
        endDate: recording.epg_end,
        // TODO: wire up recording expiration
        expiresAt: null,
      });

    const isBulkDeletionMode = useAtomValue(isBulkDeletionModeAtom);
    const isMarkedForDeletion = useAtomValue(
      isRecordingMarkedForBulkDeletionAtom(recording.id),
    );

    const hasEpgEntryId = "epgEntryId" in recording;
    const cwData = useAtomValue(
      hasEpgEntryId && recording.epgEntryId
        ? continueWatchingDataByEpgIdAtom(recording.epgEntryId)
        : NULL_ATOM,
    );

    const continueWatchingProgress = useMemo(() => {
      return getContinueWatchingProgress({
        continueWatchingItem: cwData?.continueWatchingItem,
        epgEndTime: recording.epg_end,
        epgStartTime: recording.epg_start,
        isFullyWatched: cwData?.isFullyWatched ?? false,
        paddingTimesInMinutes: {
          end: recording.paddingEndMinutes ?? 0,
          start: recording.paddingStartMinutes ?? 0,
        },
      });
    }, [cwData, recording]);

    const seasonAndEpisode = useAtomValue(
      recording.type === "episode" && seriesAssetId
        ? seasonAndEpisodeByAssetAndSeriesAssetIdAtom({
            assetId: recording.assetId,
            seriesAssetId,
          })
        : NULL_ATOM,
    );

    return (
      <RecordingProgramBox
        ref={ref}
        airTime={airTime}
        className={className}
        coverImageUrl={makePosterImgUrl(
          recording.posterUrl,
          programBoxSize.recordings.image.width,
          programBoxSize.recordings.image.height,
        )}
        data-focused={props.focused}
        data-testid={dataTestId}
        end={formattedEnd}
        endDate={recording.epg_end}
        expiresIn={expiresIn}
        focused={props.focused}
        index={props.index}
        isBulkDeletionMode={isBulkDeletionMode}
        isMarkedForDeletion={isMarkedForDeletion}
        liveProgress={liveProgress}
        logoUrl={channelLogo}
        recordingState={recording.recordingStatus}
        replayProgress={continueWatchingProgress}
        seasonAndEpisode={seasonAndEpisode}
        start={formattedStart}
        startDate={recording.epg_start}
        subtitle={recording.subtitle}
        title={recording.title}
        type={recording.type === "episode" ? "episode" : null}
      />
    );
  }),
  areEqual,
);
