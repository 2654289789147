import { type ReactNode, useMemo } from "react";
import clsx from "clsx";

import { type Nullable } from "@sunrise/utils";

import * as styles from "./channel-logo.css";

export type ChannelLogoProps = CommonProps & {
  url: Nullable<string>;
  className?: string;
  big?: boolean;
};

const DEFAULT_SEARCH_PARAMS = {
  dpr: "2",
  auto: "format",
  w: "50",
  h: "30",
  fit: "clip",
};

export function ChannelLogo(props: ChannelLogoProps): ReactNode {
  const url = useMemo(() => {
    if (!props.url) {
      return "";
    }

    const imageUrl = new URL(props.url);

    return `${imageUrl.href}?${new URLSearchParams({
      ...Object.fromEntries(imageUrl.searchParams),
      ...DEFAULT_SEARCH_PARAMS,
    }).toString()}`;
  }, [props.url]);

  return (
    <div
      className={clsx([
        styles.channelLogo,
        props.className,
        props.big && styles.big,
      ])}
      data-testid={props["data-testid"]}
      style={{
        backgroundImage: `url(${url})`,
      }}
    />
  );
}
