import { type ReactNode } from "react";
import clsx from "clsx";

import { Clock } from "@/features/clock/clock";

import * as styles from "./header.css";

type HeaderProps = {
  left?: ReactNode;
  right?: ReactNode;
} & CommonProps;

export function Header({
  "data-testid": dataTestId = "header",
  className,
  left,
  right,
  style,
}: HeaderProps): ReactNode {
  return (
    <div
      className={clsx(styles.root, className)}
      data-testid={dataTestId}
      style={style}
    >
      <div className={styles.slot}>{left}</div>
      <div className={clsx(styles.slot, styles.rightSlot)}>
        {right}
        <Clock className={styles.clock} />
      </div>
    </div>
  );
}
