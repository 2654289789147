import { useCallback } from "react";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import type { RecordingGroupSort } from "@sunrise/backend-types";
import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import {
  actionDialogClose,
  actionDialogOpen,
  dialogAtom,
} from "@sunrise/dialogs";
import { recordingsSortForSeriesAssetIdAtom } from "@sunrise/yallo-recordings-series";

import { recordingsListSelectedAtom } from "./recordings-list-selected.atom";

const SORT_OPTIONS: (RecordingGroupSort & { label: string })[] = [
  {
    field: "order_by_end_time",
    direction: "desc",
    label: "recording_sort_newest_aired",
  },
  {
    field: "order_by_end_time",
    direction: "asc",
    label: "recording_sort_oldest_aired",
  },
  {
    field: "order_by_episode",
    direction: "asc",
    label: "recording_sort_episodes_asc",
  },
  {
    field: "order_by_episode",
    direction: "desc",
    label: "recording_sort_episodes_desc",
  },
];

function getSortValue(sort: RecordingGroupSort): string {
  return `${sort.field}-${sort.direction}`;
}

const SORT_RECORDINGS_ID = "sort-series-recordings";

/**
 * A hook which will give you a function to open a dialog that changes the sorting for this series / recording group combo.
 */
export function useDetailsSeriesSort({
  seriesAssetId,
  recordingGroupId,
}: {
  seriesAssetId: AssetId;
  recordingGroupId: RecordingGroupId;
}): () => void {
  const selectedAtom = useAtomValue(
    recordingsListSelectedAtom({ seriesAssetId, recordingGroupId }),
  );

  return useAtomCallback(
    useCallback(
      (get, set) => {
        const current = get(
          recordingsSortForSeriesAssetIdAtom({ seriesAssetId }),
        );

        set(
          dialogAtom,
          actionDialogOpen({
            type: "list",
            id: SORT_RECORDINGS_ID,
            lastFocusKey: "",
            sections: [
              {
                options: SORT_OPTIONS.map((item) => ({
                  value: getSortValue(item),
                  label: { key: item.label },
                })),
              },
            ],
            radioButtons: true,
            onButtonClick(value) {
              const found = SORT_OPTIONS.find(
                (item) => getSortValue(item) === value,
              );
              if (found) {
                set(recordingsSortForSeriesAssetIdAtom({ seriesAssetId }), {
                  field: found.field,
                  direction: found.direction,
                });
              }

              // We should clear the currently selected item.
              // Effects don't really work on families so we need to do that manually here.
              set(selectedAtom, null);

              // Instantly close the dialog since we changed the sorting anyway.
              set(dialogAtom, actionDialogClose({ id: SORT_RECORDINGS_ID }));
            },
            title: { key: "recording_sort_title" },
            activeOptions: [getSortValue(current)],
          }),
        );
      },
      [seriesAssetId, selectedAtom],
    ),
  );
}
