import type { Nullable } from "@sunrise/utils";

export function makePosterImgUrl(
  url: Nullable<string>,
  w: number,
  h: number,
): Nullable<string> {
  if (!url) return null;
  const result = new URL(url);
  result.searchParams.set("w", w.toString());
  result.searchParams.set("h", h.toString());
  result.searchParams.set("fm", "jpg");
  result.searchParams.set("crop", "focalpoint");
  result.searchParams.set("fp-y", "0.39");
  result.searchParams.set("fp-z", "1");

  return result.href;
}

export function makeEpgImgUrl(
  url: Nullable<string>,
  w: number,
  h: number,
): Nullable<string> {
  if (!url) return null;
  const result = new URL(url);
  result.searchParams.set("dpr", "2");
  result.searchParams.set("auto", "format");
  result.searchParams.set("facepad", "10");
  result.searchParams.set("fit", "facearea");
  result.searchParams.set("w", w.toString());
  result.searchParams.set("h", h.toString());

  return result.href;
}
