import { useCallback, useEffect } from "react";
import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue, useSetAtom } from "jotai";
import backgroundImage from "static/background/beta.webp";
import logoImage from "static/icons/logo-beta.svg";

import { actionDialogOpen, dialogAtom } from "@sunrise/dialogs";
import { nowAtom } from "@sunrise/time";
import { useTranslator } from "@sunrise/translator";
import { platformAtom } from "@sunrise/yallo-settings";

import * as styles from "./beta.css";

export function useBeta() {
  const now = useAtomValue(nowAtom);
  const t = useTranslator();
  const dispatchDialog = useSetAtom(dialogAtom);
  const platform = useAtomValue(platformAtom);

  const markBetaScreenAsShown = useCallback((currentDate: string) => {
    localStorage.setItem("beta-test-dialog-shown-date", currentDate);
  }, []);

  useEffect(() => {
    // Do not show the beta dialog on Tizen devices
    if (platform === "tizen") {
      return;
    }

    const isBetaTestDialogAlreadyShown = localStorage.getItem(
      "beta-test-dialog-shown-date",
    );
    const currentDate = JSON.stringify(now.toISOString().split("T")[0] ?? "");

    if (isBetaTestDialogAlreadyShown === currentDate) {
      return;
    }

    const betaTestDialogDescription = (
      <div className={styles.description}>
        <p className={styles.descriptionPart}>
          {t("beta_dialog_description_pt_1")}
        </p>
        <p>{t("beta_dialog_description_pt_2")}</p>
      </div>
    );
    const markAsShown = (): void => markBetaScreenAsShown(currentDate);

    dispatchDialog(
      actionDialogOpen({
        type: "actions",
        title: t("beta_dialog_title"),
        description: {
          type: "component",
          component: betaTestDialogDescription,
        },
        backBehaviour: "blocked",
        actions: [
          {
            label: t("get_started"),
            action: markAsShown,
            key: "start",
            initialFocus: true,
          },
        ],
        textAlignment: "left",
        lastFocusKey: getCurrentFocusKey(),
        bgImage: backgroundImage,
        logo: logoImage,
        id: "beta-startup-screen-dialog",
      }),
    );
  }, [dispatchDialog, markBetaScreenAsShown, now, t, platform]);
}
