import { atom } from "jotai";

import type { AdBreakMarker } from "@sunrise/backend-types";
import {
  playerCurrentEpgItemAtom,
  playerCurrentStreamAtom,
} from "@sunrise/player";

import { ffwdAdsEnabledAtom } from "./ffwd-ads-enabled.atom";

/**
 * Returns a list of markers for the current EPG item.
 * These markers indicate where the fast-forward ads are.
 *
 * The result is always sorted by start time.
 */
export const ffwdMarkersAtom = atom(async (get) => {
  const isEnabled = get(ffwdAdsEnabledAtom);
  if (!isEnabled) return null;

  const stream = get(playerCurrentStreamAtom);

  if (stream && "markers" in stream && "offset" in stream) {
    const { markers, offset } = stream;
    if (!markers) return null;

    const data = await get(playerCurrentEpgItemAtom);
    if (!data) {
      return null;
    }

    const start = new Date(data.actualStart).getTime();
    const end = new Date(data.actualEnd).getTime();

    return markers
      .reduce<AdBreakMarker[]>((acc, marker) => {
        return (
          marker.ad_breaks?.reduce((acc, adBreak) => {
            const adStart = new Date(adBreak.start + offset);
            const adEnd = new Date(adBreak.end + offset);

            if (adStart.getTime() >= start && adEnd.getTime() <= end) {
              acc.push({ start: adStart, end: adEnd });
            }

            return acc;
          }, acc) ?? acc
        );
      }, [])
      .sort((a, b) => a.start.getTime() - b.start.getTime());
  }

  return null;
});
