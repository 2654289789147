import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import {
  actionDialogClose,
  dialogAtom,
  selectCurrentlyOpenedDialog,
} from "@sunrise/dialogs";
import { errorAtom } from "@sunrise/error";
import { PlayerError, selectPlayerIsSuspended } from "@sunrise/player";
import { isOfflineAtom } from "@sunrise/process-visibility";
import {
  selectRecoveryHasFailed,
  selectRecoveryIsOngoing,
} from "@sunrise/yallo-common-player-manager";
import {
  networkConnectionErrorEnabledAtom,
  OfflineError,
} from "@sunrise/yallo-error-mapping";

/**
 * Determines which error to show to endusers in the dialog.
 *
 * It's possible some errors are not shown.
 */
export const errorToShowInDialogAtom = atom<{
  error: Error | null;
  hidden: boolean;
}>((get) => {
  get(effectRemoveOfflineErrorModal);

  // When we are offline, we immediately show that we are offline.
  // But only do this when we are configured to do so.
  if (get(networkConnectionErrorEnabledAtom) && get(isOfflineAtom)) {
    return { error: new OfflineError(), hidden: false };
  }

  const error = get(errorAtom);
  const isPlayerError = error instanceof PlayerError;
  const isAutorecovering = get(selectRecoveryIsOngoing);

  // When we are not autorecovering but we see a SuspendedError, we will ONLY show it when we already failed a recovery for this error.
  const shouldHideErrorBeforeRecovery = (): boolean => {
    const hasFailedToRecover = get(selectRecoveryHasFailed);

    return isPlayerError && error.shouldAutoRecover && !hasFailedToRecover;
  };

  // Suppress any player error when auto recovering OR when the player is stopped.
  // NOTE: We still want to log it to Sentry somehow. But wrapped as a different error.
  if (
    isPlayerError &&
    (isAutorecovering ||
      get(selectPlayerIsSuspended) ||
      shouldHideErrorBeforeRecovery())
  ) {
    return { error, hidden: true };
  }

  return { error: error ?? null, hidden: false };
});

/**
 * When we have an offline error present in the dialog, we want to remove it when we are no longer offline.
 */
const effectRemoveOfflineErrorModal = atomEffect((get, set) => {
  const isOffline = get(isOfflineAtom);
  const dialog = get(selectCurrentlyOpenedDialog);

  if (
    !isOffline &&
    dialog?.type === "actions" &&
    dialog.technicalErrorName === "no_internet_connection"
  ) {
    // We also want to clear any underlying errors.
    // They were probably caused by being offline anyway.
    set(errorAtom, null);
    set(dialogAtom, actionDialogClose({ id: dialog.id }));
  }
});
