import { atomEffect } from "jotai-effect";

import {
  processVisibilityAtom,
  selectProcessIsBackgrounded,
} from "@sunrise/process-visibility";

import { msInBackgroundUntilReloadToRootAtom } from "./ms-in-background-until-reload-to-root.atom";

/**
 * An atom effect which will trigger a reload of the application.
 *
 * The current rule is that after 10m of being in the background, the application will reload to the initial screen for the current user.
 */
export const autoReloadAtom = atomEffect((get) => {
  if (!("location" in window)) {
    return;
  }

  // Nothing has to happen when we are backgrounded.
  const isBackgrounded = get(selectProcessIsBackgrounded);
  if (isBackgrounded) return;
  const msInBackground = get(msInBackgroundUntilReloadToRootAtom);
  if (!msInBackground) return;

  const visibility = get(processVisibilityAtom);

  // When there is no backgroundedAt time it means we did not spend any time in the background yet.
  if (!visibility.lastBackgroundedAt) return;

  const timeSpentInBackgroundInMs =
    visibility.lastForegroundedAt.getTime() -
    visibility.lastBackgroundedAt.getTime();

  if (timeSpentInBackgroundInMs >= msInBackground) {
    window.location.href = "/";
  }
});
