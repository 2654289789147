import { atom } from "jotai";

import { selectShouldShowWhatIsNext } from "@sunrise/what-is-next";

import { tvIsFocusedAtom } from "@/modules/tv/tv-is-focused.atom";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";

export const canPullFocusToWhatIsNextAtom = atom((get) => {
  const canPullFocus = get(canPullFocusAtom);
  const tvIsFocused = get(tvIsFocusedAtom);
  const shouldShowWhatIsNext = get(selectShouldShowWhatIsNext);

  return canPullFocus && tvIsFocused && shouldShowWhatIsNext;
});
