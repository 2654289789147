import type { ReactNode } from "react";

import type { RecordingState } from "@sunrise/backend-types";
import type { TimeInfo } from "@sunrise/details";
import { useTranslator } from "@sunrise/translator";
import { isWithinReplayWindow } from "@sunrise/yallo-replay";

import { InfoBox } from "@/components/boxes/info-box/info-box";

export function shouldShowOutOfReplayMessage(
  recordingStatus: RecordingState | undefined,
  time: TimeInfo,
  now: Date,
): boolean {
  const inFuture = now <= time.start;

  return (
    !inFuture &&
    recordingStatus !== "recorded" &&
    !isWithinReplayWindow(time.start, now).isInReplayWindow
  );
}

export function OutOfReplayWindow(): ReactNode {
  const t = useTranslator();

  return <InfoBox>{t("detail_page_out_of_replay_window_text")}</InfoBox>;
}
