import { type JSX, useEffect } from "react";
import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useAtomValue } from "jotai";

import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import { useTranslator } from "@sunrise/translator";
import { deviceInfo } from "@sunrise/utils";
import { recordingItemsWithPagingForRecordingGroupIdAtom } from "@sunrise/yallo-recordings-series";

import { PageSpinner } from "@/components";
import { useMenu } from "@/features/menu/use-menu";
import { recordingsListSelectedAtom } from "@/modules/details/recordings-list-selected.atom";
import { typography } from "@/styles/typography.css";

import { programBoxSize } from "../../config/size";
import { List } from "./list";
import * as styles from "./recordings-list.css";

export type ConnectedRecordingsSeriesProps = CommonProps & {
  focusKey: string;
  recordingGroupId: RecordingGroupId;
  seriesAssetId: AssetId;
  onExitUp?: (column: number) => void;
};

export function RecordingsSeriesList({
  "data-testid": dataTestId = "RecordingsList",
  focusKey,
  recordingGroupId,
  seriesAssetId,
  onExitUp,
}: ConnectedRecordingsSeriesProps): JSX.Element {
  useMenu({ hidden: true });

  const dataAtom = recordingItemsWithPagingForRecordingGroupIdAtom({
    recordingGroupId,
    seriesAssetId,
  });

  const data = useAtomValue(dataAtom);

  const selectedAtom = useAtomValue(
    recordingsListSelectedAtom({ seriesAssetId, recordingGroupId }),
  );

  const { hasItems, isLoading } = data;
  useEffect(() => {
    if (hasItems) {
      setFocus(focusKey);
    }
  }, [focusKey, hasItems]);

  // NOTE: fix for Chrome < 69 - layout full of flexboxes is too much for it
  // NOTE: if e.g. the title has 2 lines, the size will still be reduced by the browser to match the body
  const chromeVersion = deviceInfo.getMajorBrowserVersion();
  const isLegacyWebOSChrome = chromeVersion && chromeVersion < 69;

  if (isLoading) {
    return (
      <PageSpinner
        data-testid="recording-group-items-spinner"
        style={{
          minHeight: isLegacyWebOSChrome ? "unset" : undefined,
        }}
      />
    );
  }

  if (hasItems) {
    return (
      <div
        className={styles.listContainer}
        style={{
          height: isLegacyWebOSChrome
            ? programBoxSize.recordings.height + 180
            : undefined,
        }}
      >
        <List
          data-testid={dataTestId}
          focusKey={focusKey}
          queryAtom={dataAtom}
          selectedAtom={selectedAtom}
          seriesAssetId={seriesAssetId}
          onExitUp={onExitUp}
        />
      </div>
    );
  }

  return <EmptyInformation data-testid={dataTestId} />;
}

type EmptyInformationProps = CommonProps;

function EmptyInformation({
  "data-testid": dataTestId,
  className,
  ...props
}: EmptyInformationProps): JSX.Element {
  const t = useTranslator();
  return (
    <p
      {...props}
      className={clsx([className, styles.empty, typography.h6.regular])}
      data-testid={`${dataTestId}.no-recordings`}
    >
      {t("recordings_nothing_found")}
    </p>
  );
}
