import type { ReactElement } from "react";
import { Suspense, useCallback } from "react";
import { captureException } from "@sentry/react";
import { useAtomCallback } from "jotai/utils";

import { logButtonClickAtom } from "@sunrise/analytics";
import { LoggedOutError } from "@sunrise/backend-core";
import { actionJWTClear, jwtAtom } from "@sunrise/jwt";
import { useTranslator } from "@sunrise/translator";

import { FocusButton, Spinner } from "@/components";
import { globalFocusKey } from "@/config/focus-key";
import { useMenu } from "@/features/menu/use-menu";
import { useRoutes } from "@/features/routing/use-routes";
import { SettingsBase } from "@/features/settings/settings-base";
import { closeApp } from "@/modules/platform/system.service";
import * as settingsStyle from "@/routes/settings.css";
import { FocusContainer } from "@/utils/focus-container";

import { buttons, root } from "./profile.css";
import { ProfileSubscription } from "./profile-subscription";

export function Profile(): ReactElement {
  useMenu({ hidden: true });
  const routes = useRoutes();
  const t = useTranslator();

  const doLogOut = useAtomCallback(
    useCallback(async (get, set) => {
      const log = get(logButtonClickAtom);

      await log.invoke({
        type: "do_logout",
      });

      captureException(new LoggedOutError("user_requested_logout"));
      set(jwtAtom, actionJWTClear());
    }, []),
  );

  return (
    <SettingsBase
      className={root}
      data-testid="profile"
      titleLabel="menu_profile"
    >
      <Suspense fallback={<Spinner data-testid="profile-spinner" />}>
        <ProfileSubscription />
      </Suspense>

      <FocusContainer
        focusKey={globalFocusKey.activePage}
        shouldFocus
        onBack={routes.back}
      >
        {() => (
          <div className={buttons}>
            <FocusButton
              className={settingsStyle.settingsButton}
              data-testid="profile.button.logout"
              text={t("button_logout")}
              variant="ghost"
              onEnterPress={doLogOut}
            />
            <FocusButton
              className={settingsStyle.settingsButton}
              data-testid="profile.button.close"
              text={t("button_close_app")}
              variant="ghost"
              onEnterPress={() => closeApp?.()}
            />
          </div>
        )}
      </FocusContainer>
    </SettingsBase>
  );
}
