import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import type { Nullable } from "@sunrise/utils";

import { queryKeys } from "./query-keys";
import { getTitanOSDeviceInfo } from "./system.service";

export const titanOSDeviceInfoAtom = atomWithSuspenseQuery<
  Nullable<TitanDeviceInfo>
>(() => {
  return {
    queryKey: queryKeys.deviceInfo,
    queryFn: async () => {
      try {
        return await getTitanOSDeviceInfo();
      } catch {
        return null;
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    keepPreviousData: true,
  };
});
titanOSDeviceInfoAtom.debugLabel = "titanOSProductInfoAtom";
