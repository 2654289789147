import { atom } from "jotai";

import { PlayerError, selectPlayerIsSuspended } from "@sunrise/player";
import {
  selectRecoveryHasFailed,
  selectRecoveryIsOngoing,
} from "@sunrise/yallo-common-player-manager";

export const shouldHideErrorDueToRecoveryFnAtom = atom((get) => {
  const isAutorecovering = get(selectRecoveryIsOngoing);

  // When we are not autorecovering but we see a SuspendedError, we will ONLY show it when we already failed a recovery for this error.

  return (error: Error) => {
    const isPlayerError = error instanceof PlayerError;
    if (!isPlayerError) return false;

    const shouldHideErrorBeforeRecovery = (): boolean => {
      const hasFailedToRecover = get(selectRecoveryHasFailed);

      return isPlayerError && error.shouldAutoRecover && !hasFailedToRecover;
    };

    // Suppress any player error when auto recovering OR when the player is stopped.
    // NOTE: We still want to log it to Sentry somehow. But wrapped as a different error.
    return (
      isPlayerError &&
      (isAutorecovering ||
        get(selectPlayerIsSuspended) ||
        shouldHideErrorBeforeRecovery())
    );
  };
});
