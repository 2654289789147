import { isNil } from "lodash";

import { VIDEO_ADS_ELEMENT_ID } from "./constants";

export function getVideoAdsElement(): HTMLDivElement {
  const element = document.getElementById(VIDEO_ADS_ELEMENT_ID);

  if (isNil(element)) {
    throw new Error(
      `Video Ads element with id ${VIDEO_ADS_ELEMENT_ID} does not exist`,
    );
  }

  if (element.tagName !== "DIV" || !(element instanceof HTMLDivElement)) {
    throw new Error("Video Ads element is not an HTMLDivElement");
  }

  return element;
}
