let loadWebApiPromise: PromiseLike<unknown> | null = null;

export async function loadWebOSApi(): Promise<unknown> {
  if (window.webOS) return;

  if (!loadWebApiPromise) {
    loadWebApiPromise = Promise.all([
      // @ts-expect-error no module declaration available
      import("../../static/scripts/webOSTV.js"),
      // @ts-expect-error no module declaration available
      import("../../static/scripts/webOSTV-dev.js"),
    ]);
    loadWebApiPromise.then(() => console.log("webOS API loaded"));
  }

  return loadWebApiPromise;
}
