import type { ReactNode, Ref } from "react";
import { forwardRef } from "react";
import clsx from "clsx";

import type { MouseNavigationProps } from "@sunrise/bigscreen";

import type { IconProps } from "@/components/icon";
import { Icon } from "@/components/icon";

import * as styles from "./icon-button.css";

type IconButtonProps = CommonProps &
  MouseNavigationProps & {
    focused?: boolean;
    onMouseLeave?: () => void;
    disabled?: boolean;
    iconName: IconProps["name"];
  };

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(
    {
      focused,
      disabled,
      className,
      iconName,
      onMouseEnter,
      onMouseClick,
      onMouseLeave,
      "data-testid": dataTestId = "iconButton",
    }: IconButtonProps,
    ref: Ref<HTMLButtonElement>,
  ): ReactNode {
    const state = disabled ? "disabled" : focused ? "focused" : "nostate";

    const iconColor = focused && !disabled ? "dark" : "white";

    return (
      <button
        ref={ref}
        className={clsx([styles.iconButtonStyle, styles[state], className])}
        data-focused={focused}
        data-testid={dataTestId}
        onClick={onMouseClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Icon
          color={iconColor}
          data-testid={`${dataTestId}.icon`}
          name={iconName}
        />
      </button>
    );
  },
);
