import { type ReactElement, useCallback } from "react";
import type { ArrowPressHandler } from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue, useSetAtom } from "jotai";

import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { playerCurrentEpgItemAtom } from "@sunrise/player";

import { FocusIconButton } from "@/components/buttons/focus-icon-button/focus-icon-button";
import { route } from "@/config/route";
import * as styles from "@/features/player-controls/player-controls.css";

type InfoButtonProps = {
  focusKey: string;
  onArrowPress: ArrowPressHandler;
};

export function InfoButton({
  focusKey,
  onArrowPress,
}: InfoButtonProps): ReactElement {
  const dispatchLocation = useSetAtom(locationAtom);
  const epgEntry = useAtomValue(playerCurrentEpgItemAtom);
  const disabled = !epgEntry;
  const onEnterPress = useCallback(() => {
    if (!epgEntry) return;

    dispatchLocation(
      actionLocationNavigate(
        route.details.root({
          epgId: epgEntry.id,
          assetId: epgEntry.asset.id,
        }),
      ),
    );
  }, [epgEntry, dispatchLocation]);

  return (
    <FocusIconButton
      key="info"
      className={styles.button}
      data-testid="player-controls.button.info"
      disabled={disabled}
      focusKey={focusKey}
      iconName="info"
      onArrowPress={onArrowPress}
      onEnterPress={onEnterPress}
    />
  );
}
