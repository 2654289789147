import { useCallback, useEffect } from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";

import { debouncedQueryAtoms } from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";

import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";
import { typography } from "@/styles/typography.css";

import { TextInput } from "../../../components/text-input/text-input";
import * as styles from "./search-input.css";

type SearchBarProps = {
  focusKey: string;
  onArrowPress?: (direction: string) => boolean;
  onEnterPress?: () => void;
} & CommonProps;

export const SearchInput = ({
  "data-testid": dataTestId = "search-input",
  className,
  focusKey,
  onArrowPress,
  onEnterPress,
}: SearchBarProps): JSX.Element => {
  const canPullFocus = useAtomValue(canPullFocusAtom);
  const t = useTranslator();
  const value = useAtomValue(debouncedQueryAtoms.currentValueAtom);

  // NOTE: ONLY the text needs to be debounced. The filters do not.
  const setDebouncedQuery = useSetAtom(debouncedQueryAtoms.debouncedValueAtom);
  const performSearch = useCallback(
    (text: string): void => {
      setDebouncedQuery(text);
    },
    [setDebouncedQuery],
  );
  const { focused, ref, focusSelf } = useFocusable({
    focusKey,
    focusable: true,
    isFocusBoundary: true,
    onArrowPress,
    onEnterPress,
  });

  useEffect(() => {
    if (canPullFocus && focused) {
      ref.current.focus();
    } else {
      ref.current.blur();
    }
  }, [canPullFocus, focused, ref]);

  const onFocusChange = useCallback(
    (isFocused: boolean): void => {
      if (isFocused && canPullFocus) {
        focusSelf();
        return;
      }

      if (isFocused && !canPullFocus) {
        ref.current.blur();
      }
    },
    [canPullFocus, focusSelf, ref],
  );

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        className={clsx([
          className,
          styles.root,
          typography.h6.regular,
          focused && styles.focused,
        ])}
      >
        |
        <TextInput
          ref={ref}
          className={styles.input}
          data-testid={dataTestId}
          focused={focused}
          name="search-input"
          placeholder={t("menu_search_new")}
          query={value}
          onChange={performSearch}
          onFocusChange={onFocusChange}
        />
      </div>
    </FocusContext.Provider>
  );
};
