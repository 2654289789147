import { atom } from "jotai";
import { isNil } from "lodash";

import { recommendationsDataLegacyAtom } from "@sunrise/yallo-recommendations";

/**
 * Creates a function to easily know what item is at a given position in the recommendations "grid".
 * This is async but the function returned is sync. So we can easily use it in the navigation callbacks.
 */
export const itemAtRecommendationCoordinatesAtom = atom(async (get) => {
  const data = await get(recommendationsDataLegacyAtom);

  return (reference: { rowIndex: number; colIndex: number }) => {
    if (isNil(data)) {
      return null;
    }

    return data[reference.rowIndex]?.items[reference.colIndex] ?? null;
  };
});

itemAtRecommendationCoordinatesAtom.debugLabel =
  "itemAtRecommendationCoordinatesAtom";
