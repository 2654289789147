import type {
  PageableItems,
  PageWithItemsInfiniteQueryResult,
} from "@sunrise/types";

import {
  selectHasRecordingsQueryFetchedFirstPage,
  selectRecordingsIsFetchingInitialData,
} from "../recordings-selectors";

/**
 * A helper function to create a pageable items object from a paginated query in the format of the NG backend.
 * We can re-use this function for legacy queries if we just re-map the legacy response object to the NG paging format.
 */
export function pageableItemsFromQuery<T>(
  query: PageWithItemsInfiniteQueryResult<T>,
): PageableItems<T> {
  const hasFetchedFirstPage = selectHasRecordingsQueryFetchedFirstPage(query);

  const items = query.data?.pages?.flatMap((page) => page.items) ?? [];

  return {
    fetchNextPage: () => {
      const shouldNotFetch = !query.hasNextPage || query.isFetchingNextPage;
      if (shouldNotFetch) return;

      (async () => {
        await query.fetchNextPage();
      })().catch((err) => {
        throw err;
      });
    },
    hasNextPage: query.hasNextPage,
    items,
    isLoading: selectRecordingsIsFetchingInitialData(query),
    isLoadingNextPage: hasFetchedFirstPage && query.isFetchingNextPage,
    hasItems: query.isFetched && items.length > 0,
    isFetched: query.isFetched,
  };
}
