import { atom } from "jotai";

import { playerControlsVisibleAtom } from "@sunrise/yallo-player-controls";

import { isChannelListHiddenAtom } from "@/features/channel-list";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";
import { zappingControlIsVisibleAtom } from "@/modules/zapping/zapping-control.atom";

/**
 * Ad controls can only pull focus when:
 * - the channel list is hidden
 * - there is no menu or modal open
 * - there are no player controls open
 * - the zapping control is not visible
 */
export const canPullFocusOnAdControlAtom = atom((get) => {
  return (
    get(canPullFocusAtom) &&
    get(isChannelListHiddenAtom) &&
    !get(zappingControlIsVisibleAtom) &&
    !get(playerControlsVisibleAtom)
  );
});
