import type { JSX, ReactElement, ReactNode } from "react";
import { useEffect, useRef } from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import * as Sentry from "@sentry/react";
import { useAtomValue } from "jotai";
import { useBrowserLocation } from "wouter/use-browser-location";

import { useKeyboardNavigation } from "@sunrise/bigscreen";
import { useTranslator } from "@sunrise/translator";

import { FocusButton } from "@/components";
import { DebugVersion } from "@/components/debug/debug-version";
import { globalFocusKey } from "@/config/focus-key";
import { rawRoute } from "@/config/route";
import { closeAppAtom } from "@/modules/platform/close-app.atom";

import * as styles from "./error-boundary.css";

const localWidgetFocusKey = {
  errorPage: globalFocusKey.errorPage,
  closeButton: globalFocusKey.errorPage + "error-button-close",
};

export type MonitoringErrorBoundaryProps = {
  children: ReactNode;
  isRoot?: boolean;
  fallback?: Sentry.FallbackRender;
};

function getErrorMessage(error: unknown): string | null {
  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === "string") {
    return error;
  }

  return null;
}

// TODO: Inject a QR code that contains the eventId for easy copy/pasta.
// TODO: YALLOTV-13290 implement error page
const fallback: (
  props: Parameters<Sentry.FallbackRender>[0] & {
    isRoot?: boolean;
    date: string;
  },
) => React.ReactElement = ({ error, eventId, isRoot, date }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>oh snap!</h1>
      <p className={styles.description}>{getErrorMessage(error)}</p>
      <p className={styles.trait}>eventId: {eventId}</p>
      <p className={styles.trait}>at: {date}</p>
      <DebugVersion />
      <FallbackButtons reload={isRoot} />;
    </div>
  );
};

function FallbackButtons({ reload }: { reload?: boolean }): ReactNode {
  const [, navigate] = useBrowserLocation();

  const navigateBack = (): void => {
    navigate(rawRoute.home);
    // the router etc is not available in this context, so we need to reload the page to recover
    window.location.reload();
  };

  useKeyboardNavigation({
    onBack: navigateBack,
    isEnabled: true,
  });

  return reload ? <RootButton navigate={navigateBack} /> : <CloseButton />;
}

export function ErrorBoundary(
  props: MonitoringErrorBoundaryProps,
): JSX.Element {
  const date = useRef(new Date().toISOString());

  return (
    <Sentry.ErrorBoundary
      fallback={(fallbackProps) => {
        const FallbackComponent = props.fallback ?? fallback;

        return (
          <FallbackComponent
            {...fallbackProps}
            date={date.current}
            isRoot={props.isRoot}
          />
        );
      }}
      onError={
        import.meta.env.MODE !== "production"
          ? // eslint-disable-next-line no-console
            (e) => console.error(e)
          : undefined
      }
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
}

const Button = ({
  text,
  navigate,
}: {
  text: string;
  navigate: () => void;
}): ReactElement | undefined => {
  const { focusKey, ref, focusSelf } = useFocusable({
    focusKey: localWidgetFocusKey.errorPage,
    focusable: true,
    preferredChildFocusKey: localWidgetFocusKey.closeButton,
  });

  useEffect(() => {
    focusSelf();
  }, [focusSelf]);

  return (
    <div ref={ref} className={styles.button}>
      <FocusContext.Provider value={focusKey}>
        <FocusButton
          focusKey={localWidgetFocusKey.closeButton}
          text={text}
          block
          onEnterPress={navigate}
        />
      </FocusContext.Provider>
    </div>
  );
};

const CloseButton = () => {
  const t = useTranslator();
  const close = useAtomValue(closeAppAtom);

  return (
    <Button navigate={() => close?.closeApp()} text={t("button_close_app")} />
  );
};

const RootButton = ({ navigate }: { navigate: () => void }) => (
  <Button navigate={navigate} text="Close"></Button>
);
