import type { DefinedQueryObserverResult } from "@tanstack/query-core";
import type { ExtractAtomValue } from "jotai";
import { atom } from "jotai";
import { atomWithReducer } from "jotai/utils";
import { atomEffect } from "jotai-effect";
import { isNil } from "lodash";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { PageBaseChannelSchema } from "@sunrise/backend-ng-channel";
import type { ChannelId } from "@sunrise/backend-types-core";
import { activeChannelIdAtom } from "@sunrise/yallo-active-channel";
import { currentChannelGroupAtom } from "@sunrise/yallo-channel-group";
import {
  CHANNEL_LIST_PAGE_SIZE,
  channelsForChannelGroupPerPageQueryAtom,
} from "@sunrise/yallo-channel-list";

import type { GuideChannel, GuideChannelPlaceholder } from "./guide.types";
import { visibleChannelsAtom } from "./guide-visible-data.atom";
import { guideDataChannelsLegacyAtom } from "./legacy/guide-data-channels.legacy.atom";

type LoadableChannels =
  | {
      type: "pages";
      start: number;
      end: number;
    }
  | {
      type: "channel";
      channelId: ChannelId;
    };

export type GuideChannelItem = {
  data: GuideChannel | GuideChannelPlaceholder;
  needed: boolean;
};

/**
 * When set we know what pages we need to load.
 *
 * It'll be set depending on the pages that are currently visible.
 *
 * The idea is that this set of loaded pages always widens. So let's say for a specific channel group, we load the first page.
 * There we make a selection. Then we scroll down a bit. So we will now load the second page and drop the first page.
 * This is problematic because the selection was done on a channel in the first page.
 * We still need to know about the first page in order to not clear the selection.
 *
 * TODO: Whenever the channel list is reset, we will need to reset this atom as well.
 * TODO: Would be cool if it could just start widening over time until all pages are loaded.
 */
const loadablePagesAtom = atomWithReducer<
  LoadableChannels | null,
  {
    type: "set";
    payload: LoadableChannels | { type: "indexes"; start: number; end: number };
  }
>(null, (state, action) => {
  switch (action?.payload.type) {
    case "channel":
      return action.payload;
    case "indexes": {
      // These are the new boundaries.
      // They needed to be added on top of any existing boundaries.
      const newBoundaries = {
        start: Math.floor(action.payload.start / CHANNEL_LIST_PAGE_SIZE) + 1,
        end: Math.floor(action.payload.end / CHANNEL_LIST_PAGE_SIZE) + 1,
      };

      return extendBoundaries(state, newBoundaries);
    }
    case "pages": {
      return extendBoundaries(state, action.payload);
    }
    default: {
      return state;
    }
  }
});

function extendBoundaries(
  state: LoadableChannels | null,
  extended: { start: number; end: number },
): LoadableChannels {
  const existing = state?.type === "pages" ? state : null;

  if (!existing) {
    return {
      type: "pages",
      start: extended.start,
      end: extended.end,
    };
  }

  return {
    type: "pages" as const,
    start: Math.min(extended.start, existing.start),
    end: Math.max(extended.end, existing.end),
  };
}

type VisibleChannels = ExtractAtomValue<typeof visibleChannelsAtom>;

/**
 * Similar to how `channelsForChannelListAtom` works, but for the guide.
 *
 * We will expose the known channels as well as placeholders for the channels that are not yet loaded.
 * And we will indicate if we need to load the EPG datails for the channel or not. The loading of the EPG will be done in the guideDataAtom.
 */
const guideDataChannelsAtomNg = atom<Promise<GuideChannelItem[]>>(
  async (get) => {
    get(runEffectAtom);

    const visible = get(visibleChannelsAtom);
    const loadable = get(loadablePagesAtom);

    if (!loadable) {
      return [];
    }

    const channelGroupId = await get(currentChannelGroupAtom);
    if (!channelGroupId) {
      return [];
    }

    switch (loadable.type) {
      case "channel":
        return channelPagesToOutput(
          [
            get(
              channelsForChannelGroupPerPageQueryAtom({
                channelGroupId: channelGroupId.id,
                reference: { type: "channel", id: loadable.channelId },
              }),
            ),
          ],
          visible,
        );
      case "pages": {
        const pages = new Array(loadable.end - loadable.start + 1)
          .fill(null)
          .map((_, index) => index + loadable.start);

        return channelPagesToOutput(
          pages.map((page) =>
            get(
              channelsForChannelGroupPerPageQueryAtom({
                channelGroupId: channelGroupId.id,
                reference: { type: "page", number: page },
              }),
            ),
          ),
          visible,
        );
      }
    }
  },
);

export const guideDataChannelsAtom = atom<Promise<GuideChannelItem[] | null>>(
  (get) => {
    if (get(isLegacyBackendAtom)) {
      return get(guideDataChannelsLegacyAtom);
    }

    return get(guideDataChannelsAtomNg);
  },
);

type QueryResponse = DefinedQueryObserverResult<PageBaseChannelSchema, Error>;
async function channelPagesToOutput(
  pages: (Promise<QueryResponse> | QueryResponse)[],
  visible: VisibleChannels,
): Promise<GuideChannelItem[]> {
  const all = (await Promise.all(pages)).filter((item) => !!item.data.page);

  const first = all[0];
  if (!first) {
    return [];
  }

  const total = first.data.total;
  if (!total) {
    return [];
  }

  const { start, end } = visible ?? {};
  function isNeeded(channelIndex: number) {
    if (isNil(start) || isNil(end)) {
      return false;
    }

    return channelIndex >= start && channelIndex <= end;
  }

  const output: GuideChannelItem[] = Array(total)
    .fill(null)
    .map((_, index) => {
      return {
        data: {
          channelNumber: index + 1,
        },
        needed: false,
      };
    });

  for (const page of all) {
    if (!page.data?.page) {
      continue;
    }

    const startIndex = (page.data.page - 1) * CHANNEL_LIST_PAGE_SIZE;
    page.data.items.forEach((channel, index) => {
      const channelNumber = startIndex + index + 1;
      const idx = channelNumber - 1;
      output[idx] = {
        data: {
          channelNumber,
          id: channel.id as ChannelId,
          name: channel.name,
          logo: channel.logo,
          items: [],
        },
        needed: isNeeded(idx),
      };
    });
  }
  return output;
}

// TODO: Also introduce an effect to set the channels initially to the right channelId.
//       As it will need to be either the playing one or the selected one.

/**
 * This effect will ensure that we set an initial value for the channelId to load.
 * We will then fetch a page of data for that channelId.
 *
 * The channel we consider active is the one that is selected OR the one that is playing OR the one that should be playing.
 * TODO: Get from the selection logic.
 */
const runEffectAtom = atomEffect((get, set) => {
  const visible = get(visibleChannelsAtom);
  const loadable = get(loadablePagesAtom);

  // When we have visible channels and no loadable pages, we need to reset the visible channels.
  if (visible && !loadable) {
    set(visibleChannelsAtom, null);
    return;
  }

  // When we have a loadable and visible information we will want to update the loadable.
  if (loadable && visible) {
    set(loadablePagesAtom, {
      type: "set",
      payload: {
        type: "indexes",
        start: visible.start,
        end: visible.end,
      },
    });
    // TODO: Maybe we need to update the loadable here ... .
    return;
  }

  if (loadable) {
    return;
  }

  let cancelled = false;
  get(activeChannelIdAtom)
    .then((id) => {
      if (cancelled) {
        return;
      }

      if (!id) {
        set(loadablePagesAtom, {
          type: "set",
          payload: {
            type: "pages" as const,
            start: 1,
            end: 1,
          },
        });
        return;
      }

      set(loadablePagesAtom, {
        type: "set",
        payload: {
          type: "channel" as const,
          channelId: id,
        },
      });
    })
    .catch(() => {});

  return () => {
    cancelled = true;
  };
});
