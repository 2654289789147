import { useEffect, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAtomValue } from "jotai";

import type { ChannelListItem } from "@sunrise/backend-types";
import { isCursorVisibleAtom } from "@sunrise/bigscreen";
import { useInfiniteScroll } from "@sunrise/utils";

import { channelList } from "@/config/size";
import { SCREEN_HEIGHT_IN_PX, SCREEN_WIDTH_IN_PX } from "@/core";
import { mergeRefs } from "@/utils/merge-refs";
import { useVirtualizerNavigation } from "@/utils/use-virtualizer-navigation";
import { localWidgetFocusKey } from "@/utils/virtualizer";

import { channelListContainer, innerScroll } from "../channel-list.css";
import { VirtualizedChannelItem } from "./virtualized-channel-item";

const estimateSize = () => channelList.item.height;

type VirtualizedChannelListProps = {
  onRightBound: () => void;
  onLeftBound: () => void;
  channels: ChannelListItem[];
  fetchNextPage: (() => void) | undefined;
  initialIndex: number;
  focusKey: string;
  "data-testid"?: string;
};

export function VirtualizedChannelList({
  onLeftBound,
  onRightBound,
  channels,
  focusKey,
  initialIndex,
  "data-testid": dataTestId,
  fetchNextPage,
}: VirtualizedChannelListProps) {
  const [initialCentered, setInitialCentered] = useState(false);

  // TODO: have something so we start pre-loading the next page before we reach the end.
  const infiniteScroll = useInfiniteScroll<HTMLDivElement>({
    loadMore: fetchNextPage,
  });
  const isCursorVisible = useAtomValue(isCursorVisibleAtom);
  const initialOffset = initialIndex * channelList.item.height;
  const scrollRef = useRef<HTMLDivElement>(null);
  const channelsVirtualizer = useVirtualizer({
    count: channels.length,
    estimateSize,
    getScrollElement: () => scrollRef.current,
    overscan: 2,
    initialOffset,
    initialRect: {
      width: SCREEN_WIDTH_IN_PX,
      height: SCREEN_HEIGHT_IN_PX, // size of scroll element
    },
  });

  useEffect(() => {
    // NOTE: we don't want to center everytime we change the channel with the mouse - unexpected moving of the list
    if (!initialCentered) {
      setInitialCentered(true);
    }
    if (isCursorVisible && initialCentered) {
      return;
    }

    channelsVirtualizer.scrollToIndex(initialIndex, {
      align: "center",
    });
  }, [isCursorVisible, channelsVirtualizer, initialIndex, initialCentered]);

  const { handleGoToRow } = useVirtualizerNavigation({
    virtualizer: channelsVirtualizer,
    focusKey,
    align: "center",
  });
  const virtualItems = channelsVirtualizer.getVirtualItems();

  return (
    <div
      ref={mergeRefs(scrollRef)}
      className={channelListContainer}
      data-testid={dataTestId}
    >
      <div
        className={innerScroll}
        style={{
          position: "relative",
          height: `${channelsVirtualizer.getTotalSize()}px`,
        }}
      >
        {virtualItems.map((virtualItem) => {
          const index = virtualItem.index;
          const channel = channels[index];
          if (!channel) return null;

          const childFocusKey = localWidgetFocusKey.row(focusKey, index);
          const onArrowPress = (direction: string) => {
            if (direction === "left") {
              onLeftBound();
              return false;
            }

            if (direction === "right") {
              onRightBound();
              return false;
            }

            if (direction === "up") {
              handleGoToRow(index, 0, -1)();
              return false;
            }

            if (direction === "down") {
              handleGoToRow(index, channels.length - 1, 1)();
              return false;
            }

            return true;
          };
          const isLast = index === channels.length - 5;

          return (
            <VirtualizedChannelItem
              key={channel.id}
              channel={channel}
              data-testid={dataTestId}
              focusKey={childFocusKey}
              isLast={isLast}
              lastRef={infiniteScroll.setLastRef}
              virtualItem={virtualItem}
              onArrowPress={onArrowPress}
            />
          );
        })}
      </div>
    </div>
  );
}
