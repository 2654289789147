import { type ReactElement } from "react";
import { useAtomValue } from "jotai";

import { usePauseAds } from "@sunrise/ads";
import { selectIsDialogOpen } from "@sunrise/dialogs";

import * as styles from "./pause-ads.css";

export function PauseAds(): ReactElement | null {
  const { url, onShown, onError } = usePauseAds();
  const isDialogOpen = useAtomValue(selectIsDialogOpen);

  if (!url || isDialogOpen) {
    return null;
  }

  return (
    <div className={styles.container} data-testid="pause_ad">
      <img
        className={styles.image}
        data-testid="pause_ad.image"
        src={url}
        onError={onError}
        onLoad={onShown}
      />
    </div>
  );
}
