import { addDays, endOfDay, startOfDay } from "date-fns";
import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily, unwrap } from "jotai/utils";
import { uniqBy } from "lodash";

import type { ChannelId, TimeDay } from "@sunrise/backend-types-core";
import { atomPerDay, dateToTimeDay, nowAtom } from "@sunrise/time";
import { selectEpgCollectionPerDayAtom } from "@sunrise/yallo-epg";

import type {
  GuideChannel,
  GuideChannelPlaceholder,
  GuideProgram,
} from "./guide.types";
import { guideDataChannelsLoadableAtom } from "./guide-data-channels-loadable.atom";
import { visibleTimingsAtom } from "./guide-visible-data.atom";
import { epgEntryToGuideProgram } from "./utils/epg-entry-to-guide-program";

/**
 * An atom to determine what should be loaded in the grid.
 */
const gridDataTimingsThatShouldBeLoadedAtom = atom((get) => {
  const visible = get(visibleTimingsAtom);

  if (visible) {
    return visible;
  }

  const now = get(nowAtom);

  return {
    startTime: startOfDay(now),
    endTime: endOfDay(now),
  };
});

/**
 * This is the start time of the first timeslot.
 */
const selectStartTimeDay = atomPerDay(
  atom((get) => get(gridDataTimingsThatShouldBeLoadedAtom).startTime),
);

/**
 * This is the start time of the next timeslot which we will not yet fetch.
 * We will load up to this point but not including this point.
 */
const selectEndTimeDay = atomPerDay(
  atom((get) => addDays(get(gridDataTimingsThatShouldBeLoadedAtom).endTime, 1)),
);

/**
 * The atom will attempt to load the guide data as needed.
 *
 * It'll take into account what data is visible and what data is not.
 * So it will only load data as needed.
 *
 * It will also take into account if a selection is made or not. So that data is loaded as well.
 *
 * It'll be a sync atom because we do not want to trigger suspense because of this.
 * When things load in the background it truly needs to load in the background.
 */
export const guideDataAtom = atom<{
  data: (GuideChannel | GuideChannelPlaceholder)[];
  isLoadingInitial: boolean;
}>((get) => {
  const channels = get(guideDataChannelsLoadableAtom);
  const startTime = get(selectStartTimeDay);
  const endTime = get(selectEndTimeDay);

  // TODO: I could re-build it so every channel loads its own data.

  const data = (channels ?? []).map(({ data: channel, needed }) => {
    if (!needed || !("id" in channel)) {
      return channel;
    }

    // loop over all the days
    let current = startTime;
    const epgs: GuideProgram[] = [];
    do {
      const day = dateToTimeDay(current);

      // Grab all the EpgEntries for this day.
      epgs.push(
        ...get(
          unwrappedWindows({
            channelId: channel.id,
            day,
          }),
        ),
      );

      current = addDays(current, 1);
    } while (current < endTime);

    // If it is needed, we need to inject the correct data in it.
    return {
      ...channel,

      // NOTE: days can have overlapping epg entries, so all promises combined would contain some items twice.
      // This causes the guide to render the same item multiple times and thus breaking the navigation
      items: uniqBy(epgs, (program) => program.id),
    };
  });

  return {
    data,
    isLoadingInitial: !channels,
  };
});

/**
 * This ensures we only map every item for every channel for every timeframe just a single time to a GuideProgram.
 */
const unwrappedWindows = atomFamily(
  (params: { channelId: ChannelId; day: TimeDay }) => {
    const atomForParams = unwrap(selectEpgCollectionPerDayAtom(params));

    return atom((get) => {
      try {
        const query = get(atomForParams);
        return (query?.data ?? []).map(epgEntryToGuideProgram);
      } catch {
        return [];
      }
    });
  },
  areEqual,
);
