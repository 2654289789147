import { useCallback } from "react";
import { useAtomValue } from "jotai/index";

import { selectPlayerIsPlaying } from "@sunrise/player";
import { getPlayerManager } from "@sunrise/yallo-common-player-manager";

export type RemotePlayPauseReturn = {
  toggle: () => void;
  play: () => void;
  pause: () => void;
};

export function useTogglePlayPause(): RemotePlayPauseReturn {
  const isPlaying = useAtomValue(selectPlayerIsPlaying);

  const toggle = useCallback(() => {
    if (isPlaying) {
      void getPlayerManager().pause();
    } else {
      void getPlayerManager().play();
    }
  }, [isPlaying]);

  const play = useCallback(() => {
    if (!isPlaying) {
      void getPlayerManager().play();
    }
  }, [isPlaying]);

  const pause = useCallback(() => {
    if (isPlaying) {
      void getPlayerManager().pause();
    }
  }, [isPlaying]);

  return {
    toggle,
    play,
    pause,
  };
}
