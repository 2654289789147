import { atom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import {
  type RecordingSortingOption,
  recordingsOverviewSortAtom,
  recordingsOverviewSortOptionsQueryAtom,
} from "@sunrise/backend-ng-recordings";
import type { RecordingsSort } from "@sunrise/backend-types";
import type { SortedListOptions } from "@sunrise/types";

import { recordingsOverviewSortLegacyAtom } from "./legacy/recordings-overview-sortable.legacy.atom";

/**
 * An atom which will return the available sorting options as well as the current option.
 * You can write to it to set it to one of the given options.
 *
 * This will work generically for ng or legacy.
 *
 * The atom will only have effect on the sorting of the recordings overview page.
 *
 * It is async since the ng backend will fetch the sorting options from the backend instead of using a hardcoded list.
 *
 * TODO: Replace this with the one for the NG backend when legacy is removed.
 */
export const recordingsOverviewSortableAtom = atom<
  Promise<SortedListOptions<string>>,
  [string],
  unknown
>(
  async (get) => {
    return get(isLegacyBackendAtom)
      ? get(recordingsOverviewSortLegacyAtom)
      : await get(recordingsOverviewSortNgAtom);
  },
  (get, set, sort) => {
    if (get(isLegacyBackendAtom)) {
      set(recordingsOverviewSortLegacyAtom, sort as RecordingsSort);
      return;
    }

    set(recordingsOverviewSortNgAtom, sort as RecordingSortingOption);
  },
);

const recordingsOverviewSortNgAtom = atom<
  Promise<SortedListOptions<RecordingSortingOption>>,
  [RecordingSortingOption],
  unknown
>(
  async (get) => {
    const { data } = await get(recordingsOverviewSortOptionsQueryAtom);

    const options = data.map((option) => ({
      label: { key: option.translation_key },
      value: option.query_value as RecordingSortingOption,
    }));

    const value = get(recordingsOverviewSortAtom);

    return {
      options,
      current: options.find((item) => item.value === value) ?? null,
    };
  },
  (_, set, sort) => {
    set(recordingsOverviewSortAtom, sort);
  },
);
