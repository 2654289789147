import { languages } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectPlayerCurrentError, shakaPlayerAtom } from "@sunrise/player";
import {
  actionPlayerStatsSetEnabled,
  actionPlayerStatsToggleEnabled,
  playerStatsAtom,
} from "@sunrise/player-stats";
import { forceLiveTvTimeLimitReachedAtom } from "@sunrise/yallo-user";

import { tizenStore } from "./core";

export function enableDevConfig(): void {
  if (import.meta.env.VITE_PLAYER_STATS_DEFAULT === "true") {
    tizenStore.set(playerStatsAtom, actionPlayerStatsSetEnabled());
  }

  document.addEventListener("keypress", (event) => {
    switch (event.key) {
      case "i": {
        tizenStore.set(playerStatsAtom, actionPlayerStatsToggleEnabled());
        break;
      }
      case "]":
      case "[": {
        const increase = event.key === "]";
        const currentLanguage = tizenStore.get(currentLanguageAtom);
        const bound = increase
          ? languages[0]
          : languages[languages.length - 1]!;
        const nextLanguage =
          languages[languages.indexOf(currentLanguage) + (increase ? 1 : -1)] ??
          bound;
        tizenStore.set(currentLanguageAtom, nextLanguage);
        break;
      }
      case "l": {
        if (event.ctrlKey) {
          tizenStore.set(
            forceLiveTvTimeLimitReachedAtom,
            !tizenStore.get(forceLiveTvTimeLimitReachedAtom),
          );
        }
        break;
      }
      case "e": {
        if (event.ctrlKey) {
          // This breaks the player and as soon as it sees a playerError it fixes it again.
          const player = tizenStore.get(shakaPlayerAtom);
          if (!player) {
            return;
          }

          player.configure({
            streaming: { retryParameters: { timeout: 2 } },
          });

          const sub = tizenStore.sub(selectPlayerCurrentError, () => {
            const err = tizenStore.get(selectPlayerCurrentError);
            if (!err) {
              return;
            }

            player.configure({
              streaming: { retryParameters: { timeout: 50_000 } },
            });
            sub();
          });
        }
        break;
      }
    }
  });
}

export function logVersion(): void {
  if (!import.meta.env.PROD) {
    return;
  }

  const appVersion = import.meta.env.VITE_APP_VERSION;

  // eslint-disable-next-line no-console
  console.info(`mode: ${import.meta.env.MODE}
  platform: ${import.meta.env.VITE_APP_PLATFORM}
  version: ${appVersion}
  commit: ${import.meta.env.VITE_APP_COMMIT_HASH}
  build: ${import.meta.env.VITE_APP_BUILD_NR} ${
    import.meta.env.VITE_APP_BUILD_DATE
      ? `(${new Date(
          Number(import.meta.env.VITE_APP_BUILD_DATE),
        ).toLocaleString()})`
      : ""
  }`);
}
