import { captureException } from "@sentry/react";
import type { Getter } from "jotai";

import { BaseError } from "@sunrise/error";
import { PlayerError } from "@sunrise/player";
import { getErrorMapping } from "@sunrise/yallo-error-mapping";

/**
 * Will attempt to trace the error to Sentry.
 * Should also set a tag for the technicalErrorName should we have it configured in the player mapping.
 */
export async function traceToSentry({
  error,
  hidden,
  skipSentryCapture,
  location,
  get,
}: {
  error: Error;
  hidden: boolean;
  skipSentryCapture: boolean;
  location: string;
  get: Getter;
}): Promise<{ eventId: string | undefined; errorCode: string | null }> {
  const isBaseError = error instanceof BaseError;

  // When the error indicates we should trace, then let's trace it.
  // The error will no longer be sent to the ErrorBoundary.
  const shouldTrace = isBaseError ? error.shouldTrace : true;
  let eventId: string | undefined;

  const config = getErrorMapping(error);
  const evaluatedConfig =
    typeof config === "function" ? await config(get) : config;

  if (shouldTrace && !skipSentryCapture) {
    eventId = captureException(error, {
      tags: {
        errorCode: isBaseError ? error.errorCode : null,
        location,
        "player.error": error instanceof PlayerError,
        hidden,
        "error.technical_name": evaluatedConfig?.technicalErrorName,
      },
      extra: isBaseError ? error.extras : undefined,
      level: hidden ? "info" : "error",
    });
  }

  if (skipSentryCapture) {
    // eslint-disable-next-line no-console
    console.info(
      shouldTrace
        ? "this error is normally sent to sentry but sending to sentry is disabled atm"
        : "this error will never be sent to sentry",
      {
        errorCode: isBaseError ? error.errorCode : null,
        eventId,
        hidden,
        "error.technical_name": evaluatedConfig?.technicalErrorName,
        "player.error": error instanceof PlayerError,
      },
      error,
      isBaseError
        ? {
            extras: error.extras,
            errorCode: error.errorCode,
            cause: error.cause,
          }
        : undefined,
    );
  }

  return { eventId, errorCode: isBaseError ? error.errorCode : null };
}
