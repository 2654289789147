import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";

import { useGridNavDate } from "@sunrise/yallo-guide";

import { FocusButton } from "@/components";
import { ICON_SIZE_20 } from "@/components/icon/icon.config";
import type { FocusableOnArrowPress } from "@/utils/focus-container";

import * as styles from "./guide-controls.css";

type GuideControlsNavDateProps = {
  onArrowLeft: FocusableOnArrowPress;
};

export function GuideControlsNavDate({
  onArrowLeft,
}: GuideControlsNavDateProps) {
  const { selectDate, currentlySelectedDate } = useGridNavDate({
    getFocusKey: getCurrentFocusKey,
  });

  return (
    <FocusButton
      className={clsx([styles.button, styles.dayFilterButton])}
      focusKey="grid-nav-date"
      iconRight="dropdown-down"
      iconSize={ICON_SIZE_20}
      text={currentlySelectedDate}
      textAlign="left"
      onArrowPress={onArrowLeft}
      onEnterPress={selectDate} // open dialog to select new date
    />
  );
}
