import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { type Nullable } from "@sunrise/utils";

import type { SelectedRecordingReference } from "./types";
export const selectedRecordingReferenceByNameAtom = atomFamily(
  (name: string) => {
    const innerAtom = atom<Nullable<SelectedRecordingReference>>(null);
    innerAtom.debugLabel = `selectedRecordingReferenceByNameAtom(${name})`;
    return innerAtom;
  },
);
