import { atom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";

import { currentChannelGroupAtom } from "./current-channel-group.atom";
import { selectedChannelGroupLegacyAtom } from "./legacy/selected-channel-group-with-channels.legacy.atom";
import { allChannelsForGivenChannelGroupIdNgAtom } from "./ng/all-channels-for-given-channel-group-id.ng.atom";
import { ngChannelToSimpleChannel } from "./ng/utils/ng-channel-to-simple-channel";
import type { MappedChannel } from "./types";

/**
 * Try to avoid using this atom. It is better to use the `selectedChannelGroupWithPaginatedChannels` atom for performance reasons.
 * The Guide relies on all channels being there from the start. So it is necessary at the moment without reworking the entire guide.
 * The returned channels are sorted.
 *
 * @deprecated We should use the `selectedChannelGroupWithPaginatedChannels` atom instead. But in some places paging is super hard atm.
 */
export const selectedChannelGroupWithAllChannels = atom<
  Promise<MappedChannel[]>
>(async (get) => {
  if (get(isLegacyBackendAtom)) {
    return (await get(selectedChannelGroupLegacyAtom))?.channels ?? [];
  }

  const selected = await get(currentChannelGroupAtom);
  if (!selected) {
    return [];
  }

  const data = await get(allChannelsForGivenChannelGroupIdNgAtom(selected.id));
  return data.map(ngChannelToSimpleChannel);
});
selectedChannelGroupWithAllChannels.debugLabel =
  "selectedChannelGroupWithAllChannels";
