import { atom } from "jotai";
import { unwrap } from "jotai/utils";
import { isNil } from "lodash";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { activeChannelIdAtom } from "@sunrise/yallo-active-channel";
import {
  currentChannelGroupAtom,
  selectedChannelGroupWithAllChannels,
} from "@sunrise/yallo-channel-group";

import { channelsForChannelGroupPerPageQueryAtom } from "./channels-for-channel-group-per-page.query.atom";
import { CHANNEL_LIST_PAGE_SIZE } from "./constants";

/**
 * Stores the channelIndex for the currently playing channel.
 * We set this as soon as we know it and we reset it whenever we switch the channel list.
 */
export const activeChannelIndexAtom = atom(async (get) => {
  const activeChannelId = get(unwrap(activeChannelIdAtom));
  const channelGroup = get(unwrap(currentChannelGroupAtom));

  if (isNil(channelGroup) || isNil(activeChannelId)) {
    return null;
  }

  if (get(isLegacyBackendAtom)) {
    const allChannelsForChannelGroup = await get(
      selectedChannelGroupWithAllChannels,
    );

    // Get the full channel list and return the index. So hard in legacy damn.
    return allChannelsForChannelGroup.findIndex(
      (channel) => channel.id === activeChannelId,
    );
  }

  const query = await get(
    channelsForChannelGroupPerPageQueryAtom({
      channelGroupId: channelGroup.id,
      reference: { type: "channel", id: activeChannelId },
    }),
  );

  if (!query.data || !query.data.page) {
    return null;
  }

  const activeChannelIndex = query.data.items.findIndex(
    (channel) => channel.id === activeChannelId,
  );

  // Need to offset it with the page number and page size.
  return activeChannelIndex + (query.data.page - 1) * CHANNEL_LIST_PAGE_SIZE;
});
