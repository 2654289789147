import { withAtomEffect } from "jotai-effect";

import { featureAtom } from "@sunrise/feature-flags";

import { recordingsOverviewClientFilterAtom } from "./recording-overview-filter.atom";
import { RecordingOverviewFilter } from "./types";

export const recordingOverviewShowAllFilterFeatureAtom = withAtomEffect(
  featureAtom("recordings-all-filter-enabled", false, {
    description: "Display `All` option in the recordings overview filters",
    values: [null, false, true],
  }),
  (get, set) => {
    if (!get(recordingOverviewShowAllFilterFeatureAtom)) {
      set(
        recordingsOverviewClientFilterAtom,
        RecordingOverviewFilter.AllRecorded,
      );
    }
  },
);
