import type { BulkDeleteRecordingsPayload } from "@sunrise/backend-ng-recordings";
import type { RecordingBulkDelete } from "@sunrise/backend-types";

export function groupRecordingsByType(
  recordings: RecordingBulkDelete[],
): BulkDeleteRecordingsPayload {
  return recordings.reduce<BulkDeleteRecordingsPayload>(
    (acc, recording) => {
      let newRecordings = acc.recordings;
      let newRecordingGroups = acc.recording_groups;
      if (recording.type === "recording")
        newRecordings = [...newRecordings, recording.id];
      if (recording.type === "recordinggroup")
        newRecordingGroups = [...newRecordingGroups, recording.id];

      return {
        recordings: newRecordings,
        recording_groups: newRecordingGroups,
      };
    },
    {
      recordings: [],
      recording_groups: [],
    },
  );
}
