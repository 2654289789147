import { useCallback, useEffect, useState } from "react";
import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";
import { captureException } from "@sentry/core";
import { useAtomValue, useSetAtom, useStore } from "jotai";
import { useAtomCallback } from "jotai/utils";

import {
  type ActionDialogAction,
  actionDialogClose,
  actionDialogOpen,
  dialogAtom,
  selectCurrentlyOpenedDialog,
} from "@sunrise/dialogs";
import { BaseError, errorAtom } from "@sunrise/error";
import { PlayerError } from "@sunrise/player";
import type { GetErrorButtonFn } from "@sunrise/yallo-error-mapping";
import {
  DialogButtonType,
  getErrorDialogConfiguration,
} from "@sunrise/yallo-error-mapping";

import { errorToShowInDialogAtom } from "@/modules/dialogs/error-to-show-in-dialog.atom";

import { ErrorDialogDescription } from "./error-dialog-description";

/**
 * This component is responsible to show the errors that are put in the atoms which need to be shown to the users.
 * These errors are not really blocking errors but they may cause a certain interaction to fail.
 *
 * Whatever triggered the error should have also cleaned up after itself.
 * For example, if playout failed because the user's tokens were expired or because refresh showed the user no longer exists,
 * it should have already logged out the user.
 *
 * The only cleanup this component does is to remove the error from the state.
 *
 * TODO: remove the business logic to something for easier re-use.
 */
export function ErrorDialog(): undefined {
  const dispatchError = useSetAtom(errorAtom);
  const { error, hidden } = useAtomValue(errorToShowInDialogAtom);
  const store = useStore();

  const dispatchConfirmationDialog = useSetAtom(dialogAtom);
  const [dialogId, setDialogId] = useState<string | null>(null);

  // NOTE: This needs to be a stable dependency since else we will potentially log errors multiple times.
  const close = useAtomCallback(
    useCallback(
      (get): void => {
        dispatchError(null);
        dispatchConfirmationDialog(
          actionDialogClose({ id: get(selectCurrentlyOpenedDialog)?.id }),
        );
      },
      [dispatchConfirmationDialog, dispatchError],
    ),
  );

  const getButtonAction: GetErrorButtonFn = useCallback(
    (code?: DialogButtonType): (() => void) => {
      switch (code) {
        case DialogButtonType.OK:
        case DialogButtonType.CLOSE:
          return close;
        case DialogButtonType.REPLAY_SETTINGS:
          return () => {
            // TODO: Open replay settings once replay settings are there.
            close();
          };
        default:
          return () => {
            /* noop */
          };
      }
    },
    [close],
  );

  // Make sure to clear the relevant error modal we triggered as soon as the error is removed.
  useEffect(() => {
    if ((!error || hidden) && dialogId) {
      dispatchError(null);
      dispatchConfirmationDialog(actionDialogClose({ id: dialogId }));
      setDialogId(null);
    }
  }, [
    error,
    close,
    dialogId,
    dispatchConfirmationDialog,
    dispatchError,
    hidden,
  ]);

  useEffect(() => {
    if (!error) {
      return;
    }

    const doAsync = async (): Promise<void> => {
      const isBaseError = error instanceof BaseError;

      // When the error indicates we should trace, then let's trace it.
      // The error will no longer be sent to the ErrorBoundary.
      const shouldTrace = isBaseError ? error.shouldTrace : true;
      let eventId: string | undefined;

      if (shouldTrace) {
        if (!import.meta.env.VITE_SENTRY_DSN && import.meta.env.DEV) {
          console.info(
            "captureException",
            error,
            isBaseError
              ? {
                  extras: error.extras,
                  errorCode: error.errorCode,
                  cause: error.cause,
                  hidden,
                }
              : undefined,
          );
        }

        eventId = captureException(error, {
          tags: {
            errorCode: isBaseError ? error.errorCode : null,
            location: "error_dialog",
            "player.error": error instanceof PlayerError,
            hidden,
          },
          extra: isBaseError ? error.extras : undefined,
          level: hidden ? "info" : "error",
        });
      }

      // When the dialog is hidden, we don't want to show it.
      if (hidden) {
        return;
      }

      // We force to always have a dialog through forceResult.
      // So whenever an error is set on the error state, we will at least show the general error, even if there is no mapping found.
      const dialog = await getErrorDialogConfiguration(
        error,
        getButtonAction,
        store,
        {
          forceResult: true,
        },
      );

      if (dialog) {
        const actions: ActionDialogAction[] = [];

        if (dialog.confirmationLabel && dialog.onConfirm) {
          actions.push({
            label: dialog.confirmationLabel,
            action: dialog.onConfirm,
            key: "confirm",
            initialFocus: !dialog.focusReject,
          });
        }

        if (dialog.rejectionLabel && dialog.onReject) {
          actions.push({
            label: dialog.rejectionLabel,
            action: dialog.onReject,
            key: "reject",
            initialFocus: !!dialog.focusReject,
          });
        }

        dispatchConfirmationDialog(
          actionDialogOpen({
            title: dialog.title,
            description: dialog.isGeneralError
              ? {
                  type: "component",
                  component: (
                    <ErrorDialogDescription
                      description={dialog.description}
                      eventId={eventId}
                    />
                  ),
                }
              : dialog.description,
            type: "actions",
            backBehaviour: dialog.backBehaviour,
            actions,
            lastFocusKey: getCurrentFocusKey(),
            id: dialog.id,
            technicalErrorName: dialog.technicalErrorName,
          }),
        );

        setDialogId(dialog.id);
      }
    };

    void doAsync();
  }, [
    dispatchConfirmationDialog,
    dispatchError,
    error,
    getButtonAction,
    store,
    hidden,
  ]);
}
