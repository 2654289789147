import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { RecordingGroupId } from "@sunrise/backend-types-core";

export const hasRecordingsForRecordingGroupIdAtom = atomFamily(
  (id: RecordingGroupId) => {
    // TODO: need to implement this atom.

    console.warn(
      "hasRecordingsForRecordingGroupIdAtom not implemented yet.",
      id,
    );
    return atom(() => true);
  },
);
