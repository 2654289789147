import { atom } from "jotai";

import { shakaPlayerAtom } from "../atoms/shaka-player.atom";
import {
  playerCurrentStreamAtom,
  selectPlayerCurrentPlayRequest,
  selectPlayerState,
} from "../player.atom";
import type { ThumbnailGenerator } from "./thumbnail-generator.atom";
import { THUMBNAIL_POSITION_PADDING } from "./thumbnail-generator.atom";

export const shakaThumbnailGeneratorAtom = atom<ThumbnailGenerator | null>(
  (get) => {
    const player = get(shakaPlayerAtom);
    // NOTE: This is here so the atom will re-evaluate when the stream changes.
    get(playerCurrentStreamAtom);
    if (!player) {
      return null;
    }

    const pr = get(selectPlayerCurrentPlayRequest);
    if (!pr || pr.type === "live") {
      return null;
    }

    const state = get(selectPlayerState);

    if (state !== "playing" && state !== "paused") {
      return null;
    }

    return createShakaThumbnailGenerator(player);
  },
);

/**
 * NOTE: Should only be called when the player already has loaded the stream and knows the *correct* image track id. Else it will return nothing at all.
 *
 * @param player
 * @returns
 */
function createShakaThumbnailGenerator(
  player: shaka.Player,
): ThumbnailGenerator | null {
  const currentTrackId = player.getImageTracks()[0]?.id;

  if (!currentTrackId) {
    return null;
  }

  return {
    name: "shaka",
    generate: async (timestamp: number) => {
      try {
        if (timestamp < 0) return null;

        // NOTE: Not used for live, so no white thumbnail workaround needed
        const thumb = await player.getThumbnails(
          currentTrackId,
          timestamp + THUMBNAIL_POSITION_PADDING,
        );
        if (!thumb || !thumb.uris[0]) return null;

        // Guard against non-sprited images. Since we do not support that yet.
        if (thumb.height === thumb.imageHeight) return null;

        return {
          fullHeight: thumb.imageHeight,
          fullWidth: thumb.imageWidth,
          width: thumb.width,
          height: thumb.height,
          // TODO: figure out how it works with multiple uris
          url: thumb.uris[0],
          x: thumb.positionX,
          y: thumb.positionY,
        };
      } catch (_e) {
        return null;
      }
    },
  };
}
