import { type ReactElement, Suspense } from "react";
import { FocusContext } from "@noriginmedia/norigin-spatial-navigation";

import { Header, PageSpinner } from "@/components";
import { RouteLayout } from "@/components/layout/layout";
import { globalFocusKey } from "@/config/focus-key";
import { RecordingUpsellBanner } from "@/features/recordings/recording-upsell-banner";
import { RecordingsList } from "@/features/recordings/recordings-list";
import { useRecordings } from "@/routes/recordings/recordings.hooks";
import { RecordingsLeftMenu } from "@/routes/recordings/recordings-left-menu";
import { RecordingsRightMenu } from "@/routes/recordings/recordings-right-menu";

import * as styles from "./recordings.css";

export const localWidgetFocusKey = {
  page: globalFocusKey.recordingsPage,
  recordingsButtons: globalFocusKey.recordingsPage + ".buttons",
  recordings: globalFocusKey.recordingsPage + ".recordings",
  selectButton: globalFocusKey.recordingsPage + ".buttons.select-button",
  deleteButton: globalFocusKey.recordingsPage + ".buttons.delete-button",
  cancelButton: globalFocusKey.recordingsPage + ".buttons.cancel-button",
  sortButton: globalFocusKey.recordingsPage + ".buttons.sort-button",
  filterButton: globalFocusKey.recordingsPage + ".buttons.filter-button",
  selectAllButton: globalFocusKey.recordingsPage + ".buttons.select-all-button",
};

const DATA_TEST_ID = "recordings";

/**
 * Could use a refactoring where the logic for bulk deletion is extracted for general re-use. Like with web.
 */
export default function Recordings(): ReactElement {
  const { ref, focusKey, focusSelf } = useRecordings();

  return (
    <FocusContext.Provider value={focusKey}>
      <main
        ref={ref}
        className={styles.root}
        data-testid={DATA_TEST_ID}
        tabIndex={0}
      >
        <RouteLayout>
          <Header
            className={styles.header}
            left={<RecordingsLeftMenu />}
            right={<RecordingsRightMenu />}
          />
          <RecordingUpsellBanner />

          <Suspense fallback={<PageSpinner />}>
            <RecordingsList
              focusKey={localWidgetFocusKey.recordings}
              focusList={focusSelf}
            />
          </Suspense>
        </RouteLayout>
      </main>
    </FocusContext.Provider>
  );
}
