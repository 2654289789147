import { atom } from "jotai";

import { pathnameAtom } from "@sunrise/analytics";

import { tizenPaths } from "./tizen-paths";

/**
 * Returns the page name from the pathname.
 * It is not the pageId as known by analytics.
 */
export const pageNameAtom = atom((get) => {
  const pathname = get(pathnameAtom);
  if (!pathname) return;

  // pattern is /protected/page-name/...
  return tizenPaths.get(pathname.split("/").slice(0, 3).join("/"));
});
