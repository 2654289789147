import { atom } from "jotai";

import { isWebOS } from "@sunrise/utils";

import { getWebOSNetwork } from "./system.service";

export const webOSOfflineAtom = atom<null | boolean>(null);
webOSOfflineAtom.onMount = (setValue) => {
  if (!isWebOS()) {
    return;
  }

  getWebOSNetwork().then((network) => {
    if (!network) return;

    network.getStatus({
      onSuccess: function (res) {
        if (!res.isInternetConnectionAvailable) {
          setValue(true);
        } else {
          setValue(false);
        }
      },
      onFailure: function () {
        // API calling error
        setValue(null);
      },
      subscribe: true,
    });
  });
};
