import type { ReactNode } from "react";
import { useCallback, useEffect, useRef } from "react";
import {
  FocusContext,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useAtom } from "jotai";
import gradient from "static/gradients/dialog-title.webp";

import {
  actionScrollableDialogClose,
  scrollableDialogAtom,
} from "@sunrise/dialogs";

import { Dialog } from "@/components";
import { dialogConfig } from "@/config/dialog";
import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import { typography } from "@/styles/typography.css";
import {
  isArrowDownKey,
  isArrowLeftKey,
  isArrowUpKey,
} from "@/utils/navigation";

import * as styles from "./scrollable-dialog.css";

const TEST_ID = "scrollable-dialog";

export function ScrollableDialog(): ReactNode {
  const [dialog, dispatchDialog] = useAtom(scrollableDialogAtom);

  const textContainerRef = useRef<HTMLDivElement>(null);

  const doClose = useCallback(() => {
    dispatchDialog(actionScrollableDialogClose());
  }, [dispatchDialog]);

  const { focusKey, focusSelf, ref } = useFocusable({
    isFocusBoundary: true,
    focusable: dialog.isOpen,
    preferredChildFocusKey: `${dialog.text}`,
    onArrowPress: (direction) => {
      switch (true) {
        case isArrowLeftKey(direction): {
          doClose();
          return false;
        }

        case isArrowUpKey(direction):
        case isArrowDownKey(direction): {
          textContainerRef.current?.scrollBy({
            top: (isArrowUpKey(direction) ? -1 : 1) * dialogConfig.scrollBy,
            behavior: "smooth",
          });

          return false;
        }

        default:
          return false;
      }
    },
  });

  useEffect(() => {
    if (dialog.isOpen) focusSelf();

    const refContainer = textContainerRef.current;
    return () => {
      setFocus(dialog.lastFocusKey);
      refContainer?.scrollTo(0, 0);
    };
  }, [focusSelf, dialog.isOpen, dialog.lastFocusKey]);

  useKeyboardNavigation({
    onBack: doClose,
    isEnabled: !!dialog,
  });
  const testId = dialog.id ? `${TEST_ID}.${dialog.id}` : TEST_ID;

  return (
    <FocusContext.Provider value={focusKey}>
      <Dialog ref={ref} data-testid={testId} open={dialog.isOpen}>
        <div
          ref={textContainerRef}
          className={styles.content}
          data-testid={`${testId}.content`}
        >
          {dialog.title && (
            <div
              className={clsx([styles.title, typography.h6.regular])}
              data-testid={`${testId}.title`}
              style={{
                backgroundImage: `url(${gradient})`,
                backgroundRepeat: `repeat`,
                backgroundSize: "contain",
              }}
            >
              {dialog.title}
            </div>
          )}
          <div
            className={clsx([
              styles.text,
              typography.h6.regular,
              dialog.title && styles.textWithTitle,
            ])}
            data-testid={`${testId}.text`}
          >
            {dialog.text}
          </div>
        </div>
      </Dialog>
    </FocusContext.Provider>
  );
}
