import areEqual from "fast-deep-equal";
import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery, queryClientAtom } from "jotai-tanstack-query";

import type { PageBaseChannelSchema } from "@sunrise/backend-ng-channel";
import { ngChannelApiAtom } from "@sunrise/backend-ng-channel";
import { queryKeys } from "@sunrise/backend-types";
import type { ChannelGroupId, ChannelId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectJwtUserToken } from "@sunrise/jwt";

import { CHANNEL_LIST_PAGE_SIZE } from "./constants";

type ReferenceType =
  | { type: "page"; number: number }
  | { type: "channel"; id: ChannelId };

const CACHE_TIME_IN_MS = 1000 * 60 * 60 * 24;

/**
 * Also needed for the guide package.
 */
export const channelsForChannelGroupPerPageQueryAtom = atomFamily(
  ({
    channelGroupId,
    reference,
  }: {
    channelGroupId: ChannelGroupId;
    reference: ReferenceType;
  }) => {
    return atomWithSuspenseQuery<PageBaseChannelSchema>((get) => {
      const ngApi = get(ngChannelApiAtom);
      const language = get(currentLanguageAtom);
      const qc = get(queryClientAtom);
      const token = get(selectJwtUserToken);

      return {
        queryKey: queryKeys.channelGroupChannelsPage(
          token,
          channelGroupId,
          language,
          reference.type === "channel" ? reference.id : reference.number,
        ),
        queryFn: async () => {
          const data = (
            await ngApi.channel.channelListDetailChannelsChannelV1ChannelListsChannelListIdChannelsGet(
              channelGroupId,
              {
                page: reference.type === "page" ? reference.number : undefined,
                channel:
                  reference.type === "channel" ? reference.id : undefined,
                size: CHANNEL_LIST_PAGE_SIZE,
              },
            )
          ).data;

          if (reference.type === "channel" && data.page) {
            qc.setQueryData(
              queryKeys.channelGroupChannelsPage(
                token,
                channelGroupId,
                language,
                data.page,
              ),
              data,
            );
          }

          return data;
        },
        gcTime: CACHE_TIME_IN_MS,
        staleTime: CACHE_TIME_IN_MS,
      };
    });
  },
  areEqual,
);
