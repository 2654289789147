import type { createStore } from "jotai/vanilla";
import { DevTools, useAtomsDevtools } from "jotai-devtools";

type Store = ReturnType<typeof createStore>;

import "jotai-devtools/styles.css";

export default function LazyDevTools({ store }: { store?: Store }) {
  // hooks into redux dev tools
  useAtomsDevtools("atoms", {
    store,
    enabled: true,
  });

  return <DevTools store={store} />;
}
