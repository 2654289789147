import { type ReactNode, useMemo } from "react";
import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import {
  actionDialogClose,
  actionDialogOpen,
  dialogAtom,
} from "@sunrise/dialogs";
import { useTranslator } from "@sunrise/translator";
import type { Nullable } from "@sunrise/utils";
import {
  lastSelectedSeasonAssetBySeriesAssetIdAtom,
  recordingSeasonsByRecordingGroupId,
} from "@sunrise/yallo-recordings-series";

import { FocusButton } from "@/components";

export type DetailsSeriesFilterProps = {
  recordingGroupId: RecordingGroupId;
  seriesAssetId: AssetId;
  "data-testid"?: string;
  focusKey?: string;
};

export function DetailsSeriesFilter({
  seriesAssetId,
  recordingGroupId,
  "data-testid": testId,
  focusKey,
}: DetailsSeriesFilterProps): ReactNode {
  const t = useTranslator();
  const dispatchDialog = useSetAtom(dialogAtom);
  const [seasonFilter, setSeasonFilter] = useAtom(
    lastSelectedSeasonAssetBySeriesAssetIdAtom({
      seriesAssetId,
      recordingGroupId,
    }),
  );
  const { seasons, recordingsCount } = useAtomValue(
    recordingSeasonsByRecordingGroupId({ recordingGroupId, seriesAssetId }),
  );

  const seasonOptions = useMemo(() => {
    const allText = `${t(
      "season_recording_all_episodes",
    )} (${recordingsCount})`;
    return [
      {
        label: allText,
        value: "all",
      },
      ...seasons.map((season) => ({
        label: `${t("text_season")} ${season.seasonNumber ?? ""}`,
        value: season.seasonId,
      })),
    ];
  }, [seasons, t, recordingsCount]);

  const openFilter = (): void => {
    const sections = [
      {
        options: seasonOptions,
      },
    ];
    const focusOption = sections[0]?.options.find(
      (option) => option.value === seasonFilter,
    );

    dispatchDialog(
      actionDialogOpen({
        id: "details-series-filter",
        type: "list",
        buttonTextAlign: "left",
        onButtonClick: (value: Nullable<string>) => {
          const season = value as RecordingGroupId | "all";

          setSeasonFilter(season);
          dispatchDialog(actionDialogClose());
        },
        sections: sections,
        activeOptions: [focusOption?.value ?? "all"],
        lastFocusKey: getCurrentFocusKey(),
      }),
    );
  };

  if (seasons.length <= 1) {
    return <div />;
  }

  const buttonKey =
    seasonOptions.find((season) => season.value === seasonFilter)?.label ?? "";

  return (
    <FocusButton
      data-testid={testId}
      focusKey={focusKey}
      iconRight="dropdown-down"
      text={buttonKey}
      onEnterPress={openFilter}
    />
  );
}
