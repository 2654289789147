import type { ReactElement } from "react";
import { useAtomValue } from "jotai";
import logoImageUrl from "static/icons/logo-extended.svg";

import { selectIsDialogOpen } from "@sunrise/dialogs";
import { useTranslator } from "@sunrise/translator";

import { useMenu } from "@/features/menu/use-menu";
import { QrCodeLogin } from "@/features/qr-code-login/qr-code-login";
import { useRoutes } from "@/features/routing/use-routes";
import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import { theme } from "@/styles/theme.css";

import styles from "./login.module.css";

export function Login(): ReactElement {
  useMenu({ hidden: true });
  const routes = useRoutes();

  const isDialogOpen = useAtomValue(selectIsDialogOpen);
  useKeyboardNavigation({
    isEnabled: !isDialogOpen,
    onBack: routes.back,
  });

  const t = useTranslator();

  return (
    <main
      className={styles.root}
      style={{ backgroundColor: theme.color.purple[95] }}
    >
      <div className={styles.wrapper}>
        <img className={styles.logo} src={logoImageUrl} />

        <div className={styles.content}>
          <div className={styles.copy}>
            <h1 className={styles.header}>{t("v2_pin_page_title")}</h1>
            <p className={styles.paragraph}>{t("v2_pin_page_description")}</p>
          </div>

          <QrCodeLogin />
        </div>
      </div>
    </main>
  );
}
