import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type {
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";

import { isBulkDeletionModeAtom } from "../bulk-deletion/is-bulk-deletion-mode.atom";
import { recordingsMarkedForBulkDeletionAtom } from "./bulk-deletion.atom";

const FALSE_ATOM = atom(false);

/**
 * Pass it an ID to check if your recording is currently marked for deletion by the client or not.
 * If it is, we should still show the recording. But when we confirm bulk deletion we will tell the backend to delete this recording.
 */
export const isRecordingMarkedForBulkDeletionAtom = atomFamily(
  (id?: RecordingId | RecordingGroupId) => {
    if (!id) {
      return FALSE_ATOM;
    }

    const innerAtom = atom((get) => {
      const inBulkDeletionMode = get(isBulkDeletionModeAtom);
      if (!inBulkDeletionMode) {
        return false;
      }

      const markedForBulkDeletionRecordings = get(
        recordingsMarkedForBulkDeletionAtom,
      );

      return markedForBulkDeletionRecordings.some((item) => item.id === id);
    });

    innerAtom.debugLabel = `isRecordingMarkedForDeletionAtom(${id})`;

    return innerAtom;
  },
);
