import type { ReactElement } from "react";
import type { VirtualItem } from "@tanstack/react-virtual";
import clsx from "clsx";

import { isNil } from "@sunrise/utils";
import type {
  GuideChannel,
  GuideChannelPlaceholder,
} from "@sunrise/yallo-guide";

import { ChannelLogo } from "@/components/channel-logo/channel-logo";
import { ChannelLockIndicator } from "@/features/channel/channel-lock-indicator";
import * as styles from "@/features/guide/guide-channels.css";

type GuideChannelItemProps = {
  size: number;
  items: VirtualItem[];
  channels: (GuideChannel | GuideChannelPlaceholder)[];
  channelHeightInPx: number;
} & CommonProps;

export const GuideChannels = ({
  size,
  items,
  channels,
  channelHeightInPx,
  className,
  "data-testid": dataTestId = "guide-channel-bar",
}: GuideChannelItemProps): ReactElement => {
  /**
   * A sticky positioned bar at the left side of the grid.
   * It contains the channel name for every channel and it will always be visible.
   */
  return (
    <div
      className={clsx([styles.channelBar, className])}
      data-testid={dataTestId}
      style={{
        height: size,
      }}
    >
      {items.map((virtualItem) => {
        const channel = channels[virtualItem.index];

        if (isNil(channel) || !("id" in channel)) {
          return null;
        }

        return (
          <div
            key={virtualItem.key}
            className={styles.channelBarChannelTitle}
            data-testid={`${dataTestId}-${channel.id}`}
            style={{
              height: channelHeightInPx,
              transform: `translateY(${virtualItem.start}px)`,
            }}
          >
            <div className={styles.container}>
              <ChannelLockIndicator
                borderRadius={0}
                channelId={channel.id}
                className={styles.lockIndicator}
                data-testid={`${dataTestId}-${channel.id}.channel-lock-indicator`}
              />
              <div className={styles.channelNo}>{channel.channelNumber}</div>
              {channel.logo ? (
                <ChannelLogo className={styles.logo} url={channel.logo} />
              ) : (
                <span data-testid={`${dataTestId}-${channel.id}.channel-name`}>
                  {channel.name}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
