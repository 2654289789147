import { atomEffect } from "jotai-effect";

import { growthBookAttributesAtom } from "@sunrise/feature-flags";
import { selectIsLoggedIn } from "@sunrise/jwt";
import { appVersionAtom } from "@sunrise/yallo-settings";
import { deviceIdAtom } from "@sunrise/yallo-websocket";

export const updateGrowthbookAttributesEffect = atomEffect((get, set) => {
  const deviceIdAt = get(deviceIdAtom);
  const loggedIn = get(selectIsLoggedIn);
  let usable = true;

  const run = async (): Promise<void> => {
    const deviceId = deviceIdAt ? await get(deviceIdAt) : undefined;
    if (!usable) {
      return;
    }

    set(growthBookAttributesAtom, {
      version: get(appVersionAtom),
      loggedIn,
      deviceId,
      platform: "tizen",
      mobile: false,
      tenant: "yallo",
    });
  };

  run();

  return () => {
    usable = false;
  };
});
