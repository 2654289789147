import { type ReactNode, useEffect } from "react";
import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";
import { useSetAtom } from "jotai";

import { actionDialogOpen, dialogAtom } from "@sunrise/dialogs";
import { actionLocationNavigate, locationAtom } from "@sunrise/location";

import { route } from "@/config/route";

export function MissingDetails(): ReactNode {
  const dispatchDialog = useSetAtom(dialogAtom);
  const dispatchLocation = useSetAtom(locationAtom);

  useEffect(() => {
    dispatchDialog(
      actionDialogOpen({
        type: "actions",
        actions: [
          {
            label: { key: "button_ok" },
            action: () => {
              dispatchLocation(actionLocationNavigate(route.home.root()));
            },
            key: "close",
          },
        ],
        title: { key: "resource_not_found_title" },
        description: { key: "resource_not_found_description" },
        lastFocusKey: getCurrentFocusKey(),
        id: "missing-details",
      }),
    );
  }, [dispatchDialog, dispatchLocation]);

  return <div />;
}
