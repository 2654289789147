import { atom } from "jotai";

import { selectShouldShowWhatIsNext } from "@sunrise/what-is-next";

import { isChannelListHiddenAtom } from "@/features/channel-list";

/**
 * General preconditions for zapping to be allowed.
 */
export const zappingAllowedAtom = atom((get) => {
  return !get(selectShouldShowWhatIsNext) && get(isChannelListHiddenAtom);
});
