import { atom } from "jotai";

import type {
  AscDescSort,
  RecordingsItemsSortSearchParams,
  RecordingsSort,
} from "@sunrise/backend-types";
import { settingsAtom } from "@sunrise/yallo-settings";

import { currentRecordingsTypeFilterAtom } from "./current-recordings-filter.atom";

export const recordingsSortFieldMapping: Record<
  RecordingsSort,
  {
    field: keyof RecordingsItemsSortSearchParams;
    direction: AscDescSort;
  }
> = {
  titleAsc: {
    field: "order_by_title",
    direction: "asc",
  },
  titleDesc: {
    field: "order_by_title",
    direction: "desc",
  },
  dateAsc: {
    field: "order_direction",
    direction: "asc",
  },
  dateDesc: {
    field: "order_direction",
    direction: "desc",
  },
};

// Map the filter type to the asset type from backend
export const recordingsFiltersTypeMappingAtom = atom(async (get) => {
  const settings = await get(settingsAtom);
  const moviesValue = settings?.recordings.filters.find(
    (e) => e.title.en === "Films",
  )?.parameters[0]?.asset_type;
  const seriesValue = settings?.recordings.filters.find(
    (e) => e.title.en === "Series",
  )?.parameters[0]?.asset_type;

  const filterType = get(currentRecordingsTypeFilterAtom);

  if (filterType === "movies") {
    return moviesValue;
  }
  if (filterType === "series") {
    return seriesValue;
  }

  // we can return "all" here since that is checked on the query and in that case we fetch all recordings types
  return "all";
});
