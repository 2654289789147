import { atom } from "jotai/index";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { AssetId } from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";

import { assetRatingByIdLegacyAtom } from "./legacy/asset-rating-by-id.legacy.atom";
import { assetRatingByIdNgAtom } from "./ng/asset-rating-by-id.ng.atom";

export const assetRatingByIdAtom = atomFamily((assetId: Nullable<AssetId>) => {
  const innerAtom = atom<Promise<Nullable<number>>>(async (get) => {
    if (get(isLegacyBackendAtom)) {
      const data = await get(assetRatingByIdLegacyAtom(assetId));
      return data.data?.rating ?? null;
    }

    const data = await get(assetRatingByIdNgAtom(assetId));
    return data.data ?? null;
  });

  innerAtom.debugLabel = `assetRatingByIdAtom(${assetId})`;

  return innerAtom;
});
