import type { JSX } from "react";
import type { ArrowPressHandler } from "@noriginmedia/norigin-spatial-navigation";

import type { ButtonProps } from "@/components";
import { FocusButton } from "@/components";

import { FocusListRadioButton } from "./focus-list-radio-button";

type FocusListButtonProps = CommonProps &
  Pick<ButtonProps, "textAlign" | "active"> & {
    label: string;
    onEnterPress: () => void;
    focusKey?: string;
    isRadioButton?: boolean;
    onArrowPress?: ArrowPressHandler;
  };

export function FocusListButton(props: FocusListButtonProps): JSX.Element {
  if (props.isRadioButton) {
    return (
      <FocusListRadioButton
        active={props.active}
        className={props.className}
        data-testid={props["data-testid"]}
        focusKey={props.focusKey}
        text={props.label}
        textAlign={props.textAlign}
        variant="ghost"
        block
        scrollOnFocus
        short
        onArrowPress={props.onArrowPress}
        onEnterPress={props.onEnterPress}
      />
    );
  }
  return (
    <FocusButton
      active={props.active}
      className={props.className}
      data-testid={props["data-testid"]}
      focusKey={props.focusKey}
      text={props.label}
      textAlign={props.textAlign}
      variant="ghost"
      block
      scrollOnFocus
      short
      onArrowPress={props.onArrowPress}
      onEnterPress={props.onEnterPress}
    />
  );
}
