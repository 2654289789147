import type { ReactNode } from "react";
import {
  FocusContext,
  ROOT_FOCUS_KEY,
} from "@noriginmedia/norigin-spatial-navigation";
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useHydrateAtoms } from "jotai/utils";
import { queryClientAtom } from "jotai-tanstack-query";

import { BaseError } from "@sunrise/error";
import { type Store, StoreProvider } from "@sunrise/store";
import { TranslationProvider } from "@sunrise/translator";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error): boolean {
        if (failureCount >= 2) {
          return false;
        }

        if (error instanceof BaseError) {
          return error.shouldRetry;
        }

        return true;
      },
    },
  },
});

const HydrateAtoms = () => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);

  return null;
};

export function AppProvider(props: {
  children: ReactNode;
  enableDevTools: boolean;
  storeInstance: Store;
}): ReactNode {
  return (
    <StoreProvider
      enableDevTools={props.enableDevTools}
      store={props.storeInstance}
    >
      <HydrateAtoms />
      <QueryClientProvider client={queryClient}>
        {props.enableDevTools && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom" />
        )}
        <TranslationProvider>
          <FocusContext.Provider value={ROOT_FOCUS_KEY}>
            {props.children}
          </FocusContext.Provider>
        </TranslationProvider>
      </QueryClientProvider>
    </StoreProvider>
  );
}
