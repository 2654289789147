/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AssetSchema */
export interface AssetSchema {
  /**
   * Asset ID
   * The id of the asset
   */
  id: string;
  /**
   * Asset Title
   * The title of the asset
   */
  title: string;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   * @default ""
   */
  subtitle?: string | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url: string;
  /**
   * Season Number
   * The season number of the series episode
   */
  season_number?: number | null;
  /**
   * Episode Number
   * The episode number of the series episode
   */
  episode_number?: number | null;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Type
   * The type of the asset (movie, series episode...)
   * @default "unknown"
   */
  type?: AssetType | null;
  /** Production Year */
  production_year?: number | null;
  /** Age Recommendation */
  age_recommendation?: number | null;
  /** Genres */
  genres?: ReducedGenreSchema[] | null;
  /** Description */
  description?: string | null;
  /** Cast */
  cast?: CastMember[] | null;
  /** Series */
  readonly series: string;
  /** Series Season */
  readonly series_season: string;
  /**
   * Asset Recording Available
   * Is it possible to record this asset as a series?
   */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** AssetType */
export enum AssetType {
  Series = "series",
  Seriesepisode = "seriesepisode",
  Seriesseason = "seriesseason",
  Movie = "movie",
  Program = "program",
  Unknown = "unknown",
}

/** BaseRecordingGroupSchema */
export interface BaseRecordingGroupSchema {
  /**
   * Recording Item ID
   * The id of the recording item
   */
  id: string;
  /** Type */
  type: "recording_group";
  asset: ReducedAssetSchema;
  /**
   * Status
   * The status of the recording group
   */
  status: RecordingGroupStatus;
  /**
   * Episode Count
   * @default 0
   */
  episode_count?: number;
}

/** BaseRecordingSchema */
export interface BaseRecordingSchema {
  /**
   * Recording Item ID
   * The id of the recording item
   */
  id: string;
  /** Type */
  type?: "recording";
  asset: ReducedAssetSchema;
  /**
   * EPG entry ID
   * The id of the epg entry used to create the recording. Unavailable when the start date is outside the epg window.
   */
  epg_entry_id: string;
  /**
   * EPG Start Time
   * The start time of the EPG
   * @format date-time
   */
  epg_start: TimeISOString;
  /**
   * EPG End Time
   * The end time of the EPG
   * @format date-time
   */
  epg_end: TimeISOString;
  /**
   * Recording status
   * Status of the recording
   */
  status: RecordingStatus;
  /**
   * Channel name
   * name of the channel
   * @default ""
   */
  channel_name?: string;
  /**
   * Channel logo
   * URL of the channel's logo
   * @default ""
   */
  channel_logo?: string;
  /**
   * Channel ID
   * The original id of the channel. When the channel is deleted, this attribute will return null.
   */
  channel_id?: string | null;
  /**
   * Padding Start Minutes
   * The padding start minutes of the recording
   */
  padding_start_minutes: number;
  /**
   * Padding End Minutes
   * The padding end minutes of the recording
   */
  padding_end_minutes: number;
  /**
   * Recordings Stream Service URL
   * The recording URL of the stream service
   */
  readonly stream_service_url: string | null;
  /**
   * Download Stream Service URL
   * The download URL of the stream service
   */
  readonly stream_service_download_url: string | null;
}

/** CastMember */
export interface CastMember {
  /** Character */
  character?: string | null;
  /** Full Name */
  readonly full_name: string | null;
  /** Asset Id */
  readonly asset_id: string | null;
}

/** CrewMember */
export interface CrewMember {
  /** Job */
  job?: string | null;
  /** Full Name */
  readonly full_name: string | null;
  /** Asset Id */
  readonly asset_id: string | null;
}

/** EpgEntryRecordingStatusSchema */
export interface EpgEntryRecordingStatusSchema {
  /**
   * Recording ID
   * The recording id of the asset
   */
  recording_id: string;
  /**
   * Status
   * The recording status of the asset
   */
  status: RecordingStatus;
}

/** FilterAvailabilitySchema */
export interface FilterAvailabilitySchema {
  /**
   * type
   * type
   */
  type: FilterRecordingStatus;
  /**
   * amount number
   * amount
   */
  count: number;
}

/** FilterChannelSchema */
export interface FilterChannelSchema {
  /**
   * Channel id
   * channel id
   */
  id: string;
  /**
   * Logo
   * Logo of the channel
   */
  logo: string;
  /**
   * Name
   * Name of the channel
   */
  name: string;
}

/** FilterRecordingStatus */
export enum FilterRecordingStatus {
  All = "all",
  Available = "available",
  Upcoming = "upcoming",
  Recorded = "recorded",
  Planned = "planned",
}

/** FilterSeasonSchema */
export interface FilterSeasonSchema {
  /**
   * season id
   * season id
   */
  recording_group_id: string;
  /**
   * season number
   * Season number
   */
  season_number: number;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Page[Annotated[Union[BaseRecordingSchema, BaseRecordingGroupSchema], FieldInfo(annotation=NoneType, required=True, discriminator='type')]] */
export interface PageAnnotatedUnionBaseRecordingSchemaBaseRecordingGroupSchemaFieldInfoAnnotationNoneTypeRequiredTrueDiscriminatorType {
  /** Items */
  items: (
    | ({
        type: "recording";
      } & BaseRecordingSchema)
    | ({
        type: "recording_group";
      } & BaseRecordingGroupSchema)
  )[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** Page[BaseRecordingSchema] */
export interface PageBaseRecordingSchema {
  /** Items */
  items: BaseRecordingSchema[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** Page[RecordingStatusSchema] */
export interface PageRecordingStatusSchema {
  /** Items */
  items: RecordingStatusSchema[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** PersonSchema */
export interface PersonSchema {
  /** Id of the asset */
  id: string;
  /**
   * Type
   * @default "person"
   */
  type?: "person";
  /** Full Name */
  full_name?: string | null;
  /** Picture */
  picture?: string | null;
}

/** RecordingGroupFilterSchema */
export interface RecordingGroupFilterSchema {
  /**
   * FilterChannelSchemas
   * List of FilterChannelSchema to filter to
   */
  channels: FilterChannelSchema[];
  /**
   * Season schemas
   * List of season schemas to filter to
   */
  seasons: FilterSeasonSchema[];
  /**
   * list of FilterSeasonSchema
   * List of FilterSeasonSchema to filter to
   */
  availability: FilterAvailabilitySchema[];
}

/** RecordingGroupSchema */
export interface RecordingGroupSchema {
  /**
   * Recording Item ID
   * The id of the recording item
   */
  id: string;
  /** Type */
  type: "recording_group";
  asset: AssetSchema;
  /**
   * Status
   * The status of the recording group
   */
  status: RecordingGroupStatus;
  /**
   * Episode Count
   * @default 0
   */
  episode_count?: number;
  /**
   * Season
   * Season number. (Present only if this recording group is for a season)
   */
  season: number | null;
  /**
   * Recording Schedule Active
   * This flag is used by the applications to decide wheter to display the "cancel recording" or "record series options"
   * At the moment, it's kind of a mock. To determine if there is a scheduler for this recording group with precision,
   * we need to also replicate recording_schedules to this service's db, which is planned to be implemented by
   * https://entwicklungspark.atlassian.net/browse/YALLOTV-18133.
   * Don't remove the optional in the return without checking with Android (mobile) people that won't break their
   * parsing.
   */
  readonly recording_schedule_active: boolean | null;
}

/** RecordingGroupStatus */
export enum RecordingGroupStatus {
  AllRecorded = "all_recorded",
  AllPlanned = "all_planned",
  Deleted = "deleted",
  Mixed = "mixed",
}

/** RecordingGroupStatusSchema */
export interface RecordingGroupStatusSchema {
  /** Id */
  id: string;
  /** Status */
  status: string;
}

/** RecordingItemFilter */
export enum RecordingItemFilter {
  Recording = "recording",
  RecordingGroup = "recording_group",
  All = "all",
}

/** RecordingScheduleRequest */
export interface RecordingScheduleRequest {
  /**
   * EPG ID
   * The id of an epg entry
   */
  epg_entry_id: string;
  /**
   * Recording Schedule Type
   * Defines if the schedule is for a series or for a single recording
   */
  type: RecordingScheduleTypes;
}

/** RecordingScheduleTypes */
export enum RecordingScheduleTypes {
  Single = "single",
  Asset = "asset",
}

/**
 * RecordingSchema
 * TODO: Add more needed detail endpoint episodes
 */
export interface RecordingSchema {
  /**
   * Recording Item ID
   * The id of the recording item
   */
  id: string;
  /** Type */
  type: "recording";
  asset: AssetSchema;
  /**
   * EPG entry ID
   * The id of the epg entry used to create the recording. Unavailable when the start date is outside the epg window.
   */
  epg_entry_id: string;
  /**
   * EPG Start Time
   * The start time of the EPG
   * @format date-time
   */
  epg_start: TimeISOString;
  /**
   * EPG End Time
   * The end time of the EPG
   * @format date-time
   */
  epg_end: TimeISOString;
  /**
   * Recording status
   * Status of the recording
   */
  status: RecordingStatus;
  /**
   * Channel name
   * name of the channel
   * @default ""
   */
  channel_name?: string;
  /**
   * Channel logo
   * URL of the channel's logo
   * @default ""
   */
  channel_logo?: string;
  /**
   * Channel ID
   * The original id of the channel. When the channel is deleted, this attribute will return null.
   */
  channel_id?: string | null;
  /**
   * Padding Start Minutes
   * The padding start minutes of the recording
   */
  padding_start_minutes: number;
  /**
   * Padding End Minutes
   * The padding end minutes of the recording
   */
  padding_end_minutes: number;
  /** Episode */
  episode?: number | null;
  /**
   * Recordings Stream Service URL
   * The recording URL of the stream service
   */
  readonly stream_service_url: string | null;
  /**
   * Download Stream Service URL
   * The download URL of the stream service
   */
  readonly stream_service_download_url: string | null;
}

/** RecordingSortingOption */
export enum RecordingSortingOption {
  EpgStartDescending = "epg_start_descending",
  EpgStartAscending = "epg_start_ascending",
  TitleDescending = "title_descending",
  TitleAscending = "title_ascending",
}

/** RecordingStatus */
export enum RecordingStatus {
  All = "all",
  Recorded = "recorded",
  Planned = "planned",
  Canceled = "canceled",
  Deleted = "deleted",
}

/** RecordingStatusSchema */
export interface RecordingStatusSchema {
  /**
   * Recording ID
   * The recording id of the asset
   */
  recording_id: string;
  /**
   * Status
   * The recording status of the asset
   */
  status: RecordingStatus | RecordingGroupStatus;
}

/** RecordingsCountSchema */
export interface RecordingsCountSchema {
  /**
   * Single Recordings
   * The number of the users single recordings
   */
  single_recordings: number;
  /**
   * Planned Recordings
   * The number of the users planned recordings
   */
  planned_recordings: number;
  /**
   * Series Recording Groups
   * The number of the users series recording groups
   */
  recording_groups: number;
  /**
   * User limit
   * The users limit count for recordings
   */
  user_limit: number;
}

/** ReducedAssetSchema */
export interface ReducedAssetSchema {
  /**
   * Asset ID
   * The id of the asset
   */
  id: string;
  /**
   * Asset Title
   * The title of the asset
   */
  title: string;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   * @default ""
   */
  subtitle?: string | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url: string;
  /**
   * Season Number
   * The season number of the series episode
   */
  season_number?: number | null;
  /**
   * Episode Number
   * The episode number of the series episode
   */
  episode_number?: number | null;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Type
   * The type of the asset (movie, series episode...)
   * @default "unknown"
   */
  type?: AssetType | null;
  /** Series */
  readonly series: string;
  /** Series Season */
  readonly series_season: string;
  /**
   * Asset Recording Available
   * Is it possible to record this asset as a series?
   */
  readonly asset_recording_available: boolean;
}

/** ReducedGenreSchema */
export interface ReducedGenreSchema {
  /**
   * Genre Name
   * The name of the genre
   */
  name?: string | null;
}

/** SortingOption */
export interface SortingOption {
  /** Translation Key */
  translation_key: string;
  /** Query Value */
  query_value: string;
}

/** ValidLanguages */
export enum ValidLanguages {
  En = "en",
  De = "de",
  Fr = "fr",
  It = "it",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { ContentType, HttpClient, RequestParams } from "@sunrise/backend-ng-core";
import type { TimeISOString } from "@sunrise/backend-types-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  recording = {
    /**
     * No description
     *
     * @name HealthRecordingV1HealthGet
     * @summary Health
     * @request GET:/recording/v1/health
     */
    healthRecordingV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/recording/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recordings
     * @name RecordingItemsListRecordingV1RecordingsGet
     * @summary Recording Items List
     * @request GET:/recording/v1/recordings
     * @secure
     */
    recordingItemsListRecordingV1RecordingsGet: (
      query?: {
        /**
         * Item Type
         * @default "all"
         */
        item_type?: RecordingItemFilter;
        /**
         * Item Status
         * @default "recorded"
         */
        item_status?: RecordingStatus | RecordingGroupStatus;
        /**
         * Sort By
         * @default "epg_start_descending"
         */
        sort_by?: RecordingSortingOption;
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        PageAnnotatedUnionBaseRecordingSchemaBaseRecordingGroupSchemaFieldInfoAnnotationNoneTypeRequiredTrueDiscriminatorType,
        void | HTTPValidationError
      >({
        path: `/recording/v1/recordings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recordings
     * @name GetRecordingRecordingV1RecordingsRecordingIdGet
     * @summary Get Recording
     * @request GET:/recording/v1/recordings/{recording_id}
     * @secure
     */
    getRecordingRecordingV1RecordingsRecordingIdGet: (recordingId: string, params: RequestParams = {}) =>
      this.http.request<
        | ({
            type: "recording";
          } & RecordingSchema)
        | ({
            type: "recording_group";
          } & RecordingGroupSchema),
        void | HTTPValidationError
      >({
        path: `/recording/v1/recordings/${recordingId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recordings
     * @name DeleteRecordingRecordingV1RecordingsRecordingIdDelete
     * @summary Delete Recording
     * @request DELETE:/recording/v1/recordings/{recording_id}
     * @secure
     */
    deleteRecordingRecordingV1RecordingsRecordingIdDelete: (recordingId: string, params: RequestParams = {}) =>
      this.http.request<void, void | HTTPValidationError>({
        path: `/recording/v1/recordings/${recordingId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags recordings-status
     * @name GetRecordingsStatusRecordingV1RecordingsStatusGet
     * @summary Get Recordings Status
     * @request GET:/recording/v1/recordings-status
     * @secure
     */
    getRecordingsStatusRecordingV1RecordingsStatusGet: (
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageRecordingStatusSchema, void | HTTPValidationError>({
        path: `/recording/v1/recordings-status`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recordings-count
     * @name GetUserRecordingsCountRecordingV1RecordingsCountGet
     * @summary Get User Recordings Count
     * @request GET:/recording/v1/recordings-count
     * @secure
     */
    getUserRecordingsCountRecordingV1RecordingsCountGet: (params: RequestParams = {}) =>
      this.http.request<RecordingsCountSchema, void>({
        path: `/recording/v1/recordings-count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recording-groups
     * @name DeleteRecordingGroupRecordingV1RecordingGroupsRecordingGroupIdDelete
     * @summary Delete Recording Group
     * @request DELETE:/recording/v1/recording-groups/{recording_group_id}
     * @secure
     */
    deleteRecordingGroupRecordingV1RecordingGroupsRecordingGroupIdDelete: (
      recordingGroupId: string,
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | HTTPValidationError>({
        path: `/recording/v1/recording-groups/${recordingGroupId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags recording-groups
     * @name GetEpisodesOfRecordingGroupRecordingV1RecordingGroupsRecordingGroupIdEpisodesGet
     * @summary Get Episodes Of Recording Group
     * @request GET:/recording/v1/recording-groups/{recording_group_id}/episodes
     * @secure
     */
    getEpisodesOfRecordingGroupRecordingV1RecordingGroupsRecordingGroupIdEpisodesGet: (
      recordingGroupId: string,
      query?: {
        /**
         * Sort By
         * @default "epg_start_descending"
         */
        sort_by?: RecordingSortingOption;
        /** Status */
        status?: RecordingStatus;
        /** Channel Id */
        channel_id?: string;
        /** Season Number */
        season_number?: number;
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageBaseRecordingSchema, void | HTTPValidationError>({
        path: `/recording/v1/recording-groups/${recordingGroupId}/episodes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recording-groups
     * @name GetRecordingGroupFiltersByIdRecordingV1RecordingGroupsRecordingGroupIdFiltersGet
     * @summary Get Recording Group Filters By Id
     * @request GET:/recording/v1/recording-groups/{recording_group_id}/filters
     * @secure
     */
    getRecordingGroupFiltersByIdRecordingV1RecordingGroupsRecordingGroupIdFiltersGet: (
      recordingGroupId: string,
      params: RequestParams = {},
    ) =>
      this.http.request<RecordingGroupFilterSchema, void | HTTPValidationError>({
        path: `/recording/v1/recording-groups/${recordingGroupId}/filters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recording-groups
     * @name GetRecordingGroupStatusRecordingV1RecordingGroupsRecordingGroupIdStatusGet
     * @summary Get Recording Group Status
     * @request GET:/recording/v1/recording-groups/{recording_group_id}/status
     * @secure
     */
    getRecordingGroupStatusRecordingV1RecordingGroupsRecordingGroupIdStatusGet: (
      recordingGroupId: string,
      params: RequestParams = {},
    ) =>
      this.http.request<RecordingGroupStatusSchema, void | HTTPValidationError>({
        path: `/recording/v1/recording-groups/${recordingGroupId}/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recording-schedules
     * @name CreateNewRecordingScheduleRecordingV1RecordingSchedulesPost
     * @summary Create New Recording Schedule
     * @request POST:/recording/v1/recording-schedules
     * @secure
     */
    createNewRecordingScheduleRecordingV1RecordingSchedulesPost: (
      data: RecordingScheduleRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<any, void | HTTPValidationError>({
        path: `/recording/v1/recording-schedules`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Cancels recording group by recording_group id. This stops the recording of a recording group, but won't delete any items.
     *
     * @tags recording-schedules
     * @name DeleteRecordingScheduleRecordingV1RecordingSchedulesDelete
     * @summary Delete Recording Schedule
     * @request DELETE:/recording/v1/recording-schedules
     * @secure
     */
    deleteRecordingScheduleRecordingV1RecordingSchedulesDelete: (
      query?: {
        /** Recording Group Id */
        recording_group_id?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RecordingStatusSchema, void | HTTPValidationError>({
        path: `/recording/v1/recording-schedules`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags epg-entry-recording-status
     * @name GetEpgEntryRecordingStatusRecordingV1EpgEntryRecordingStatusEpgEntryIdGet
     * @summary Get Epg Entry Recording Status
     * @request GET:/recording/v1/epg-entry-recording-status/{epg_entry_id}
     * @secure
     */
    getEpgEntryRecordingStatusRecordingV1EpgEntryRecordingStatusEpgEntryIdGet: (
      epgEntryId: string,
      params: RequestParams = {},
    ) =>
      this.http.request<EpgEntryRecordingStatusSchema, void | HTTPValidationError>({
        path: `/recording/v1/epg-entry-recording-status/${epgEntryId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags sorting-options
     * @name GetSortingOptionsRecordingV1SortingOptionsGet
     * @summary Get Sorting Options
     * @request GET:/recording/v1/sorting-options
     */
    getSortingOptionsRecordingV1SortingOptionsGet: (params: RequestParams = {}) =>
      this.http.request<SortingOption[], void>({
        path: `/recording/v1/sorting-options`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags sorting-options
     * @name GetEpisodesSortingOptionsRecordingV1EpisodesSortingOptionsGet
     * @summary Get Episodes Sorting Options
     * @request GET:/recording/v1/episodes-sorting-options
     */
    getEpisodesSortingOptionsRecordingV1EpisodesSortingOptionsGet: (params: RequestParams = {}) =>
      this.http.request<SortingOption[], void>({
        path: `/recording/v1/episodes-sorting-options`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags metrics
     * @name GetAllMetricsRecordingV1MetricsDataGet
     * @summary Get All Metrics
     * @request GET:/recording/v1/metrics-data
     * @secure
     */
    getAllMetricsRecordingV1MetricsDataGet: (params: RequestParams = {}) =>
      this.http.request<object[], void>({
        path: `/recording/v1/metrics-data`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootRecordingV1Get
     * @summary Root
     * @request GET:/recording/v1
     */
    rootRecordingV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/recording/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
