import { atom } from "jotai";

import { isSmartTV, isWebOS } from "@sunrise/utils";

import { enableMagicMouseAtom } from "./mouse-navigation";

export const isCursorVisibleAtom = atom((get) => {
  const enabled = get(enableMagicMouseAtom);

  // can be changed in the 'feature flags' page
  if (enabled) {
    return get(_isCursorVisibleAtom);
  }

  return false;
});

const _isCursorVisibleAtom = atom(false);

_isCursorVisibleAtom.onMount = (set) => {
  function onMouseMove(): void {
    set(true);
  }

  function onKeyDown(): void {
    // TODO check only for relevant keys like left/right/up/down/back
    set(false);
  }

  function cursorVisibilityChange(
    event: CustomEvent<{ visibility: boolean }>,
  ): void {
    set(event.detail.visibility);
  }

  if (!isSmartTV()) {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("keydown", onKeyDown);
    };
  }

  if (isWebOS()) {
    document.addEventListener(
      "cursorStateChange",
      cursorVisibilityChange as EventListener,
      false,
    );

    return () => {
      document.removeEventListener(
        "cursorStateChange",
        cursorVisibilityChange as EventListener,
        false,
      );
    };
  }

  return;
};
