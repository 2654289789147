import { type ReactElement } from "react";

import { useTranslator } from "@sunrise/translator";
import { useGridDateSelector } from "@sunrise/yallo-guide";

import { FocusButton, Header } from "@/components";
import { GuideControlsNavDate } from "@/features/guide/guide-controls/guide-controls-nav-date";

import { FocusContainer } from "../../../utils/focus-container";
import { ChannelGroupSelector } from "./channel-group-selector";
import * as styles from "./guide-controls.css";

export function GuideControls({
  focusKey,
  onBack,
}: {
  focusKey: string;
  onBack: () => void;
}): ReactElement {
  const t = useTranslator();

  const { moveToNow, moveToPrimeTime } = useGridDateSelector();

  // TODO: Refactor:
  // Move Header to routes/guid, and passed "grid-nav-now" button as a child.
  // The now button should encapsulate "moveToNow" callback
  return (
    <FocusContainer focusKey={focusKey} onBack={onBack} onLeft={onBack}>
      {(directions) => (
        <Header
          left={
            <>
              <GuideControlsNavDate
                onArrowLeft={directions.onArrowPress("left")}
              />
              <FocusButton
                className={styles.button}
                focusKey="grid-nav-now"
                text={t("guide_now_button")}
                onEnterPress={moveToNow}
              />
              <FocusButton
                className={styles.button}
                focusKey="grid-nav-prime-time"
                text={t("guide_primetime_button")}
                onEnterPress={moveToPrimeTime}
              />
            </>
          }
          right={<ChannelGroupSelector />}
        />
      )}
    </FocusContainer>
  );
}
