import type { InfiniteData } from "@tanstack/query-core";
import { atomFamily } from "jotai/utils";
import {
  atomWithInfiniteQuery,
  atomWithSuspenseQuery,
} from "jotai-tanstack-query";

import type {
  HTTPValidationError,
  PageUnionEpgEntrySchemaReducedChannelSchema,
} from "@sunrise/backend-ng-recommendations";
import { ngRecommendationsApiAtom } from "@sunrise/backend-ng-recommendations";
import type {
  Language,
  RecommendationListId,
} from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { isNil } from "@sunrise/utils";

const PAGE_SIZE = 10;

// get all rows to display in the recommendations page
export const ngRecommendationsRowsQueryAtom = atomWithSuspenseQuery((get) => {
  const api = get(ngRecommendationsApiAtom);
  if (isNil(api)) {
    throw new Error("ngRecommendationsApiAtom is not initialized");
  }

  const language = get(currentLanguageAtom);

  return {
    // TODO: also extract queryKeys to a shared place.
    queryKey: ["recommendations_rows", language],
    queryFn: async () => {
      const result =
        await api.recommendation.recommendationListsRecommendationV1RecommendationListsGet();

      return result.data;
    },
  };
});

// get all recommendations for a specific row
export const ngRowRecommendationsQueryAtom = atomFamily(
  (id: RecommendationListId) => {
    return atomWithInfiniteQuery<
      PageUnionEpgEntrySchemaReducedChannelSchema,
      HTTPValidationError,
      InfiniteData<PageUnionEpgEntrySchemaReducedChannelSchema>,
      [string | null, Language, string],
      number
    >((get) => {
      const api = get(ngRecommendationsApiAtom);
      if (isNil(api)) {
        throw new Error("ngChannelApiAtom is not initialized");
      }

      const language = get(currentLanguageAtom);

      return {
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
          if (!lastPage) return undefined;
          const { page, pages } = lastPage;
          if (!pages || !page) return undefined;

          return page < pages ? page + 1 : undefined;
        },
        // TODO: also extract queryKeys to a shared place.
        queryKey: ["recommendation_row_id", language, id],
        queryFn: async ({ pageParam }) => {
          const result =
            await api.recommendation.recommendationListDetailRecommendationsRecommendationV1RecommendationListsRecommendationListIdRecommendationsGet(
              id,
              {
                page: pageParam,
                size: PAGE_SIZE,
              },
            );

          return result.data;
        },
      };
    });
  },
);
