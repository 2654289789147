import type { ReactNode } from "react";
import { forwardRef } from "react";
import clsx from "clsx";
import gradientChannel from "static/gradients/channel-box.webp";
import gradientLarge from "static/gradients/program-box-large.webp";
import gradientNormal from "static/gradients/program-box-normal.webp";

import { isNil, type Nullable } from "@sunrise/utils";
import type { SimplifiedRecordingStatus } from "@sunrise/yallo-recordings";

import { ChannelLogo } from "@/components/channel-logo/channel-logo";
import { Icon } from "@/components/icon";
import { ProgressBar } from "@/components/progress-bar/progress-bar";
import { Tag } from "@/components/tag/tag";

import type { Kind } from "../types";
import * as styles from "./program-cover.css";

export type ProgramCoverProps = CommonProps & {
  coverImageUrl: Nullable<string>;
  kind: Kind;
  focused?: boolean;
  liveProgress?: Nullable<number>;
  replayProgress?: Nullable<number>;
  logoUrl: Nullable<string>;
  isSeries?: boolean;
  recordingState?: Nullable<SimplifiedRecordingStatus>;
  isMarkedForDeletion?: boolean;
};

type ContentProps = {
  kind: ProgramCoverProps["kind"];
  logoUrl: Nullable<string>;
  liveProgress?: Nullable<number>;
  replayProgress?: Nullable<number>;
  isSeries?: boolean;
} & CommonProps;

function Content({
  "data-testid": dataTestId = "content",
  kind,
  logoUrl,
  liveProgress,
  replayProgress,
  isSeries,
}: ContentProps): ReactNode {
  const shouldShowLiveProgress = !isNil(liveProgress) || !isNil(replayProgress);
  if (kind === "channel")
    return (
      logoUrl && (
        <ChannelLogo
          className={styles.channelLogo}
          data-testid="channel-box-logo"
          url={logoUrl}
          big
        />
      )
    );
  else {
    return isSeries ? (
      <div className={styles.seriesIcon} data-testid="series-icon">
        <Icon color="white" name="directory" />
      </div>
    ) : (
      <div className={styles.content}>
        {logoUrl && (
          <ChannelLogo
            className={styles.logo}
            data-testid="cover-channel-logo"
            url={logoUrl}
          />
        )}
        {shouldShowLiveProgress && (
          <div className={styles.progressBar} data-testid="cover-progress-bar">
            <ProgressBar
              data-testid={`${dataTestId}-progress-bar`}
              height={8}
              liveProgress={
                replayProgress && !liveProgress ? 100 : liveProgress
              }
              radius={8}
              replayProgress={replayProgress}
            />
          </div>
        )}
      </div>
    );
  }
}

export const ProgramCover = forwardRef<HTMLDivElement, ProgramCoverProps>(
  function ForwardRefProgramBox(
    { "data-testid": dataTestId = "program-box", ...props },
    ref,
  ) {
    const gradients = {
      normal: gradientNormal,
      large: gradientLarge,
      row: null,
      channel: gradientChannel,
      recordings: gradientNormal,
    };
    const gradient = gradients[props.kind];

    const recordingIcon = getRecordingIcon(props.recordingState);

    return (
      <div
        ref={ref}
        className={clsx(
          styles.cover,
          styles[props.kind],
          props.focused && styles.focused,
          props.className,
        )}
        data-testid={dataTestId}
        style={{
          backgroundImage: `url(${gradient ?? ""}), url(${
            props.coverImageUrl
          })`,
          backgroundRepeat: `repeat, no-repeat`,
          backgroundSize: "contain, cover",
          backgroundPositionX: `left, center`,
        }}
      >
        {props.isMarkedForDeletion ? (
          <div className={styles.markedForDeletion} />
        ) : null}
        {recordingIcon && (
          <div className={styles.recordedIcon}>
            <Tag
              data-testid={`${dataTestId}-recording-tag`}
              icon={recordingIcon}
              text="REC"
            />
          </div>
        )}
        <Content
          data-testid={`${dataTestId}-content`}
          isSeries={props.isSeries}
          kind={props.kind}
          liveProgress={props.liveProgress}
          logoUrl={props.logoUrl}
          replayProgress={props.replayProgress}
        />
      </div>
    );
  },
);

function getRecordingIcon(
  recordingState: Nullable<SimplifiedRecordingStatus>,
): "recorded" | "recording" | null {
  switch (recordingState) {
    case "planned":
      return "recording";
    case "recorded":
      return "recorded";
    default:
      return null;
  }
}
