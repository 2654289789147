import { memo, Suspense } from "react";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import type { VirtualItem } from "@tanstack/react-virtual";

import type { ChannelListItem } from "@sunrise/backend-types";
import { usePlayChannel } from "@sunrise/yallo-common-player-manager";

import { ChannelItemSpinner } from "@/components";
import { channelListItem } from "@/features/channel-list/channel-list.css";

import { ChannelItem } from "../channel-item";

type VirtualizedChannelItemProps = {
  virtualItem: VirtualItem;
  "data-testid"?: string;
  channel: ChannelListItem;
  lastRef: React.RefObject<HTMLDivElement>;
  onArrowPress: (direction: string) => boolean;
  isLast: boolean;
  focusKey: string;
};

export function VirtualizedChannelItemInternal({
  virtualItem,
  focusKey,
  lastRef,
  onArrowPress,
  isLast,
  channel,
  "data-testid": dataTestId,
}: VirtualizedChannelItemProps) {
  const itemStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    transform: `translateY(${virtualItem.start}px)`,
  };
  const { play, isActive } = usePlayChannel({
    channelId: channel.id,
  });
  const { ref, focusSelf, focused } = useFocusable({
    focusKey,
    onArrowPress,
    onEnterPress: play,
  });

  return (
    <div key={`${virtualItem.index}`} ref={ref}>
      <Suspense
        fallback={
          <ChannelItemSpinner
            className={channelListItem}
            data-testid={dataTestId}
            style={itemStyle}
          />
        }
      >
        <ChannelItem
          data-testid={`${dataTestId}.list-el.${channel.id}`}
          focusSelf={focusSelf}
          focused={focused}
          isActive={isActive}
          item={channel}
          play={play}
          style={itemStyle}
          onScreen={!!virtualItem}
        />
      </Suspense>
      {isLast && (
        <div ref={lastRef} id="channel-list-last-ref" style={itemStyle} />
      )}
    </div>
  );
}

export const VirtualizedChannelItem = memo(VirtualizedChannelItemInternal);
