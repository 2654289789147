import type { VirtualItem } from "@tanstack/react-virtual";
import { useAtomValue } from "jotai";

import { isNil } from "@sunrise/utils";
import {
  getProgramsStartingAtTime,
  type GuideChannel,
  type GuideChannelPlaceholder,
  selectorGuideSelection,
} from "@sunrise/yallo-guide";

import {
  TIME_BLOCK_SIZE_IN_MINUTES,
  type TimeBlock,
} from "@/features/guide/guide-grid";
import { GuideProgramRow } from "@/features/guide/guide-row/guide-program-row";

import * as styles from "../guide-grid.css";

type GuideRowProps = {
  channel: GuideChannel | GuideChannelPlaceholder;
  oneMinuteWidthInPx: number;
  channelBarCutoff: number | undefined;
  fullWidth: number;
  virtualTimeBlocks: VirtualItem[];
  timeBlocks: TimeBlock[];
  virtualItem: VirtualItem;
  shouldDisableAnimations: boolean;
  channelHeightInPx: number;
  "data-testid": string;
};

export function GuideRow({
  "data-testid": testId,
  channel,
  fullWidth,
  oneMinuteWidthInPx,
  shouldDisableAnimations,
  virtualTimeBlocks,
  timeBlocks,
  virtualItem,
  channelBarCutoff,
  channelHeightInPx,
}: GuideRowProps) {
  const selection = useAtomValue(selectorGuideSelection);

  if (isNil(channel)) {
    return null;
  }

  const isChannelSelected =
    !!selection && "id" in channel && channel.id === selection.channelId;

  return (
    <div
      className={styles.channelRow}
      data-testid={`${testId}-channel-${"id" in channel ? channel.id : virtualItem.key}`}
      style={{
        height: channelHeightInPx,
        // We need to make sure the width of the channelbar is also the width of the hours.
        width: fullWidth,
        transform: `translateY(${virtualItem.start}px)`,
        willChange: "transform",
      }}
    >
      {/* in every channel row we again loop over the hours virtualizer to
        render the programs associated with that hour for the current channel.
        But they will have an offset related to the hour. */}
      {virtualTimeBlocks.map((virtualTimeBlockForChannel, i) => {
        const timeBlock = timeBlocks[virtualTimeBlockForChannel.index];

        if (isNil(timeBlock) || !("items" in channel)) {
          return null;
        }

        const programs = getProgramsStartingAtTime(
          timeBlock.date,
          TIME_BLOCK_SIZE_IN_MINUTES,
          channel.items,
          i === 0,
        );

        const offsetForHour = virtualTimeBlockForChannel.start;

        return (
          <GuideProgramRow
            key={virtualTimeBlockForChannel.index}
            channelBarCutoff={channelBarCutoff}
            channelHeightInPx={channelHeightInPx}
            fullWidth={fullWidth}
            isChannelSelected={isChannelSelected}
            offsetForHour={offsetForHour}
            oneMinuteWidthInPx={oneMinuteWidthInPx}
            programs={programs}
            shouldDisableAnimations={shouldDisableAnimations}
            timeOffset={timeBlock.date}
          />
        );
      })}
    </div>
  );
}
