import { isNil, isString } from "lodash";
import type { NetworkManager } from "tizen-tv-webapis";

import { isTizen } from "@sunrise/utils";

import { loadWebApi } from "./load-web-api";

const UNKNOWN_VALUE = "unknown";

export type ProductInfo = {
  duid: string;
  model: string;
  modelCode: string;
  realModel: string;
  firmware: string;
};

/**
 * Helper function to read Tizen system info capability
 *
 * @see https://docs.tizen.org/application/web/guides/device/system-information/#system-information-properties
 * @see https://www.tizen.org/feature
 * @see https://www.tizen.org/runtime
 */
export function readSystemInfoCapability(url: string): string {
  const tizen = window.tizen;
  if (isNil(tizen) || isNil(tizen.systeminfo)) return UNKNOWN_VALUE;
  const systemInfoCapability = tizen.systeminfo.getCapability(url);
  return isString(systemInfoCapability) ? systemInfoCapability : UNKNOWN_VALUE;
}

export function readTizenPlatformVersion(): string {
  return readSystemInfoCapability("http://tizen.org/feature/platform.version");
}

export function readTizenCpuArch(): string {
  return readSystemInfoCapability(
    "http://tizen.org/feature/platform.core.cpu.arch",
  );
}

export function readTizenCoreApiVersion(): string {
  return readSystemInfoCapability(
    "http://tizen.org/feature/platform.core.api.version",
  );
}

export function readTizenId(): string {
  return readSystemInfoCapability("http://tizen.org/system/tizenid");
}

export function readTizenBuildDate(): string {
  return readSystemInfoCapability("http://tizen.org/system/build.date");
}

export function readTizenBuildId(): string {
  return readSystemInfoCapability("http://tizen.org/system/build.id");
}

export function readTizenBuildString(): string {
  return readSystemInfoCapability("http://tizen.org/system/build.string");
}

export function readTizenBuildVariant(): string {
  return readSystemInfoCapability("http://tizen.org/system/build.variant");
}

export function readTizenManufacturer(): string {
  return readSystemInfoCapability("http://tizen.org/system/manufacturer");
}

export function readTizenModelName(): string {
  return readSystemInfoCapability("http://tizen.org/system/model_name");
}

export function readTizenMemoryStatus(): {
  total: number;
  available: number;
  used: number;
} | null {
  const tizen = window.tizen;
  if (isNil(tizen) || isNil(tizen.systeminfo)) return null;

  try {
    const total = tizen.systeminfo.getTotalMemory();
    const available = tizen.systeminfo.getAvailableMemory();

    return {
      total,
      available,
      used: total - available,
    };
  } catch {
    return null;
  }
}

export function closeTizenApp(): void {
  const tizen = window.tizen;
  if (isNil(tizen)) return;
  tizen.application.getCurrentApplication().exit();
}

export async function getTizenProductInfo(): Promise<ProductInfo | null> {
  if (!isTizen()) return null;
  if (isNil(window.webapis.productinfo)) {
    try {
      await loadWebApi();
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  const productInfo = window.webapis.productinfo;
  return {
    duid: productInfo.getDuid(),
    model: productInfo.getModel(),
    modelCode: productInfo.getModelCode(),
    realModel: productInfo.getRealModel(),
    firmware: productInfo.getFirmware(),
  };
}

export async function getTizenNetwork(): Promise<NetworkManager | null> {
  if (!isTizen()) return null;
  if (isNil(window.webapis.network)) {
    try {
      await loadWebApi();
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  return window.webapis.network;
}
