// eslint-disable-next-line simple-import-sort/imports
import "@/utils/enable-scan";
import { createRoot } from "react-dom/client";
import { init as initSpatialNavigation } from "@noriginmedia/norigin-spatial-navigation";
import {
  addBreadcrumb,
  captureException,
  setExtras,
  setTags,
  setUser,
} from "@sentry/react";
import type { AxiosError } from "axios";
import { atom } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import { isNil } from "lodash";

import {
  areVideoAdsPlayingAtom,
  initPauseAds,
  initVideoAds,
} from "@sunrise/ads";
import { deviceTypeAtom, fetchRefreshAuthTokens } from "@sunrise/auth";
import {
  isLegacyBackendAtom,
  manualRetryAxiosInterceptor,
} from "@sunrise/backend-core";
import { ngHttpClientConfigAtom } from "@sunrise/backend-ng-core";
import { type Language } from "@sunrise/backend-types-core";
import {
  type AppInitConfig,
  type BigScreenPlatform,
  determineDeviceType,
} from "@sunrise/bigscreen";
import { type BaseError, errorAtom } from "@sunrise/error";
import { growthbookConfigAtom } from "@sunrise/feature-flags";
import {
  createPrivateApi,
  hostsAtom,
  httpClientAtom,
  isRetryableError,
  publicApi,
} from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import {
  actionJWTClear,
  actionJWTSetTokens,
  jwtAtom,
  selectAccessToken,
  selectRefreshToken,
} from "@sunrise/jwt";
import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { initMonitoring } from "@sunrise/monitoring";
import {
  actionPlayerShouldReportAsStopped,
  getVideoElement,
  initVideoPlayer,
  playerAtom,
  playerDelayedBufferSettingsAtom,
  playerLiveBufferSettingsAtom,
  playerShouldDetachConfigAtom,
} from "@sunrise/player";
import { isOfflineAtom } from "@sunrise/process-visibility";
import type { Store } from "@sunrise/store";
import {
  getTranslationFileAtom,
  hydrateTranslationQuery,
} from "@sunrise/translator";
import { isSmartTV, type Nullable } from "@sunrise/utils";
import { initPlayerManager } from "@sunrise/yallo-common-player-manager";
import { preferRecordingsAtom } from "@sunrise/yallo-recordings";
import {
  appVersionAtom,
  platformAtom,
  settingsVersionAtom,
} from "@sunrise/yallo-settings";
import { isNotKnownUserError, userAtom } from "@sunrise/yallo-user";
import {
  deviceIdAtom,
  deviceVersionAtom,
  legacySocketUrlAtom,
} from "@sunrise/yallo-websocket";

import "@/styles/fonts.css";
import "@/styles/reset.css";
import "./main.css";

import { AppProvider, queryClient } from "@/app-provider";
import { SCREEN_HEIGHT_IN_PX, SCREEN_WIDTH_IN_PX, tizenStore } from "@/core";
import {
  GenericDialog,
  QrCodeDialog,
  ScrollableDialog,
} from "@/features/dialogs";
import { ErrorDialog } from "@/features/dialogs/error-dialog/error-dialog";
import { ErrorBoundary } from "@/features/monitoring/error-boundary";
import { createHandleRetry } from "@/modules/dialogs/create-handle-retry";
import { getTranslationFile } from "@/modules/i18n/get-translation-file";

import { route } from "./config/route";
import { enableDevConfig, logVersion } from "./dev-utils";
import { isProdMode } from "./is-prod-mode";
import { backendRetryDelayInSecondsAtom } from "./modules/backend-retry-delay-in-seconds.atom";
import { closeAppAtom } from "./modules/platform/close-app.atom";
import { playerIsVisibleAtom } from "./modules/player/player-is-visible.atom";
import { playerShouldDetachAtom } from "./modules/player/player-should-detach.atom";
import { doKillAppAtom } from "./modules/process/do-kill-app.atom";
import { intentToCloseAtom } from "./modules/ui/intent-to-close.atom";
import { Root } from "./root";
import { webConfig } from "./web-config";
import { disablePlayerFeatureAtom } from "@sunrise/yallo-autostart";

const environment = import.meta.env.MODE;
const platform = (import.meta.env.VITE_APP_PLATFORM ||
  "web") as BigScreenPlatform;

// TODO: Read value from env variable defined on AWS
// We fallback to null when the value is falsey ("") for example.
const dsn = import.meta.env.VITE_SENTRY_DSN || null;

initMonitoring({
  dsn,
  environment,
  isProdMode,
});

function showPlayer(): void {
  tizenStore.set(locationAtom, actionLocationNavigate(route.tv.root()));
}

const shouldReviveStreamSelectorAtom = atom((get) => {
  return !get(areVideoAdsPlayingAtom);
});

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_ENDPOINT;

/**
 * Initialize libraries
 */
async function initIntegrations(
  getConfig: (store: Store) => Promise<AppInitConfig>,
): Promise<void> {
  // NOTE: needs to be set already here, else translation prefetching doesn't work
  tizenStore.set(queryClientAtom, queryClient);

  // this must be defined at the very beginning else it could get called before its initialized
  tizenStore.set(getTranslationFileAtom, { fn: getTranslationFile });

  // set the platform and hosts, relevant for everything else
  const deviceType = determineDeviceType();
  tizenStore.set(platformAtom, platform);
  tizenStore.set(settingsVersionAtom, "8");
  tizenStore.set(deviceTypeAtom, deviceType);
  tizenStore.set(preferRecordingsAtom, tizenStore.get(isLegacyBackendAtom)); // on legacy backend we need to give the preference to recordings
  tizenStore.set(hostsAtom, {
    api: import.meta.env.VITE_API_ENDPOINT,
    clients: import.meta.env.VITE_CLIENTS_ENDPOINT,
    data: import.meta.env.VITE_DATA_ENDPOINT,
  });

  const config = await getConfig(tizenStore);

  const onRetry = (error: AxiosError) => {
    addBreadcrumb({
      category: "http-retry",
      message: "Request was retried",
      level: "info",
      data: {
        name: error.name,
        status: error.response?.status,
      },
    });
  };

  // NOTE: This is a temporary solution to avoid having a refresh from ng backend and a refresh from legacy backend at the same time.
  let refreshPromise: Nullable<ReturnType<typeof fetchRefreshAuthTokens>>;
  const doRefreshTokens = async (refreshToken: string) => {
    const host = tizenStore.get(hostsAtom).api;
    if (!host) {
      throw new Error("No host found");
    }

    if (!refreshPromise) {
      refreshPromise = fetchRefreshAuthTokens(
        host,
        refreshToken,
        !tizenStore.get(isLegacyBackendAtom),
      );
    }

    const result = await refreshPromise;
    refreshPromise = null;
    return result;
  };

  /**
   * Should resolve when the user interacted with the page to confirm they want to retry.
   */
  const handleRetry = createHandleRetry(tizenStore);
  const getRetryDelayInSeconds = (): Nullable<number> =>
    tizenStore.get(backendRetryDelayInSecondsAtom);

  tizenStore.set(appVersionAtom, import.meta.env.VITE_APP_VERSION);
  if (WEBSOCKET_URL) {
    tizenStore.set(legacySocketUrlAtom, WEBSOCKET_URL);
  }

  const ngBaseUrl = import.meta.env.VITE_NG_API_ENDPOINT;
  if (ngBaseUrl) {
    tizenStore.set(ngHttpClientConfigAtom, {
      // The real config.
      baseUrl: ngBaseUrl,
      // Stuff for the error interceptor.
      doRefreshTokens,
      isNotKnownUserError,
      onRetry,
      getAccessToken: () => tizenStore.get(selectAccessToken),
      getRefreshToken: () => selectRefreshToken(tizenStore.get(jwtAtom)),
      setTokens: (accessToken, refreshToken, wsToken) => {
        tizenStore.set(
          jwtAtom,
          actionJWTSetTokens({
            accessToken,
            refreshToken,
            wsToken,
          }),
        );
      },
      resetTokens: (error: BaseError) => {
        tizenStore.set(errorAtom, error);
        tizenStore.set(jwtAtom, actionJWTClear());
      },
      getLanguage: () => tizenStore.get(currentLanguageAtom),
      // Stuff for the manual retry interceptor.
      awaitRetry: handleRetry,
      getRetryDelayInSeconds,
      isRetryableError,
    });
  }

  tizenStore.set(playerShouldDetachConfigAtom, playerShouldDetachAtom);

  tizenStore.set(httpClientAtom, {
    privateApi: createPrivateApi(
      tizenStore,
      doRefreshTokens,
      handleRetry,
      getRetryDelayInSeconds,
      isNotKnownUserError,
      onRetry,
    ),
    // NOTE: We probably want a `createPublicApi` function as well.
    publicApi: manualRetryAxiosInterceptor(
      publicApi,
      handleRetry,
      isRetryableError,
      getRetryDelayInSeconds,
    ),
  });

  // IMPORTANT: async stuff that needs to wait should be called after setting more important atoms like hostsAtom
  if (isSmartTV()) {
    tizenStore.set(doKillAppAtom, {
      perform: (instant = false) => {
        tizenStore.set(playerAtom, actionPlayerShouldReportAsStopped());
        tizenStore.set(intentToCloseAtom, true);

        if (instant) {
          config.closeApp?.();
          return Promise.resolve();
        }

        return new Promise<void>((resolve) => {
          setTimeout(() => {
            config.closeApp?.();
            resolve();
          }, 1000); // timeout to let the websocket send the stop message
        });
      },
    });
  }

  if (config.offlineAtom) {
    tizenStore.set(isOfflineAtom, config.offlineAtom);
  }
  tizenStore.set(deviceIdAtom, config.deviceIdAtom);
  tizenStore.set(deviceVersionAtom, config.deviceVersion);

  // initialize the remote controls (overrides some atoms)
  config.initRemote?.(tizenStore, environment === "production");

  if (config.closeApp) {
    tizenStore.set(closeAppAtom, { closeApp: config.closeApp });
  }

  initSpatialNavigation({ debug: false, visualDebug: false });
  initPlayerManager(tizenStore, import.meta.env.MODE === "production");
  initVideoAds(
    tizenStore,
    getVideoElement,
    showPlayer,
    import.meta.env.MODE !== "production",
    { width: SCREEN_WIDTH_IN_PX, height: SCREEN_HEIGHT_IN_PX },
  );
  initPauseAds(tizenStore);
  initVideoPlayer(
    tizenStore,
    {
      showPlayer,
      getPlayerBufferSettings: {
        live: () => tizenStore.get(playerLiveBufferSettingsAtom),
        delayed: () => tizenStore.get(playerDelayedBufferSettingsAtom),
      },
      playerVisibleAtom: playerIsVisibleAtom(playerAtom),
      playerShouldDetachAtom: playerShouldDetachAtom,
      // Make sure to inject the player error into the regular error atom so we can catch it before the ErrorBoundary.
      onError: (e) => tizenStore.set(errorAtom, e),
      logError: (e) =>
        captureException(e, {
          level: "info",
          tags: {
            "player.error": "true",
            location: "player-controller",
            errorCode: e.errorCode,
            hidden: true,
          },
          extra: e.extras,
        }),
      isEnabled: !tizenStore.get(disablePlayerFeatureAtom),
      /**
       * NOTE: Tizen-TV-only-thing. Never needed on web. Often on the TV.
       */
      shouldReviveStreamSelector: shouldReviveStreamSelectorAtom,
    },
    (playerController) => {
      setTags({
        "player.type": playerController.getPlayerWrapperName(),
      });
    },
  );

  if (import.meta.env.MODE === "test") {
    tizenStore.set(currentLanguageAtom, "de" as Language);
  }

  const growthBookApiHost = import.meta.env.VITE_GROWTHBOOK_API_HOST as
    | string
    | undefined;
  const growthBookClientKey = import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY as
    | string
    | undefined;
  if (growthBookApiHost && growthBookClientKey) {
    tizenStore.set(growthbookConfigAtom, {
      enableDevMode: false,
      apiHost: growthBookApiHost,
      clientKey: growthBookClientKey,
    });
  }

  // update sentry context
  async function updateStaticSentryContext(): Promise<void> {
    if (config.getDeviceTags) {
      const tags = await config.getDeviceTags?.();
      setTags(tags);
    }
    if (config.getDeviceExtras) {
      const extras = await config.getDeviceExtras?.();
      setExtras(extras);
    }
  }

  void updateStaticSentryContext();

  async function updateDynamicSentryContext(): Promise<void> {
    const user = await tizenStore.get(userAtom);
    setUser({ id: user.data?.id });
  }

  tizenStore.sub(userAtom, () => {
    void updateDynamicSentryContext();
  });

  // For testing purposes
  if (import.meta.env.MODE !== "production") {
    enableDevConfig();
  }

  await hydrateTranslationQuery(tizenStore, queryClient);
}

window.addEventListener("DOMContentLoaded", async () => {
  logVersion();

  const appNode = document.getElementById("app");
  if (isNil(appNode)) throw new Error("App element does not exist");

  const tizenConfig = async () =>
    (await import("@sunrise/bigscreen-tizen")).tizenConfig;
  const webOSConfig = async () =>
    (await import("@sunrise/bigscreen-webos")).webOSConfig;
  const titanOSConfig = async () =>
    (await import("@sunrise/bigscreen-titanos")).titanOSConfig;

  const loadConfig =
    platform === "tizen"
      ? tizenConfig
      : platform === "titanos"
        ? titanOSConfig
        : platform === "webos"
          ? webOSConfig
          : () => webConfig;

  // NOTE: await that everything is ready before we start rendering
  await initIntegrations(await loadConfig());

  createRoot(appNode).render(
    <ErrorBoundary isRoot>
      <AppProvider
        enableDevTools={!import.meta.env.PROD}
        storeInstance={tizenStore}
      >
        <Root />
        <QrCodeDialog />
        <ScrollableDialog />
        <ErrorDialog />
        <GenericDialog />
      </AppProvider>
    </ErrorBoundary>,
  );
});
