import { useCallback } from "react";
import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";
import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { actionDialogOpen, dialogAtom } from "@sunrise/dialogs";

import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import { doKillAppAtom } from "@/modules/process/do-kill-app.atom";

type UseCloseAppProps = { isEnabled: boolean } & (
  | { useExit: boolean; onClose?: never }
  | {
      useExit?: never;
      onClose: (showDialog: () => void, forceClose: () => void) => void;
    }
);

export function useCloseApp({
  isEnabled,
  useExit,
  onClose,
}: UseCloseAppProps): void {
  const dispatchDialog = useSetAtom(dialogAtom);

  const exit = useAtomCallback(
    useCallback((get) => {
      const doKill = get(doKillAppAtom);
      doKill?.perform();
    }, []),
  );

  const showCloseDialog = useCallback(() => {
    dispatchDialog(
      actionDialogOpen({
        type: "actions",
        title: { key: "dialog_close_app_title" },
        actions: [
          {
            label: { key: "dialog_close_app_button" },
            action: () => exit(),
            key: "close",
          },
          {
            label: { key: "dialog_button_cancel" },
            action: () => {
              /* noop */
            },
            key: "cancel",
            initialFocus: true,
          },
        ],
        lastFocusKey: getCurrentFocusKey(),
        id: "close-app",
      }),
    );
  }, [dispatchDialog, exit]);

  useKeyboardNavigation({
    isEnabled: isEnabled,
    onBack: onClose ? () => onClose(showCloseDialog, exit) : undefined,
    onExit: useExit ? exit : undefined,
  });
}
