// NOTE: shaka types needed for dealWithError function
/// <reference types="shaka-player" />
import areEqual from "fast-deep-equal";
import type { Atom } from "jotai";
import { selectAtom } from "jotai/utils";
import throttle from "lodash/throttle";
// @ts-expect-error: see above
import shakaPlayer from "shaka-player/dist/shaka-player.compiled";

import type { Stream } from "@sunrise/backend-types";
import { selectIsLoggedIn } from "@sunrise/jwt";
import {
  actionPlayerStatsDetectedPlayable,
  actionPlayerStatsSetConfiguration,
  actionPlayerStatsSetRawStats,
  actionPlayerStatsStartPlayout,
  playerStatsAtom,
  selectPlayerStatsEnabled,
} from "@sunrise/player-stats";
import { type Store } from "@sunrise/store";
import { deviceInfo, isNil, isSmartTV, type Nullable } from "@sunrise/utils";

import { allowParallelPrefetchAtom } from "./allow-parallel-prefetch.atom";
import { shakaPlayerAtom } from "./atoms/shaka-player.atom";
import { THROTTLE_TIME_IN_MS } from "./constants";
import { CriticalPlayerError } from "./errors/critical-player.error";
import { PlayerError } from "./errors/player.error";
import { UnauthorizedStreamPlayerError } from "./errors/unauthorized-stream-player.error";
import { getVideoElement } from "./get-video-element";
import { getLoadPositionForPlayRequest } from "./helpers/get-load-position-for-play-request";
import { HtmlPlayerWrapper } from "./html-player.wrapper";
import {
  actionPlayerBufferInterruptionEnd,
  actionPlayerBufferInterruptionStart,
  actionPlayerClearDuration,
  actionPlayerReset,
  actionPlayerSeeked,
  actionPlayerSetCurrentTime,
  actionPlayerSetDesiredCurrentTime,
  actionPlayerSetDuration,
  actionPlayerSetError,
  actionPlayerSetLoaded,
  actionPlayerSetPaused,
  actionPlayerSetPictureInPictureSupported,
  actionPlayerSetPlaying,
  actionPlayerSetStopped,
  actionPlayerSetSubtitlesAndAudio,
  actionPlayerShouldPause,
  actionPlayerShouldPlay,
  actionPlayerToggleFullscreen,
  actionPlayerToggleMute,
  actionPlayerTogglePictureInPicture,
  playerAtom,
  selectPlayerCurrentAudioTrack,
  selectPlayerCurrentPlayRequest,
  selectPlayerCurrentStream,
  selectPlayerCurrentSubtitleTrack,
  selectPlayerDesiredCurrentTime,
  selectPlayerFullscreenSupportedMode,
  selectPlayerIsPlaying,
  selectPlayerIsSuspended,
  selectPlayerPictureInPictureSupported,
  selectPlayerShouldBeFullscreen,
  selectPlayerShouldBeMuted,
  selectPlayerShouldBePaused,
  selectPlayerShouldBePictureInPicture,
  selectPlayerShouldBePlaying,
} from "./player.atom";
import { fairPlayCertificateAtom } from "./player.service";
import type {
  GetPlayerBufferSettings,
  WebkitHTMLVideoElement,
} from "./player.types";
import type { PlayerWrapper } from "./player.wrapper";
import { ShakaPlayerWrapper } from "./shaka-player.wrapper";
import { trackKnownBandwidthAtom } from "./track-known-bandwidth.atom";
import { createEnsureValidCurrentTime } from "./utils/create-ensure-valid-current-time";
import { isAutoplayError } from "./utils/is-autoplay-error";
import { isLoadInterruptedError } from "./utils/is-load-interrupted-error";
import { isShakaError } from "./utils/is-shaka-error";

let playerController: Nullable<PlayerController>;

/**
 * Only exposes the real "public" functions on PlayerController.
 * The stuff we want the UI to use without pushing stuff on the atoms.
 */
export const getPlayerController = (): Pick<
  PlayerController,
  "setCurrentDate"
> => {
  if (!playerController) {
    throw new Error(
      "The player controller is not initialized. Please call initVideoPlayer() first.",
    );
  }

  return playerController;
};

type PlayerControllerOptions = {
  /**
   * If an onError handler is provided we will no longer re-throw the errors but just call the onError handler.
   * We also always transform whatever error is received to an Error-like object.
   */
  onError?: <T extends Error>(error: T) => void;
  /**
   * Log the error without having to set the error state.
   *
   * Only to be logged when errors are not propagated. So when they are hidden from the user.
   */
  logError?: (error: PlayerError) => void;
  /**
   * On Tizen we notice that the player does not always regain control over the video element after playing out ads.
   * It sometimes gets stuck in a loading state.
   *
   * The reviveSelector should indicate whenever something may need a revive.
   *
   * So in our case, it is whenever we are done playing out ads. Then should needs to be true.
   */
  shouldReviveStreamSelector?: Atom<boolean>;
};

/**
 * Initializes the video player.
 *
 * It is responsible for integrating the video player library with the adapter.
 *
 **/
export function initVideoPlayer(
  store: Store,
  {
    showPlayer,
    getPlayerBufferSettings,
    onError,
    logError,
    isEnabled = true,
    shouldReviveStreamSelector: reviveSelector,
    playerVisibleAtom,
    playerShouldDetachAtom,
  }: {
    playerVisibleAtom?: Atom<boolean>;
    playerShouldDetachAtom?: Atom<boolean>;
    showPlayer: () => void;
    /**
     * It's optional. By default we will just use whatever shaka does by default.
     * If passed you can override the configuration somewhat.
     * @returns
     */
    getPlayerBufferSettings?: GetPlayerBufferSettings;
    /**
     * When the player is not enabled we will not pass the stream urls to the video element.
     * This should only ever be set in a test environment where the player is not expected to work anyway.
     */
    isEnabled?: boolean;
  } & PlayerControllerOptions,
  cb?: (controller: PlayerController) => void,
): void {
  const videoElement = getVideoElement();

  const isNativeHls = deviceInfo.isSafari || deviceInfo.isIOS;
  // NOTE: We prefer Shaka on Tizen for now.
  const playerWrapper =
    !isNativeHls || isSmartTV()
      ? new ShakaPlayerWrapper(videoElement)
      : new HtmlPlayerWrapper(videoElement);

  if (playerWrapper instanceof ShakaPlayerWrapper) {
    // We store the shaka player in an atom. This allows us to access it in atoms so we can for example get thumbnails from shaka through an atom.
    store.set(shakaPlayerAtom, playerWrapper.player);
  }

  playerController = new PlayerController({
    store,
    atom: playerAtom,
    videoElement,
    player: playerWrapper,
    ensureValidCurrentTime: createEnsureValidCurrentTime(store, playerAtom),
    playerVisibleAtom,
    playerShouldDetachAtom,
    showPlayer,
    getPlayerBufferSettings: getPlayerBufferSettings
      ? getPlayerBufferSettings
      : {
          live: () => null,
          delayed: () => null,
        },
    isEnabled,
    options: {
      onError,
      logError,
      shouldReviveStreamSelector: reviveSelector,
    },
  });

  cb?.(playerController);
}

/**
 * Adapter for the video player library.
 *
 * It is responsible for binding the player to the video element and the store.
 * Interaction with the library happens through subscribing to the player store's state
 * and reacting to changes.
 *
 * Let's try to inject yallo business logic as much as possible instead of having it coded in the controller.
 **/
export class PlayerController {
  private stream: Nullable<Stream>;

  private readonly store: Store;
  private readonly atom: typeof playerAtom;
  private readonly videoElement: HTMLVideoElement;
  private readonly player: PlayerWrapper;
  /**
   * This function receives a "currentTime". This is in the same format as the "currentTime" in the player state.
   * So for replay that is the seconds since the offset. For live that is the seconds since epoch.
   *
   * The problem is that the player doesn't like it when it is passed in a value that is not (yet) in the stream.
   * So we need to correct the currentTime to be in the stream.
   *
   * There are also scenarios where we can't even set the currentTime (like live streams). In that case, we return null.
   */
  private readonly ensureValidCurrentTime: (
    currentTime: number | Date,
  ) => Nullable<number>;
  private readonly showPlayer: () => void;
  private readonly getPlayerBufferSettings: GetPlayerBufferSettings;
  private readonly isEnabled: boolean;
  private readonly options: PlayerControllerOptions = {};
  private readonly playerVisibleAtom: Atom<boolean>;
  private readonly playerShouldDetachAtom?: Atom<boolean>;

  constructor({
    store,
    atom,
    videoElement,
    player,
    ensureValidCurrentTime,
    showPlayer,
    getPlayerBufferSettings,
    playerVisibleAtom,
    playerShouldDetachAtom,
    isEnabled,
    options,
  }: {
    store: Store;
    atom: typeof playerAtom;
    videoElement: HTMLVideoElement;
    player: PlayerWrapper;
    /**
     * This function receives a "currentTime". This is in the same format as the "currentTime" in the player state.
     * So for replay that is the seconds since the offset. For live that is the seconds since epoch.
     *
     * The problem is that the player doesn't like it when it is passed in a value that is not (yet) in the stream.
     * So we need to correct the currentTime to be in the stream.
     *
     * There are also scenarios where we can't even set the currentTime (like live streams). In that case, we return null.
     */
    ensureValidCurrentTime: (currentTime: number | Date) => Nullable<number>;
    showPlayer: () => void;
    getPlayerBufferSettings: GetPlayerBufferSettings;
    isEnabled: boolean;
    playerVisibleAtom?: Atom<boolean>;
    playerShouldDetachAtom?: Atom<boolean>;
    options?: PlayerControllerOptions;
  }) {
    this.store = store;
    this.atom = atom;
    this.videoElement = videoElement;
    this.player = player;
    this.ensureValidCurrentTime = ensureValidCurrentTime;
    this.showPlayer = showPlayer;
    this.getPlayerBufferSettings = getPlayerBufferSettings;
    this.isEnabled = isEnabled;
    this.options = options ?? {};

    this.playerVisibleAtom =
      playerVisibleAtom ?? selectAtom(atom, (state) => state.shouldBeVisible);
    this.playerShouldDetachAtom = playerShouldDetachAtom;

    // initialize subscriptions
    this.store.sub(selectPlayerCurrentStream(this.atom), () => {
      void this.loadStreamUrl();
    });
    // unload player when we are suspended
    // we need to do it like this to preserve the stream
    this.store.sub(selectPlayerIsSuspended, () => {
      // Reset the stream to null since we want to transfer to no stream.
      // Else it will block loadStream and not unload.
      if (this.store.get(selectPlayerIsSuspended)) {
        this.stream = null;
      }
      void this.loadStreamUrl();
    });
    this.store.sub(selectPlayerShouldBePaused(this.atom), this.togglePlayPause);
    this.store.sub(this.playerVisibleAtom, this.togglePlayerVisibility);
    if (this.playerShouldDetachAtom) {
      this.store.sub(
        this.playerShouldDetachAtom,
        () => void this.onShouldPlayerDetachChanged(),
      );
    }
    this.store.sub(selectPlayerShouldBeMuted, this.toggleMute);
    this.store.sub(
      selectPlayerShouldBePictureInPicture,
      this.togglePictureInPicture,
    );
    this.store.sub(selectPlayerShouldBeFullscreen, this.toggleFullscreen);

    // Set video element attributes
    this.videoElement.setAttribute("webkit-playsinline", "true");
    this.videoElement.setAttribute("playsinline", "true");

    // initialize video element events
    this.videoElement.addEventListener(
      "canplay",
      () => void this.onVideoCanPlay(),
    );

    this.player.onBuffering?.(this.onBufferingStart, this.onBufferingStop);

    this.videoElement.addEventListener("playing", this.onVideoPlaying);
    this.videoElement.addEventListener("pause", this.onVideoPaused);
    this.videoElement.addEventListener("ended", this.onVideoEnded);
    this.videoElement.addEventListener(
      "canplaythrough",
      this.onVideoCanPlayThrough,
    );
    this.videoElement.addEventListener(
      "timeupdate",
      this.onVideoProgressThrottled,
    );
    this.videoElement.addEventListener("volumechange", this.onVolumeChange);

    this.videoElement.addEventListener("enterpictureinpicture", () =>
      this.onPictureInPictureChange(true),
    );
    this.videoElement.addEventListener("leavepictureinpicture", () =>
      this.onPictureInPictureChange(false),
    );
    this.videoElement.addEventListener("fullscreenchange", () =>
      this.onFullscreenChange(),
    );

    // NOTE: special support for iOS Safari
    this.videoElement.addEventListener("webkitbeginfullscreen", () =>
      this.onFullscreenChange(true),
    );
    this.videoElement.addEventListener("webkitendfullscreen", () =>
      this.onFullscreenChange(false),
    );

    this.player.onTracksChanged(this.trackChanged);
    this.handleTracks();

    // This will stop the player when we log out.
    this.store.sub(selectIsLoggedIn, () => {
      if (store.get(selectIsLoggedIn)) {
        return;
      }

      // reset
      this.store.set(playerAtom, actionPlayerReset());
    });

    const handleStats = () => {
      this.player.onStats?.(
        () => this.store.get(selectPlayerStatsEnabled),
        (
          stats: shakaPlayer.extern.Stats,
          buffer: shakaPlayer.extern.BufferedInfo,
          bufferFullness: number,
        ) =>
          this.store.set(
            playerStatsAtom,
            actionPlayerStatsSetRawStats(stats, buffer, bufferFullness),
          ),
      );
    };

    this.store.sub(selectPlayerStatsEnabled, handleStats);
    handleStats();

    this.handleStreamRevive();

    this.player.onQualityChanges?.(
      this.store.get(trackKnownBandwidthAtom),
      (cb) =>
        this.store.sub(trackKnownBandwidthAtom, () =>
          cb(this.store.get(trackKnownBandwidthAtom)),
        ),
    );

    this.player.onError((e: unknown) => {
      if (typeof e === "object" && e !== null) {
        this.dealWithError(e);
      }
    });

    this.store.set(
      playerAtom,
      actionPlayerSetPictureInPictureSupported(
        "pictureInPictureEnabled" in document &&
          document.pictureInPictureEnabled,
      ),
    );

    this.player.setIsDetachedFn?.(this.isDetached.bind(this));

    // For debugging purposes allow skipping forward and backward in the video 10m at a time.
    if (process.env["NODE_ENV"] === "development") {
      addEventListener("keypress", (e) => {
        switch (e.key) {
          case "{":
            this.videoElement.currentTime -= 600;
            break;
          case "}":
            this.videoElement.currentTime += 600;
            break;
        }
      });
    }
  }

  public getPlayerWrapperName = (): string => {
    return this.player.name;
  };

  private onBufferingStart = () => {
    this.store.set(this.atom, actionPlayerBufferInterruptionStart());
  };

  private onBufferingStop = () => {
    this.store.set(this.atom, actionPlayerBufferInterruptionEnd());
  };

  private trackChanged = () => {
    const subtitles = this.player.getSubtitleTracks();
    let subtitleTrackId = subtitles.activeId;

    const audioTracks = this.player.getAudioTracks();
    let audioTrackId = audioTracks.activeId;

    // Try to override ids when we have preferences regarding audio.
    const {
      preferredAudioTrackLang,
      preferredSubtitleTrackLang,
      preferredAudioTrackFormat,
    } = this.store.get(this.atom);

    if (preferredAudioTrackLang) {
      const audio = audioTracks.options.find(
        (t) =>
          t.lang === preferredAudioTrackLang &&
          t.format === preferredAudioTrackFormat,
      );
      if (audio) {
        audioTrackId = audio.id;
      }

      const audioLanguageOnly = audioTracks.options.find(
        (t) => t.lang === preferredAudioTrackLang,
      );
      if (audioLanguageOnly) {
        audioTrackId = audioLanguageOnly.id;
      }
    }

    if (preferredSubtitleTrackLang) {
      const track = subtitles.options.find(
        (t) => t.lang === preferredSubtitleTrackLang,
      );
      if (track) {
        subtitleTrackId = track.id;
      }
    }

    this.store.set(
      this.atom,
      actionPlayerSetSubtitlesAndAudio({
        audioTrackId,
        subtitleTrackId,
        audioTracks: audioTracks.options,
        subtitleTracks: subtitles.options,
      }),
    );
  };

  private handleTracks() {
    this.store.sub(selectPlayerCurrentAudioTrack, () => {
      const active = this.store.get(selectPlayerCurrentAudioTrack);

      if (!active || !active.lang) {
        return;
      }

      this.player.selectAudioLanguage(active.lang, active.channelsCount);
    });

    this.store.sub(selectPlayerCurrentSubtitleTrack, () => {
      const current = this.store.get(selectPlayerCurrentSubtitleTrack);
      if (!current || !current.lang) {
        this.player.setTextTrack(null, false);
        return;
      }

      this.player.setTextTrack(current.lang, true);
    });
  }

  private isNotReallyPlaying(): boolean {
    return (
      this.store.get(this.atom).state === "loading" ||
      this.store.get(this.atom).state === "error"
    );
  }

  private handleStreamRevive() {
    if (!this.options.shouldReviveStreamSelector) {
      return;
    }

    let timeout: number;

    // Kick the player out of sleeping when we notice we are still loading and we should actually already be playing video.
    // It means the re-attach happened too soon. IMA was not done with its work on the video element and Shaka attempted to play out too soon.
    // So we need to poke shaka to load stuff again.
    const revive = () => {
      if (
        !this.options.shouldReviveStreamSelector ||
        !this.store.get(this.options.shouldReviveStreamSelector)
      ) {
        return;
      }

      // TODO: improve on detecting if the player is actually stuck or not.
      const isNotReallyPlaying = this.isNotReallyPlaying();

      if (isNotReallyPlaying) {
        // Clear the cached stream so the load will not block loading the same stream again.
        // TODO: Should keep in mind the last seeked to timepoint. But it should already do that .... .
        this.stream = null;
        this.loadStreamUrl();
      }
    };

    this.store.sub(this.options.shouldReviveStreamSelector, () => {
      window.clearTimeout(timeout);

      if (
        !this.options.shouldReviveStreamSelector ||
        !this.store.get(this.options.shouldReviveStreamSelector)
      ) {
        return;
      }

      timeout = window.setTimeout(revive, 5000);
    });
  }

  /**
   * Will set the new current time of the video if it does not exceed the stream's boundaries.
   *
   * @param currentTime
   *   The time in seconds to set the video to.
   */
  public setCurrentTime(currentTime: number | Date): void {
    const setTime = (time: number): void => {
      this.videoElement.currentTime = time;

      // We emit a seeked event so that our player atom knows it can drop the pretend seek time.
      // NOTE: We are not 100% entirely sure we actually seeked in the player ... . We just told the player to seek.
      // But perhaps it can not seek at the moment.
      this.store.set(this.atom, actionPlayerSeeked());

      // Immediately advertise the new currentTime.
      this.onVideoProgress();
    };

    const converted = this.ensureValidCurrentTime(currentTime);
    // Capture the desired seek time so that on a stream restart we can re-use it.
    this.store.set(this.atom, actionPlayerSetDesiredCurrentTime(converted));

    if (isNil(converted)) return;

    setTime(converted);
  }

  public setCurrentDate(time: number | Date): void {
    const { stream } = this.store.get(this.atom);
    if (!stream) return;

    this.setCurrentTime(time);
  }

  private shouldDetach(): boolean {
    if (!this.playerShouldDetachAtom) {
      return false;
    }

    return this.store.get(this.playerShouldDetachAtom);
  }

  private onVideoProgress = (): void => {
    if (this.isDetached()) {
      return;
    }

    // NOTE: workaround for HLS live streams which don't report the time since epoch but just the time since the stream started playing
    // TODO: somehow for HLS replay streams, shaka player doesn't fire the timeupdate event at all
    const playRequest = this.store.get(selectPlayerCurrentPlayRequest);
    if (
      playRequest?.type === "live" &&
      (this.stream?.type === "hls7" || this.stream?.type === "hls7_fairplay")
    ) {
      this.store.set(this.atom, actionPlayerSetCurrentTime(Date.now() / 1000));
    } else {
      this.store.set(
        this.atom,
        actionPlayerSetCurrentTime(this.videoElement.currentTime),
      );
    }
  };

  private onVideoProgressThrottled = throttle(
    this.onVideoProgress.bind(this),
    THROTTLE_TIME_IN_MS,
    { leading: true },
  );

  /**
   * This also contains a sync with the player state. Let's say we need to be playing and the video element is already playing but the player state is not playing, we would correct that here.
   * Ideally the player state does not get out of sync and we find the root cause of this desync.
   */
  private async kickPlayer(): Promise<void> {
    const shouldPause = this.store.get(selectPlayerShouldBePaused(this.atom));

    if (!shouldPause) {
      // When we are already playing this should not attempt to play again.
      if (!this.videoElement.paused) {
        // Check if we are playing according to the player atom.
        // If not then we should correct ourselves.
        const isPlaying = this.store.get(selectPlayerIsPlaying);
        if (!isPlaying) {
          this.store.set(this.atom, actionPlayerSetPlaying());
        }

        return;
      }

      try {
        await this.videoElement.play();
      } catch (e: unknown) {
        // This is an autoplay error.
        // When this happens, we should set the player's desired playing state to false (so shouldPlay false).
        // That way, the user can manually trigger the play button again and it should start playing.
        if (isAutoplayError(e)) {
          this.store.set(this.atom, actionPlayerShouldPause());
        } else {
          this.dealWithError(e);
        }
      }
    } else {
      // When we are already paused this should not attempt to pause again.
      if (this.videoElement.paused) {
        // When the state thinks we are playing we should correct ourselves again.
        const isPlaying = this.store.get(selectPlayerIsPlaying);
        if (isPlaying) {
          this.store.set(this.atom, actionPlayerSetPaused());
        }

        return;
      }

      this.videoElement.pause();
    }
  }

  private onVideoCanPlay = async (): Promise<void> => {
    if (this.isDetached()) {
      return;
    }

    const video = this.videoElement;

    // NOTE: onVideoCanPlay is sometimes fired twice, but the reducer catches this case.
    this.store.set(this.atom, actionPlayerSetLoaded());

    let duration = video.duration;

    // For super recent recordings, the backend returns a different kind of recording feed
    // where the duration is actually not in seconds but in *micro*seconds. So we need to divide by 1_000_000.
    const { playRequest } = this.store.get(this.atom);
    if (playRequest?.type === "recording" && duration >= 1_000_000) {
      duration = duration / 1_000_000;
    }

    this.store.set(this.atom, actionPlayerSetDuration(duration));

    await this.kickPlayer();
  };

  /**
   * TODO: Move to the PlayerManager?
   *       It does make sense that it is not in the PlayerManager.
   *       Because it is up to the client to decide when to route to which page as soon as content starts playing.
   */
  private pushToTvRoot = (): void => {
    this.showPlayer();
  };

  private onVideoPlaying = (): void => {
    if (this.isDetached()) {
      return;
    }

    this.store.set(this.atom, actionPlayerSetPlaying());
  };

  private onVideoPaused = (): void => {
    if (this.isDetached()) {
      return;
    }

    this.store.set(this.atom, actionPlayerSetPaused());
  };

  private onVideoEnded = (): void => {
    if (this.isDetached()) {
      return;
    }

    this.store.set(this.atom, actionPlayerSetStopped());
  };

  private onVideoCanPlayThrough = (): void => {
    if (this.isDetached()) {
      return;
    }

    this.store.set(
      playerStatsAtom,
      actionPlayerStatsDetectedPlayable(new Date()),
    );
  };

  private onVolumeChange = (): void => {
    if (this.isDetached()) {
      return;
    }

    const stateMuted = this.store.get(selectPlayerShouldBeMuted);

    const video = this.videoElement;
    // TODO: if volume percentage can be changed, extend this functionality and store it on the playerAtom
    // const volumePercent = video.volume * 100;
    const muted = video.muted;

    if (stateMuted !== muted) {
      this.store.set(this.atom, actionPlayerToggleMute());
    }
  };

  private onPictureInPictureChange = (enabled: boolean): void => {
    if (this.isDetached()) {
      return;
    }

    const isPictureInPicture = this.store.get(
      selectPlayerShouldBePictureInPicture,
    );
    if (isPictureInPicture !== enabled) {
      this.store.set(this.atom, actionPlayerTogglePictureInPicture());
    }
  };

  /**
   * This function is called when the player is in fullscreen mode and the fullscreen mode changes.
   * startingFullscreen is only set for Safari
   * @param startingFullscreen
   */
  private onFullscreenChange = (startingFullscreen?: boolean): void => {
    if (this.isDetached()) {
      return;
    }

    if (this.store.get(selectPlayerFullscreenSupportedMode) !== "native") {
      return;
    }

    const fullscreen = document.fullscreenElement !== null;
    const shouldBeFullscreen = this.store.get(selectPlayerShouldBeFullscreen);

    if (fullscreen !== shouldBeFullscreen) {
      this.store.set(this.atom, actionPlayerToggleFullscreen());
    }

    // NOTE: on safari native, the player gets paused after exiting fullscreen
    if (startingFullscreen === false) {
      const isPaused = this.store.get(selectPlayerShouldBePaused(this.atom));
      if (!isPaused) {
        // NOTE: workaround for iOS Safari, drag-to-close needs more time than just clicking the close button
        setTimeout(() => {
          // NOTE: check if the player should still be playing after the timeout
          if (this.store.get(selectPlayerShouldBePaused(this.atom))) return;
          void this.videoElement.play();
        }, 500);
      }
    }
  };

  private isDetached(): boolean {
    return this.shouldDetach();
  }

  /**
   * Toggle the video player's visibility.
   *
   * There is only one video element on the page it has to be hidden on some pages.
   * Also reacts when ads are playing. Should ads be playing we need to force ourselves to be visible and we should also unload the player.
   */
  private togglePlayerVisibility = (): void => {
    const isVisible = this.store.get(this.playerVisibleAtom);

    this.videoElement.style.opacity = isVisible ? "1" : "0";
    this.videoElement.style.visibility = isVisible ? "visible" : "hidden";
  };

  /**
   * Toggle the video's element pause state.
   */
  private togglePlayPause = (): void => {
    const video = this.videoElement;
    const isPaused = this.store.get(selectPlayerShouldBePaused(this.atom));

    // When in detached mode, do not allow playing ads.
    if (this.shouldDetach()) return;

    if (isPaused) video.pause();
    else void video.play();
  };

  /**
   * Toggle mute
   */
  private toggleMute = (): void => {
    const video = this.videoElement;
    const isMuted = this.store.get(selectPlayerShouldBeMuted);

    // When in detached mode, do not allow
    if (this.shouldDetach()) return;

    video.muted = isMuted;
  };

  /**
   * Toggle Picture in Picture
   */
  private togglePictureInPicture = (): void => {
    const isPictureInPicture = this.store.get(
      selectPlayerShouldBePictureInPicture,
    );

    // When in detached mode, do not allow
    if (this.shouldDetach()) return;

    this.setPictureInPictureMode(isPictureInPicture);
  };

  private toggleFullscreen = (): void => {
    if (this.store.get(selectPlayerFullscreenSupportedMode) !== "native") {
      return;
    }

    const isFullscreen = this.store.get(selectPlayerShouldBeFullscreen);

    // When in detached mode, do not allow
    if (this.shouldDetach()) return;

    if (isFullscreen) {
      void this.videoElement.requestFullscreen?.();
      void (
        this.videoElement as WebkitHTMLVideoElement
      ).webkitEnterFullscreen?.(); // NOTE: iOS Safari
    } else {
      (this.videoElement as WebkitHTMLVideoElement).webkitExitFullscreen?.(); // NOTE: iOS Safari
      document.exitFullscreen?.();
    }
  };

  private onShouldPlayerDetachChanged = async (): Promise<void> => {
    if (!this.shouldDetach()) {
      // We need to know if we were playing something already or not and then correctly resume that or else, start the new stream.
      // For now, we can assume that we are not playing anything since ads are always pre-roll.
      // This is coupled with the unload in togglePlayerVisibility.
      // We need to make sure to re-attach to the video element before we attempt to load the stream.
      await this.player.attach(this.videoElement, false);
      void this.loadStreamUrl();
    } else {
      // Flush the stream since we are detaching from the player.
      this.stream = null;
      // We need to manually detach from the player because on Tizen the video element will be re-used by IMA.
      await this.player.detach();
    }
  };

  // TODO: move shaka-specific error handling to ShakaPlayerWrapper
  private dealWithError(e: unknown) {
    // When we have an error we should also consider ourselves loaded.
    // Ignore LOAD_INTERRUPTED as it means another load has been initiated and we don't care about this error as it is for the old load
    if (isLoadInterruptedError(e)) {
      return;
    }

    const amIShakaError = isShakaError(e);

    const isPlaying = this.store.get(selectPlayerShouldBePlaying);
    const isCriticalError =
      !amIShakaError || e.severity === shakaPlayer.util.Error.Severity.CRITICAL;

    // Special case where the stream fails to play (due to multi-stream error on the stream provider side), we suppress the error in this case since it already is shown to the user in the stream
    // And most importantly we do not want to crash the player by accepting the error.
    if (
      this.options.logError &&
      isPlaying &&
      amIShakaError &&
      !isCriticalError &&
      e.code === shakaPlayer.util.Error.Code.BAD_HTTP_STATUS &&
      e.data[1] === 401
    ) {
      // still want to log to sentry though
      this.options.logError(new UnauthorizedStreamPlayerError(e));
      return;
    }

    const err = isCriticalError
      ? new CriticalPlayerError(e)
      : new PlayerError(e);

    if (!isCriticalError) {
      // When it's not critical we don't really want to do anything. Let's just log it.
      this.options.logError?.(err);
      return;
    }

    if (this.options.onError) {
      this.options.onError(err);
    }

    this.store.set(this.atom, actionPlayerSetError(err));
    this.player.unload();
  }

  /**
   * Loads the stream.
   */
  private loadStreamUrl = async (): Promise<void> => {
    if (this.shouldDetach()) {
      // We should not respond to stream changes while ads are playing.
      // We will start the stream after the ads are done playing.
      return;
    }

    const stream = this.store.get(selectPlayerCurrentStream(this.atom));

    if (areEqual(this.stream, stream)) {
      return;
    } else {
      this.stream = stream;
    }

    if (isNil(stream) || this.store.get(selectPlayerIsSuspended)) {
      return void this.player.unload();
    }

    this.store.set(this.atom, actionPlayerClearDuration());

    this.store.set(playerStatsAtom, actionPlayerStatsStartPlayout(new Date()));

    const { initialCurrentTime, playRequest, forceRedirect } = this.store.get(
      this.atom,
    );

    let certificate;
    if (stream.type === "hls7_fairplay") {
      const { data } = await this.store.get(fairPlayCertificateAtom);
      certificate = data;
    }

    const playerConfig = this.player.configure({
      stream,
      playRequest,
      getPlayerBufferSettings: this.getPlayerBufferSettings,
      allowParallelPrefetch: this.store.get(allowParallelPrefetchAtom),
      trackKnownBandwidth: this.store.get(trackKnownBandwidthAtom),
      fairPlayCertificate: certificate,
    });

    if (this.player instanceof ShakaPlayerWrapper) {
      this.store.set(
        playerStatsAtom,
        actionPlayerStatsSetConfiguration(
          playerConfig as shakaPlayer.extern.PlayerConfiguration,
        ),
      );
    }

    try {
      if (this.isEnabled) {
        const desiredCurrentTime = this.store.get(
          selectPlayerDesiredCurrentTime,
        );
        await this.player.load(
          stream.url,
          // Get the last requested current time from the player atom.
          desiredCurrentTime ??
            // If that does not exist, grab the initial current time from the player atom.
            // Meaning, the place where the user wanted to resume form originally.
            initialCurrentTime ??
            // If that does not exist, garb a default value that makes the most sense fot the stream.
            getLoadPositionForPlayRequest(playRequest, stream),
        );
      }

      if (!this.store.get(selectPlayerShouldBePaused(this.atom))) {
        this.store.set(this.atom, actionPlayerShouldPlay());
      }

      if (forceRedirect) {
        this.pushToTvRoot();
      }
    } catch (e: unknown) {
      this.dealWithError(e);
    }
  };

  private setPictureInPictureMode = (enabled: boolean) => {
    if (enabled && this.store.get(selectPlayerPictureInPictureSupported)) {
      // NOTE: Chrome exits out of fullscreen when going in pip.
      this.videoElement.requestPictureInPicture?.();
    } else if (!enabled && document.pictureInPictureElement) {
      document.exitPictureInPicture?.();
    }
  };
}
