import type { ReactElement } from "react";
import { format } from "date-fns";
import { useAtomValue } from "jotai";

import { developerDeviceInfoAtom } from "@sunrise/bigscreen";
import { isDolbySupportedAtom } from "@sunrise/player";

import * as styles from "@/components/debug/debug-version.css";
import { asBoolean } from "@/features/settings/developer/developer.utils";

export const DebugVersion = (): ReactElement | undefined => {
  const productInfo = useAtomValue(developerDeviceInfoAtom);
  const env = import.meta.env;
  const platform = import.meta.env.VITE_APP_PLATFORM;
  const date = env.VITE_APP_BUILD_DATE
    ? new Date(Number(env.VITE_APP_BUILD_DATE))
    : null;
  const isDolbySupported = useAtomValue(isDolbySupportedAtom);

  if (env.DEV) {
    return;
  }

  const rows = [
    [
      env.MODE,
      env.VITE_APP_COMMIT_HASH ? env.VITE_APP_COMMIT_HASH.slice(0, 7) : null,
      `${env.VITE_APP_VERSION}${env.VITE_APP_BUILD_NR ? `(${env.VITE_APP_BUILD_NR})` : ""}`,
      date && format(date, "dd.MM.yy - HH:mm"),
    ],
    [
      platform,
      productInfo
        ? Object.entries(productInfo).reduce(
            (acc, [key, value]) => `${acc}${key}: ${value} `,
            "",
          )
        : null,
      `Dolby: ${asBoolean(isDolbySupported)}`,
    ],
  ].map((row) => row.filter((item) => !!item));

  return (
    <div className={styles.root}>
      {rows.map((row, index1) => (
        <div key={index1} className={styles.row}>
          {row.map((part, index2) => (
            <div key={index2} className={styles.part}>
              {part}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
