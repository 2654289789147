import { atom } from "jotai";

import type { RecordingsSort } from "@sunrise/backend-types";

/**
 * This is the sorting for the recordings overview.
 *
 * @deprecated
 */
export const recordingsSortOverviewLegacyAtom =
  atom<RecordingsSort>("dateDesc");
