import { type ReactElement } from "react";
import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";

import { useTranslator } from "@sunrise/translator";
import { useGridDateSelector } from "@sunrise/yallo-guide";

import { FocusButton, Header } from "@/components";
import { ICON_SIZE_20 } from "@/components/icon/icon.config";

import { FocusContainer } from "../../utils/focus-container";
import { ChannelGroupSelector } from "./channel-group-selector";
import * as styles from "./guide-controls.css";

export function GuideControls({
  focusKey,
  onBack,
}: {
  focusKey: string;
  onBack: () => void;
}): ReactElement {
  const t = useTranslator();

  const { currentlySelectedDate, selectDate, moveToNow, moveToPrimeTime } =
    useGridDateSelector({
      getFocusKey: getCurrentFocusKey,
    });

  // TODO: Refactor:
  // Move Header to routes/guid, and passed "grid-nav-now" button as a child.
  // The now button should encapsulate "moveToNow" callback
  return (
    <FocusContainer focusKey={focusKey} onBack={onBack} onLeft={onBack}>
      {(directions) => (
        <Header
          left={
            <>
              <FocusButton
                className={clsx([styles.button, styles.dayFilterButton])}
                focusKey="grid-nav-date"
                iconRight="dropdown-down"
                iconSize={ICON_SIZE_20}
                text={currentlySelectedDate}
                textAlign="left"
                onArrowPress={directions.onArrowPress("left")}
                onEnterPress={selectDate} // open dialog to select new date
              />
              <FocusButton
                className={styles.button}
                focusKey="grid-nav-now"
                text={t("guide_now_button")}
                onEnterPress={moveToNow}
              />
              <FocusButton
                className={styles.button}
                focusKey="grid-nav-prime-time"
                text={t("guide_primetime_button")}
                onEnterPress={moveToPrimeTime}
              />
            </>
          }
          right={<ChannelGroupSelector />}
        />
      )}
    </FocusContainer>
  );
}
