import { useCallback } from "react";
import { useAtomValue } from "jotai/react";
import { useAtomCallback } from "jotai/utils";

import type {
  RecordingStatusFilter,
  RecordingTypeFilter,
} from "@sunrise/backend-types";
import { actionDialogOpen, dialogAtom } from "@sunrise/dialogs";
import { useTranslator } from "@sunrise/translator";

import {
  currentRecordingsFilterAtom,
  currentRecordingsStatusFilterAtom,
  currentRecordingsTypeFilterAtom,
} from "./current-recordings-filter.atom";

export const useFilteringRecordings = () => {
  const t = useTranslator();
  const currentFilterValue = useAtomValue(currentRecordingsFilterAtom);
  const currentFilterTranslated = t(currentFilterValue);

  const filterRecordings = useAtomCallback(
    useCallback(
      (get, set) => {
        const type = get(currentRecordingsTypeFilterAtom);
        const status = get(currentRecordingsStatusFilterAtom);

        set(
          dialogAtom,
          actionDialogOpen({
            type: "list",
            id: "recordings-filters-list-dialog",
            title: t("recordings_filters"),
            buttonTextAlign: "left",
            onButtonClick: (value, sectionIdx) => {
              if (!value) return;
              if (sectionIdx === 0) {
                set(
                  currentRecordingsStatusFilterAtom,
                  value as RecordingStatusFilter,
                );
              } else {
                set(
                  currentRecordingsTypeFilterAtom,
                  value as RecordingTypeFilter,
                );
              }
            },
            sections: [
              {
                title: t("recordings_status_title"),
                options: [
                  { label: t("recordings_filter_button_all"), value: "all" },
                  {
                    label: t("recordings_filter_button_recorded"),
                    value: "recorded",
                  },
                  {
                    label: t("recordings_filter_button_planned"),
                    value: "planned",
                  },
                ],
              },
              {
                title: t("recordings_type_title"),
                options: [
                  { label: t("recordings_filter_button_all"), value: "all" },
                  {
                    label: t("recordings_filter_button_movies"),
                    value: "movies",
                  },
                  {
                    label: t("recordings_filter_button_series"),
                    value: "series",
                  },
                ],
              },
            ],
            activeOptions: [status, type],
            lastFocusKey: "",
            radioButtons: true,
          }),
        );
      },
      [t],
    ),
  );

  return {
    filterRecordings,
    currentRecordingsFilter: currentFilterTranslated,
  };
};
