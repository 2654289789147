import { forwardRef } from "react";
import clsx from "clsx";

import {
  type MouseNavigationProps,
  useMouseNavigation,
} from "@sunrise/bigscreen";

import type { IconColor, IconProps } from "@/components/icon";
import { Icon } from "@/components/icon";
import { typography as textStyle } from "@/styles/typography.css";

import * as styles from "./button.css";

type Variant =
  | "primary"
  | "secondary"
  | "light"
  | "ghost"
  | "outlined"
  | "outlineddanger";

type ButtonCommonProps = CommonProps & {
  text: string;
  block?: boolean;
  variant?: Variant;
  textAlign?: "left" | "right" | "center";
  short?: boolean; // in terms of height
  hideText?: boolean;
  disabled?: boolean;
  focused?: boolean;
  active?: boolean;
  typography?: {
    size: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "h7" | "h8";
    weight: "bold" | "regular";
  };
};

export type ButtonProps = ButtonCommonProps &
  MouseNavigationProps & {
    iconSize?: IconProps["size"];
  } & (
    | {
        iconLeft?: IconProps["name"];
        iconRight?: never;
      }
    | {
        iconLeft?: never;
        iconRight?: IconProps["name"];
      }
  );

// moved content outside of the button due to cognitive complexity issue
const ButtonContent = ({
  text,
  textAlign = "center",
  iconLeft,
  iconRight,
  iconSize,
  variant,
  dataTestId,
  hideText,
  iconColor,
}: {
  text: string;
  textAlign?: ButtonProps["textAlign"];
  iconLeft?: ButtonProps["iconLeft"];
  iconRight?: ButtonProps["iconRight"];
  iconSize?: ButtonProps["iconSize"];
  variant: Variant;
  dataTestId: string;
  hideText?: boolean;
  iconColor: IconColor;
}): JSX.Element => {
  return (
    <div
      className={clsx([
        styles[textAlign],
        iconRight && styles.iconRightTextStyle,
        hideText && styles.hideText,
      ])}
      data-testid={`${dataTestId}.content`}
    >
      {iconLeft && (
        <Icon
          className={styles.iconStyle[variant]}
          color={iconColor}
          data-testid={`${dataTestId}.iconLeft`}
          name={iconLeft}
          size={iconSize}
        />
      )}
      <div>{text}</div>
      {iconRight && (
        <Icon
          className={styles.rightIconStyle}
          color={iconColor}
          data-testid={`${dataTestId}.iconRight`}
          name={iconRight}
          size={iconSize}
        />
      )}
    </div>
  );
};

const getIconColor = (state: string): IconColor => {
  return state === "disabled"
    ? "white"
    : state === "focused"
      ? "dark"
      : state === "active"
        ? "yellow"
        : "white";
};

/**
 * @note If you want to wire up focus on the button please check {@link FocusButton}.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      "data-testid": dataTestId = "button",
      block,
      className,
      disabled,
      focused,
      active,
      iconLeft,
      iconRight,
      iconSize,
      short,
      text,
      textAlign = "center",
      variant = "primary",
      navId,
      onMouseClick,
      onMouseEnter,
      hideText = false,
      typography = { size: "h7", weight: "bold" },
    }: ButtonProps,
    ref,
  ): JSX.Element {
    const hasIcon = !!iconLeft || !!iconRight;
    const { onClick, onEnter } = useMouseNavigation({
      navId,
      onMouseClick,
      onMouseEnter,
    });

    const state = disabled
      ? "disabled"
      : focused
        ? "focused"
        : active
          ? "active"
          : "nostate";

    const iconColor = getIconColor(state);

    return (
      <button
        ref={ref}
        className={clsx([
          styles.root,
          block && styles.blockButtonStyle,
          hasIcon && styles.hasIconStyle,
          short && styles.shortButtonStyle,
          styles[variant],
          textStyle[typography.size][typography.weight],
          styles[state],
          className,
        ])}
        data-active={!!active}
        data-focused={state === "focused"}
        data-state={state}
        data-testid={dataTestId}
        data-text={text}
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={onEnter}
      >
        {active && hideText && (
          <div
            className={clsx([styles.activeButtonBorder])}
            data-testid={`${dataTestId}.active-border`}
          />
        )}
        <ButtonContent
          dataTestId={dataTestId}
          hideText={hideText}
          iconColor={iconColor}
          iconLeft={iconLeft}
          iconRight={iconRight}
          iconSize={iconSize}
          text={text}
          textAlign={textAlign}
          variant={variant}
        />
      </button>
    );
  },
);
