import { atom } from "jotai";
import { atomWithReducer } from "jotai/utils";

import { type Nullable } from "@sunrise/utils";

type ZappingChannelAtomState = {
  isVisible: boolean;
};

export function makeZappingChannelAtomDefaultState(
  state?: Partial<ZappingChannelAtomState>,
): ZappingChannelAtomState {
  return {
    isVisible: state?.isVisible ?? false,
  };
}

type ActionHide = {
  type: "zapping-channel/hide";
};
type ActionShow = {
  type: "zapping-channel/show";
};

type ZappingChannelAction = ActionHide | ActionShow;

export const zappingControlAtom = atomWithReducer<
  ZappingChannelAtomState,
  ZappingChannelAction
>(makeZappingChannelAtomDefaultState(), zappingChannelAtomReducer);

export function zappingChannelAtomReducer(
  ps: ZappingChannelAtomState,
  action: Nullable<ZappingChannelAction>,
): ZappingChannelAtomState {
  switch (action?.type) {
    case "zapping-channel/hide": {
      return { ...ps, isVisible: false };
    }
    case "zapping-channel/show": {
      return { ...ps, isVisible: true };
    }
    case null:
    case undefined: {
      return ps;
    }
  }
}

/*
 *
 * ACTIONS
 *
 */

export function actionZappingChannelHide(): ActionHide {
  return {
    type: "zapping-channel/hide",
  };
}

export function actionZappingChannelShow(): ActionShow {
  return {
    type: "zapping-channel/show",
  };
}

export const zappingControlIsVisibleAtom = atom(
  (get) => get(zappingControlAtom).isVisible,
);
