import { useEffect } from "react";
import { useSetAtom } from "jotai";

import { isNil } from "@sunrise/utils";

import { visibleChannelsAtom } from "../guide-visible-data.atom";

export function useGridVisibleDataChangedChannels({
  firstChannelIndexVisible,
  lastChannelIndexVisible,
}: {
  firstChannelIndexVisible: number | null;
  lastChannelIndexVisible: number | null;
}) {
  const dispatch = useSetAtom(visibleChannelsAtom);
  /**
   * We need to communicate what data is currently visible to the hook.
   * So it can load in the correct data.
   *
   * This effect depends mostly on firstChannelIndexVisible and firstTimeVisible
   */
  useEffect(() => {
    if (isNil(firstChannelIndexVisible) || isNil(lastChannelIndexVisible)) {
      return;
    }

    dispatch({
      start: firstChannelIndexVisible ?? 0,
      end: lastChannelIndexVisible ?? 0,
    });
  }, [firstChannelIndexVisible, lastChannelIndexVisible, dispatch]);
}
