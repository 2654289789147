import { type ReactNode } from "react";
import { calc } from "@vanilla-extract/css-utils";
import { assignInlineVars } from "@vanilla-extract/dynamic";

import { useThumbnail } from "@sunrise/player";
import type { Nullable } from "@sunrise/utils";

import { baseGap } from "@/styles/theme.css";

import * as styles from "./thumbnail.css";

type ThumbnailProps = {
  width: Nullable<number>;
  arrowPosition: Nullable<number>;
} & CommonProps;

export function Thumbnail({
  width,
  arrowPosition,
  "data-testid": dataTestId = "thumbnail",
}: ThumbnailProps): ReactNode {
  const { thumbnail, imageBase64 } = useThumbnail({ width });

  if (!thumbnail) {
    return null;
  }

  return (
    <div
      className={styles.thumbnailContainer}
      style={{
        marginLeft: `${(thumbnail.width / 2) * -1}px`,
        marginTop: `${(thumbnail.height + baseGap) * -1}px`,
        ...assignInlineVars({
          [styles.arrowPositionLeft]: arrowPosition
            ? `${arrowPosition}px`
            : calc.subtract(`50% - ${baseGap * 2}px`),
        }),
      }}
    >
      <div
        className={styles.thumbnailImage}
        data-testid={`${dataTestId}.thumbnail-image`}
        style={{
          width: `${thumbnail.width}px`,
          height: `${thumbnail.height}px`,
          backgroundImage: `url(${imageBase64 ? imageBase64 : thumbnail.url})`,
          backgroundSize: `${thumbnail.fullWidth}px ${thumbnail.fullHeight}px`,
          backgroundPosition: `${thumbnail.x}px ${thumbnail.y}px`,
        }}
      />
    </div>
  );
}
