import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import {
  selectPlayerCurrentStream,
  selectPlayerCurrentTime,
  selectPlayerIsPlaying,
} from "./player.atom";
import { playerShouldDetachAtom } from "./player-should-detach.atom";

export const playerIsHangingWhenIdleForXMillisecondsAtom = atom<null | number>(
  10_000,
);

const _playerIsHangingAtom = atom(false);
_playerIsHangingAtom.debugPrivate = true;

/**
 * To remember if we should be checking for hanging streams or not.
 */
const _shouldCheckHangingAtom = atom<boolean | null>(null);
_shouldCheckHangingAtom.debugPrivate = true;

/**
 * Indicates if the player hangs or not.
 *
 * Can be set in order to reset the hanging indicator.
 * It will again check for hanging streams as soon as the stream changes.
 */
export const playerIsHangingAtom = atom(
  (get) => {
    const milliseconds = get(playerIsHangingWhenIdleForXMillisecondsAtom);
    if (!milliseconds) {
      // There's no point in starting any of the effects if there is no timer set.
      return false;
    }

    get(monitorShouldCheckHangingEffect);

    return get(_playerIsHangingAtom);
  },
  (_, set) => {
    set(_shouldCheckHangingAtom, false);
  },
);

/**
 * Whenever the stream changes, we can start checking if the stream hangs.
 */
const monitorShouldCheckHangingEffect = atomEffect((get, set) => {
  get(selectPlayerCurrentStream());
  // NOTE: We need to embed this effect in here since otherwise changing the _shouldCheckHangingAtom will not trigger the effect.
  get(monitorHangingEffect);
  set(_shouldCheckHangingAtom, true);
});

/**
 * When the player is supposed to be playing and loaded,
 * we will set isHangingAtom to true when we detect no updates to the currentTime for longer than X seconds.
 *
 * That will also serve as an indicator to clients to decide the stream may need a restart.
 */
const monitorHangingEffect = atomEffect((get, set) => {
  const shouldCheck = get(_shouldCheckHangingAtom);

  const setHanging = (isHanging: boolean) => {
    set(_playerIsHangingAtom, isHanging);
  };

  const milliseconds = get(playerIsHangingWhenIdleForXMillisecondsAtom);
  if (milliseconds === null || !milliseconds || !shouldCheck) {
    setHanging(false);
    return;
  }

  // When there is no stream playing, we don't need to monitor.
  // Also by listening on the stream, we make sure to reset the timer whenever the stream changes as well.
  const stream = get(selectPlayerCurrentStream());
  if (!stream) {
    setHanging(false);
    return;
  }

  // When the player is detached, we should not be checking idle time.
  const shouldDetach = get(playerShouldDetachAtom);
  if (shouldDetach) {
    setHanging(false);
    return;
  }

  const isPlaying = get(selectPlayerIsPlaying);
  if (!isPlaying) {
    setHanging(false);
    return;
  }

  // Store a reference to the time the player is currently indicating.
  let last = get.peek(selectPlayerCurrentTime);

  const timer = setInterval(() => {
    const current = get.peek(selectPlayerCurrentTime);

    // NOTE: When the stream changes, the interval is cleaned up. So we are really only comparing the player times for the exact same stream.
    setHanging(last === current);
    last = current;
  }, milliseconds);

  return () => {
    clearInterval(timer);
  };
});
