import { type JSX, Suspense } from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";

import { EscapeRouteLayout, PageSpinner, RouteLayout } from "@/components";
import { globalFocusKey } from "@/config/focus-key";
import { useMenu } from "@/features/menu/use-menu";
import { Recommendations } from "@/features/recommendations";
import { isArrowLeftKey } from "@/utils/navigation";

import * as styles from "./home.css";

const localWidgetFocusKey = {
  recommendations: globalFocusKey.activePage + ".recommendations",
};

function ConnectedHome(): JSX.Element {
  return (
    <EscapeRouteLayout>
      <Recommendations
        className={styles.recommendations}
        focusKey={localWidgetFocusKey.recommendations}
      />
    </EscapeRouteLayout>
  );
}

export default function Home(): JSX.Element {
  const menu = useMenu({ shouldShowMenu: true, expandOnBack: true });

  const { ref, focusKey } = useFocusable({
    focusKey: globalFocusKey.activePage,
    preferredChildFocusKey: localWidgetFocusKey.recommendations,
    focusable: true,
    isFocusBoundary: true,
    onArrowPress: function handleArrowPress(direction) {
      if (isArrowLeftKey(direction)) menu.expand();

      // Return false to block the default behavior.
      return false;
    },
  });

  return (
    <FocusContext.Provider value={focusKey}>
      <main ref={ref} className={styles.root} data-testid="home" tabIndex={0}>
        <RouteLayout>
          <Suspense fallback={<PageSpinner data-testid="home" />}>
            <ConnectedHome />
          </Suspense>
        </RouteLayout>
      </main>
    </FocusContext.Provider>
  );
}
