export enum DialogButtonType {
  OK = "OK",
  CLOSE = "CLOSE",
  REPLAY_SETTINGS = "REPLAY_SETTINGS",
}

type ErrorConfigurationBase = {
  /**
   * Title to be shown when the error is triggered.
   */
  title: string;
  /**
   * The technical error name to be sent as message through websocket, mandatory for all errors.
   */
  technicalErrorName: string;
  /**
   * The optional reject button type.
   */
  rejectionButton?: DialogButtonType;
  /**
   * If reject is present, should we make that the default action or not.
   */
  focusReject?: boolean;
  /**
   * Description to be shown when error is triggered, key is the translation key and dynamicParams are bounded to the key.
   */
  description: { key: string; dynamicParams?: (string | number)[] }[];
};

type NonBlockingErrorConfiguration = ErrorConfigurationBase & {
  /**
   * When true the user can not press back to dismiss the dialog.
   * OK button will also not be shown.
   */
  type?: "non-blocking";
  /**
   * The confirmation button type.
   */
  confirmationButton: DialogButtonType;
};

type BlockingErrorConfiguration = ErrorConfigurationBase & {
  type: "blocking";
};

export type ErrorConfiguration =
  | NonBlockingErrorConfiguration
  | BlockingErrorConfiguration;
