/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AssetType */
export enum AssetType {
  Series = "series",
  Seriesepisode = "seriesepisode",
  Seriesseason = "seriesseason",
  Movie = "movie",
  Program = "program",
  Unknown = "unknown",
}

/** DisplayConfiguration */
export interface DisplayConfiguration {
  /** @default "box" */
  display_type?: DisplayType;
  /**
   * Numbered
   * @default false
   */
  numbered?: boolean;
}

/** DisplayType */
export enum DisplayType {
  Box = "box",
  Portrait = "portrait",
  Small = "small",
  Hero = "hero",
}

/** EpgEntrySchema */
export interface EpgEntrySchema {
  /**
   * EPG ID
   * The id of the EPG
   */
  id: string;
  /**
   * Type
   * @default "epg_entry"
   */
  type?: "epg_entry";
  /**
   * Start
   * The start time of the epg
   * @format date-time
   */
  start: TimeISOString;
  /**
   * End
   * The end time of the epg
   * @format date-time
   */
  end: TimeISOString;
  asset: ReducedAssetSchema;
  channel: ReducedChannelSchema;
  /**
   * Replay stream URL
   * The replay stream URL of the EPG entry
   */
  stream_service_url: string;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** ListType */
export enum ListType {
  EpgEntry = "epg_entry",
  Channel = "channel",
  ContinueWatching = "continue_watching",
  Highlights = "highlights",
  Vod = "vod",
}

/** Page[RecommendationListSchema] */
export interface PageRecommendationListSchema {
  /** Items */
  items: RecommendationListSchema[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** Page[Union[EpgEntrySchema, ReducedChannelSchema]] */
export interface PageUnionEpgEntrySchemaReducedChannelSchema {
  /** Items */
  items: (EpgEntrySchema | ReducedChannelSchema)[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** RecommendationListSchema */
export interface RecommendationListSchema {
  /**
   * Recommendation List ID
   * The ID of the recommendation list (not a mongo id)
   */
  recommendation_list_id: string;
  /**
   * Title
   * The title of the recommendation list
   */
  title: string;
  display_configuration: DisplayConfiguration;
  type: ListType;
}

/** ReducedAssetSchema */
export interface ReducedAssetSchema {
  /**
   * Asset ID
   * The id of the asset
   */
  id: string;
  /**
   * Asset Type
   * The type of the asset (movie, series episode...)
   */
  type: AssetType;
  /**
   * Title
   * The title of the asset
   */
  title: string;
  /**
   * Subtitle
   * The subtitle of the asset
   * @default ""
   */
  subtitle?: string | null;
  /**
   * Poster
   * The poster url of the asset
   */
  poster_url: string;
  /**
   * The blurhash of the poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Season Number
   * The season number of the series episode
   */
  season_number?: number | null;
  /**
   * Episode Number
   * The episode number of the series episode
   */
  episode_number?: number | null;
  /**
   * Asset Recording Available
   * Is it possible to record this asset as a series?
   */
  readonly asset_recording_available: boolean;
}

/** ReducedChannelSchema */
export interface ReducedChannelSchema {
  /**
   * Channel ID
   * The id of a channel
   */
  id: string;
  /**
   * Type
   * @default "channel"
   */
  type?: "channel";
  /**
   * Name
   * The name of the channel
   */
  name: string;
  /**
   * Logo
   * The logo of the channel
   */
  logo: string;
  /**
   * Stream Service URL
   * The url of the stream service
   */
  stream_service_url: string;
}

/** ValidLanguages */
export enum ValidLanguages {
  En = "en",
  De = "de",
  Fr = "fr",
  It = "it",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";
import type { TimeISOString } from "@sunrise/backend-types-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  recommendation = {
    /**
     * @description This endpoint returns a list of rows to be displayed by the client.
     *
     * @tags recommendation-lists
     * @name RecommendationListsRecommendationV1RecommendationListsGet
     * @summary Recommendation Lists
     * @request GET:/recommendation/v1/recommendation-lists
     * @secure
     */
    recommendationListsRecommendationV1RecommendationListsGet: (
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageRecommendationListSchema, void | HTTPValidationError>({
        path: `/recommendation/v1/recommendation-lists`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint returns a list of recommendation entries (either EPG entries or channels)
     *
     * @tags recommendation-lists
     * @name RecommendationListDetailRecommendationsRecommendationV1RecommendationListsRecommendationListIdRecommendationsGet
     * @summary Recommendation List Detail Recommendations
     * @request GET:/recommendation/v1/recommendation-lists/{recommendation_list_id}/recommendations
     * @secure
     */
    recommendationListDetailRecommendationsRecommendationV1RecommendationListsRecommendationListIdRecommendationsGet: (
      recommendationListId: string,
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageUnionEpgEntrySchemaReducedChannelSchema, void | HTTPValidationError>({
        path: `/recommendation/v1/recommendation-lists/${recommendationListId}/recommendations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthRecommendationV1HealthGet
     * @summary Health
     * @request GET:/recommendation/v1/health
     */
    healthRecommendationV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/recommendation/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootRecommendationV1Get
     * @summary Root
     * @request GET:/recommendation/v1
     */
    rootRecommendationV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/recommendation/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
