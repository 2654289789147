import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { MappedAsset } from "@sunrise/asset";
import type {
  AssetId,
  ChannelId,
  TimeISOString,
} from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";
import { epgEntryByIdAtom } from "@sunrise/yallo-epg";
import { recordingByIdNgAtom } from "@sunrise/yallo-recordings";

import { assetDetailsByIdAtom } from "./asset-details-by-id.atom";
import { getPrefixAndSubtitle } from "./helpers/get-prefix-and-subtitle";
import type { EpgOrRecordingId } from "./recording-status.atom";

type CoreDetailsAtomArgs = { assetId: AssetId } & EpgOrRecordingId;

type CoreDetailReturn = {
  title: string;
  subtitle?: string | ReturnType<typeof getPrefixAndSubtitle>;
  description?: string;
  channelId: Nullable<ChannelId>;
  channelName: Nullable<string>;
  channelLogo: Nullable<string>;
  start: Nullable<TimeISOString>;
  end: Nullable<TimeISOString>;
  posterBlurHash?: Nullable<string>;
  posterPath: Nullable<string>;
  seriesId: Nullable<AssetId>;
  type: MappedAsset["type"] | ("recording" | "recording_group");
};

export const coreDetailsAtom = atomFamily(
  (args: CoreDetailsAtomArgs) =>
    atom<Promise<Nullable<CoreDetailReturn>>>(async (get) => {
      // details
      if ("epgId" in args) {
        const { assetId, epgId } = args;
        const asset = await get(assetDetailsByIdAtom(assetId));
        const epg = await get(epgEntryByIdAtom(epgId));
        const channel = epg.data
          ? await get(channelByIdAtom(epg.data.channel.id))
          : null;

        if (!asset || !channel || !epg.data) return null;

        const prefixAndSubtitle = getPrefixAndSubtitle(
          asset?.type,
          asset?.seasonNumber,
          asset?.episodeNumber,
          asset?.subtitle ?? epg.data?.asset.subtitle ?? "",
        );

        return {
          id: asset.id,
          title: asset.title ?? epg.data?.asset.title ?? "",
          subtitle:
            prefixAndSubtitle ??
            asset.subtitle ??
            epg.data?.asset.subtitle ??
            undefined,
          description: asset.plot ?? undefined,
          channelId: channel.id,
          channelName: channel.name,
          channelLogo: channel.logo,
          start: epg.data.actualStart,
          end: epg.data.actualEnd,
          posterBlurHash: asset.posterBlurHash ?? null,
          posterPath: asset.posterPath,
          seriesId: asset.seriesId,
          type: asset.type,
        };
      }

      // Single recording and Recording Group
      const recordingId =
        "recordingId" in args ? args.recordingId : args.recordingGroupId;
      const recording = (await get(recordingByIdNgAtom(recordingId))).data;

      if (!recording) return null;

      const title = recording.asset.title;
      const subtitle = recording.asset.subtitle
        ? recording.asset.subtitle !== title
          ? recording.asset.subtitle
          : undefined
        : undefined;
      const isSingleRecording = recording.type === "recording";

      return {
        title,
        subtitle,
        channelId: isSingleRecording
          ? ((recording.channel_id as ChannelId) ?? null)
          : null,
        channelName: isSingleRecording ? recording.channel_name : null,
        channelLogo: isSingleRecording ? recording.channel_logo : null,
        description: recording.asset.description ?? undefined,
        start: isSingleRecording ? recording.epg_start : null,
        end: isSingleRecording ? recording.epg_end : null,
        posterBlurHash: recording.asset.poster_blurhash ?? null,
        posterPath: recording.asset.poster_url ?? null,
        seriesId: null,
        type: recording.type,
      };
    }),
  areEqual,
);
