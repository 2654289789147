import { atom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { recordingsOverviewQueryAtom } from "@sunrise/backend-ng-recordings";
import { currentLanguageAtom } from "@sunrise/i18n";
import type { PageableItems } from "@sunrise/types";
import {
  type GenericRecording,
  legacyRecordingToGenericRecording,
  ngRecordingToGenericRecording,
} from "@sunrise/yallo-recordings";
import { pageableItemsFromQuery } from "@sunrise/yallo-recordings-list";

import { recordingsLegacyQueryAtom } from "../legacy/queries/recordings.legacy.query.atom";

/**
 * Returns the generic recordings overview list regardless of which backend is active.
 *
 * The paging is in the style of the ng api.
 * The items are the generic recording types.
 */
export const pagedGenericRecordingsAtom = atom<PageableItems<GenericRecording>>(
  (get) => {
    if (get(isLegacyBackendAtom)) {
      const query = get(recordingsLegacyQueryAtom);
      if (!query) {
        // TODO: Should we also make the response nullable here? Since the legacy ones seem to return null when the user is not logged in.
        throw new Error("No query found for legacy backend");
      }

      const result = pageableItemsFromQuery(query);

      return {
        ...result,
        items:
          result.items.map((item) =>
            legacyRecordingToGenericRecording(item, get(currentLanguageAtom)),
          ) ?? [],
      };
    }

    const result = pageableItemsFromQuery(get(recordingsOverviewQueryAtom));

    return {
      ...result,
      items: result.items.map(ngRecordingToGenericRecording) ?? [],
    };
  },
);
