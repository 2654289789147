import type { ReactNode } from "react";

import { QrCode } from "@/components";

export const qrCodeUrl =
  "https://4.bp.blogspot.com/_fqgP02TDHV4/TULQpNsbA1I/AAAAAAAAAW0/FvXMaNe7zB4/s1600/QRcode_frompage.jpg";
export const qrLink =
  "https://media.idownloadblog.com/wp-content/uploads/2018/01/Netflix-Logo.png";

export function DebugQrCodes(): ReactNode {
  return (
    <div style={{ margin: "3%", width: "70%" }}>
      <div>
        <h2>QR codes</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ marginBottom: "3%" }}>
            <QrCode link="" qrCodeUrl={qrCodeUrl} />
          </div>
          <div style={{ marginBottom: "3%" }}>
            {" "}
            <QrCode link={qrLink} qrCodeUrl={qrCodeUrl} />
          </div>
          <div style={{ marginBottom: "3%" }}>
            {" "}
            <QrCode link={qrLink} qrCodeUrl={qrCodeUrl} textColor="yellow" />
          </div>
          <div style={{ marginBottom: "3%" }}>
            {" "}
            <h3>SIZE 200</h3>
            <QrCode
              link={qrLink}
              qrCodeUrl={qrCodeUrl}
              size={200}
              textColor="white"
            />
          </div>
          <div style={{ marginBottom: "3%" }}>
            {" "}
            <h3>SIZE 500</h3>
            <QrCode
              link={qrLink}
              qrCodeUrl={qrCodeUrl}
              size={500}
              textColor="yellow"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
