import type { ReactNode } from "react";
import { useAtomValue } from "jotai";

import { channelsOfCurrentSelectedGroupLegacyAtom } from "@sunrise/yallo-channel-list";
import { currentlyRequestedPlayRequestAtom } from "@sunrise/yallo-common-player-manager";

import { globalFocusKey } from "@/config/focus-key";
import { FocusContainer } from "@/utils/focus-container";
import { localWidgetFocusKey } from "@/utils/virtualizer";

import { focusContainer } from "../channel-list.css";
import { VirtualizedChannelList } from "./virtualized-channel-list";

type ChannelListProps = CommonProps & {
  doClose?: () => void;
  doShowMore?: () => void;
  shouldFocus?: boolean;
};

/**
 * Just renders the list of channels.
 * Should auto-select the currently playing channel or the last selected channel.
 * It will also auto-scroll to the currently playing channel.
 */
export function InnerChannelList({
  "data-testid": dataTestId = "channel-list",
  doClose,
  doShowMore,
  shouldFocus,
}: ChannelListProps): ReactNode {
  const playRequest = useAtomValue(currentlyRequestedPlayRequestAtom);
  const focusKey = globalFocusKey.channelList;

  const { channels, fetchNextPage } = useAtomValue(
    channelsOfCurrentSelectedGroupLegacyAtom,
  );

  const findInitialIndex = channels.findIndex(
    (channel) => channel.id === playRequest?.channelId,
  );
  const initialIndex = findInitialIndex > -1 ? findInitialIndex : 0;

  const preferredChildFocusKey = playRequest?.channelId
    ? localWidgetFocusKey.row(focusKey, initialIndex)
    : undefined;

  return (
    <FocusContainer
      className={focusContainer}
      focusKey={focusKey}
      preferredChildFocusKey={preferredChildFocusKey}
      shouldFocus={shouldFocus}
      boundary
      onLeft={doClose}
      onRight={doShowMore}
    >
      {(handlers) => (
        <VirtualizedChannelList
          channels={channels}
          data-testid={dataTestId}
          fetchNextPage={fetchNextPage}
          focusKey={focusKey}
          initialIndex={initialIndex}
          onLeftBound={handlers.onLeftBound}
          onRightBound={handlers.onRightBound}
        />
      )}
    </FocusContainer>
  );
}
