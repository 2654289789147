import type { ReactNode } from "react";

import { theme } from "@/styles/theme.css";

import * as styles from "./design-system.css";

export function DebugColors(): ReactNode {
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "1%" }}>
      <h3>PURPLE</h3>
      <div
        style={{
          display: "flex",
          margin: "1%",
          backgroundColor: theme.color.white[70],
        }}
      >
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[100] }}
        >
          100
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[95] }}
        >
          95
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[90] }}
        >
          90
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[85] }}
        >
          85
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[80] }}
        >
          80
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[70] }}
        >
          70
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[60] }}
        >
          60
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[50] }}
        >
          50
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[40] }}
        >
          40
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[30] }}
        >
          30
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[20] }}
        >
          20
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.purple[10] }}
        >
          10
        </div>
      </div>

      <h3>GREY</h3>
      <div
        style={{
          display: "flex",
          margin: "1%",
          backgroundColor: theme.color.white[70],
        }}
      >
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.grey[100] }}
        >
          100
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.grey[90] }}
        >
          90
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.grey[80] }}
        >
          80
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.grey[70] }}
        >
          70
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.grey[60] }}
        >
          60
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.grey[50] }}
        >
          50
        </div>
      </div>

      <h3>WHITE</h3>
      <div style={{ display: "flex", margin: "0.1%", color: "black" }}>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.white[100] }}
        >
          100
        </div>

        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.white[70] }}
        >
          70
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.white[50] }}
        >
          50
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.white[30] }}
        >
          30
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.white[20] }}
        >
          20
        </div>
      </div>

      <h3>CYAN</h3>
      <div
        style={{
          display: "flex",
          margin: "0.1%",
        }}
      >
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.cyan[100] }}
        >
          100
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.cyan[20] }}
        >
          20
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.cyan[10] }}
        >
          10
        </div>
      </div>

      <h3>YELLOW AND RED</h3>
      <div
        style={{
          display: "flex",
          margin: "0.1%",
        }}
      >
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.yellow }}
        />
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.red }}
        />
      </div>
      <h3>BLUE</h3>
      <div
        style={{
          display: "flex",
          margin: "0.1%",
        }}
      >
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.blue[100] }}
        >
          100
        </div>
        <div
          className={styles.color}
          style={{ backgroundColor: theme.color.blue[90] }}
        >
          90
        </div>
      </div>
    </div>
  );
}
