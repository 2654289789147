import { loadScript } from "@/utils/script";

let loadDeviceInfoApiPromise: Promise<void> | null = null;
let deviceInfoApiLoaded = false; // window.webapis is already populated before

export async function loadDeviceInfoApi(): Promise<void> {
  if (deviceInfoApiLoaded) return;

  if (!loadDeviceInfoApiPromise) {
    loadDeviceInfoApiPromise = loadScript(
      "https://cache.zeasn.tv/webstatic/homepage_web/deviceinfo/zeasn_deviceInfo_sdk.js",
    );
    loadDeviceInfoApiPromise.then(() => (deviceInfoApiLoaded = true));
  }

  return loadDeviceInfoApiPromise;
}
