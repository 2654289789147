import { forwardRef, memo, type ReactNode } from "react";
import areEqual from "fast-deep-equal";
import { useAtomValue } from "jotai";

import type { AssetId } from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";
import type { GenericRecordingGroup } from "@sunrise/yallo-recordings";
import {
  isBulkDeletionModeAtom,
  isRecordingMarkedForBulkDeletionAtom,
} from "@sunrise/yallo-recordings-overview";

import { RecordingProgramBox } from "@/components/boxes";
import { programBoxSize } from "@/config/size";
import { makePosterImgUrl } from "@/utils/image";

export type RecordingItemProps = CommonProps & {
  seriesAssetId: Nullable<AssetId>;
  focused: boolean;
  recording: GenericRecordingGroup;
  active?: boolean;
  index?: number;
};

/**
 * Program box specifically for recordings.
 * This does not mean that the data shown on it is actually from the recording.
 * When we show Continue-Watching and we favour playout of replay over recordings ...
 * then the CW data should come from the replay item and not from the recordings item.
 */
export const RecordingItemGroup = memo(
  forwardRef<HTMLDivElement, RecordingItemProps>(function RecordingItem(
    {
      "data-testid": dataTestId = "RecordingItem",
      className,
      recording,
      ...props
    },
    ref,
  ): ReactNode {
    const isBulkDeletionMode = useAtomValue(isBulkDeletionModeAtom);
    const isMarkedForDeletion = useAtomValue(
      isRecordingMarkedForBulkDeletionAtom(recording.id),
    );

    return (
      <RecordingProgramBox
        ref={ref}
        airTime={null}
        className={className}
        coverImageUrl={makePosterImgUrl(
          recording.posterUrl,
          programBoxSize.recordings.image.width,
          programBoxSize.recordings.image.height,
        )}
        data-focused={props.focused}
        data-testid={dataTestId}
        end={""}
        focused={props.focused}
        index={props.index}
        isBulkDeletionMode={isBulkDeletionMode}
        isMarkedForDeletion={isMarkedForDeletion}
        logoUrl={null}
        numberOfEpisodes={recording.episodeCount}
        recordingState={null}
        seasonAndEpisode={null}
        start={""}
        subtitle={recording.subtitle}
        title={recording.title}
        type="series"
      />
    );
  }),
  areEqual,
);
