import { atom, useAtomValue } from "jotai";
import { atomEffect } from "jotai-effect";

import type { Translatable } from "@sunrise/i18n";
import { useTranslatable } from "@sunrise/translator";
import {
  generalErrorDisplayContentsFeatureAtom,
  getPlayerErrorMessageAndTitle,
} from "@sunrise/yallo-error-mapping";

import { errorToShowInPlayerAtom } from "./error-to-show-in-player.atom";
import { traceToSentry } from "./helpers/trace-to-sentry";

const errorToShowOnPlayerWithTitleAndDescription = atom<
  Promise<{
    description: Translatable;
    title: Translatable;
    error: Error;
    hidden: boolean;
  } | null>
>(async (get) => {
  get(logToSentryEffectInPlayerErrorOverlay);

  const { error, hidden } = get(errorToShowInPlayerAtom);

  if (!error) return null;

  const displayContents = get(generalErrorDisplayContentsFeatureAtom);

  const data = await getPlayerErrorMessageAndTitle(error, displayContents, get);

  if (!data) return null;

  return {
    description: data.description,
    title: data.title,
    error,
    hidden,
  };
});

const playerErrorSentryeventIdAtom = atom<string | null>(null);
const playerErrorOcurredAtAtom = atom<Date | null>(null);

const logToSentryEffectInPlayerErrorOverlay = atomEffect((get, set) => {
  const { error, hidden } = get(errorToShowInPlayerAtom);

  set(playerErrorSentryeventIdAtom, null);
  set(playerErrorOcurredAtAtom, null);

  if (!error) return;

  let cancelled = false;
  traceToSentry({
    error,
    hidden,
    skipSentryCapture: false,
    location: "player_error_overlay",
    get,
  })
    .then(({ eventId }) => {
      if (cancelled) return;

      set(playerErrorSentryeventIdAtom, eventId ?? null);
      set(playerErrorOcurredAtAtom, new Date());
    })
    .catch(() => {
      // Ignore
    });

  return () => {
    cancelled = true;
    set(playerErrorSentryeventIdAtom, null);
    set(playerErrorOcurredAtAtom, null);
  };
});

export function usePlayerErrorOverlay(): {
  description: string;
  title: string;
  eventId: string | null;
  at: Date | null;
} | null {
  const data = useAtomValue(errorToShowOnPlayerWithTitleAndDescription);
  const eventId = useAtomValue(playerErrorSentryeventIdAtom);
  const at = useAtomValue(playerErrorOcurredAtAtom);

  const t = useTranslatable();

  if (!data) return null;

  const description = t(data.description);
  const title = t(data.title);

  if (!description || !title) return null;

  return {
    description,
    title,
    eventId,
    at,
  };
}
