import type { ReactNode } from "react";

import { RecordingProgramBox } from "@/components/boxes";

export function DebugRecordingBoxes(): ReactNode {
  const longTitle =
    "Very long movie title for a very long time LONG long title very long lots of words Very long movie title for a very long time LONG long title very long lots of words";
  const longSubtitle =
    "Very long subtitle for a very long time LONG long subtitle very long lots of words Very long subtitle for a very long time LONG long subtitle very long lots of words";
  const shortTitle = "Short title";
  const shortSubtitle = "short subtitle";
  const liveProgress = 68;
  const replayProgress = 43;
  const coverImageUrl =
    "https://t4.ftcdn.net/jpg/03/76/25/05/360_F_376250596_JSflOKCNDwwYOmQ6VfuUU7rfO5KlPugZ.jpg";
  const logoUrl =
    "https://media.idownloadblog.com/wp-content/uploads/2018/01/Netflix-Logo.png";
  return (
    <div style={{ margin: "3%" }}>
      <h2>Large program boxes</h2>
      <div style={{ display: "flex" }}>
        <div>
          <RecordingProgramBox
            airTime="Yesterday"
            coverImageUrl={coverImageUrl}
            end="14:00"
            isBulkDeletionMode={false}
            liveProgress={liveProgress}
            logoUrl={logoUrl}
            recordingState={"recorded"}
            seasonAndEpisode="S1E1"
            start="13:00"
            subtitle={longSubtitle}
            title={shortTitle}
            type="episode"
            active
          />
          <RecordingProgramBox
            airTime="Tue 02.01.24"
            coverImageUrl={coverImageUrl}
            end="14:00"
            expiresIn={2}
            isBulkDeletionMode={false}
            liveProgress={liveProgress}
            logoUrl={logoUrl}
            recordingState="planned"
            seasonAndEpisode="S1E1"
            start="13:00"
            subtitle={longSubtitle}
            title={shortTitle}
            type="episode"
          />
        </div>
        <div>
          <RecordingProgramBox
            airTime="Today"
            coverImageUrl={coverImageUrl}
            end="14:00"
            isBulkDeletionMode={false}
            liveProgress={liveProgress}
            logoUrl={logoUrl}
            recordingState={"recorded"}
            replayProgress={replayProgress}
            start="13:00"
            subtitle={shortSubtitle}
            title={longTitle}
            focused
          />
        </div>
        <div>
          <RecordingProgramBox
            airTime="Today"
            coverImageUrl={coverImageUrl}
            end="14:00"
            isBulkDeletionMode={true}
            liveProgress={liveProgress}
            logoUrl={logoUrl}
            recordingState={"recorded"}
            replayProgress={replayProgress}
            start="13:00"
            subtitle={shortSubtitle}
            title={longTitle}
            focused
          />
          <RecordingProgramBox
            airTime="Today"
            coverImageUrl={coverImageUrl}
            end="14:00"
            isBulkDeletionMode={true}
            liveProgress={liveProgress}
            logoUrl={logoUrl}
            recordingState={"recorded"}
            replayProgress={replayProgress}
            start="13:00"
            subtitle={shortSubtitle}
            title={longTitle}
            focused
            isMarkedForDeletion
          />
        </div>
      </div>
    </div>
  );
}
