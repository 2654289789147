import { atomWithReducer } from "jotai/utils";

import type { ChannelGroupId, ChannelId } from "@sunrise/backend-types-core";
import { isDefined } from "@sunrise/utils";

export type ScrollTask =
  | { type: "auto-scroll"; id: ChannelId; index: number }
  | { type: "restore"; position: number };

export const scrollPositionReducerAtom = atomWithReducer<
  {
    /**
     * We need to know which channel is currently active.
     * So we can auto-scroll to it if needed.
     */
    activeChannel: {
      id: ChannelId;
      index: number;
      /**
       * To know if we have already auto-scrolled to the channel.
       * For now this is not used but it might be useful in the future.
       */
      autoScrolled: boolean;
    } | null;
    /**
     * We need to know which task is currently running.
     * So we can complete it when it's done.
     */
    task: ScrollTask | null;
    /**
     * We need to know which channel group is currently active.
     * So we can reset certain things when the channel group changes.
     */
    channelGroupId: ChannelGroupId | null;
    /**
     * We need to know the current scroll position.
     * So we can restore it when the component is mounted.
     */
    position: number | null;
    /**
     * We need to know if the component is mounted.
     * So we can inject a new task to restore the scroll position.
     */
    mounted: boolean;
    /**
     * We need to know if the component is loading.
     * So we can prevent auto-scrolling to the same channel twice.
     */
    loading: boolean;
  },
  | {
      type: "set-position";
      payload: { position: number };
    }
  | {
      type: "set-channel-group-id";
      payload: { channelGroupId: ChannelGroupId };
    }
  | { type: "completed-task"; payload: { task: ScrollTask } }
  | {
      type: "set-active-channel";
      payload: { channelId: ChannelId; index: number };
    }
  | { type: "clear-active-channel" }
  | { type: "mounted" }
  | { type: "unmounted" }
  | { type: "channels-loaded" }
  | { type: "channels-loading" }
>(
  {
    activeChannel: null,
    task: null,
    channelGroupId: null,
    loading: true,
    position: null,
    mounted: false,
  },
  (state, action) => {
    switch (action?.type) {
      case "set-channel-group-id":
        return {
          ...state,
          channelGroupId: action.payload.channelGroupId,
          task:
            state?.channelGroupId &&
            state.channelGroupId !== action.payload.channelGroupId
              ? { type: "restore", position: 0 }
              : state?.task,
        };
      case "set-position":
        return {
          ...state,
          position: action.payload.position,
        };
      case "mounted": {
        return {
          ...state,
          task:
            state.task ??
            (isDefined(state.position)
              ? { type: "restore", position: state.position }
              : null),
          mounted: true,
        };
      }
      case "unmounted": {
        return {
          ...state,
          mounted: false,
        };
      }
      case "set-active-channel": {
        if (state.activeChannel?.id === action.payload.channelId) {
          return state;
        }

        return {
          ...state,
          activeChannel: {
            id: action.payload.channelId,
            index: action.payload.index,
            autoScrolled: false,
          },
          task: {
            type: "auto-scroll",
            id: action.payload.channelId,
            index: action.payload.index,
          },
        };
      }
      case "clear-active-channel": {
        return {
          ...state,
          activeChannel: null,
        };
      }
      case "completed-task":
        if (!state.task || state.task.type !== action.payload.task.type) {
          return state;
        }

        switch (action.payload.task.type) {
          case "auto-scroll": {
            if (state.activeChannel?.id !== action.payload.task.id) {
              return state;
            }

            return {
              ...state,
              activeChannel: {
                ...state.activeChannel,
                autoScrolled: true,
              },
              task: null,
            };
          }
          case "restore": {
            return {
              ...state,
              task: null,
            };
          }
          default: {
            return state;
          }
        }
      case "channels-loaded": {
        return {
          ...state,
          loading: false,
        };
      }
      case "channels-loading": {
        return {
          ...state,
          loading: true,
        };
      }
    }

    return state;
  },
);
