import { atomEffect } from "jotai-effect";

import { shouldBlockPageAnalyticsAtom } from "@sunrise/analytics";

import { isMenuActiveAtom } from "../menu/is-menu-active.atom";

/**
 * Automatically blocks page analytics when the menu is open.
 */
export const shouldBlockPageAnalyticsWhenMenuOpenEffect = atomEffect(
  (get, set) => {
    const isMenuOpen = get(isMenuActiveAtom);

    set(shouldBlockPageAnalyticsAtom, isMenuOpen);
  },
);
