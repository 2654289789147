import { atom } from "jotai";

import type { PageableItems } from "@sunrise/types";
import {
  type GenericRecording,
  recordingsMarkedForDeletionAtom,
  recordingStatusSocketAtom,
} from "@sunrise/yallo-recordings";
import { removeUnwantedItemsFromList } from "@sunrise/yallo-recordings-list";

import { pagedGenericRecordingsAtom } from "./data/paged-generic-recordings.atom";

/**
 * The main atom to be used on the recordings list overview page.
 * It contains the items and the paging information. So the infinite scroll can fetch the next page as needed.
 *
 * It will show only the items that still need to be shown on the UI.
 * So whenever something has already been marked as deleted or is known to be deleted, it will be filtered out.
 */
export const recordingItemsWithPagingAtom = atom<
  PageableItems<GenericRecording>
>((get) => {
  const page = get(pagedGenericRecordingsAtom);

  const recordingStatuses = get(recordingStatusSocketAtom);
  const markedForDeletion = get(recordingsMarkedForDeletionAtom);
  const items = removeUnwantedItemsFromList(
    page.items,
    recordingStatuses,
    markedForDeletion,
  );

  return {
    ...page,
    items,
    hasItems: items.length > 0,
  };
});
