import { type ReactNode, Suspense, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";

import { areVideoAdsPlayingAtom } from "@sunrise/ads";
import { useIdle } from "@sunrise/utils";
import { simplifiedChannelInfoAtom } from "@sunrise/yallo-channel";
import {
  type MappedChannel,
  playableChannelsInCurrentChannelGroupAtom,
} from "@sunrise/yallo-channel-group";
import { currentlyRequestedPlayRequestAtom } from "@sunrise/yallo-common-player-manager";

import { ChannelItem, ChannelItemSpinner } from "@/components";
import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import {
  actionZappingChannelHide,
  zappingControlAtom,
  zappingControlIsVisibleAtom,
} from "@/modules/zapping/zapping-control.atom";
import { zappingThroughArrowsAllowedAtom } from "@/modules/zapping/zapping-through-arrows-allowed.atom";
import { zappingThroughChannelKeysAllowedAtom } from "@/modules/zapping/zapping-through-channel-keys-allowed.atom";

import { useZapping } from "./hooks/use-zapping";
import * as styles from "./zapping-channel.css";

/**
 * Component that handles arrow/channel up/down presses under certain conditions which also shows the channel we just zapped to.
 */
export function ZappingChannel(): ReactNode {
  const dispatchZappingChannel = useSetAtom(zappingControlAtom);
  const isVisible = useAtomValue(zappingControlIsVisibleAtom);

  const hideSelf = useCallback(() => {
    dispatchZappingChannel(actionZappingChannelHide());
  }, [dispatchZappingChannel]);

  const videoAdsPlaying = useAtomValue(areVideoAdsPlayingAtom);

  const shouldHide = useIdle(videoAdsPlaying ? 500 : 2000);

  const isArrowZappingAllowed = useAtomValue(zappingThroughArrowsAllowedAtom);
  const isChannelUpDownZappingAllowed = useAtomValue(
    zappingThroughChannelKeysAllowedAtom,
  );

  const isSomeFormOfZappingAllowed =
    isArrowZappingAllowed || isChannelUpDownZappingAllowed;
  useEffect(() => {
    if (shouldHide || !isSomeFormOfZappingAllowed) {
      hideSelf();
    }

    return () => hideSelf();
  }, [
    dispatchZappingChannel,
    hideSelf,
    isSomeFormOfZappingAllowed,
    shouldHide,
  ]);

  const { zap } = useZapping();

  const showAndSwitchToNextChannelWithChannelKeys = useCallback(
    () => zap("down", "channel-up-down"),
    [zap],
  );
  const showAndSwitchToPreviousChannelWithChannelKeys = useCallback(
    () => zap("up", "channel-up-down"),
    [zap],
  );

  const showAndSwitchToNextChannelWithArrows = useCallback(
    () => zap("down", "arrow-up-down"),
    [zap],
  );
  const showAndSwitchToPreviousChannelWithArrows = useCallback(
    () => zap("up", "arrow-up-down"),
    [zap],
  );

  useKeyboardNavigation({
    isEnabled: isArrowZappingAllowed,
    onArrowDown: showAndSwitchToNextChannelWithArrows,
    onArrowUp: showAndSwitchToPreviousChannelWithArrows,
  });

  useKeyboardNavigation({
    isEnabled: isChannelUpDownZappingAllowed,
    onChannelDown: showAndSwitchToNextChannelWithChannelKeys,
    onChannelUp: showAndSwitchToPreviousChannelWithChannelKeys,
  });

  if (!isVisible) {
    return null;
  }

  // Initial Suspense boundary here while the list of zappable channels has not yet loaded.
  return (
    <Suspense
      fallback={
        <ChannelItemSpinner
          className={clsx(styles.root)}
          data-testid="zapping-channel-spinner"
        />
      }
    >
      <RequestedChannelInfo />
    </Suspense>
  );
}

/**
 * @returns Returns a ChannelItem with the channel id at least pre-filled. While the data still loads.
 */
function RequestedChannelInfo(): ReactNode {
  const currentChannelId = useAtomValue(
    currentlyRequestedPlayRequestAtom,
  )?.channelId;
  const zappableChannels = useAtomValue(
    playableChannelsInCurrentChannelGroupAtom,
  );
  const zappedChannel = zappableChannels.find(
    (it) => it.id === currentChannelId,
  );

  if (!zappedChannel) {
    return null;
  }

  return <ChannelItemWithLoadingData channel={zappedChannel} />;
}

function ChannelItemWithLoadingData({
  channel,
}: {
  channel: MappedChannel;
}): ReactNode {
  const data = useAtomValue(loadable(simplifiedChannelInfoAtom(channel.id)));
  const channelItem = data.state === "hasData" ? data.data : null;

  return (
    <ChannelItem
      channelLogo={channel.logo}
      channelNumber={channel.order}
      className={clsx(styles.root)}
      currentEpg={channelItem?.currentEpg}
      data-testid="zapping-channel"
      liveProgress={channelItem?.progress ?? 0}
      nextEpg={channelItem?.nextEpg}
      active
      focused
      zapping
    />
  );
}
