import { useAtomValue } from "jotai";

import {
  isBulkDeletionModeAtom,
  useFilteringRecordings,
} from "@sunrise/yallo-recordings-overview";

import { FocusButton } from "@/components";
import { ICON_SIZE_20 } from "@/components/icon/icon.config";
import { useMenu } from "@/features/menu/use-menu";
import { RecordingsBulkDeleteTitle } from "@/features/recordings/recordings-bulk-delete-title";
import { RecordingsCapacityIndicator } from "@/features/recordings/recordings-capacity-indicator";
import { RecordingsOverviewNetworkIndicator } from "@/features/recordings/recordings-overview-network-indicator";
import { localWidgetFocusKey } from "@/routes/recordings/recordings";
import { isArrowLeftKey } from "@/utils/navigation";

import * as styles from "./recordings.css";

const DATA_TEST_ID = "recordings";

export function RecordingsLeftMenu() {
  const { filterRecordings, currentRecordingsFilter } =
    useFilteringRecordings();
  const isBulkDeletionMode = useAtomValue(isBulkDeletionModeAtom);
  const menu = useMenu();

  if (isBulkDeletionMode) {
    return (
      <RecordingsBulkDeleteTitle
        className={styles.indicators}
        data-testid={`${DATA_TEST_ID}.bulk-delete-title`}
      />
    );
  }

  return (
    <div className={styles.indicators}>
      <FocusButton
        active={true}
        className={styles.filterButton}
        data-testid={`${DATA_TEST_ID}.filter-button`}
        focusKey={localWidgetFocusKey.filterButton}
        iconRight="dropdown-down"
        iconSize={ICON_SIZE_20}
        text={currentRecordingsFilter}
        textAlign="left"
        onArrowPress={(direction) => {
          if (isArrowLeftKey(direction)) {
            menu.expand();
            return false;
          }
          return true;
        }}
        onEnterPress={filterRecordings}
      />
      <RecordingsCapacityIndicator />
      <RecordingsOverviewNetworkIndicator />
    </div>
  );
}
