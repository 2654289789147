import { useCallback } from "react";
import {
  doesFocusableExist,
  setFocus,
} from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import {
  hasRecordingsOnOverviewAtom,
  isBulkDeletionModeAtom,
} from "@sunrise/yallo-recordings-overview";

import { useMenu } from "@/features/menu/use-menu";
import { localWidgetFocusKey } from "@/routes/recordings/recordings";

export function useRecordingsList(focusList: () => void) {
  const hasRecordings = useAtomValue(hasRecordingsOnOverviewAtom);
  const menu = useMenu();

  /**
   * Attempt to focus on what makes sense and what is available.
   * If there are no buttons, just focus on ourselves.
   */
  const onExitUp = useAtomCallback(
    useCallback(
      (get, _, column: number): void => {
        const isBulkDeletionMode = get(isBulkDeletionModeAtom);

        if (
          isBulkDeletionMode &&
          doesFocusableExist(localWidgetFocusKey.cancelButton)
        ) {
          setFocus(localWidgetFocusKey.cancelButton);
          return;
        }

        if (
          !hasRecordings ||
          !doesFocusableExist(localWidgetFocusKey.sortButton)
        ) {
          setFocus(localWidgetFocusKey.filterButton);
          return;
        }

        switch (column) {
          case 0:
          case 1:
            if (doesFocusableExist(localWidgetFocusKey.filterButton)) {
              setFocus(localWidgetFocusKey.filterButton);
              return;
            }
            break;
          case 2:
            setFocus(localWidgetFocusKey.sortButton);
            break;
          case 3:
            if (doesFocusableExist(localWidgetFocusKey.selectButton)) {
              setFocus(localWidgetFocusKey.selectButton);
              return;
            }
            break;
        }

        if (doesFocusableExist(localWidgetFocusKey.sortButton)) {
          setFocus(localWidgetFocusKey.sortButton);
        } else {
          focusList();
        }
      },
      [focusList, hasRecordings],
    ),
  );

  const onExitLeft = useAtomCallback(
    useCallback(
      (get, set) => {
        const isBulkDeletionMode = get(isBulkDeletionModeAtom);

        if (isBulkDeletionMode) {
          set(isBulkDeletionModeAtom, false);
        } else {
          menu.expand();
        }
      },
      [menu],
    ),
  );

  return { onExitUp, onExitLeft, hasContent: !!hasRecordings };
}
