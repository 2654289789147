import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";

import { ngRecordingsApiAtom } from "@sunrise/backend-ng-recordings";
import type { RecordingBulkDelete } from "@sunrise/backend-types";
import { flushRecordingsState } from "@sunrise/yallo-recordings";

import { groupRecordingsByType } from "./group-recordings-by-type";

export const bulkDeleteRecordingsMutationNgAtom = atomWithMutation<
  void,
  {
    recordings: RecordingBulkDelete[];
  },
  unknown
>((get) => {
  const queryClient = get(queryClientAtom);

  return {
    mutationKey: ["bulk-delete-recordings"],
    mutationFn: async ({ recordings }) => {
      const api = get(ngRecordingsApiAtom);

      await api.recording.bulkDeleteRecordingsRecordingV1RecordingsBulkDeleteRecordingsPost(
        groupRecordingsByType(recordings),
      );
    },
    onSettled: function () {
      flushRecordingsState(queryClient, get);
    },
  };
});
