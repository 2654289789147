import { atom } from "jotai";

import { createDateToCurrentTimeConverter } from "@sunrise/yallo-stream";

import {
  playerAtom,
  selectPlayerCurrentStream,
  selectPlayerCurrentStreamModel,
} from "./player.atom";

/**
 * A utility atom which will return a DateToNumberConverter for the current stream.
 * This is only relevant for linear streams as only linear streams need to have a mapping between date and player position.
 *
 * The converter allows to do conversion both ways. From player number to date and from date to player number.
 */
export const playerDateTimeConverterAtom = atom((get) => {
  const model = get(selectPlayerCurrentStreamModel);

  if (model !== "linear") {
    return null;
  }

  const stream = get(selectPlayerCurrentStream(playerAtom));
  if (!stream) return null;

  return createDateToCurrentTimeConverter(
    "offset" in stream ? stream.offset : null,
  );
});
