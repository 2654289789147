import type { ReactNode } from "react";
import {
  FocusContext,
  ROOT_FOCUS_KEY,
} from "@noriginmedia/norigin-spatial-navigation";
import type { QueryClient } from "@tanstack/query-core";
import type { queryClientAtom } from "jotai-tanstack-query";

import { type Store, StoreProvider } from "@sunrise/store";
import { TranslationProvider } from "@sunrise/translator";

import { QueryProvider } from "@/modules/query-provider";

export function AppProvider(props: {
  children: ReactNode;
  queryClient: QueryClient;
  enableDevTools: boolean;
  queryClientAtom: typeof queryClientAtom;
  storeInstance: Store;
}): ReactNode {
  return (
    <QueryProvider
      enableDevTools={props.enableDevTools}
      queryClientInstance={props.queryClient}
    >
      <StoreProvider
        enableDevTools={props.enableDevTools}
        store={props.storeInstance}
      >
        <TranslationProvider>
          <FocusContext.Provider value={ROOT_FOCUS_KEY}>
            {props.children}
          </FocusContext.Provider>
        </TranslationProvider>
      </StoreProvider>
    </QueryProvider>
  );
}
