import type { ReactNode } from "react";
import { Suspense } from "react";

import { IconButton } from "@/components";
import type { IconProps } from "@/components/icon";
import * as styles from "@/features/player-controls/player-controls.css";

export function SuspendButton({
  children,
  iconName,
}: {
  children: ReactNode;
  iconName: IconProps["name"];
}): ReactNode {
  return (
    <Suspense
      fallback={
        <IconButton className={styles.button} iconName={iconName} disabled />
      }
    >
      {children}
    </Suspense>
  );
}
