import deepEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { RecordingGroupSort } from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";

const DEFAULT_SORT: RecordingGroupSort = {
  field: "order_by_end_time",
  direction: "desc",
};

/**
 * This is the sorting for recordings in a specific series.
 * We store it on the seriesAssetId since that stays consistent also when filtering by season.
 * The season filter would change the recordingGroupId.
 * And we don't want the sorting to reset when filtering.
 */
export const recordingsSortForSeriesAssetIdAtom = atomFamily(
  ({ seriesAssetId }: { seriesAssetId: AssetId }) => {
    const inner = atom<RecordingGroupSort>(DEFAULT_SORT);
    inner.debugLabel = `recordingsSortForSeriesAssetIdAtom(seriesAssetId:${seriesAssetId})`;
    return inner;
  },
  deepEqual,
);
