import React, { type ReactNode } from "react";
import type { DefaultParams, PathPattern, RouteProps } from "wouter";
import { Route } from "wouter";

import { ErrorBoundary } from "@/features/monitoring/error-boundary";

export function ErrorBoundaryRoute<
  T extends DefaultParams | undefined = undefined,
  RoutePath extends PathPattern = PathPattern,
>(props: RouteProps<T, RoutePath>): ReactNode {
  return (
    <ErrorBoundary key={props.path?.toString()}>
      <Route {...props} />
    </ErrorBoundary>
  );
}
