import type { ReactNode } from "react";
import { useAtomValue } from "jotai";

import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import { useTranslator } from "@sunrise/translator";
import { hasRecordingsForRecordingGroupIdAtom } from "@sunrise/yallo-recordings-series";

import { FocusButton } from "@/components";
import { useDetailsSeriesSort } from "@/modules/details/use-details-series-sort";

import * as styles from "./details-series-actions.css";
import { DetailsSeriesFilter } from "./details-series-filter";

const TEST_ID = "details-series-actions";

type DetailsSeriesActionsProps = {
  seriesAssetId: AssetId;
  recordingGroupId: RecordingGroupId;
  onCancelRecordings: () => void;
  onDeleteRecordings: () => void;
  haveRecordingSchedules: boolean;
  seasonButtonFocusKey: string;
  deleteButtonFocusKey: string;
  cancelButtonFocusKey: string;
  sortButtonFocusKey: string;
};

export function DetailsSeriesActions({
  onCancelRecordings,
  onDeleteRecordings,
  seriesAssetId,
  recordingGroupId,
  haveRecordingSchedules,
  cancelButtonFocusKey,
  deleteButtonFocusKey,
  seasonButtonFocusKey,
  sortButtonFocusKey,
}: DetailsSeriesActionsProps): ReactNode {
  const t = useTranslator();

  const openSortRecordings = useDetailsSeriesSort({
    seriesAssetId,
    recordingGroupId,
  });

  const hasRecordings = useAtomValue(
    hasRecordingsForRecordingGroupIdAtom(recordingGroupId),
  );

  return (
    <div className={styles.root}>
      <DetailsSeriesFilter
        data-testid={`${TEST_ID}.season-filter`}
        focusKey={seasonButtonFocusKey}
        recordingGroupId={recordingGroupId}
        seriesAssetId={seriesAssetId}
      />
      <div className={styles.right}>
        <FocusButton
          className={styles.button}
          data-testid={`${TEST_ID}.cancel-recordings`}
          disabled={!haveRecordingSchedules}
          focusKey={cancelButtonFocusKey}
          text={t("button_cancel_recordings")}
          onEnterPress={onCancelRecordings}
        />
        <FocusButton
          className={styles.button}
          data-testid={`${TEST_ID}.delete-recordings`}
          disabled={!hasRecordings}
          focusKey={deleteButtonFocusKey}
          text={t("recordings_delete_all")}
          onEnterPress={onDeleteRecordings}
        />
        {hasRecordings && (
          <FocusButton
            className={styles.button}
            data-testid={`${TEST_ID}.change-sort`}
            focusKey={sortButtonFocusKey}
            iconLeft="sort"
            text={t("recording_sort")}
            onEnterPress={openSortRecordings}
          />
        )}
      </div>
    </div>
  );
}
