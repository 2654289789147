import type { ReactNode } from "react";
import { format } from "date-fns";
import { useAtomValue } from "jotai";

import { jwtAtom } from "@sunrise/jwt";
import { formatStreamingUsage } from "@sunrise/time";
import { useTranslator } from "@sunrise/translator";
import { useRefetchAtom } from "@sunrise/utils";
import { freeUsageAtom, userAtom } from "@sunrise/yallo-user";

import {
  freeText,
  subscriptionContainer,
  title,
  usageContainer,
  userIdContainer,
  value,
} from "./profile-subscription.css";

export function ProfileSubscription(): ReactNode {
  const t = useTranslator();
  const user = useAtomValue(userAtom).data;
  const jwtData = useAtomValue(jwtAtom);
  const showPlan = jwtData.decodedPayload?.feature_set.features.show_plan;
  const { nextResetAt, liveStreamingTimeLimit, usage } =
    useAtomValue(freeUsageAtom);

  // We need to make sure we show completely up to date information here.
  // The freeUsageAtom will reload itself when we detect that the usage limit was reached via the socket.
  // But it will not update whenever the time left has updated.
  useRefetchAtom(userAtom);

  if (!user) {
    return undefined;
  }

  const usageRenewal = nextResetAt
    ? format(new Date(nextResetAt), "dd.MM.yyyy")
    : "";

  return (
    <div data-testid="profile.subscription">
      {showPlan && (
        <div className={subscriptionContainer}>
          <div className={title}>{t("profile_subscription")}</div>
          <div className={value} data-testid="profile.subscription.user.plan">
            {user.account?.plan?.display_name}
          </div>
          {!!liveStreamingTimeLimit && (
            <div
              className={freeText}
              data-testid="profile.subscription.user.plan-free-text"
            >
              {t("profile_subscription_free_description", [
                formatStreamingUsage(liveStreamingTimeLimit),
              ])}
            </div>
          )}
        </div>
      )}

      {liveStreamingTimeLimit && (
        <div className={usageContainer}>
          <div className={title}>{t("profile_usage")}</div>
          <div className={value} data-testid="profile.subscription.user.usage">
            {t("profile_hours_used_tizen", [
              formatStreamingUsage(usage),
              formatStreamingUsage(liveStreamingTimeLimit),
              usageRenewal,
            ])}
          </div>
        </div>
      )}

      <div className={userIdContainer}>
        <div className={title}>{t("user_id")}</div>
        <div className={value} data-testid="profile.subscription.user.id">
          {user.id}
        </div>
      </div>
    </div>
  );
}
