import { type ReactElement } from "react";
import clsx from "clsx";

import { useTranslator } from "@sunrise/translator";
import { usePlayerPlayingInfo } from "@sunrise/yallo-player-controls";

import { ChannelLogo } from "@/components/channel-logo/channel-logo";
import * as styles from "@/features/player-controls/player-controls.css";
import { typography } from "@/styles/typography.css";

export function PlayerControlsPlayingInfo(): ReactElement {
  const t = useTranslator();
  const { title, channelLogo, status, schedule, subtitle } =
    usePlayerPlayingInfo(t);

  return (
    <div className={styles.informationContainer}>
      {title && (
        <h1
          className={clsx(styles.title, typography.h1.bold)}
          data-testid="title"
        >
          {title}
        </h1>
      )}
      {(!!schedule || !!subtitle) && (
        <div className={styles.subtitleContainer}>
          {channelLogo ? (
            <ChannelLogo
              className={styles.channelLogo}
              data-testid="channel-logo"
              url={channelLogo}
            />
          ) : null}

          <h2
            className={clsx(styles.subtitle, typography.h6.bold)}
            data-testid="subtitle"
          >
            {schedule
              ? [status, schedule.formattedStartEnd].filter(Boolean).join(", ")
              : subtitle}
          </h2>
        </div>
      )}
    </div>
  );
}
