import type { Getter } from "jotai";

import type { Translatable } from "@sunrise/i18n";

import { getErrorMapping } from "./get-error-mapping";
import { getErrorMessageAndDescription } from "./helpers/get-error-message-and-description";

/**
 * Can be consumed by the player to get the correct error message and title to show.
 *
 * @param error
 * @returns
 */
export async function getPlayerErrorMessageAndTitle(
  error: Error,
  displayContents: "raw" | "hide" | "general",
  getter: Getter,
): Promise<{
  title: Translatable;
  description: Translatable;
} | null> {
  const configuration = getErrorMapping(error);

  if (!configuration || displayContents === "raw") {
    const data = getErrorMessageAndDescription(error, displayContents);

    if (!data) return null;

    return {
      title: data.title,
      description: data.description,
    };
  }

  const config =
    typeof configuration === "function"
      ? await configuration(getter)
      : configuration;

  return {
    title: config.title,
    description: config.description,
  };
}
