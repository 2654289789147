import type { DeviceType } from "@sunrise/auth";
import { isTitanOS, isTizen, isWebOS } from "@sunrise/utils";

export function determineDeviceType(): DeviceType {
  if (isWebOS()) {
    return "webos";
  }

  if (isTitanOS()) {
    return "titanos";
  }

  if (isTizen()) {
    return "tizen";
  }

  return "webclient";
}
