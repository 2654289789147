import { useState } from "react";
import { useAtomValue } from "jotai";

import { isCursorVisibleAtom } from "@sunrise/bigscreen";

import { IconButton } from "@/components";

import * as styles from "./scroll-arrows.css";

export type ScrollDirection = "left" | "right" | "";

type ScrollArrowsProps = {
  scroll: (direction: ScrollDirection) => void;
  firstSelected: boolean;
  lastSelected: boolean;
};

export function ScrollArrows({
  scroll,
  firstSelected,
  lastSelected,
}: ScrollArrowsProps) {
  const isCursorVisible = useAtomValue(isCursorVisibleAtom);
  const [focused, setFocused] = useState<ScrollDirection>("");

  if (!isCursorVisible) {
    return;
  }

  return (
    <div className={styles.arrowsContainer}>
      <IconButton
        className={styles.arrow}
        disabled={firstSelected}
        focused={focused === "left"}
        iconName="dropdown-left"
        onMouseClick={() => !firstSelected && scroll("left")}
        onMouseEnter={() => setFocused("left")}
        onMouseLeave={() => setFocused("")}
      />
      <IconButton
        className={styles.arrow}
        disabled={lastSelected}
        focused={focused === "right"}
        iconName="dropdown-right"
        onMouseClick={() => !lastSelected && scroll("right")}
        onMouseEnter={() => setFocused("right")}
        onMouseLeave={() => setFocused("")}
      />
    </div>
  );
}
