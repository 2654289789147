import type { ReactElement } from "react";
import { useEffect, useRef, useState } from "react";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue, useSetAtom } from "jotai";

import { debouncedQueryAtoms } from "@sunrise/search";
import {
  clearSearchHistoryButtonAtom,
  searchHistoryItemsAtom,
} from "@sunrise/search-history";
import { useTranslator } from "@sunrise/translator";

import type { ButtonProps } from "@/components";
import { Button } from "@/components";
import { isArrowLeftKey, isArrowRightKey } from "@/utils/navigation";
import { useScrollOnFocus } from "@/utils/use-scoll-on-focus";

import { button, container, innerContainer } from "./search-history.css";

export function SearchHistory({
  focusKey,
  onHide,
  exitLeft,
}: {
  focusKey: string;
  onHide: () => void;
  exitLeft: () => void;
}) {
  const items = useAtomValue(searchHistoryItemsAtom);
  if (items.length === 0) {
    return null;
  }

  return (
    <SearchHistoryInner
      exitLeft={exitLeft}
      focusKey={focusKey}
      items={items}
      onHide={onHide}
    />
  );
}

/**
 * Shows the items when any are present.
 * Will never pull focus to itself.
 */
function SearchHistoryInner({
  focusKey,
  onHide,
  exitLeft,
  items,
}: {
  focusKey: string;
  onHide: () => void;
  exitLeft: () => void;
  items: string[];
}) {
  const clearButton = useAtomValue(clearSearchHistoryButtonAtom);
  const setSearch = useSetAtom(debouncedQueryAtoms.currentValueAtom);
  const t = useTranslator();

  // Have a reference somewhere of which item is focused.
  const [activeText, setActiveText] = useState<string>();
  const isClearButtonFocused = !activeText;
  const focus = useFocusable({
    focusKey,
    focusable: items.length > 0,
    onArrowPress(direction) {
      if (isArrowRightKey(direction)) {
        if (isClearButtonFocused) {
          return false;
        }

        setActiveText((prev) => {
          const item = items.indexOf(prev ?? "");
          return items[item + 1];
        });
        return false;
      }

      if (isArrowLeftKey(direction)) {
        if (isClearButtonFocused) {
          setActiveText(items[items.length - 1]);
          return false;
        }

        if (activeText === items[0]) {
          exitLeft();
          return false;
        }

        setActiveText((prev) => {
          const item = items.indexOf(prev ?? "");
          return items[item === -1 ? 0 : item - 1];
        });
        return false;
      }

      return true;
    },
    onEnterPress() {
      if (clearButton.visible && isClearButtonFocused) {
        setActiveText(undefined);
        clearButton.action();
        onHide();
        return false;
      }

      if (activeText) {
        setSearch(activeText);
        return false;
      }

      return true;
    },
  });

  const outerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Select the first one whenever the items change.
    setActiveText(items[0]);

    // Scroll into view as well.
    outerRef.current?.scrollTo(0, 0);
  }, [items, outerRef]);

  // Should scroll into view the search history itself when it regains focus.
  useScrollOnFocus({
    enabled: true,
    focused: focus.focused,
    ref: outerRef,
  });

  if (items.length === 0) {
    return null;
  }

  return (
    <div ref={outerRef} className={container} data-testid="search-history">
      <div ref={focus.ref} className={innerContainer}>
        {items.map((item) => (
          <CarouselButton
            key={item}
            className={button}
            data-testid={`search-history-item-${item}`}
            focused={focus.focused && item === activeText}
            iconLeft="search-small"
            shouldScroll={item === activeText}
            text={item}
            variant="light"
          />
        ))}
        {clearButton.visible && (
          <CarouselButton
            className={button}
            focused={focus.focused && isClearButtonFocused}
            shouldScroll={focus.focused && isClearButtonFocused}
            text={t("search_history_clear")}
          />
        )}
      </div>
    </div>
  );
}

function CarouselButton({
  shouldScroll,
  ...rest
}: ButtonProps & { shouldScroll: boolean }): ReactElement {
  const ref = useRef<HTMLButtonElement>(null);

  useScrollOnFocus({
    enabled: true,
    focused: shouldScroll,
    ref,
  });

  return <Button ref={ref} {...rest} />;
}
