import type {
  PlayerDelayedBufferSettings,
  PlayerLiveBufferSettings,
} from "./player.types";

export const VIDEO_ELEMENT_ID = "video-player";

export const THROTTLE_TIME_IN_MS = 1000;

export const DEFAULT_PLAYER_LIVE_BUFFER_SETTINGS: PlayerLiveBufferSettings = {
  min: 2,
  max: 30,
  delay: 7,
};

export const DEFAULT_PLAYER_DELAYED_BUFFER_SETTINGS: PlayerDelayedBufferSettings =
  { min: 2, max: 30 };
