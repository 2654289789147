import type { ReactNode } from "react";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useAtomValue } from "jotai";

import type { AssetId } from "@sunrise/backend-types-core";
import { assetRatingByIdAtom, imdbTagEnabledAtom } from "@sunrise/details";
import type { Nullable } from "@sunrise/utils";

import { Tag } from "@/components/tag/tag";

import * as styles from "./details-tags.css";

type ImdbRatingTagProps = {
  assetId: Nullable<AssetId>;
};

function SuspendedImdbRatingTag({ assetId }: ImdbRatingTagProps): ReactNode {
  const rating = useAtomValue(assetRatingByIdAtom(assetId));
  if (!rating) return;

  return (
    <Tag
      key={"imdb"}
      className={styles.tag}
      data-testid={"imdb-rating-tag"}
      text={`IMDb ${rating}`}
      uppercase={false}
    />
  );
}

export function ImdbRatingTag({ assetId }: ImdbRatingTagProps): ReactNode {
  const isImdbRatingEnabled = useAtomValue(imdbTagEnabledAtom);
  if (!assetId || !isImdbRatingEnabled) return;

  return (
    <ErrorBoundary fallback={null}>
      <Suspense>
        <SuspendedImdbRatingTag assetId={assetId} />
      </Suspense>
    </ErrorBoundary>
  );
}
