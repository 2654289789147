import type { ReactNode } from "react";

import { RowProgramBox } from "@/components/boxes";

export function DebugProgramPreview(): ReactNode {
  const longTitle =
    "Very long program box title for a very long time LONG long title very long lots of words Very long program box title for a very long time LONG long title very long lots of words";
  const longSubtitle =
    "Very long subtitle for a very long time LONG long subtitle very long lots of words Very long subtitle for a very long time LONG long subtitle very long lots of words";
  const shortTitle = "Short title";
  const shortSubtitle = "short subtitle";
  const airTime = "Yesterday";
  const start = "20:00";
  const end = "21:00";
  const description = "This is a description";
  const coverImageUrl =
    "https://t4.ftcdn.net/jpg/03/76/25/05/360_F_376250596_JSflOKCNDwwYOmQ6VfuUU7rfO5KlPugZ.jpg";
  const logoUrl =
    "https://media.idownloadblog.com/wp-content/uploads/2018/01/Netflix-Logo.png";
  return (
    <div style={{ margin: "3%" }}>
      <h2>Program preview guide</h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          height: "600px",
        }}
      >
        <RowProgramBox
          airTime={airTime}
          coverImageUrl={coverImageUrl}
          description={description}
          durationInMinutes={45}
          end={end}
          logoUrl={logoUrl}
          start={start}
          subtitle={longSubtitle}
          title={longTitle}
          focused
        />
        <RowProgramBox
          airTime={airTime}
          coverImageUrl={coverImageUrl}
          description={description}
          end={end}
          logoUrl={logoUrl}
          start={start}
          subtitle={shortSubtitle}
          title={shortTitle}
        />
      </div>
    </div>
  );
}
