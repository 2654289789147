import {
  type ReactElement,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue, useSetAtom } from "jotai";

import {
  guideAutoSelectionEffect,
  guideCleanGridOnExitEffect,
  guideCleanSelectionEffect,
  guideDataAtom,
} from "@sunrise/yallo-guide";
import { disableAnimationsAtom } from "@sunrise/yallo-settings";

import {
  EscapeRouteLayout,
  PageSpinner,
  RouteLayout,
  Spinner,
} from "@/components";
import { globalFocusKey } from "@/config/focus-key";
import { GuideControls } from "@/features/guide/guide-controls/guide-controls";
import { GuideGrid } from "@/features/guide/guide-grid";
import { SelectedProgram } from "@/features/guide/selected-program";
import { useMenu } from "@/features/menu/use-menu";
import type { OnBlurRequestFunction } from "@/features/settings/types";
import { actionMenuExpand, menuAtom } from "@/modules/menu/menu.store";
import * as styles from "@/routes/guide.css";
import { isArrowLeftKey, isArrowUpKey } from "@/utils/navigation";

const localWidgetFocusKey = {
  grid: globalFocusKey.activePage + ".grid",
  navigation: globalFocusKey.activePage + ".navigation",
};

function ConnectedGuide(): ReactElement {
  const { isLoadingInitial } = useAtomValue(guideDataAtom);

  useAtomValue(guideAutoSelectionEffect);
  useAtomValue(guideCleanSelectionEffect);
  useAtomValue(guideCleanGridOnExitEffect);

  const dispatchMenu = useSetAtom(menuAtom);

  const disableAnimationsAtomValue = useAtomValue(disableAnimationsAtom);

  const onBlurRequestGuide: OnBlurRequestFunction = useCallback(
    (direction) => {
      if (isArrowLeftKey(direction)) {
        dispatchMenu(actionMenuExpand());
      }

      if (isArrowUpKey(direction) || direction === "back") {
        setFocus(localWidgetFocusKey.navigation);
      }
    },
    [dispatchMenu],
  );

  return (
    <EscapeRouteLayout className={styles.guideGridContainer}>
      {isLoadingInitial ? (
        <Spinner className={styles.gridLoader} data-testid="spinner-grid" />
      ) : null}
      <GuideGrid
        disableAnimations={disableAnimationsAtomValue}
        focusKey={localWidgetFocusKey.grid}
        onBlurRequest={onBlurRequestGuide}
      />
    </EscapeRouteLayout>
  );
}

export default function Guide(): ReactElement {
  // Back behaviour is handled elsewhere.
  // But as long as we are loading, we need to handle back behaviour.
  const [guideLoading, setGuideLoading] = useState(false);

  const menu = useMenu({ expandOnBack: guideLoading, shouldShowMenu: true });

  const { focusSelf, ref } = useFocusable({
    focusKey: globalFocusKey.activePage,
    onArrowPress: function handleArrowPress(direction) {
      if (isArrowLeftKey(direction)) menu.expand();
      return false;
    },
  });

  useEffect(
    function delegateFocus() {
      if (menu.isExpanded) return;
      setFocus(localWidgetFocusKey.grid);
    },
    [focusSelf, menu.isExpanded],
  );

  const dispatchMenu = useSetAtom(menuAtom);
  const expandMenu = useCallback(() => {
    dispatchMenu(actionMenuExpand());
  }, [dispatchMenu]);

  return (
    <main ref={ref} className={styles.root} data-testid="guide" tabIndex={0}>
      <Suspense
        fallback={
          <PageSpinner data-testid="guide" onVisible={setGuideLoading} />
        }
      >
        <RouteLayout>
          <GuideControls
            focusKey={localWidgetFocusKey.navigation}
            onBack={expandMenu}
          />
          <Suspense>
            <SelectedProgram
              className={styles.programArea}
              data-testid="guide"
            />
          </Suspense>
          <ConnectedGuide />
        </RouteLayout>
      </Suspense>
    </main>
  );
}
