import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";

import type { RecordingBulkDelete } from "@sunrise/backend-types";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { isNil } from "@sunrise/utils";
import { flushRecordingsState } from "@sunrise/yallo-recordings";

import { bulkDeleteRecordings } from "../recordings.service";

export const bulkDeleteRecordingsMutationAtom = atomWithMutation<
  void,
  {
    recordings: RecordingBulkDelete[];
  },
  unknown
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);

  return {
    mutationKey: ["bulk-delete-recordings"],
    mutationFn: ({ recordings }) => {
      return bulkDeleteRecordings(host, privateApi, recordings);
    },
    onSettled: function () {
      flushRecordingsState(queryClient, get);
    },
  };
});
