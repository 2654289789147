import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import type { ArrowPressHandler } from "@noriginmedia/norigin-spatial-navigation";
import {
  doesFocusableExist,
  FocusContext,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useSetAtom } from "jotai";
import gradient from "static/gradients/dialog-title.webp";

import type { ListDialog as ListDialogType } from "@sunrise/dialogs";
import { actionListDialogSetActiveId, dialogAtom } from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";

import { Dialog, FocusButton } from "@/components";
import { typography } from "@/styles/typography.css";
import { isArrowDownKey, isArrowUpKey } from "@/utils/navigation";

import type { DialogOptions } from "../types";
import { FocusListButton } from "./focus-list-button";
import * as styles from "./list-dialog.css";

const localWidgetFocusKey = {
  component: "LIST_DIALOG",
  actionButton: (parentFocusKey: string) => parentFocusKey + ".action-button",
  listButton: (parentFocusKey: string, sectionIdx: number, buttonIdx: number) =>
    `${parentFocusKey}.button.${sectionIdx}.${buttonIdx}`,
};

type ListDialogActiveButton = {
  sectionIdx: number;
  buttonIdx: number;
};

const TEST_ID = "list-dialog";

export function ListDialog({
  dialog,
  "data-testid": dataTestId,
  doClose,
}: DialogOptions & { dialog: ListDialogType }): ReactNode {
  const dispatchDialog = useSetAtom(dialogAtom);
  const testId = dataTestId ?? TEST_ID;

  const { activeFocusKey: activeButtonFocusKey, firstActiveButtonIdx } =
    getSelectedListButton(dialog);

  const { focusKey, ref, focusSelf } = useFocusable({
    focusKey: localWidgetFocusKey.component,
    isFocusBoundary: true,
    focusable: true,
    preferredChildFocusKey: activeButtonFocusKey,
  });

  const t = useTranslatable();

  useEffect(() => {
    if (activeButtonFocusKey && doesFocusableExist(activeButtonFocusKey)) {
      setFocus(activeButtonFocusKey);
    } else {
      focusSelf();
    }
  }, [focusSelf, activeButtonFocusKey]);

  const contentRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);
  const buttonsSectionRef = useRef<HTMLDivElement>(null);

  // used to calculate focus position and as a scroll anchor in arrow navigation
  const getButtonHeight = useCallback((): number => {
    const buttonsFirstSectionHeight = buttonsSectionRef.current?.clientHeight;
    const buttonsListsFirstSectionCount = dialog.sections[0]?.options.length;

    if (!buttonsFirstSectionHeight || !buttonsListsFirstSectionCount) return 0;
    return buttonsFirstSectionHeight / buttonsListsFirstSectionCount;
  }, [dialog.sections]);

  // scrolls to the first active button and shows it in the middle of the container or if no first active button found scrolls to the top
  useEffect(() => {
    contentRef.current?.scrollTo({
      top: firstActiveButtonIdx.buttonIdx
        ? (firstActiveButtonIdx.buttonIdx + 1) * getButtonHeight() -
          contentRef.current.clientHeight / 2
        : 0,
    });
  }, [firstActiveButtonIdx, getButtonHeight]);

  const isContentScrollable =
    (buttonsRef.current?.clientHeight ?? 0) >
    (contentRef.current?.clientHeight ?? 0);

  const nextSectionExists = (sectionIdx: number): boolean =>
    !!dialog.sections[sectionIdx + 1];

  const hasConfirmationButton = !!(
    dialog.actionButtonLabel && dialog.onActionButtonClick
  );
  const confirmationButtonFocusKey = localWidgetFocusKey.actionButton(focusKey);
  const focusOnConfirmationButton = useMemo(() => {
    if (hasConfirmationButton) {
      return () => setFocus(confirmationButtonFocusKey);
    }

    return;
  }, [hasConfirmationButton, confirmationButtonFocusKey]);

  const onConfirmationButtonArrowUp = useMemo<
    ArrowPressHandler | undefined
  >(() => {
    if (!hasConfirmationButton) return;

    return (direction: string) => {
      if (isArrowUpKey(direction)) {
        const lastSection = dialog.sections.at(-1);
        const lastOption = lastSection?.options.at(-1);

        if (lastSection && lastOption) {
          setFocus(
            localWidgetFocusKey.listButton(
              focusKey,
              dialog.sections.indexOf(lastSection),
              lastSection.options.indexOf(lastOption),
            ),
          );
        }
      }

      return false;
    };
  }, [hasConfirmationButton, dialog.sections, focusKey]);

  return (
    <FocusContext.Provider value={focusKey}>
      <Dialog
        ref={ref}
        className={clsx([styles.container])}
        data-dialog-type="list"
        data-testid={testId}
        open={true}
      >
        {dialog.title && (
          <div
            className={clsx([styles.title, typography.h6.regular])}
            data-testid={`${testId}.title`}
            style={{
              backgroundImage: `url(${gradient})`,
              backgroundRepeat: `repeat`,
              backgroundSize: "contain",
            }}
          >
            {t(dialog.title)}
          </div>
        )}
        <div
          ref={contentRef}
          className={clsx([
            styles.content,
            dialog.title && styles.contentWithTitle,
            dialog.radioButtons && styles.radioContentWithTitle,
            isContentScrollable && styles.actionButtonWithScrollableList,
          ])}
          data-testid={`${testId}.content`}
        >
          <div
            ref={buttonsRef}
            className={clsx([
              styles.buttons,
              dialog.radioButtons && styles.radioButtons,
            ])}
            data-testid={`${testId}.buttons`}
          >
            {dialog.sections.map((section, sectionIdx, sections) => {
              const isLastSection = sectionIdx === sections.length - 1;

              return (
                <div
                  key={`section-${sectionIdx}`}
                  ref={buttonsSectionRef}
                  className={clsx([
                    styles.buttonsSection,
                    !section.title && styles.buttonsSectionWithoutTitle,
                    nextSectionExists(sectionIdx) &&
                      styles.buttonsSectionWithBorder,
                  ])}
                  data-testid={`${testId}.section.${sectionIdx}`}
                >
                  {section.title && (
                    <div
                      className={clsx([
                        styles.sectionTitle,
                        typography.h7.bold,
                      ])}
                      data-testid={`${testId}.section-title`}
                    >
                      {t(section.title)}
                    </div>
                  )}

                  {section.options.map((button, buttonIdx, buttons) => (
                    <FocusListButton
                      key={`${button.value}-${buttonIdx}`}
                      active={dialog.activeOptions[sectionIdx] === button.value}
                      className={styles.button}
                      data-testid={`${testId}.button.${sectionIdx}.${buttonIdx}`}
                      focusKey={localWidgetFocusKey.listButton(
                        focusKey,
                        sectionIdx,
                        buttonIdx,
                      )}
                      isRadioButton={dialog.radioButtons}
                      label={
                        t(button.label) ?? button.value ?? `button ${buttonIdx}`
                      }
                      textAlign={dialog.buttonTextAlign}
                      onArrowPress={
                        focusOnConfirmationButton
                          ? (direction: string) => {
                              if (
                                isArrowDownKey(direction) &&
                                isLastSection &&
                                buttonIdx === buttons.length - 1
                              ) {
                                focusOnConfirmationButton();
                                return false;
                              }

                              return true;
                            }
                          : undefined
                      }
                      onEnterPress={() => {
                        dialog.onButtonClick(button.value, sectionIdx);

                        dispatchDialog(
                          actionListDialogSetActiveId({
                            value: button.value,
                            sectionIdx,
                          }),
                        );
                      }}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </div>

        {hasConfirmationButton && (
          <div
            className={clsx([
              styles.actionButton,
              isContentScrollable && styles.actionButtonWithScrollableList,
            ])}
            data-testid={`${testId}.actionButton`}
          >
            <FocusButton
              focusKey={confirmationButtonFocusKey}
              text={t(dialog.actionButtonLabel) ?? "button"}
              block
              onArrowPress={onConfirmationButtonArrowUp}
              onEnterPress={() => {
                dialog.onActionButtonClick?.();
                doClose();
              }}
            />
          </div>
        )}
      </Dialog>
    </FocusContext.Provider>
  );
}

function getSelectedListButton(dialog: ListDialogType): {
  firstActiveButtonIdx: ListDialogActiveButton;
  activeFocusKey: string | undefined;
} {
  let buttonIdx: number | undefined;
  const sectionIdx = dialog.sections.findIndex((section) => {
    buttonIdx = section.options.findIndex(
      (option) => option.value === dialog.activeOptions[0],
    );
    return buttonIdx !== -1;
  });

  return {
    firstActiveButtonIdx:
      !buttonIdx || buttonIdx === -1
        ? { sectionIdx: 0, buttonIdx: 0 }
        : { sectionIdx: 0, buttonIdx },
    activeFocusKey:
      sectionIdx !== -1 && buttonIdx !== undefined && buttonIdx !== -1
        ? localWidgetFocusKey.listButton(
            localWidgetFocusKey.component,
            sectionIdx,
            buttonIdx,
          )
        : undefined,
  };
}
