import type {
  EpgEntrySchema,
  ReducedChannelSchema,
} from "@sunrise/backend-ng-recommendations";
import { ListType } from "@sunrise/backend-ng-recommendations";

export const isEpgEntry = (
  item: ReducedChannelSchema | EpgEntrySchema,
): item is EpgEntrySchema => {
  return item?.type === ListType.EpgEntry;
};

export const isChannel = (
  item: ReducedChannelSchema | EpgEntrySchema,
): item is ReducedChannelSchema => {
  return item?.type === ListType.Channel;
};
