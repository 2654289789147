import type { ReactElement, ReactNode } from "react";
import { useCallback, useEffect } from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";

import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import {
  isArrowDownKey,
  isArrowLeftKey,
  isArrowRightKey,
  isArrowUpKey,
} from "@/utils/navigation";

type NavigationBoundsHandlers = {
  onTop?: () => void;
  onBottom?: () => void;
  onRight?: () => void;
  onLeft?: () => void;
  onEnter?: () => void;
  onBack?: () => void;
};

export type FocusContainerCallbackHandlers = {
  onArrowPress: (...directions: NavigationDirection[]) => FocusableOnArrowPress;
  onRightBound: OnBound;
  onLeftBound: OnBound;
  onTopBound: OnBound;
  onBottomBound: OnBound;
};

export type FocusableOnArrowPress = (direction: string) => boolean;

export type OnChangedFocused = "self" | "child" | false;

type OnBound = () => void;

export type FocusContainerProps = {
  boundary?: boolean;
  forceFocus?: boolean;
  shouldFocus?: boolean;
  children: (handlers: FocusContainerCallbackHandlers) => ReactNode;
  preferredChildFocusKey?: string;
  onFocusChanged?: (focused: OnChangedFocused) => void;
  focusKey: string;
  style?: React.CSSProperties;
} & CommonProps &
  NavigationBoundsHandlers;

/**
 * A helper component to make directional navigation a little bit easier.
 * On the FocusContainer you can just pass in what to do when you are on the edges of the container or when you press back.
 *
 * The idea is that you pass in a function that returns children instead of your children directly.
 * The function receives an argument with directional handlers that map to spatial navigation's onArrowPress API.
 * And the FocusContainer then maps those handlers to the directional handlers you passed in.
 *
 * You would only wire up the injected onLeft function on components that sit to the left of the FocusContainer.
 * The onBack handler is automatically mapped with the keyboard navigation.
 */
export function FocusContainer({
  boundary = false,
  forceFocus = false,
  shouldFocus,
  children,
  className,
  focusKey,
  onBottom,
  onLeft,
  onRight,
  onTop,
  onBack,
  onFocusChanged,
  style,
  onEnter,
  preferredChildFocusKey,
  "data-testid": dataTestId,
}: FocusContainerProps): ReactElement {
  const handleArrowPress = useCallback(
    (dir: string) => {
      if (isArrowLeftKey(dir) && onLeft) {
        onLeft();
        return false;
      }

      if (isArrowRightKey(dir) && onRight) {
        onRight();
        return false;
      }

      if (isArrowUpKey(dir) && onTop) {
        onTop();
        return false;
      }

      if (isArrowDownKey(dir) && onBottom) {
        onBottom();
        return false;
      }

      return true;
    },
    [onLeft, onRight, onTop, onBottom],
  );

  const { ref, focused, hasFocusedChild, focusSelf } = useFocusable({
    focusKey,
    trackChildren: true,
    saveLastFocusedChild: true,
    forceFocus,
    preferredChildFocusKey,
    autoRestoreFocus: shouldFocus,
    isFocusBoundary: boundary,
    onArrowPress: handleArrowPress,
    onEnterPress: onEnter,
  });

  // Sometimes we want to pull focus to ourselves.
  // We can't rely on a component pushing focus to us when we have not rendered yet.
  // When we are told we should focus we should pull focus again.
  useEffect(() => {
    if (shouldFocus) {
      focusSelf();
    }
  }, [shouldFocus, focusSelf]);

  useKeyboardNavigation({
    onBack,
    isEnabled: hasFocusedChild || focused,
  });

  useEffect(() => {
    onFocusChanged?.(focused ? "self" : hasFocusedChild ? "child" : false);
  }, [focused, hasFocusedChild, onFocusChanged]);

  const onArrowPressCreator = useCallback(
    (...directions: NavigationDirection[]): FocusableOnArrowPress => {
      return (dir: string) => {
        if (!directions.includes(dir as NavigationDirection)) return true;

        return handleArrowPress(dir);
      };
    },
    [handleArrowPress],
  );

  const OnBoundCreator = useCallback(
    (direction: NavigationDirection): OnBound => {
      return () => {
        if (direction === "left" && onLeft) {
          onLeft();
          return;
        }

        if (direction === "right" && onRight) {
          onRight();
          return;
        }

        if (direction === "up" && onTop) {
          onTop();
          return;
        }

        if (direction === "down" && onBottom) {
          onBottom();
        }
      };
    },
    [onLeft, onRight, onTop, onBottom],
  );

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        className={className}
        data-focused={hasFocusedChild || focused}
        data-testid={dataTestId}
        style={style}
      >
        {children({
          onArrowPress: onArrowPressCreator,
          onLeftBound: OnBoundCreator("left"),
          onRightBound: OnBoundCreator("right"),
          onTopBound: OnBoundCreator("up"),
          onBottomBound: OnBoundCreator("down"),
        })}
      </div>
    </FocusContext.Provider>
  );
}
