import type React from "react";

export function mergeRefs<T = unknown>(
  ...refs: (React.MutableRefObject<T> | ((node: T | null) => void) | null)[]
): React.RefCallback<T> {
  return (node) => {
    refs.forEach((ref) => {
      if (ref !== null) {
        if (typeof ref === "function") {
          ref(node);
        } else {
          (ref as React.MutableRefObject<T | null>).current = node;
        }
      }
    });
  };
}
