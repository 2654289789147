import { useCallback } from "react";
import { differenceInSeconds } from "date-fns/fp";
import { useAtomCallback } from "jotai/utils";

import { PlayerContentType } from "@sunrise/backend-ng-events";
import {
  playerCurrentDateTimeAtom,
  playerCurrentEpgItemAtom,
  playerDateTimeConverterAtom,
  playerPreviousEpgItemAtom,
  selectPlayerCurrentTime,
} from "@sunrise/player";
import { isDefined } from "@sunrise/utils";
import {
  actionPlayerManagerPlayReplay,
  currentlyRequestedPlayRequestAtom,
  getPlayerManager,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";
import type { ReplayPlayRequest } from "@sunrise/yallo-player-types";
import {
  isSingleRecording,
  recordingByRecordingIdAtom,
} from "@sunrise/yallo-recordings";

/**
 * This hook is used to play the current program from the beginning.
 * It will seek to the start of the program and play it.
 *
 * It works for EPG/linear-based streams and for recordings/on-demand streams.
 * In both cases it will check if first 5sec of stream has been played and if so, it will seek to the start of the program.
 * Else it will seek to the start of the previous program.
 *
 * For recordings it will also check if the recording has a padding start time and seek to that time if it exists.
 *
 * @returns A function that when called will play the program from the beginning.
 */

export function usePlayFromBeginning(): () => Promise<void> {
  return useAtomCallback(
    useCallback(async (get, set) => {
      const playRequest = get(currentlyRequestedPlayRequestAtom);
      if (!playRequest) return;

      let time;

      if (playRequest.type === "recording") {
        const currentTimeInSeconds = get(selectPlayerCurrentTime);
        const recording = await get(
          recordingByRecordingIdAtom(playRequest.recordingId),
        );

        const paddingStartMinutes =
          recording?.type !== "group" && recording?.paddingStartMinutes;
        const recordingPaddingStartSeconds = paddingStartMinutes
          ? paddingStartMinutes * 60
          : 0;
        const shouldSeekToPreviousEpg =
          isDefined(currentTimeInSeconds) &&
          currentTimeInSeconds - recordingPaddingStartSeconds < 5;
        const previousEpgEntry =
          shouldSeekToPreviousEpg && (await get(playerPreviousEpgItemAtom));

        if (shouldSeekToPreviousEpg && previousEpgEntry) {
          const replayRequest: ReplayPlayRequest = {
            type: PlayerContentType.Replay,
            epgId: previousEpgEntry.id,
            channelId: previousEpgEntry.channel.id,
          };

          // check if the previous program is in replay window, else show out of replay dialog
          // Do not warn about it since if we can't actually replay it, let's just zip to the start of the recording.
          const canPlayReplay = await getPlayerManager().canPlay(
            replayRequest,
            false,
          );

          if (canPlayReplay) {
            // change the stream to replay
            set(
              playerManagerAtom,
              actionPlayerManagerPlayReplay(
                previousEpgEntry.id,
                previousEpgEntry.channel.id,
                new Date(previousEpgEntry.actualStart),
                true,
              ),
            );
            return;
          }
        }

        time = recordingPaddingStartSeconds ?? 0;

        if (isSingleRecording(recording)) {
          await getPlayerManager().seekToInCurrentPlayRequest(time, {
            originatingAction: "seek-to-start",
            // We set this to true because we want to make sure the seconds are interpreted as seconds inside of the recording stream.
            interpretAsOnDemand: true,
          });
          return;
        }
      } else {
        // replay or live
        const converter = get(playerDateTimeConverterAtom);
        if (!converter) return;

        const epgEntry = await get(playerCurrentEpgItemAtom);
        if (!epgEntry) return;

        const currentTime = get(playerCurrentDateTimeAtom);

        // when the program has started, but is less than 5s in (duration * progress < 5s)
        const shouldSeekToPreviousEpg =
          currentTime &&
          differenceInSeconds(new Date(epgEntry.actualStart), currentTime) < 5;
        const previousEpgEntry =
          shouldSeekToPreviousEpg && (await get(playerPreviousEpgItemAtom));

        if (shouldSeekToPreviousEpg && previousEpgEntry) {
          time = converter.fromDate(new Date(previousEpgEntry.actualStart));
        } else {
          time = converter.fromDate(new Date(epgEntry.actualStart));
        }
      }

      await getPlayerManager().seekToInCurrentPlayRequest(time, {
        originatingAction: "seek-to-start",
      });
    }, []),
  );
}
