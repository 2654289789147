import type { ReactNode } from "react";

import { ProgressBar } from "@/components";

import * as styles from "./design-system.css";

export function DebugProgressBar(): ReactNode {
  const liveProgress = 68;
  const replayProgress = 43;
  return (
    <div style={{ width: "500px", display: "flex", flexDirection: "column" }}>
      <h3>default size</h3>
      <ProgressBar className={styles.progressBar} liveProgress={liveProgress} />
      <h3>default size + replay</h3>
      <ProgressBar
        className={styles.progressBar}
        liveProgress={liveProgress}
        replayProgress={replayProgress}
      />
      <h3>height 10px</h3>
      <ProgressBar
        className={styles.progressBar}
        height={10}
        liveProgress={liveProgress}
      />
      <h3>height 10px, radius 16px</h3>
      <ProgressBar
        className={styles.progressBar}
        height={10}
        liveProgress={liveProgress}
        radius={16}
        replayProgress={replayProgress}
      />
      <h3>height 10px, radius 16px, 2 breaks</h3>
      <ProgressBar
        breaks={[
          { start: 4, length: 10 },
          { start: 89, length: 8 },
        ]}
        className={styles.progressBar}
        height={10}
        liveProgress={liveProgress}
        radius={16}
      />
    </div>
  );
}
