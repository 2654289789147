import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { legacyRecordingToGenericRecording } from "@sunrise/yallo-recordings";
import { pageableItemsFromQuery } from "@sunrise/yallo-recordings-list";

import { lastSelectedSeasonAssetBySeriesAssetIdAtom } from "../last-selected-season-asset-by-series-asset-id.atom";
import { recordingGroupItemsLegacyQueryAtom } from "../legacy/queries/recording-group-items.legacy.query.atom";

/**
 * TODO: extend for NG (does not require a seriesAssetId. Does not have filtering atm.)
 */
export const pagedGenericRecordingsForRecordingGroupIdAtom = atomFamily(
  ({
    recordingGroupId,
    seriesAssetId,
  }: {
    recordingGroupId: RecordingGroupId;
    seriesAssetId: AssetId;
  }) => {
    const inner = atom(async (get) => {
      const seasonFilter = await get(
        lastSelectedSeasonAssetBySeriesAssetIdAtom({
          seriesAssetId,
          recordingGroupId,
        }),
      );

      // When we have no season selected we want the loader to map to paging over all the items.
      // When we have a specific season selected, we need to map the loader to the loading of the pages for that specific season.
      const query = get(
        recordingGroupItemsLegacyQueryAtom(
          seasonFilter === "all"
            ? { recordingGroupId, traverseChildGroups: true, seriesAssetId }
            : {
                recordingGroupId: seasonFilter,
                traverseChildGroups: false,
                seriesAssetId,
              },
        ),
      );

      const data = pageableItemsFromQuery(query);

      const language = get(currentLanguageAtom);
      return {
        ...data,
        items: data.items.map((item) =>
          legacyRecordingToGenericRecording(item, language),
        ),
      };
    });

    inner.debugLabel = `pagedGenericRecordingsForRecordingGroupIdAtom(recordingGroupId:${recordingGroupId},seriesAssetId:${seriesAssetId})`;

    return inner;
  },
  areEqual,
);
