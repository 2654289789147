import { lazy, Suspense } from "react";
import { useAtomValue } from "jotai";
import { Redirect, Route, Switch } from "wouter";

import { selectIsLoggedIn } from "@sunrise/jwt";
import { useAutostop } from "@sunrise/yallo-common-player-manager";

import { rawRoute } from "@/config/route";
import { DeveloperControls } from "@/features/settings/developer/developer-controls";
import { Profile } from "@/features/settings/profile/profile";
import { DesignSystem } from "@/routes/design-system";
import { Details } from "@/routes/details/details";
import { DetailsSeries } from "@/routes/details-series/details-series";
import { Landing } from "@/routes/landing";
import { Login } from "@/routes/login";
import { Register } from "@/routes/register";
import { Settings } from "@/routes/settings";

import { PageSpinner } from "./components";
import { developerModeAtom } from "./features/settings/developer/developer-mode.atom";
import {
  DeveloperFeatureFlagDetails,
  DeveloperFeatureFlags,
} from "./features/settings/developer/feature-flags";
import { RedirectionGuard } from "./redirection-guard";

// dynamic imports
const Tv = lazy(() => import("@/routes/tv"));
const Recordings = lazy(() => import("@/routes/recordings"));
const Guide = lazy(() => import("@/routes/guide"));
const Home = lazy(() => import("@/routes/home"));
const Search = lazy(() => import("@/routes/search"));

export function Router(): JSX.Element {
  const isLoggedIn = useAtomValue(selectIsLoggedIn);
  const developerMode = useAtomValue(developerModeAtom);

  return (
    <Suspense fallback={<PageSpinner />}>
      <RedirectionGuard />
      <Switch>
        {isLoggedIn ? (
          <LoggedInLayout>
            <Route component={Home} path={rawRoute.home} />
            <Route component={Search} path={rawRoute.search} />
            <Route component={Guide} path={rawRoute.guide} />
            <Route component={Recordings} path={rawRoute.recordings} />
            <Route component={Settings} path={rawRoute.settings} />
            <Route component={Profile} path={rawRoute.settingsProfile} />
            {developerMode && (
              <>
                <Route
                  component={DeveloperControls}
                  path={rawRoute.settingsDeveloper}
                />
                <Route
                  component={DeveloperFeatureFlags}
                  path={rawRoute.settingsDeveloperFeatureFlags}
                />
                <Route
                  component={DeveloperFeatureFlagDetails}
                  path={rawRoute.settingsDeveloperFeatureFlagsDetails}
                />
              </>
            )}
            <Route component={Tv} path={rawRoute.tv} />
            <Route component={Details} path={rawRoute.details} />
            <Route component={DetailsSeries} path={rawRoute.detailsSeries} />
            {import.meta.env.MODE !== "production" &&
              import.meta.env.VITE_SHOW_DESIGN_SYSTEM === "true" && (
                <Route component={DesignSystem} path={rawRoute.designSystem} />
              )}
          </LoggedInLayout>
        ) : (
          <>
            <Route component={Register} path={rawRoute.register} />
            <Route component={Login} path={rawRoute.login} />
            <Route component={Landing} path={rawRoute.landing} />
          </>
        )}

        <Route>
          <Redirect to={isLoggedIn ? rawRoute.home : rawRoute.landing} />
        </Route>
      </Switch>
    </Suspense>
  );
}

function LoggedInLayout({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode {
  // Autostop is at this level since it also needs to stop when users are browsing on the guide for example.
  useAutostop();

  return children;
}
