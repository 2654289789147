import { selectAtom } from "jotai/utils";

import { pagedGenericRecordingsAtom } from "./data/paged-generic-recordings.atom";

/**
 * This atom is used to determine if the initial data for the overview is being fetched.
 * So we can show a full-page loader.
 */
export const isFetchingInitialDataAtom = selectAtom(
  pagedGenericRecordingsAtom,
  (d) => d.isLoading,
);
