type TitanOSRemoteKey =
  | "Back"
  | "ColorF0Red"
  | "ColorF1Green"
  | "ColorF2Yellow"
  | "ColorF3Blue"
  | "MediaFastForward"
  | "MediaPause"
  | "MediaPlay"
  | "MediaPlayPause"
  | "MediaRewind"
  | "MediaStop";

type TitanOSRemoteKeyIdKeyCodes = Record<TitanOSRemoteKey, string>;

export const titanosRemoteKeyIdToKeyboardEventKeyDict = {
  Back: "Backspace",
  ColorF0Red: "ColorF0Red",
  ColorF1Green: "ColorF1Green",
  ColorF2Yellow: "ColorF2Yellow",
  ColorF3Blue: "ColorF3Blue",
  MediaPause: "Pause",
  MediaPlay: "MediaPlay",
  MediaPlayPause: "MediaPlayPause",
  MediaStop: "MediaStop",
  MediaRewind: "MediaRewind",
  MediaFastForward: "MediaFastForward",
} satisfies TitanOSRemoteKeyIdKeyCodes;

export function initTvRemote(o: {
  registerKeys: (r: TitanOSRemoteKeyIdKeyCodes) => void;
}): void {
  o.registerKeys(titanosRemoteKeyIdToKeyboardEventKeyDict);
}
