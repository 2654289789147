import type { ReactNode } from "react";

import { useTranslator } from "@sunrise/translator";

import { buttonListError, eventStyle } from "./details.css";

type ButtonListErrorProps = {
  eventId: string;
};

export function ButtonListError({ eventId }: ButtonListErrorProps): ReactNode {
  const t = useTranslator();

  return (
    <div>
      <div className={buttonListError}>{t("general_error_title")}</div>
      <p className={eventStyle}>Event ID: {eventId}</p>
    </div>
  );
}
