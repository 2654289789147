import type { ChannelId } from "@sunrise/backend-types-core";

import type { GuideChannel, GuideProgram } from "../guide.types";

/**
 * Selects the first program that is selectable. What is selectable is defined from the outside. That way we can have it keep the grid viewport into account if we want to.
 *
 * NOTE: Would be cool if we could have it prioritize certain selections. Like preferring the currently selected channel. Evaluating that first. Or trying the most center program first. Or check if there is a live program first. etc.
 */
export function selectRelevantProgram(
  data: GuideChannel[],
  {
    isSelectable,
  }: {
    /**
     * Used to control if a program is selectable or not.
     * We can also say that we should skip an entire channel if we detect the channel is not preferrable.
     */
    isSelectable: (
      channelId: ChannelId,
      program: GuideProgram,
    ) => "skip-channel" | "skip-program" | true;
  },
): {
  program: GuideProgram;
  channel: GuideChannel;
} | null {
  for (const channel of data) {
    for (const program of channel.items) {
      const isProgramSelectable = isSelectable(channel.id, program);

      if (isProgramSelectable === "skip-program") {
        continue;
      }

      if (isProgramSelectable === "skip-channel") {
        break;
      }

      return { program, channel };
    }
  }

  return null;
}
