import type { ReactElement } from "react";
import { memo, useCallback, useEffect, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";

import type { ChannelResult, ProgramResult } from "@sunrise/backend-types";
import { isCursorVisibleAtom } from "@sunrise/bigscreen";
import type { Nullable } from "@sunrise/utils";
import { isNil } from "@sunrise/utils";
import { disableAnimationsAtom } from "@sunrise/yallo-settings";

import { programBoxSize, recommendationRowSize } from "@/config/size";
import { SCREEN_WIDTH_IN_PX } from "@/core";
import {
  actionRecommendationsSetOffsetColumn,
  recommendationsAtom,
} from "@/features/recommendations/recommendations.atom";
import * as styles from "@/features/recommendations/recommendations.css";
import { recommendationsOffsetForRowAtom } from "@/features/recommendations/recommendations-offset-for-row.atom";
import { typography } from "@/styles/typography.css";

import { isArrowLeftKey, isArrowRightKey } from "../../utils/navigation";
import {
  actionRecommendationsSetColForRow,
  selectRecommendationsCoordinatesAtRow,
} from "./recommendations.atom";
import {
  ScrollArrows,
  type ScrollDirection,
} from "./scroll-arrows/scroll-arrows";
import { VirtualListRowItem } from "./virtual-list-row-item";

type VirtualListRowProps = CommonProps & {
  result:
    | (Omit<ChannelResult, "title"> & { title: string | null })
    | (Omit<ProgramResult, "title"> & { title: string | null });
  idx: number;
  focusedIndex: number | null;
  size: number;
  start: number;
};

export const VirtualListRow = memo(function VirtualListRow({
  "data-testid": dataTestId = "virtual-list-row",
  focusedIndex,
  ...props
}: VirtualListRowProps): ReactElement {
  const parentRef = useRef<HTMLDivElement>(null);

  const kind = props.result.kind;
  const dispatchRecommendations = useSetAtom(recommendationsAtom);

  const selectedIndex = useAtomValue(
    selectRecommendationsCoordinatesAtRow(props.idx),
  ).colIndex;

  const virtualizer = useVirtualizer({
    overscan: 1,
    count: props.result.items.length,
    horizontal: true,
    initialOffset: useAtomValue(recommendationsOffsetForRowAtom(props.idx)),
    initialRect: {
      width: SCREEN_WIDTH_IN_PX,
      height: recommendationRowSize[kind].height,
    },
    getScrollElement: () => parentRef.current,
    estimateSize: () => programBoxSize[kind].width,
  });
  const isCursorVisible = useAtomValue(isCursorVisibleAtom);
  const disableAnimations = useAtomValue(disableAnimationsAtom);

  const scrollToIndex = useCallback(
    (index: Nullable<number>) => {
      if (isNil(index)) return;

      const result = virtualizer.getOffsetForIndex(index, "center");
      const offset = result?.[0] ?? 0;

      parentRef.current?.scrollTo({
        left: offset,
        behavior: disableAnimations ? "auto" : "smooth",
      });

      dispatchRecommendations(
        actionRecommendationsSetOffsetColumn(props.idx, offset),
      );
    },
    [disableAnimations, dispatchRecommendations, props.idx, virtualizer],
  );

  const firstSelected = selectedIndex === 0;
  const lastSelected = selectedIndex === props.result.items.length - 1;

  const scroll = useCallback(
    (direction: ScrollDirection) => {
      let nextIndex = selectedIndex;
      if (isArrowLeftKey(direction) && !firstSelected) {
        nextIndex--;
      } else if (isArrowRightKey(direction) && !lastSelected) {
        nextIndex++;
      }

      scrollToIndex(nextIndex);
      dispatchRecommendations(
        actionRecommendationsSetColForRow({
          row: props.idx,
          column: nextIndex,
        }),
      );
    },
    [
      selectedIndex,
      firstSelected,
      lastSelected,
      scrollToIndex,
      dispatchRecommendations,
      props.idx,
    ],
  );

  useEffect(() => {
    // don't adjust the scroll when focusing with the mouse
    if (!isCursorVisible) {
      scrollToIndex(focusedIndex);
    }
  }, [focusedIndex, isCursorVisible, scrollToIndex]);

  return (
    <div
      className={props.className}
      style={{
        height: `${props.size}px`,
        transform: `translateY(${props.start}px)`,
        willChange: "transform",
      }}
    >
      <div
        className={clsx([typography.h5.regular, styles.rowTitle])}
        data-testid={`${dataTestId}.${props.idx}.title`}
      >
        <div className={styles.title}>{props.result.title}</div>
        <ScrollArrows
          firstSelected={firstSelected}
          lastSelected={lastSelected}
          scroll={scroll}
        />
      </div>
      <div
        className={clsx([styles.row, props.className])}
        data-testid={`${dataTestId}.${props.idx}`}
      >
        {/* The scrollable element for the list */}
        <div ref={parentRef} className={styles.scrollableRow}>
          {/* The large inner element to hold all of the items */}
          <div
            className={styles.rowItems}
            style={{
              width: `${virtualizer.getTotalSize()}px`,
            }}
          >
            {/* Only the visible items in the virtualizer, manually positioned to be in view */}
            {virtualizer.getVirtualItems().map((virtualItem) => {
              const maybeItem = props.result.items[virtualItem.index];
              if (isNil(maybeItem)) throw new Error("row is undefined");
              const isFocused = virtualItem.index === focusedIndex;

              return (
                <VirtualListRowItem
                  key={virtualItem.index}
                  data-testid={dataTestId}
                  index={props.idx}
                  isFocused={isFocused}
                  item={maybeItem}
                  kind={kind}
                  virtualItem={virtualItem}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});
