import { addDays } from "date-fns";
import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { ngEpgApiAtom } from "@sunrise/backend-ng-epg";
import { queryKeys } from "@sunrise/backend-types";
import type {
  ChannelId,
  TimeDay,
  TimeISOString,
} from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { dateToTimeDay } from "@sunrise/time";
import type { Nullable } from "@sunrise/utils";

import type { MappedEpg } from "../types";
import { mapBackendEpgNg } from "./map-backend-epg.ng";

export const epgsCollectionByChannelIdNgAtom = atomFamily(
  ({ channelId, day }: { channelId: ChannelId; day: TimeDay }) =>
    atomWithSuspenseQuery<Nullable<MappedEpg[]>>((get) => {
      const ngApi = get(ngEpgApiAtom);
      const language = get(currentLanguageAtom);

      return {
        enabled: !isNil(channelId),
        queryKey: queryKeys.channelEpgCollection(channelId, language, day),
        queryFn: async () => {
          if (!channelId) return null;
          const entries =
            await ngApi.epg.epgEntryByChannelEpgV1ChannelsChannelIdEpgEntriesGet(
              channelId,
              {
                // from BE perspective, these are date-time params (that is, full iso spec) but internally
                // we make an alternate representation just to express that it's formatted using only
                // the yyy-mm-dd initial part. The two are compatible, so it's safe to to this casting here for now
                start: day as unknown as TimeISOString,
                end: dateToTimeDay(
                  addDays(new Date(day), 1),
                ) as unknown as TimeISOString,
              },
            );
          return entries.data.items.map(mapBackendEpgNg) ?? [];
        },
        staleTime: Infinity,
        cacheTime: Infinity,
      };
    }),
);
