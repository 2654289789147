import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";

import type { Translatable } from "@sunrise/i18n";

import { actionDialogOpen, dialogAtom } from "./dialog.atom";

type ConfirmArgs<T> = {
  title: Translatable;
  options: {
    label: Translatable;
    value: T;
    initialFocus?: boolean;
    execAfterTimeout?: number;
  }[];
};

/**
 * The hook allows you to pass in any confirmation dialog with a title and options.
 * It will be presented on the side of the screen.
 *
 * TODO: Showing on the side of the screen may become optional so that it would show as a regular dialog on non-tv pages.
 */
export function useConfirmation<T>() {
  const confirm = useAtomCallback(
    useCallback((_, set, { title, options }: ConfirmArgs<T>): Promise<T> => {
      return new Promise<T>((resolve) => {
        // open dialog
        set(
          dialogAtom,
          actionDialogOpen({
            title,
            type: "actions",
            id: "confirm-zapping",
            onTheSide: true,
            actions: options.map((item) => ({
              ...item,
              label: item.label,
              action: () => resolve(item.value),
            })),
            lastFocusKey: "",
          }),
        );
      });
    }, []),
  );

  return {
    confirm,
  };
}
