import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { recordingsStaleTimeAtom } from "@sunrise/backend-ng-recordings";
import { queryKeys } from "@sunrise/backend-types";
import type { RecordingGroupId } from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import { fetchRecordingGroup } from "../recordings.service";

/**
 * This is an atom for TV clients. We need to show the seasons dropdown purely for the recorded episodes.
 * This atom will contain the total recorded episodes per season and all the recorded seasons.
 */
export const recordingGroupQueryAtom = atomFamily(
  (recordingGroupId: RecordingGroupId) => {
    const innerAtom = atomWithSuspenseQuery((get) => {
      const host = get(hostsAtom).api;
      if (isNil(host)) throw new Error("Host is not set");
      const { privateApi } = get(httpClientAtom);
      if (!privateApi) throw new Error("missing privateApi");

      return {
        queryKey: queryKeys.recordingGroupsByRecordingGroupId(
          get(selectJwtUserToken),
          recordingGroupId,
        ),
        queryFn: async () => {
          return fetchRecordingGroup(host, privateApi, recordingGroupId);
        },
        staleTime: get(recordingsStaleTimeAtom),
      };
    });

    innerAtom.debugLabel = `recordingGroupAtom(${recordingGroupId})`;

    return innerAtom;
  },
);
