import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { selectPlayerCurrentPlayRequest } from "@sunrise/player";
import type { PlayRequest } from "@sunrise/yallo-player-types";

import { actionGuideReset } from "../store/grid-state.actions";
import { gridStateAtom } from "../store/grid-state.atom";

/**
 * Reference to the PlayRequest we need to compare against.
 */
const lastCheckedPlayRequest = atom<PlayRequest | null>(null);

/**
 * This effect will make sure to reset the guide whenever the user manually changes the stream.
 * This way whenever the user manually changes the stream, the guide will re-focus on the currently playing program / channel and the scroll position is forgotten.
 */
export const guideResetEffect = atomEffect((get, set) => {
  const playRequest = get(selectPlayerCurrentPlayRequest);

  if (!playRequest) {
    set(gridStateAtom, actionGuideReset());
    return;
  }

  const lastChecked = get(lastCheckedPlayRequest);
  if (!lastChecked) {
    set(lastCheckedPlayRequest, playRequest);
    return;
  }

  if (lastChecked === playRequest) {
    return;
  }

  set(lastCheckedPlayRequest, playRequest);
  set(gridStateAtom, actionGuideReset());
});
