import { atomEffect } from "jotai-effect";

import type { GuideChannel } from "./guide.types";
import { guideDataChannelsLoadableAtom } from "./guide-data-channels-loadable.atom";
import { actionGuideClearSelection } from "./store/grid-state.actions";
import { gridStateAtom } from "./store/grid-state.atom";
import { selectorGuideSelection } from "./store/grid-state.selectors";

/**
 * This effect will clean the selection in the guide if the selection is not present in the guide.
 * For example when the channel list is no longer in the list of available channels.
 */
export const guideCleanSelectionEffect = atomEffect((get, set) => {
  const selection = get(selectorGuideSelection);
  if (!selection) {
    return;
  }

  const channels = (get(guideDataChannelsLoadableAtom) ?? [])
    .filter((c) => "id" in c.data)
    .map((c) => c.data) as GuideChannel[];

  // When there are no channels, we can not clean the selection.
  if (channels.length === 0) {
    return;
  }

  // When there are channels, we should assume that the currently playing item has to be in the list of channels present.
  // This is because on legacy we get all channels and on ng we get the page with the currently playing channel.
  // So if we were to receive the channels from the first page, we can safely assume that the current selection can be cleared.
  if (
    channels &&
    channels.every((channel) => selection.channelId !== channel.id)
  ) {
    set(gridStateAtom, actionGuideClearSelection());
  }
});
