import { atom } from "jotai";

import type { ProgramResult, Recommendations } from "@sunrise/backend-types";
import { continueWatchingKnownItemsLegacyAtom } from "@sunrise/yallo-continue-watching";

import { recommendationsQueryResultAtom } from "./recommendations.legacy.query";

// Note: quick way to include/exclude continue watching items
const INCLUDE_CONTINUE_WATCHING = true;

const continueWatchingDataAtom = atom<Promise<ProgramResult[]>>(async (get) => {
  const cwItems = get(continueWatchingKnownItemsLegacyAtom);
  const cwData: ProgramResult[] =
    cwItems.length > 0
      ? [
          {
            title: "recommendations_continue_watching", // translation key
            category: "continueWatching",
            kind: "normal",
            items: cwItems,
          },
        ]
      : [];

  return cwData;
});

/**
 * This is an atom that represents all the recommendation rows.
 *
 * @deprecated
 *   UI to be swapped out with new atoms for the NG backend.
 */
export const recommendationsDataLegacyAtom = atom<Promise<Recommendations>>(
  async (get) => {
    if (INCLUDE_CONTINUE_WATCHING) {
      const [cwData, recommendationsData] = await Promise.all([
        get(continueWatchingDataAtom),
        get(recommendationsQueryResultAtom),
      ]);
      return [...cwData, ...recommendationsData.data.result];
    } else {
      const { data } = await get(recommendationsQueryResultAtom);
      return data.result;
    }
  },
);
