import { millisecondsToSeconds } from "date-fns";
import { minutesToMilliseconds } from "date-fns/fp";

import type { ContinueWatchingItem } from "@sunrise/backend-types";
import { getLiveProgress } from "@sunrise/time";
import { type Nullable } from "@sunrise/utils";

export function getContinueWatchingProgress({
  isFullyWatched,
  epgStartTime,
  epgEndTime,
  continueWatchingItem,
  paddingTimesInMinutes,
}: {
  isFullyWatched: boolean;
  epgStartTime: Date;
  epgEndTime: Date;
  continueWatchingItem: Nullable<ContinueWatchingItem>;
  paddingTimesInMinutes: Nullable<{ start: number; end: number }>;
}) {
  if (isFullyWatched) {
    return 100;
  }

  if (paddingTimesInMinutes && continueWatchingItem?.type === "recording") {
    // When it's a recording, we need to know the duration of the recording and include the padding.
    const totalLengthInSeconds = millisecondsToSeconds(
      epgEndTime.getTime() -
        epgStartTime.getTime() +
        minutesToMilliseconds(paddingTimesInMinutes.start) +
        minutesToMilliseconds(paddingTimesInMinutes.end),
    );
    const currentTime = continueWatchingItem.play_position_second;

    return Math.floor((currentTime / totalLengthInSeconds) * 100);
  } else if (continueWatchingItem?.type === "replay") {
    return (
      getLiveProgress(
        epgStartTime,
        epgEndTime,
        new Date(continueWatchingItem.moment),
      ) ?? null
    );
  } else {
    return null;
  }
}
