import { DialogButtonType } from "./types";

export const DIALOG_BUTTONS = {
  [DialogButtonType.OK]: {
    label: "button_ok",
  },
  [DialogButtonType.CLOSE]: {
    label: "button_close",
  },
  [DialogButtonType.REPLAY_SETTINGS]: {
    label: "button_manage_replay_settings",
  },
} satisfies Record<DialogButtonType, { label: string }>;
