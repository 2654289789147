import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { ngRecordingsApiAtom } from "@sunrise/backend-ng-recordings";
import { queryKeys } from "@sunrise/backend-types";
import type { RecordingGroupId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectJwtUserToken } from "@sunrise/jwt";

/**
 * Available channels, seasons, availability with count for a specific asset
 *
 */
export const assetAvailableFiltersByRecordingGroupIdAtom = atomFamily(
  (recordingGroupId: RecordingGroupId) => {
    const inner = atomWithSuspenseQuery((get) => {
      const api = get(ngRecordingsApiAtom);
      const language = get(currentLanguageAtom);

      return {
        queryKey: queryKeys.recordingGroupFilters(
          get(selectJwtUserToken),
          recordingGroupId,
          language,
        ),
        queryFn: async () => {
          const { data } =
            await api.recording.getRecordingGroupFiltersByIdRecordingV1RecordingGroupsRecordingGroupIdFiltersGet(
              recordingGroupId,
            );

          return data;
        },
      };
    });

    inner.debugLabel = `assetAvailableFiltersByRecordingGroupIdAtom((${recordingGroupId}))`;

    return inner;
  },
);
