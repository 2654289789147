import { useCallback, useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import { type Nullable } from "@sunrise/utils";

import {
  actionPauseAdsErrored,
  actionPauseAdsSetShowing,
  actionPauseAdsTerminate,
  pauseAdsAtom,
  selectPauseAdImage,
} from "../pause-ads.atom";

type UsePauseAdsResult = {
  url: Nullable<string>;
  onShown: () => void;
  onError: () => void;
};

export function usePauseAds(): UsePauseAdsResult {
  const url = useAtomValue(selectPauseAdImage);
  const dispatchPauseAds = useSetAtom(pauseAdsAtom);

  useEffect(() => {
    return () => {
      dispatchPauseAds(actionPauseAdsTerminate());
    };
  }, [dispatchPauseAds]);

  return {
    url,
    onShown: useCallback(() => {
      dispatchPauseAds(actionPauseAdsSetShowing());
    }, [dispatchPauseAds]),
    onError: useCallback(() => {
      dispatchPauseAds(actionPauseAdsErrored());
    }, [dispatchPauseAds]),
  };
}
