import { atom } from "jotai";

import { isSmartTV, isWebOS } from "@sunrise/utils";

export const isCursorVisibleAtom = atom(false);

isCursorVisibleAtom.onMount = (set) => {
  function onMouseMove(): void {
    set(true);
  }

  function onKeyDown(): void {
    // TODO check only for relevant keys like left/right/up/down/back
    set(false);
  }

  function cursorVisibilityChange(
    event: CustomEvent<{ visibility: boolean }>,
  ): void {
    set(event.detail.visibility);
  }

  if (!isSmartTV()) {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("keydown", onKeyDown);
    };
  }

  if (isWebOS()) {
    document.addEventListener(
      "cursorStateChange",
      cursorVisibilityChange as EventListener,
      false,
    );

    return () => {
      document.removeEventListener(
        "cursorStateChange",
        cursorVisibilityChange as EventListener,
        false,
      );
    };
  }
};
