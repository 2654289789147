import { atom } from "jotai";

import { pathnameAtom } from "@sunrise/analytics";

import { tizenPaths } from "./tizen-paths";

export const pageIdAtom = atom((get) => {
  const pathname = get(pathnameAtom);
  if (!pathname) return;

  // pattern is /protected/page-name/...
  return tizenPaths.get(pathname.split("/").slice(0, 3).join("/"));
});
