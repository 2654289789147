import { type ReactElement, useMemo } from "react";
import clsx from "clsx";
import { millisecondsToMinutes } from "date-fns";

import {
  type MouseNavigationProps,
  useMouseNavigation,
} from "@sunrise/bigscreen";
import { type GuideProgram } from "@sunrise/yallo-guide";

import {
  GUIDE_PROGRAM_ITEM_PADDING,
  GUIDE_PROGRAM_MIN_RENDER_WIDTH,
} from "@/features/guide/constants";

import * as styles from "./guide-program-item.css";

type GuideProgramItemProps = {
  channelBarCutoffInPx?: number;
  disableAnimations?: boolean;
  program: GuideProgram;
  oneMinuteWidthInPx: number;
  channelHeightInPx: number;
  offset: number;
  isLive: boolean;
  isActive: boolean;
  isFocused: boolean;
  timeOffset: Date;
  fullGuideWidth: number;
} & MouseNavigationProps &
  CommonProps;

/**
 * Will contain a focusable element.
 * Needs to know the width of every minute and the initial start time so it can position itself.
 */
export function GuideProgramItem({
  channelBarCutoffInPx,
  disableAnimations,
  program,
  oneMinuteWidthInPx: oneMinuteWidth,
  channelHeightInPx,
  offset,
  isLive,
  isActive,
  isFocused,
  timeOffset,
  className,
  onMouseClick,
  onMouseEnter,
  navId,
  fullGuideWidth,
  "data-testid": dataTestId = "guide-program-item",
}: GuideProgramItemProps): ReactElement {
  const startInPx =
    millisecondsToMinutes(program.startTime.getTime() - timeOffset.getTime()) *
      oneMinuteWidth +
    offset;

  let programWidth = program.durationInMinutes * oneMinuteWidth;

  // NOTE: if program would go out of the full grid, cut it at the end
  if (programWidth + startInPx > fullGuideWidth) {
    programWidth = fullGuideWidth - startInPx;
  }

  const { onClick, onEnter } = useMouseNavigation({
    navId,
    onMouseClick,
    onMouseEnter,
  });
  const transform = useMemo(() => {
    const translateX = (channelBarCutoffInPx ?? 0) - startInPx;

    const willBeVisible =
      startInPx - GUIDE_PROGRAM_ITEM_PADDING * 2 - translateX >=
      GUIDE_PROGRAM_MIN_RENDER_WIDTH;

    return !channelBarCutoffInPx ||
      channelBarCutoffInPx < startInPx ||
      startInPx + programWidth < channelBarCutoffInPx // NOTE: do not transform boxes that are currently not in the view
      ? // TODO: as soon as this condition is true, text might extend to box boundaries for a short time until animation is finished (does not look that nice)
        {
          transform: "translateX(0)",
        }
      : {
          transform: `translateX(${translateX}px)`,
          width: `calc(100% - ${!willBeVisible ? "100%" : translateX + "px"})`,
        };
  }, [channelBarCutoffInPx, programWidth, startInPx]);

  return (
    <div
      className={clsx(
        styles.container,
        // NOTE: The order in which the CSS is defined in the styles is how they are applied. With the last one winning over the previous ones.
        isLive && styles.isLive,
        isActive && styles.isActive,
        isFocused && styles.isFocused,
        className,
      )}
      data-active={isActive}
      data-focused={isFocused}
      data-live={isLive}
      data-testid={`${dataTestId}-${program.id}`}
      style={{
        width: programWidth,
        height: channelHeightInPx,
        transform: `translateX(${startInPx}px)`,
        willChange: "transform",
        overflow: "hidden",
      }}
      onClick={onClick}
      onMouseOver={onEnter}
    >
      <div
        className={styles.title}
        data-testid={`${dataTestId}-${program.id}-title`}
        style={{
          ...transform,
          willChange: "transform",
          transitionProperty: "transform",
          transitionDelay: "300ms",
          // TODO: We need to somehow know how long the scroll of the grid offset will take.
          // Perhaps we can animate the scroll of the grid with a library so that we can control the duration exactly.
          transition: disableAnimations ? "" : "transform 600ms ease-in-out",
        }}
      >
        {program.title}
      </div>
    </div>
  );
}
