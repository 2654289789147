import { isTitanOS, promiseWithTimeout } from "@sunrise/utils";

import { loadDeviceInfoApi } from "./load-device-info-api";

const isApiLoaded = () => !!window.onDeviceInfoReady;

async function loadApi() {
  if (!isTitanOS()) return false;
  if (!isApiLoaded()) {
    try {
      await loadDeviceInfoApi();
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  return true;
}

export function closeTitanOSApp(): void {
  if (!isTitanOS()) return;

  if (typeof window.SmartTvA_API !== "undefined") {
    window.SmartTvA_API.exit();
  } else {
    window.close();
  }
}

export async function getTitanOSDeviceInfo(): Promise<TitanDeviceInfo | null> {
  if (!(await loadApi())) {
    return null;
  }

  try {
    return await promiseWithTimeout(
      new Promise((resolve) => {
        window.onDeviceInfoReady?.((info: TitanDeviceInfo) => resolve(info));
      }),
      2000,
    );
  } catch (e) {
    console.warn(e);
    return null;
  }
}
