import { useCallback, useEffect, useState } from "react";
import { useAtomValue, useSetAtom, useStore } from "jotai/index";
import { useAtomCallback } from "jotai/utils";

import {
  type ActionDialogAction,
  actionDialogClose,
  actionDialogOpen,
  dialogAtom,
  type DialogDescription,
  selectCurrentlyOpenedDialog,
} from "@sunrise/dialogs";
import { errorAtom } from "@sunrise/error";
import {
  DialogButtonType,
  type ErrorDialogConfiguration,
  type GetErrorButtonFn,
  getErrorDialogConfiguration,
} from "@sunrise/yallo-error-mapping";
import { toManageRecordingsAtom } from "@sunrise/yallo-recordings";
import { settingsOverlayPageAtom } from "@sunrise/yallo-settings";
import { upsellLinkQueryAtom } from "@sunrise/yallo-upsell";

import { errorToShowInDialogAtom } from "./error-to-show-in-dialog.atom";
import { traceToSentry } from "./helpers/trace-to-sentry";

type UseErrorDialogPops = {
  getFocusKey?: () => string;
  getConfirmationDescription?: (
    dialog: ErrorDialogConfiguration,
    eventId?: string,
    at?: Date,
  ) => DialogDescription;
  skipSentryCapture?: boolean;
  doLogin?: () => void;
  shouldShowUpsellActions?: boolean;
};

/**
 * This component is responsible to show the errors that are put in the atoms which need to be shown to the users.
 * These errors are not really blocking errors but they may cause a certain interaction to fail.
 *
 * Whatever triggered the error should have also cleaned up after itself.
 * For example, if playout failed because the user's tokens were expired or because refresh showed the user no longer exists,
 * it should have already logged out the user.
 *
 * The only cleanup this component does is to remove the error from the state.
 */
export const useErrorDialog = ({
  getFocusKey,
  getConfirmationDescription,
  skipSentryCapture = false,
  doLogin,
  shouldShowUpsellActions = false,
}: UseErrorDialogPops) => {
  const dispatchError = useSetAtom(errorAtom);
  const { error, hidden } = useAtomValue(errorToShowInDialogAtom);
  const store = useStore();
  const setSettingsOverlayPageAtom = useSetAtom(settingsOverlayPageAtom);

  const dispatchConfirmationDialog = useSetAtom(dialogAtom);
  const [dialogId, setDialogId] = useState<string | null>(null);

  // So we do not handle the same error instance twice.
  const [errorHandled, setErrorHandled] = useState<Error | null>(null);

  // NOTE: This needs to be a stable dependency since else we will potentially log errors multiple times.
  const close = useAtomCallback(
    useCallback(
      (get): void => {
        dispatchError(null);
        dispatchConfirmationDialog(
          actionDialogClose({ id: get(selectCurrentlyOpenedDialog)?.id }),
        );
      },
      [dispatchConfirmationDialog, dispatchError],
    ),
  );

  const getButtonAction: GetErrorButtonFn = useAtomCallback(
    useCallback(
      (get, _, code?: DialogButtonType): (() => void) => {
        switch (code) {
          case DialogButtonType.OK:
          case DialogButtonType.CLOSE:
          case DialogButtonType.CANCEL:
            return close;
          case DialogButtonType.REPLAY_SETTINGS:
            return () => {
              close();
              setSettingsOverlayPageAtom("replay");
            };
          case DialogButtonType.REFRESH:
            return () => {
              window.location.reload();
            };
          case DialogButtonType.MANAGE_RECORDINGS:
            return () => {
              close();
              get(toManageRecordingsAtom)?.();
            };
          default:
            return () => {
              /* noop */
            };
        }
      },
      [close, setSettingsOverlayPageAtom],
    ),
  );

  // Make sure to clear the relevant error modal we triggered as soon as the error is removed.
  useEffect(() => {
    if ((!error || hidden) && dialogId) {
      dispatchError(null);
      dispatchConfirmationDialog(actionDialogClose({ id: dialogId }));
      setDialogId(null);
    }
  }, [
    error,
    close,
    dialogId,
    dispatchConfirmationDialog,
    dispatchError,
    hidden,
  ]);

  useEffect(() => {
    if (!error || errorHandled === error) {
      setErrorHandled(error ?? null);
      return;
    }

    const doAsync = async (): Promise<void> => {
      // Log to sentry (or not)
      const { eventId, errorCode } = await traceToSentry({
        error,
        hidden,
        skipSentryCapture,
        location: "error-dialog",
        get: store.get,
      });

      // When the dialog is hidden, we don't want to show it.
      if (hidden) {
        setErrorHandled(error);
        return;
      }

      // We force to always have a dialog through forceResult.
      // So whenever an error is set on the error state, we will at least show the general error, even if there is no mapping found.
      const dialog = await getErrorDialogConfiguration(
        error,
        getButtonAction,
        store.get,
        {
          id: errorCode ?? undefined,
        },
      );

      if (dialog) {
        const actions: ActionDialogAction[] = [];

        if (dialog.confirmationLabel && dialog.onConfirm) {
          actions.push({
            label: dialog.confirmationLabel,
            action: dialog.onConfirm,
            key: "confirm",
            initialFocus: !dialog.focusReject,
          });
        }

        if (dialog.upsell && shouldShowUpsellActions) {
          const { data: upgrade } = await store.get(
            upsellLinkQueryAtom(dialog.upsell),
          );

          if (upgrade) {
            actions.push({
              label: { key: "upgrade_button" },
              url: upgrade.upsell_link,
              key: "upgrade",
              initialFocus: false,
              variant: "tonal",
            });
          }
        }

        if (dialog.rejectionLabel && dialog.onReject) {
          actions.push({
            label: dialog.rejectionLabel,
            action: dialog.onReject,
            key: "reject",
            initialFocus: !!dialog.focusReject,
          });
        }

        dispatchConfirmationDialog(
          actionDialogOpen({
            title: dialog.title,
            description:
              getConfirmationDescription?.(dialog, eventId, new Date()) ??
              dialog.description,
            type: "actions",
            backBehaviour: dialog.backBehaviour,
            actions,
            lastFocusKey: getFocusKey?.() ?? "",
            id: dialog.id,
            technicalErrorName: dialog.technicalErrorName,
          }),
        );

        setDialogId(dialog.id);
        setErrorHandled(error);
      }
    };

    void doAsync();
  }, [
    errorHandled,
    dispatchConfirmationDialog,
    dispatchError,
    error,
    getButtonAction,
    store,
    hidden,
    skipSentryCapture,
    doLogin,
    getConfirmationDescription,
    getFocusKey,
    shouldShowUpsellActions,
  ]);

  return {
    dialogId,
  };
};
