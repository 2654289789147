import { useEffect } from "react";
import { addHours } from "date-fns";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import type { PlayerCurrentContent } from "@sunrise/player";
import { playerCurrentContentAtom } from "@sunrise/player";
import { nowAtom } from "@sunrise/time";
import type { Nullable } from "@sunrise/utils";
import { channelsOfCurrentSelectedGroupAtom } from "@sunrise/yallo-channel";
import { programIsPlayingAtTime } from "@sunrise/yallo-epg";

import {
  GUIDE_WINDOW_DAYS_IN_FUTURE,
  GUIDE_WINDOW_DAYS_IN_PAST,
} from "../guide.constants";
import type { GuideProgram } from "../guide.types";
import {
  actionGuideClearSelection,
  actionGuideSetSelection,
} from "../store/grid-state.actions";
import { gridStateAtom } from "../store/grid-state.atom";
import { isInGuideWindow } from "../utils/is-in-guide-window";
import { selectRelevantProgram } from "../utils/select-relevant-program";
import { useGuideData } from "./use-guide-data";

const SKIP_PROGRAM = "skip-program";

/**
 * Responsible for automatically creating a selection in the grid.
 * It will load in guide data as needed and make a selection if no selection is present.
 *
 * When a selection is already present it will verify that the selection is done on an a channel that is currently in the user's channel list. If not, the selection is erased.
 *
 * For now, the only strategy when there is no selection present is to select the live program in the first channel.
 * In the future, this will be expanded on as we will need to auto-select whatever was playing in the background.
 * And later on, we will need to make sure to select the live event in the channel we are replaying in case we are watching a super old recording or replay.
 *
 * This is also not the only mechanism selecting programs in the grid.
 * Part of the navigation is also responsible for selecting programs if the currently selected program is not visible in the grid.
 *
 * NOTE: This is fully disabled when we are in select-date offset priority or when we already have a selection. Even if it's off-view.
 *       Then we want useGridAutoSelectAndScrollAndNavigate to do the selection on re-fucus to something that is in view.
 *
 *       When we are in initial grid selection mode we will autoselect something if there is no selection.
 */
export function useAutoSelectProgram(): void {
  const now = useAtomValue(nowAtom);
  const [{ selection, offsetPriority }, dispatchGridState] =
    useAtom(gridStateAtom);

  const { channels } = useAtomValue(channelsOfCurrentSelectedGroupAtom);
  // NOTE: This is linked to the player content including the seek time. So if we seek to a different place this will be updated.
  //       Normally this should not be a problem because when we unload the player controls we also reset the seek time.
  //       But ideally we refactor this to depend purely on playerCurrentEpgItemAtom.
  const playerCurrentContent = useAtomValue(playerCurrentContentAtom);
  const isCurrentContentInGuideWindow = isContentInGuideWindow(
    playerCurrentContent,
    now,
  );

  // Uses the same guide data hook for simplicity sake. But it preloads to now at the moment. This also serves as a cache warmer.
  const { data } = useGuideData({
    daysInFuture: GUIDE_WINDOW_DAYS_IN_FUTURE,
    daysInPast: GUIDE_WINDOW_DAYS_IN_PAST,

    // This is important for the auto-selection to work. It also caches important data but the current strategy to auto-select a live item requires live data to be present.
    initialVisibleData:
      isCurrentContentInGuideWindow &&
      playerCurrentContent.schedule &&
      playerCurrentContent.channelId &&
      // Also make sure we only do this shortcut when the current player content is actually present in the guide data.
      // Because otherwise we need to re-select something.
      channels.some((channel) => channel.id === playerCurrentContent.channelId)
        ? {
            channelIds: [playerCurrentContent.channelId],
            endTime: playerCurrentContent.schedule.endTime,
            startTime: playerCurrentContent.schedule.startTime,
          }
        : {
            channelIds: 5,
            endTime: addHours(now, 1),
            startTime: now,
          },
    enabled: !selection && offsetPriority !== "select-date",
  });

  useAutoSelect({
    isEnabled: !selection && offsetPriority !== "select-date",
    playerCurrentContent,
    isCurrentContentInGuideWindow,
    now,
    data,
  });

  useEffect(() => {
    if (!selection) {
      return;
    }

    // Doublecheck if the selection is in the list of channels that the user has active. If not, reset the selection.
    if (channels.every((channel) => channel.id !== selection.channelId)) {
      dispatchGridState(actionGuideClearSelection());
    }
  }, [channels, selection, dispatchGridState]);
}

function isContentInGuideWindow(
  content: Nullable<PlayerCurrentContent>,
  now: Date,
): boolean {
  return !!(
    content?.schedule &&
    content.channelId &&
    isInGuideWindow(content.schedule.startTime, content.schedule.endTime, now)
  );
}

function useAutoSelect({
  isEnabled,
  playerCurrentContent,
  isCurrentContentInGuideWindow,
  now,
  data,
}: {
  isEnabled: boolean;
  playerCurrentContent: PlayerCurrentContent;
  isCurrentContentInGuideWindow: boolean;
  now: Date;
  data: ReturnType<typeof useGuideData>["data"];
}): void {
  const dispatchGridState = useSetAtom(gridStateAtom);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const isSelectable: (program: GuideProgram) => true | "skip-program" =
      playerCurrentContent.epgId && isCurrentContentInGuideWindow
        ? (program) => {
            return program.id === playerCurrentContent.epgId || SKIP_PROGRAM;
          }
        : (program) => programIsPlayingAtTime(program, now) || SKIP_PROGRAM;

    let result = selectRelevantProgram(data, {
      isSelectable: (_, program) => isSelectable(program),
    });

    if (!result) {
      const firstChannel = data[0];
      const liveProgram = firstChannel?.items.find((item) =>
        programIsPlayingAtTime(item, now),
      );

      if (!firstChannel || !liveProgram) {
        return;
      }

      result = {
        channel: firstChannel,
        program: liveProgram,
      };
    }

    dispatchGridState(
      actionGuideSetSelection(result.program, result.channel.id),
    );
  }, [
    data,
    isEnabled,
    now,
    dispatchGridState,
    playerCurrentContent.epgId,
    isCurrentContentInGuideWindow,
  ]);
}
