import type { MappedAsset } from "@sunrise/asset";
import type { GeneratedApi } from "@sunrise/backend-ng-asset";
import type { AssetId } from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";
import { isDefined } from "@sunrise/utils";

export function mapBackendAssetDetailsNg(
  asset: Awaited<
    ReturnType<GeneratedApi["asset"]["assetDetailAssetV1AssetsAssetIdGet"]>
  >["data"],
): Nullable<MappedAsset> {
  if (asset.type === "person") return null;

  return {
    id: asset.id as AssetId,
    type: asset.type,
    title: asset.title,
    subtitle: asset.subtitle,
    ageRecomendation: asset.age_recommendation
      ? `${asset.age_recommendation}+`
      : null,
    episodeNumber: asset.type === "seriesepisode" ? asset.episode_number : null,
    seasonNumber: asset.type === "seriesepisode" ? asset.season_number : null,
    productionYear: asset.production_year,
    posterPath: asset.poster_url ?? null,
    posterBlurHash: asset.poster_blurhash,
    plot: asset.plot ?? asset.description,
    genres: (asset.genres ?? [])
      .map((genre) => genre.name)
      .filter(Boolean)
      .filter(isDefined),
    seasons: null,
    cast:
      asset.cast?.map((castMember) => castMember.full_name).filter(isDefined) ??
      [],
    directors: asset.directors ?? [],
    seriesId: asset.type === "seriesepisode" ? (asset.series as AssetId) : null,
  };
}
