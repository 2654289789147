import { atom } from "jotai";

import { channelByIdAtom } from "@sunrise/yallo-channel-group";

import { selectPlayerCurrentPlayRequest } from "./player.atom";
import { playerCurrentEpgItemShownAtom } from "./player-current-epg-item-shown.atom";
import type { PlayerCurrentContent } from "./types";

const EMPTY: Readonly<PlayerCurrentContent> = {
  epgId: null,
  channelId: null,
  recordingId: null,
  title: null,
  subtitle: null,
  channelLogo: null,
  channelName: null,
  schedule: null,
};

/**
 * This does include the content of the player AT SEEK time.
 * Or if we are not seeking, at the current player time.
 * It does an EPG lookup to know what is playing.
 */
export const playerCurrentContentEpgShownAtom = atom<
  Promise<PlayerCurrentContent>
>(async (get) => {
  const playRequest = get(selectPlayerCurrentPlayRequest);

  if (!playRequest || playRequest.type === "recording") {
    return EMPTY;
  }

  const [channel, epg] = await Promise.all([
    get(channelByIdAtom(playRequest.channelId)),
    get(playerCurrentEpgItemShownAtom),
  ]);

  if (!epg || !channel) {
    return EMPTY;
  }

  return {
    epgId: epg.id,
    channelId: channel.id,
    recordingId: null,
    title: epg.asset.title,
    subtitle: epg.asset.subtitle,
    channelLogo: channel.logo,
    channelName: channel.name,
    schedule: {
      endTime: new Date(epg.actualEnd),
      startTime: new Date(epg.actualStart),
    },
  };
});
