import { atom } from "jotai";

import { recordingsLegacyQueryAtom } from "../legacy/queries/recordings.legacy.query.atom";
import { recordingsMarkedForBulkDeletionAtom } from "./bulk-deletion.atom";

/**
 * Gets updated to true when user presses select all button.
 * Gets updated to false whenever we toggle recording's marked state.
 */
export const selectAllModeAtom = atom<boolean>(false);

/**
 * Introduced so that user can also manually choose ALL items to delete.
 * Set to true when deselect function is fired.
 */
export const canDeselectAllAtom = atom<boolean>(false);

/**
 * Checks if all recordings are selected (every recording on every page)
 */
export const isAllSelectedAtom = atom<boolean>((get) => {
  const recordings = get(recordingsLegacyQueryAtom);
  if (recordings.hasNextPage && !recordings.isFetchingNextPage) return false;

  const recordingsIdsMarkedForBulkDeletion = get(
    recordingsMarkedForBulkDeletionAtom,
  ).map((recording) => recording.id);

  const allRecordingsIds =
    recordings.data?.pages
      .flatMap((page) => page?.items || [])
      .map((recording) => recording.id) || [];

  return allRecordingsIds.every((recordingId) =>
    recordingsIdsMarkedForBulkDeletion.includes(recordingId),
  );
});
