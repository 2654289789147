import type { ReactElement, ReactNode } from "react";
import { useCallback } from "react";
import type { ArrowPressHandler } from "@noriginmedia/norigin-spatial-navigation";
import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue } from "jotai";

import { playerCurrentEpgItemAtom } from "@sunrise/player";
import type { MappedEpg } from "@sunrise/yallo-epg";

import { FocusIconButton } from "@/components/buttons/focus-icon-button/focus-icon-button";
import * as styles from "@/features/player-controls/player-controls.css";
import { useRecordProgram } from "@/modules/recordings/use-record-program";

import type { PlayerButton } from "./player-controls.types";

type RecordButtonProps = {
  focusKey: string;
  onArrowPress: ArrowPressHandler;
};

export function RecordButton(props: RecordButtonProps): ReactNode {
  const epgEntry = useAtomValue(playerCurrentEpgItemAtom);

  if (!epgEntry) {
    return null;
  }

  return <RecordButtonInternal {...props} epgEntry={epgEntry} />;
}

function RecordButtonInternal({
  focusKey,
  onArrowPress,
  epgEntry,
}: RecordButtonProps & { epgEntry: MappedEpg }): ReactElement {
  const {
    record,
    deleteRecording,
    status: recordingStatus,
  } = useRecordProgram({
    epgId: epgEntry.id,
    actualEnd: epgEntry.actualEnd,
    actualStart: epgEntry.actualStart,
    channelId: epgEntry.channel.id,
    type: epgEntry.asset.type,
    getCurrentFocusKey,
  });

  const getRecordingButton = useCallback((): PlayerButton => {
    switch (recordingStatus) {
      case "recorded": {
        return {
          key: "record",
          iconName: "recorded",
          onEnterPress: () => deleteRecording(),
        };
      }
      case "planned": {
        return {
          key: "record",
          iconName: "recording",
          onEnterPress: () => deleteRecording(),
        };
      }
      default: {
        return {
          key: "record",
          iconName: "record",
          onEnterPress: () => void record(),
        };
      }
    }
  }, [deleteRecording, record, recordingStatus]);

  const button = getRecordingButton();

  return (
    <FocusIconButton
      key={button.key}
      className={styles.button}
      data-testid={`player-controls.button.${button.key}`}
      disabled={button.disabled}
      focusKey={`${focusKey}.${button.key}`}
      iconName={button.iconName}
      onArrowPress={onArrowPress}
      onEnterPress={button.onEnterPress}
    />
  );
}
