import { getAnalytics } from "firebase/analytics";

import { firebaseApp } from "./firebase-app";

const hasAnalytics =
  process.env["VITE_FIREBASE_PROJECT_ID"] &&
  process.env["VITE_FIREBASE_API_KEY"] &&
  process.env["VITE_FIREBASE_MEASUREMENT_ID"];

export const firebaseAnalytics = hasAnalytics
  ? getAnalytics(firebaseApp)
  : null;
