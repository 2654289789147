import { atom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";

import { recordingsLegacyQueryAtom } from "../legacy/queries/recordings.legacy.query.atom";
import { recordingItemsWithPagingAtom } from "../recording-items-with-paging.atom";

const legacyRecordingTypesToNg = {
  single: "recording",
  episode: "recording",
  group: "recordinggroup",
} as const;

export const recordingsForDeletionQueryAtom = atom((get) => {
  if (get(isLegacyBackendAtom)) {
    const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = get(
      recordingsLegacyQueryAtom,
    );

    return {
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
      items:
        data?.pages
          .flatMap((page) => page?.items || [])
          .filter((recording) => "type" in recording)
          .map((recording) => ({
            id: recording.id,
            type: recording.type,
            episode_count:
              recording.type === "recordinggroup" ? recording.episode_count : 1,
          })) || [],
    };
  }

  const { fetchNextPage, items, hasNextPage, isLoadingNextPage } = get(
    recordingItemsWithPagingAtom,
  );

  return {
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage: isLoadingNextPage,
    items: items.map((recording) => ({
      id: recording.id,
      type: legacyRecordingTypesToNg[recording.type],
      episode_count:
        recording.type === "group" ? (recording.episodeCount ?? 1) : 1,
    })),
  };
});
