import type { ReactNode } from "react";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import clsx from "clsx";
import { useAtomValue } from "jotai";

import type { AssetId } from "@sunrise/backend-types-core";
import {
  assetStaffByIdLegacyAtom,
  castDirectorEnabledAtom,
} from "@sunrise/details";
import { useTranslator } from "@sunrise/translator";

import { typography } from "@/styles/typography.css";

import * as styles from "./details-cast-director.css";

type DetailsCastDirectorProps = {
  assetId: AssetId;
};

function SuspendedDetailsCastDirector({
  assetId,
}: DetailsCastDirectorProps): ReactNode {
  const t = useTranslator();

  const { data } = useAtomValue(assetStaffByIdLegacyAtom(assetId));
  const actors = data?.actors.map((a) => a.person.full_name) ?? [];
  const directors = data?.directors.map((d) => d.person.full_name) ?? [];
  const hasCastDirector = actors.length || directors.length;

  if (!hasCastDirector) return null;

  return (
    <div className={styles.castDirector}>
      {actors.length ? (
        <div
          className={clsx(typography.h7.regular, styles.cast)}
          data-testid="cast"
        >
          <span className={styles.castDirectorTitle}>
            {t("detailpage_cast")}
          </span>{" "}
          {actors.join(", ")}
        </div>
      ) : null}
      {directors.length ? (
        <div
          className={clsx(typography.h7.regular, styles.cast)}
          data-testid="director"
        >
          <span className={styles.castDirectorTitle}>
            {t("detailpage_director")}
          </span>{" "}
          {directors.join(", ")}
        </div>
      ) : null}
    </div>
  );
}

export function DetailsCastDirector({
  assetId,
}: DetailsCastDirectorProps): ReactNode {
  const castDirectorEnabled = useAtomValue(castDirectorEnabledAtom);

  if (!castDirectorEnabled) return null;

  return (
    <ErrorBoundary fallback={null}>
      <Suspense>
        <SuspendedDetailsCastDirector assetId={assetId} />
      </Suspense>
    </ErrorBoundary>
  );
}
