import { atom } from "jotai";

import { selectIsDialogOpen } from "@sunrise/dialogs";

import { isMenuActiveAtom } from "@/modules/menu/is-menu-active.atom";

/**
 * In general, things can only pull focus if they are visible and enabled.
 * And when:
 * - there is no modal overlay
 * - the menu is not open
 */
export const canPullFocusAtom = atom<boolean>((get) => {
  const isModalVisible = get(selectIsDialogOpen);
  const isMenuActive = get(isMenuActiveAtom);

  if (isModalVisible) return false;
  return !isMenuActive;
});
