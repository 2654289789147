import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { ListType } from "@sunrise/backend-ng-recommendations";
import {
  type AssetId,
  type ChannelId,
  type EPGEntryId,
  type RecommendationListId,
} from "@sunrise/backend-types-core";

import { isChannel, isEpgEntry } from "../helpers";
import type {
  ChannelRecommendation,
  EpgRecommendation,
  MappedDisplayType,
  RecordingRecommendation,
} from "../types.ng";
import {
  ngRecommendationsRowsQueryAtom,
  ngRowRecommendationsQueryAtom,
} from "./ng-recommendations.query";

export type RowRecommendationsData =
  | EpgRecommendation
  | ChannelRecommendation
  | RecordingRecommendation;

type MappedDisplayConfiguration = {
  displayType: MappedDisplayType;
  numbered: boolean;
};

type RecommendationData = {
  recommendationListId: RecommendationListId;
  title: string;
  displayConfiguration: MappedDisplayConfiguration;
  type: ListType;
};

export const recommendationsDataAtom = atom<Promise<RecommendationData[]>>(
  async (get) => {
    const rows = await get(ngRecommendationsRowsQueryAtom);

    const defaultDisplayType: MappedDisplayType = "box";

    return (
      rows.data?.items.map((item) => {
        return {
          title: item.title,
          displayConfiguration: {
            displayType:
              item.display_configuration.display_type ?? defaultDisplayType,
            numbered: item.display_configuration.numbered ?? false,
          },
          type: item.type,
          recommendationListId:
            item.recommendation_list_id as RecommendationListId,
        } satisfies RecommendationData;
      }) ?? []
    );
  },
);

export const rowRecommendationsDataAtom = atomFamily(
  (id: RecommendationListId) => {
    const innerAtom = atom(async (get): Promise<RowRecommendationsData[]> => {
      const row = get(ngRowRecommendationsQueryAtom(id));

      return (
        row.data?.pages
          .flatMap((page) => page.items)
          .map((item) => {
            if (isEpgEntry(item)) {
              return {
                coverImageUrl: item.asset.poster_url,
                seasonNumber: item.asset.season_number,
                episodeNumber: item.asset.episode_number,
                title: item.asset.title,
                subtitle: item.asset.subtitle,
                epgStart: item.start,
                epgEnd: item.end,
                epgId: item.id as EPGEntryId,
                channelLogo: item.channel.logo,
                channelName: item.channel.name,
                channelId: item.channel.id as ChannelId,
                assetId: item.asset.id as AssetId,
                type: item.type ?? "epg_entry",
              };
            }

            if (isChannel(item)) {
              return {
                channelId: item.id as ChannelId,
                channelLogo: item.logo,
                channelName: item.name,
                streamServiceUrl: item.stream_service_url,
                type: item.type ?? "channel",
              };
            }

            return null;
          })
          .filter((v) => v !== null) ?? []
      );
    });

    innerAtom.debugLabel = `rowRecommendationsDataAtom(${id})`;

    return innerAtom;
  },
);
