import { type ReactNode, useMemo } from "react";
import { useAtomValue } from "jotai";
import { atomWithDefault } from "jotai/utils";

import type { ChannelListItem } from "@sunrise/backend-types";
import { MouseNavigationContext } from "@sunrise/bigscreen";
import { simplifiedChannelInfoAtom } from "@sunrise/yallo-channel";

import { ChannelItem as ComponentsChannelItem } from "@/components";
import { ChannelLockIndicator } from "@/features/channel/channel-lock-indicator";

import { channelListItem } from "./channel-list.css";

const epgDefault = atomWithDefault(() => ({
  currentEpg: { start: "", title: "" },
  nextEpg: { start: "", title: "" },
  progress: 0,
}));

type ChannelItemProps = CommonProps & {
  item: ChannelListItem;
  focusSelf: () => void;
  onScreen: boolean;
  focused: boolean;
  play: () => void;
  isActive: boolean;
};

/**
 * This component combines the channel info and the current / next epg info
 * as well as the progress indicator and apsses that to the ChannelItem.
 *
 * It handles all the focus and scroll handling and just delegates
 * the visual rendering to the ChannelItem form the components library.
 *
 * When the component is not on screen it will not load any data.
 */
export function ChannelItem({
  item,
  focusSelf,
  focused,
  /**
   * Disables the interactive bit of the component so it consumes less memory.
   */
  onScreen,
  play,
  isActive,
  style,
  "data-testid": dataTestid,
}: ChannelItemProps): ReactNode {
  const epgData = useAtomValue(
    onScreen ? simplifiedChannelInfoAtom(item.id) : epgDefault,
  );

  const navigation = useMemo(
    () => ({
      focusElement: () => focusSelf(),
      enterElement: () => play(),
    }),
    [play, focusSelf],
  );

  if (!epgData) return null;

  return (
    <MouseNavigationContext.Provider value={navigation}>
      <div className={channelListItem} style={style}>
        <ComponentsChannelItem
          active={isActive}
          channelLogo={item.channelLogo}
          channelNumber={item.channelNumber}
          currentEpg={epgData.currentEpg}
          data-focused={focused}
          data-testid={dataTestid}
          focused={focused}
          id={item.id}
          // TODO: validate this default value
          liveProgress={epgData.progress ?? 0}
          nextEpg={epgData.nextEpg}
          skeleton={!onScreen}
        >
          <ChannelLockIndicator
            channelId={item.id}
            data-testid={`${dataTestid}.channel-lock-indicator`}
            focused={focused}
          />
        </ComponentsChannelItem>
      </div>
    </MouseNavigationContext.Provider>
  );
}
