import { useEffect } from "react";
import { useAtomValue } from "jotai";

import { disableAnimationsAtom } from "@sunrise/yallo-settings";

export function useScrollOnFocus({
  enabled,
  focused,
  ref,
}: {
  enabled: boolean;
  focused: boolean;
  ref: React.RefObject<HTMLElement>;
}): void {
  const disableAnimations = useAtomValue(disableAnimationsAtom);

  useEffect(() => {
    if (!focused || !enabled || !ref.current) {
      return;
    }

    // When focused, scroll into view as needed.
    ref.current.scrollIntoView({
      behavior: disableAnimations ? "instant" : "smooth",
      block: "center",
      inline: "center",
    });
  }, [focused, disableAnimations, ref, enabled]);
}
