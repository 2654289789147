import type { DefinedQueryObserverResult } from "@tanstack/query-core";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { PageBaseChannelSchema } from "@sunrise/backend-ng-channel";
import type { ChannelListItem } from "@sunrise/backend-types";
import type { ChannelId } from "@sunrise/backend-types-core";
import { activeChannelIdAtom } from "@sunrise/yallo-active-channel";
import { currentChannelGroupAtom } from "@sunrise/yallo-channel-group";

import { channelsForChannelGroupPerPageQueryAtom } from "./channels-for-channel-group-per-page.query.atom";
import { channelsForChannelListLegacyAtom } from "./channels-for-channel-list.legacy.atom";
import { CHANNEL_LIST_PAGE_SIZE } from "./constants";

// Expose an atom to set the boundaries of the view. We can either set a channelId or we can set a start and end index or nothing?
export const channelListBoundariesVisibleAtom = atom<
  | null
  | { type: "channel"; id: ChannelId }
  /**
   * We use the index to set the boundaries of the view.
   * The index is the index of the channel in the channel list.
   * The start and end are the start and end index of the channels that are visible.
   */
  | { type: "index"; start: number; end: number }
  /**
   * We use the page to set the boundaries of the view.
   * The page is the page number of the channels that are visible.
   */
  | { type: "page"; number: number }
>(null);

type QueryResponse = DefinedQueryObserverResult<PageBaseChannelSchema, Error>;
type ChannelListItemType = { type: "channel"; data: ChannelListItem };
export type ChannelsListOutput = {
  channels: (ChannelListItemType | null)[];
};

/**
 * Use the `channelListBoundariesVisibleAtom` to determine the boundaries of the view.
 *
 * Based on that atom we will be loading in different content.
 */
const channelsForChannelListAtomNg = atom<Promise<ChannelsListOutput>>(
  async (get) => {
    get(runEffectAtom);

    const selected = await get(currentChannelGroupAtom);

    // When legacy this will return an empty list.
    if (get(isLegacyBackendAtom) || !selected) {
      return {
        channels: [],
      } satisfies ChannelsListOutput;
    }

    const boundaries = get(channelListBoundariesVisibleAtom);

    // We have multiple ways of constructing the channels.
    switch (boundaries?.type) {
      case "channel": {
        return channelPagesToOutput([
          get(
            channelsForChannelGroupPerPageQueryAtom({
              channelGroupId: selected.id,
              reference: { type: "channel", id: boundaries.id },
            }),
          ),
        ]);
      }
      case "index": {
        // We need to get the page numbers that are visible.
        // But all we have are the indices of the channels that are visible.
        // So we need to convert the indices to page numbers.
        const firstPage = Math.floor(boundaries.start / CHANNEL_LIST_PAGE_SIZE);
        const lastPage = Math.floor(boundaries.end / CHANNEL_LIST_PAGE_SIZE);
        const pageIndexes = Array.from(
          { length: lastPage - firstPage + 1 },
          (_, index) => index + firstPage,
        );

        return channelPagesToOutput(
          pageIndexes.map((index) =>
            get(
              channelsForChannelGroupPerPageQueryAtom({
                channelGroupId: selected.id,
                reference: { type: "page", number: index + 1 },
              }),
            ),
          ),
        );
      }
      case "page": {
        return channelPagesToOutput([
          get(
            channelsForChannelGroupPerPageQueryAtom({
              channelGroupId: selected.id,
              reference: { type: "page", number: boundaries.number },
            }),
          ),
        ]);
      }
      default: {
        // When we do not have boundaries we return an empty list.
        // Since we do not yet know what to load.
        return {
          channels: [],
        } satisfies ChannelsListOutput;
      }
    }
  },
);

/**
 * Gets the raw page responses. Either multiple pages or a single page.
 * We will then construct the output based on the total number of channels.
 * Every channel that is not loaded in any of the pages will be replaced with a placeholder.
 *
 * @param pages
 * @returns
 */
async function channelPagesToOutput(
  pages: (Promise<QueryResponse> | QueryResponse)[],
): Promise<ChannelsListOutput> {
  const all = (await Promise.all(pages)).filter((item) => !!item.data.page);

  const first = all[0];
  if (!first) {
    return {
      channels: [],
    } satisfies ChannelsListOutput;
  }

  const total = first.data.total;
  if (!total) {
    return {
      channels: [],
    } satisfies ChannelsListOutput;
  }

  // Create array of placeholders for total number of channels
  const output: (ChannelListItemType | null)[] = Array(total).fill(null);

  // For each page of results, map the channels to the correct position
  for (const page of all) {
    if (!page.data?.page) {
      continue;
    }

    const startIndex = (page.data.page - 1) * CHANNEL_LIST_PAGE_SIZE;

    page.data.items.forEach((channel, index) => {
      const channelNumber = startIndex + index + 1;
      output[startIndex + index] = {
        type: "channel" as const,
        data: {
          id: channel.id as ChannelId,
          channelLogo: channel.logo,
          channelName: channel.name,
          channelNumber,
          stream: null, // Stream URL would need to be loaded separately
        },
      };
    });
  }

  return {
    channels: output,
  };
}

channelsForChannelListAtomNg.debugLabel = "channelsForChannelListAtom";

/**
 * This sets it to the currently playing playrequest's channelId if no boundary has been set yet.
 * That's so we can scroll to the currently playing channel and only load the page that contains it.
 */
const runEffectAtom = atomEffect((get, set) => {
  const boundaries = get(channelListBoundariesVisibleAtom);
  if (boundaries) {
    return;
  }

  let done = false;
  get(activeChannelIdAtom).then((id) => {
    if (done) {
      return;
    }

    if (!id) {
      // When there's no channel list to load we set the boundaries to the first page.
      set(channelListBoundariesVisibleAtom, {
        type: "page",
        number: 1,
      });
      return;
    }

    set(channelListBoundariesVisibleAtom, {
      type: "channel",
      id,
    });
  });

  return () => {
    done = true;
  };
});

export const channelsForChannelListAtom = atom<Promise<ChannelsListOutput>>(
  (get) => {
    if (get(isLegacyBackendAtom)) {
      return get(channelsForChannelListLegacyAtom);
    }

    return get(channelsForChannelListAtomNg);
  },
);
