import type { ReactNode } from "react";

import type { RecordingState } from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";

import { Tag } from "@/components/tag/tag";

import * as styles from "./details-tags.css";
import { ImdbRatingTag } from "./imdb-rating-tag";

export type DetailsTagsProps = {
  assetId: Nullable<AssetId>;
  tags: Nullable<string[]>;
  recordingStatus?: RecordingState | undefined;
} & CommonProps;

export function DetailsTags({
  assetId,
  tags,
  recordingStatus,
  "data-testid": testId,
}: DetailsTagsProps): ReactNode {
  return (
    <div className={styles.tags} data-testid={testId}>
      <ImdbRatingTag assetId={assetId} />
      {tags?.map((tag) => <Tag key={tag} className={styles.tag} text={tag} />)}
      {recordingStatus && (
        <Tag
          key="recording-status"
          className={styles.tag}
          data-testid={`${testId}.recording-tag`}
          icon={recordingStatus === "recorded" ? "recorded" : "recording"}
          text="REC"
        />
      )}
    </div>
  );
}
