import {
  addDays,
  format,
  getTime,
  intervalToDuration,
  isSameDay,
  subDays,
} from "date-fns";

import type { TimeDay } from "@sunrise/backend-types-core";
import type { TranslateFn } from "@sunrise/i18n";
import { isNil, type Nullable } from "@sunrise/utils";

export const HOUR_FORMAT = `HH:mm`;
export const DAY_FORMAT = "EEE dd.MM.yy";
export const DAY_FORMAT_WITHOUT_YEAR = "EE dd.MM.";

/**
 * The business requirement is that we should not have live progress if the item is in the future or in the past.
 * Else, we should indicate how far along the current epg item is.
 */
export function getLiveProgress(
  start: Date,
  end: Date,
  now: Date,
  /**
   * When force is set, we will return 100% if the item is in the past.
   */
  force?: boolean,
): number | undefined {
  const startTime = getTime(start);
  const endTime = getTime(end);
  const nowTime = getTime(now);

  // if program hasn't started today or is in the future day
  if (nowTime < startTime) return undefined;
  // if program has finished or is in the past
  if (nowTime > endTime) return force ? 100 : undefined;

  // NOTE: 8 gives us 0.125% steps to make the progress bar smoother
  return (
    Math.round(((nowTime - startTime) / (endTime - startTime)) * 100 * 8) / 8
  );
}

const DEFAULT_TIME_FORMAT = "00:00:00";
const pad = (value: number | string) => value.toString().padStart(2, "0");

export function getLiveElapsedTime(start: Date, now?: Nullable<Date>): string {
  if (!now) return DEFAULT_TIME_FORMAT;

  const startTime = getTime(start);
  const nowTime = getTime(now);

  if (nowTime < startTime) return DEFAULT_TIME_FORMAT;

  const {
    hours = "00",
    minutes = "00",
    seconds = "00",
  } = intervalToDuration({ start: startTime, end: nowTime });

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
}

/**
 * format streaming usage to 0 or 1 decimal place
 * @param seconds
 */
export function formatStreamingUsage(seconds: Nullable<number>): string {
  if (isNil(seconds) || isNaN(seconds)) return "";
  const hours = seconds / 60 / 60;
  return hours % 1 != 0 ? hours.toFixed(1) : hours.toFixed(0);
}

export function getDayOfWeekTranslation(
  start: Date,
  now: Date,
  t: TranslateFn,
  showYear: boolean,
): string {
  if (isSameDay(start, now)) {
    return t("today");
  }

  if (isSameDay(start, subDays(now, 1))) {
    return t("yesterday");
  }

  if (isSameDay(start, addDays(now, 1))) {
    return t("tomorrow");
  }

  return format(start, showYear ? DAY_FORMAT : DAY_FORMAT_WITHOUT_YEAR);
}

/**
 * Converts `Date` to domain specific format.
 *
 * This format is used to query Epg data from the backend.
 *
 * {@link https://entwicklungspark.atlassian.net/wiki/spaces/WT/pages/2682847233/Backend+DTOs+and+APIs#Full-(by-Id) Docs on DTOs and APIs}
 */
export function dateToTimeDay(date: Date): TimeDay {
  // tweak timezone offset so that we get the right value no matter what
  const cleanTimezoneDate = new Date(
    getTime(date) + date.getTimezoneOffset() * 60 * 1000,
  );

  return format(cleanTimezoneDate, "yyyy-MM-dd") as TimeDay;
}
