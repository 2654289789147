import { useCallback } from "react";
import { useSetAtom } from "jotai";

import type { Translatable } from "@sunrise/i18n";

import {
  actionDialogOpen,
  type ActionsDialog,
  dialogAtom,
} from "./dialog.atom";

type ConfirmationDialogType = {
  title: Translatable;
  description: Translatable;
  confirmationLabel: Translatable;
  onConfirm?: () => void;
  lastFocusKey: string;
  id: string;
  technicalErrorName?: string;
  type?: "destructive";
};

type CancellableConfirmationDialogType = ConfirmationDialogType & {
  rejectionLabel: Translatable;
  onRejection?: () => void;
  focusReject: boolean;
};

type UseConfirmationDialogShowArguments =
  | ConfirmationDialogType
  | CancellableConfirmationDialogType;

const NOOP = () => {
  // noop
};

export function useConfirmationDialog(): {
  showDialog: (props: UseConfirmationDialogShowArguments) => void;
} {
  const dispatchConfirmationDialog = useSetAtom(dialogAtom);

  const showDialog = useCallback(
    function (props: UseConfirmationDialogShowArguments) {
      // We just map the ConfirmationProps to the ActionsDialog.
      const dialog: ActionsDialog = {
        actions: [
          {
            action: props.onConfirm ?? NOOP,
            label: props.confirmationLabel,
            initialFocus: "focusReject" in props ? !props.focusReject : true,
            key: "confirm",
            variant: props.type === "destructive" ? "destructive" : undefined,
          },
        ],
        lastFocusKey: props.lastFocusKey,
        title: props.title,
        description: props.description,
        type: "actions",
        id: props.id,
        technicalErrorName: props.technicalErrorName,
      };

      if ("rejectionLabel" in props) {
        dialog.actions.push({
          action: props.onRejection ? props.onRejection : NOOP,
          label: props.rejectionLabel,
          initialFocus: props.focusReject,
          key: "cancel",
        });
      }

      dispatchConfirmationDialog(actionDialogOpen(dialog));
    },
    [dispatchConfirmationDialog, actionDialogOpen],
  );

  return {
    showDialog,
  };
}
