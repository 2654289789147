import { useCallback } from "react";
import { type PrimitiveAtom, useAtom } from "jotai";

/**
 *
 * @param atom atom to control which has an array of values it can cycle through.
 * @returns
 */
export function useCyclicControl<T>(
  atom: PrimitiveAtom<T>,
  values: readonly T[],
): { value: T; next: () => void } {
  const [current, setValue] = useAtom(atom);

  return {
    value: current,
    next: useCallback(() => {
      const currentIndex = values.indexOf(current);
      const nextIndex = (currentIndex + 1) % values.length;
      const nextValue = values[nextIndex];
      if (typeof nextValue !== "undefined") {
        setValue(nextValue);
      }
    }, [setValue, current, values]),
  };
}
