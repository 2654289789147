import { forwardRef, type ReactElement, type ReactNode } from "react";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";

import type { ChannelId } from "@sunrise/backend-types-core";

import { typography } from "@/styles/typography.css";

import { ChannelLogo } from "../channel-logo/channel-logo";
import { Icon } from "../icon";
import { ProgressBar } from "../progress-bar/progress-bar";
import { Spinner } from "../spinner/spinner";
import * as styles from "./channel-item.css";

type Program = {
  start: string;
  title: string;
};

export type ChannelItemProps = CommonProps &
  MouseNavigationProps & {
    currentEpg?: Program;
    id?: ChannelId;
    "data-focused"?: boolean;
    children?: ReactNode;
    nextEpg?: Program;
    channelLogo: string;
    channelNumber: number;
    liveProgress?: number;
    replayProgress?: number;
    zapping?: boolean;
    active?: boolean;
    skeleton?: boolean;
    focused?: boolean;
  };

export function ChannelItemSpinner({
  "data-testid": dataTestId,
  className,
  style,
  focusKey,
}: CommonProps & { focusKey?: string }): ReactElement {
  const { ref } = useFocusable({
    focusKey,
  });

  return (
    <div
      ref={ref}
      className={clsx(styles.channelItem, styles.center, className)}
      data-testid={`${dataTestId}.wrapper`}
      style={style}
    >
      <Spinner data-testid={dataTestId} />
    </div>
  );
}

/**
 * NOTE: This should be a generic component that has nothing to do with jotai.
 */
export const ChannelItem = forwardRef<HTMLLIElement, ChannelItemProps>(
  function ChannelItem(
    {
      "data-focused": dataFocused,
      id,
      children,
      focused,
      className,
      currentEpg,
      style,
      nextEpg,
      channelLogo,
      channelNumber,
      liveProgress,
      replayProgress,
      zapping,
      active,
      skeleton,
      onMouseEnter,
      onMouseClick,
      "data-testid": dataTestId,
    }: ChannelItemProps,
    ref,
  ): ReactNode {
    const content = skeleton ? null : (
      <>
        <div className={styles.channelNumberChange}>
          {zapping && (
            <Icon
              color="white"
              data-testid={`${dataTestId}.zapping-up`}
              name="dropdown-up"
            />
          )}
          <span
            className={clsx([typography.h7.bold, styles.channelNumber])}
            data-testid={`${dataTestId}.channel-number`}
          >
            {channelNumber}
          </span>
          {zapping && (
            <Icon
              color="white"
              data-testid={`${dataTestId}.zapping-down`}
              name="dropdown-down"
            />
          )}
        </div>

        <div className={styles.channelLogo}>
          {channelLogo ? (
            <ChannelLogo
              className={styles.logo}
              data-testid={`${dataTestId}.channel-logo`}
              url={channelLogo}
            />
          ) : null}
        </div>

        <div
          className={styles.channelEpgs}
          data-testid={dataTestId}
          // TODO: remove data-channel-id and replace assertion in e2e tests with
          // actual visible text
          data-channel-id={id}
        >
          <div
            className={clsx([
              typography.h6.bold,
              styles.channelProgram,
              active && styles.active,
            ])}
            data-testid={`${dataTestId}.current-epg`}
          >
            <span className={styles.startTime}>{currentEpg?.start}</span>
            <span
              className={styles.epgTitle}
              data-testid={`${dataTestId}.current-epg.title`}
            >
              {currentEpg?.title}
            </span>
          </div>
          <ProgressBar
            className={styles.progressBar}
            data-testid={`${dataTestId}.progress-bar`}
            liveProgress={liveProgress}
            replayProgress={replayProgress}
          />
          <div
            className={clsx([
              styles.channelProgram,
              typography.h6.regular,
              styles.nextChannelProgram,
            ])}
            data-testid={`${dataTestId}.next-epg`}
          >
            <span className={styles.startTime}>{nextEpg?.start}</span>
            <span
              className={styles.epgTitle}
              data-testid={`${dataTestId}.next-epg.title`}
            >
              {nextEpg?.title}
            </span>
          </div>
        </div>
      </>
    );

    return (
      <li
        ref={ref}
        className={clsx([
          className,
          styles.channelItem,
          focused && styles.focused,
        ])}
        data-active={active}
        data-focused={dataFocused}
        data-testid={`${dataTestId}.item`}
        style={style}
        onClick={onMouseClick}
        onMouseEnter={onMouseEnter}
      >
        {children ? <div className={styles.breakBorder}>{children}</div> : null}
        {content}
      </li>
    );
  },
);
