import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import {
  actionDialogClose,
  dialogAtom,
  selectCurrentlyOpenedDialog,
} from "@sunrise/dialogs";
import { errorAtom } from "@sunrise/error";
import { isOfflineAtom } from "@sunrise/process-visibility";
import {
  networkConnectionErrorEnabledAtom,
  OfflineError,
} from "@sunrise/yallo-error-mapping";

import { errorToShowInPlayerAtom } from "./error-to-show-in-player.atom";
import { shouldHideErrorDueToRecoveryFnAtom } from "./should-hide-error-due-to-recovery-fn.atom";

/**
 * Determines which error to show to endusers in the dialog.
 *
 * It's possible some errors are not shown.
 */
export const errorToShowInDialogAtom = atom<{
  error: Error | null;
  hidden: boolean;
}>((get) => {
  get(effectRemoveOfflineErrorModal);

  // When we are offline, we immediately show that we are offline.
  // But only do this when we are configured to do so.
  if (get(networkConnectionErrorEnabledAtom) && get(isOfflineAtom)) {
    return { error: new OfflineError(), hidden: false };
  }

  // When an error should be shown in the player, we should not show it in the dialog.
  // We also need to assume that the player will then trace it to Sentry etc.
  const inPlayer = get(errorToShowInPlayerAtom);
  const error = get(errorAtom);
  if (inPlayer.error && error === inPlayer.error) {
    return { error: null, hidden: true };
  }

  if (!error) return { error: null, hidden: false };

  const shouldHideErrorDueToRecovery = get(shouldHideErrorDueToRecoveryFnAtom);

  return {
    error,
    hidden: shouldHideErrorDueToRecovery(error),
  };
});

/**
 * When we have an offline error present in the dialog, we want to remove it when we are no longer offline.
 */
const effectRemoveOfflineErrorModal = atomEffect((get, set) => {
  const isOffline = get(isOfflineAtom);
  const dialog = get(selectCurrentlyOpenedDialog);

  if (
    !isOffline &&
    dialog?.type === "actions" &&
    dialog.technicalErrorName === "no_internet_connection"
  ) {
    // We also want to clear any underlying errors.
    // They were probably caused by being offline anyway.
    set(errorAtom, null);
    set(dialogAtom, actionDialogClose({ id: dialog.id }));
  }
});
