import {
  endpoints,
  type LegacySingleRecording,
  type PagedResponse,
  type RecordingGroupSeries,
  type RecordingGroupSort,
} from "@sunrise/backend-types";
import type { RecordingGroupId } from "@sunrise/backend-types-core";
import type { PrivateApiClient } from "@sunrise/http-client";
import type { Nullable } from "@sunrise/utils";

export async function fetchRecordingGroupItems(
  host: string,
  httpClient: PrivateApiClient,
  recordingGroupId: RecordingGroupId,
  traverseChildGroups: boolean,
  sort: RecordingGroupSort,
  page = 1,
  pageSize = 12,
): Promise<Nullable<PagedResponse<LegacySingleRecording>>> {
  const url = new URL(
    endpoints.recordingGroups.recordingsGroupItems(
      host,
      recordingGroupId,
      traverseChildGroups,
    ),
  );
  url.searchParams.append("pagesize", pageSize.toString());
  url.searchParams.append("page", page.toString());
  url.searchParams.append(sort.field, sort.direction);

  const { data } = await httpClient.get<PagedResponse<LegacySingleRecording>>(
    url.href,
  );
  return data;
}

export async function fetchRecordingGroup(
  host: string,
  httpClient: PrivateApiClient,
  recordingGroupId: RecordingGroupId,
) {
  const url = endpoints.recordingGroups.recordingsGroup(host, recordingGroupId);
  const { data } = await httpClient.get<RecordingGroupSeries>(url);

  return data;
}
