import { getTime } from "date-fns";
import { parse, toSeconds } from "iso8601-duration";
import { min } from "lodash";

import type {
  ContinueWatchingRecordingSchema,
  ContinueWatchingReplaySchema,
} from "@sunrise/backend-ng-continue-watching";
import { ContinueWatchingType } from "@sunrise/backend-ng-continue-watching";
import type {
  AssetId,
  ChannelId,
  EPGEntryId,
  TimeISOString,
} from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";

import type { MappedContinueWatching } from "../types";

const calculateProgressPercentage = (
  start: TimeISOString,
  end: TimeISOString,
  progress: string,
) => {
  const millisecondsFromStart = toSeconds(parse(progress)) * 1000;
  const diffsBetweenStartEnd = getTime(end) - getTime(start);
  return min([(millisecondsFromStart * 100) / diffsBetweenStartEnd, 100]) ?? 0;
};

export function mapBackendContinueWatchingNg(
  continueWatchingEntry:
    | ContinueWatchingRecordingSchema
    | ContinueWatchingReplaySchema,
): Nullable<MappedContinueWatching> {
  if (
    continueWatchingEntry.type ===
      ContinueWatchingType.ContinueWatchingContinueWatchingRecording &&
    "recording" in continueWatchingEntry
  ) {
    return {
      type: "continueWatchingRecording",
      fullyWatched: continueWatchingEntry.fully_watched,
      recordingId: continueWatchingEntry.recording.id,

      title: continueWatchingEntry.recording.asset.title,
      channelLogo: continueWatchingEntry.recording.channel_logo ?? "",
      channelName: continueWatchingEntry.recording.channel_name ?? "",
      coverImageUrl: continueWatchingEntry.recording.asset.poster_url,
      epgStart: continueWatchingEntry.recording.epg_start,
      epgEnd: continueWatchingEntry.recording.epg_end,
      channelId: continueWatchingEntry.recording.channel_id as ChannelId,
      assetId: continueWatchingEntry.recording.asset.id as AssetId,
      progressPercentage: calculateProgressPercentage(
        continueWatchingEntry.recording.epg_start,
        continueWatchingEntry.recording.epg_end,
        continueWatchingEntry.progress,
      ),
    };
  } else if (
    continueWatchingEntry.type ===
      ContinueWatchingType.ContinueWatchingContinueWatchingLiveContinueWatchingTimeshiftContinueWatchingReplay &&
    "epg_entry" in continueWatchingEntry
  ) {
    return {
      type: "continueWatchingReplay",
      epgId: continueWatchingEntry.epg_entry.id as EPGEntryId,
      title: continueWatchingEntry.epg_entry.asset.title,
      channelLogo: continueWatchingEntry.epg_entry.channel.logo,
      channelName: continueWatchingEntry.epg_entry.channel.name,
      coverImageUrl: continueWatchingEntry.epg_entry.asset.poster_url,
      fullyWatched: continueWatchingEntry.fully_watched,
      epgStart: continueWatchingEntry.epg_entry.start,
      epgEnd: continueWatchingEntry.epg_entry.end,
      channelId: continueWatchingEntry.epg_entry.channel.id as ChannelId,
      assetId: continueWatchingEntry.epg_entry.asset.id as AssetId,
      progressPercentage: calculateProgressPercentage(
        continueWatchingEntry.epg_entry.start,
        continueWatchingEntry.epg_entry.end,
        continueWatchingEntry.progress,
      ),
    };
  }

  return null;
}
