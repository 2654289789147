import type { AxiosError } from "axios";
import { atom } from "jotai";

import type { RefreshTokens, RetryFunction } from "@sunrise/backend-core";
import type { Language } from "@sunrise/backend-types-core";
import type { BaseError } from "@sunrise/error";
import type { Nullable } from "@sunrise/utils";

type NgHttpClientConfig = {
  /**
   * Where should we point all the NG apis towards.
   */
  baseUrl: string;
  /**
   * Used by the RefreshTokenOnErrorInterceptor.
   * Determines if the error received from the backend means that the backend does not know the user.
   */
  isNotKnownUserError?: (error: AxiosError) => boolean;
  /**
   * Callback to log which error we are retrying.
   * The RefreshTokenOnErrorInterceptor will automatically retry an error when it has failed due
   * to an expired token and the refreshing of the token succeeded.
   */
  onRetry?: (error: AxiosError) => void;
  /**
   * The RefreshTokenOnErrorInterceptor can ask us to refresh the tokens.
   */
  doRefreshTokens: RefreshTokens;
  /**
   * The RefreshTokenOnErrorInterceptor will sometimes determine that the tokens are no longer valid.
   * Calling this function should erase all the tokens (access & refresh) and basically log out the user.
   */
  resetTokens: (error: BaseError) => void;
  /**
   * Returns the current unparsed access token.
   */
  getAccessToken: () => Nullable<string>;
  /**
   * Returns the current unparsed refresh token.
   */
  getRefreshToken: () => Nullable<string>;
  /**
   * When we refresh the endpoint, we will receive new tokens.
   * When we consider the tokens usable this callback is called and we should store the tokens for future usage.
   */
  setTokens: (
    accessToken: string,
    refreshToken: string,
    wsToken: string | null,
  ) => void;

  /**
   * Used by the manualRetryAxiosInterceptor. To indicate which URLs need to be retried manually.
   * So the user will have to give their consent to retry.
   */
  isRetryableError?: (url: string | undefined) => boolean;
  /**
   * Used by the manualRetryAxiosInterceptor.
   * This is a backoff mechanism for manualRetryAxiosInterceptor so the user can't hit retry before the delay has occurred.
   */
  getRetryDelayInSeconds?: () => Nullable<number>;
  /**
   * used by the manualRetryAxiosInterceptor. Allows the UI to confirm that we can continue with the retry.
   */
  awaitRetry?: RetryFunction;

  /**
   * Used by the injectAcceptedLanguageHeaderInterceptor.
   * This will return the language that is to be used in the headers.
   */
  getLanguage: () => Language;
};

export const ngHttpClientConfigAtom = atom<NgHttpClientConfig | null>(null);
