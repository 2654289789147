import type {
  InAppKeyboardKeyId,
  KeyboardNavigationKeyCodeKeyIdDict,
} from "./keyboard-navigation.store";

export function isArrowKey(
  keyId: InAppKeyboardKeyId,
): keyId is "ArrowLeft" | "ArrowRight" | "ArrowUp" | "ArrowDown" {
  return (
    keyId === "ArrowLeft" ||
    keyId === "ArrowRight" ||
    keyId === "ArrowUp" ||
    keyId === "ArrowDown"
  );
}

export function isNumberKey(keyId: InAppKeyboardKeyId): boolean {
  return !isNaN(Number(keyId));
}

export function isInputFieldFocused() {
  const tagName = document.activeElement?.tagName?.toLowerCase() ?? "";
  return tagName === "input" || tagName === "textarea";
}

export function getKeyId(
  dict: KeyboardNavigationKeyCodeKeyIdDict,
  e: KeyboardEvent,
): InAppKeyboardKeyId {
  return (
    dict[e.code in dict ? e.code : e.key] ??
    dict[e.keyCode in dict ? e.keyCode : e.which] // NOTE: backwards compatibility for webOS
  );
}
