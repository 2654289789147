import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { ChannelId, TimeDay } from "@sunrise/backend-types-core";

import { channelEpgCollectionByIdLegacyAtom } from "./legacy/channel-epg-collection-by-id.legacy.atom";
import { epgsCollectionByChannelIdNgAtom } from "./ng/channel-epgs-collection-by-id.ng.atom";

export const selectEpgCollectionPerDayAtom = atomFamily(
  (param: { day: TimeDay; channelId: ChannelId }) => {
    const innerAtom = atom(async (get) => {
      if (get(isLegacyBackendAtom)) {
        return get(
          channelEpgCollectionByIdLegacyAtom({
            channelId: param.channelId,
            day: param.day,
          }),
        );
      }

      return get(
        epgsCollectionByChannelIdNgAtom({
          channelId: param.channelId,
          day: param.day,
        }),
      );
    });

    innerAtom.debugLabel = `selectEPGCollectionPerDay(${param.day}, ${param.channelId})`;
    return innerAtom;
  },
);
