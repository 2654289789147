import { initializeApp } from "firebase/app";

export const firebaseApp = initializeApp({
  apiKey: process.env["VITE_FIREBASE_API_KEY"],
  projectId: process.env["VITE_FIREBASE_PROJECT_ID"],
  appId: process.env["VITE_FIREBASE_APP_ID"],
  measurementId: process.env["VITE_FIREBASE_MEASUREMENT_ID"],
  authDomain: process.env["VITE_FIREBASE_AUTH_DOMAIN"],
  storageBucket: process.env["VITE_FIREBASE_STORAGE_BUCKET"],
  messagingSenderId: process.env["VITE_FIREBASE_MESSAGING_SENDER_ID"],
  databaseURL: process.env["VITE_FIREBASE_DATABASE_URL"],
});
