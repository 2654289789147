import { atom } from "jotai";

import {
  adControlsVisible,
  selectShouldVideoAdBePlaying,
  videoAdsAtom,
} from "@sunrise/ads";
import {
  isAfterIdleMarginAtom,
  playerControlsVisibleAtom,
} from "@sunrise/yallo-player-controls";

import { tvIsFocusedAtom } from "@/modules/tv/tv-is-focused.atom";

import { zappingAllowedAtom } from "./zapping-allowed.atom";

export const zappingThroughArrowsAllowedAtom = atom((get) => {
  return (
    get(zappingAllowedAtom) &&
    get(tvIsFocusedAtom) &&
    !get(adControlsVisible) &&
    !get(selectShouldVideoAdBePlaying(videoAdsAtom)) &&
    !get(playerControlsVisibleAtom) &&
    get(isAfterIdleMarginAtom)
  );
});
