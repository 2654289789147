import type { InfiniteData } from "@tanstack/query-core";
import { atomFamily } from "jotai/utils";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { PageWithItems } from "@sunrise/backend-ng-core";
import type {
  Availability,
  HTTPValidationError,
} from "@sunrise/backend-ng-epg";
import { ngEpgApiAtom } from "@sunrise/backend-ng-epg";
import {
  assetDetailEpisodesAvailabilityAtom,
  assetDetailEpisodesChannelAtom,
  assetDetailEpisodesSeasonAtom,
  assetDetailEpisodesSortAtom,
  SortingOption,
} from "@sunrise/backend-ng-recordings";
import { queryKeys } from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";

import type { MappedEpg } from "../types";
import { mapBackendEpgNg } from "./map-backend-epg.ng";

enum EpgEpisodeSortingOption {
  AiringDateAscending = "airing_date_ascending",
  AiringDateDescending = "airing_date_descending",
  SubtitleAscending = "subtitle_ascending",
  SubtitleDescending = "subtitle_descending",
}

const genericToEpgEpisodeSorting = {
  [SortingOption.DateAscending]: EpgEpisodeSortingOption.AiringDateAscending,
  [SortingOption.DateDescending]: EpgEpisodeSortingOption.AiringDateDescending,
  [SortingOption.TitleAscending]: EpgEpisodeSortingOption.SubtitleAscending,
  [SortingOption.TitleDescending]: EpgEpisodeSortingOption.SubtitleDescending,
};

const EMPTY = { items: [], total: null, page: null, size: null };

export const episodesEpgsBySeriesAssetIdNgAtom = atomFamily(
  (assetId: AssetId) => {
    return atomWithInfiniteQuery<
      PageWithItems<MappedEpg>,
      HTTPValidationError,
      InfiniteData<PageWithItems<MappedEpg>>,
      ReturnType<typeof queryKeys.assetEpisodesById>,
      number
    >((get) => {
      const ngApi = get(ngEpgApiAtom);
      const language = get(currentLanguageAtom);
      const sortBy = get(assetDetailEpisodesSortAtom(assetId));
      const availability = get(assetDetailEpisodesAvailabilityAtom(assetId));
      const channel = get(assetDetailEpisodesChannelAtom(assetId));
      const season = get(assetDetailEpisodesSeasonAtom(assetId)) ?? undefined;

      return {
        initialPageParam: 1,
        queryKey: queryKeys.assetEpisodesById(
          assetId,
          language,
          sortBy,
          availability,
          channel,
          season,
        ),
        queryFn: async ({ pageParam = 1 }) => {
          if (!assetId) return EMPTY;

          const { data } =
            await ngApi.epg.getSeriesAssetEpisodesEpgV1AssetsAssetIdEpisodesGet(
              assetId,
              {
                page: pageParam,
                size: 20,
                sort_by: sortBy
                  ? genericToEpgEpisodeSorting[sortBy]
                  : undefined,
                availability: availability
                  ? (availability as Availability)
                  : undefined,
                channel_id: channel,
                season_number: season,
              },
            );

          return {
            ...data,
            items: data.items.map(mapBackendEpgNg),
          };
        },
        getNextPageParam: (lastPage) => {
          if (isNil(lastPage) || !lastPage.pages || !lastPage.page)
            return undefined;

          const { page, pages } = lastPage;
          return page < pages ? page + 1 : undefined;
        },
        staleTime: Infinity,
        cacheTime: Infinity,
      };
    });
  },
);
