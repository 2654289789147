import { type ReactNode, useCallback, useEffect } from "react";

import type { ActionDialogAction } from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";
import { useIdle } from "@sunrise/utils";

import { FocusButton } from "@/components";

import * as styles from "./side-bar-actions-dialog.css";

export type SideBarActionsButtonProps = ActionDialogAction & {
  doClose: () => void;
  index: number;
};

export function SideBarActionsButton({
  action,
  label,
  index,
  closeDisabled,
  execAfterTimeout,
  doClose,
}: SideBarActionsButtonProps): ReactNode {
  const t = useTranslatable();
  const shouldExec = useIdle(execAfterTimeout);

  const performAction = useCallback(() => {
    action();

    if (!closeDisabled) {
      doClose();
    }
  }, [doClose, closeDisabled, action]);

  useEffect(() => {
    if (shouldExec) {
      performAction();
    }
  }, [shouldExec, performAction]);

  return (
    <FocusButton
      className={styles.button}
      focusKey={`side-bar-actions-dialog.button.${index}`}
      text={t(label) ?? `button ${index}`}
      onEnterPress={performAction}
    />
  );
}
