import { atom } from "jotai";

import { selectedChannelGroupWithAllChannels } from "@sunrise/yallo-channel-group";

import type { GuideChannelItem } from "../guide-data-channels.atom";
import { visibleChannelsAtom } from "../guide-visible-data.atom";

/**
 * Ye old legacy implementatiopn which is much much simpler since we don't have to deal with the pagination.
 */
export const guideDataChannelsLegacyAtom = atom<Promise<GuideChannelItem[]>>(
  async (get) => {
    const allChannelsForChannelGroup = await get(
      selectedChannelGroupWithAllChannels,
    );
    const visible = get(visibleChannelsAtom);
    function isNeeded(index: number) {
      if (!visible) {
        return false;
      }

      return visible.start <= index && index <= visible.end;
    }

    return allChannelsForChannelGroup.map((channel, index) => ({
      data: {
        id: channel.id,
        channelNumber: channel.order,
        logo: channel.logo,
        items: [],
        name: channel.name,
      },
      needed: isNeeded(index),
    }));
  },
);
