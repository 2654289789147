import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { debounce } from "lodash";

import {
  actionPlayerSetSeekTime,
  playerAtom,
  selectPlayerCurrentTimeIncludingSeekTime,
} from "@sunrise/player";
import { isNil } from "@sunrise/utils";
import { getPlayerManager } from "@sunrise/yallo-common-player-manager";

const DEBOUNCE_TIME = 200;

type QuickSeekOptions = {
  forwardSeconds: number;
  backwardSeconds: number;
  debounceTime?: number;
};

export function useQuickSeek(options: QuickSeekOptions) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceActualSeek = useCallback(
    debounce(
      (time: number) => {
        getPlayerManager().seekToInCurrentPlayRequest(time);
      },
      options.debounceTime ?? DEBOUNCE_TIME,
      {
        leading: false,
        trailing: true,
      },
    ),
    [options.debounceTime],
  );

  const seek = useAtomCallback(
    useCallback(
      async (get, set, seconds: number) => {
        const currentTime = get(selectPlayerCurrentTimeIncludingSeekTime);

        if (isNil(currentTime)) {
          return;
        }

        const newTime = currentTime + seconds;

        // Do not allow seeking lower than the start of the stream.
        // We need to first wait until we receive a new stream in which we can actually seek backwards again.
        if (newTime <= seconds * -1) {
          return;
        }

        const time =
          await getPlayerManager().couldSeekToInCurrentPlayRequest(newTime);

        if (!time) {
          return;
        }

        // seek to time, and then later on update the actual player.
        // Do not allow setting values lower than 0.
        set(playerAtom, actionPlayerSetSeekTime(Math.max(time, 0)));

        debounceActualSeek(time);

        // Instantly flush the debounce when we are seeking lower than 0.
        if (time < 0) {
          debounceActualSeek.flush();
        }
      },
      [debounceActualSeek],
    ),
  );

  return {
    forward: useCallback(
      () => seek(options.forwardSeconds),
      [seek, options.forwardSeconds],
    ),
    backward: useCallback(
      () => seek(-options.backwardSeconds),
      [seek, options.backwardSeconds],
    ),
  };
}
