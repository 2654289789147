import { atom } from "jotai";

import type { ProgramResult, Recommendations } from "@sunrise/backend-types";
import { continueWatchingKnownItemsAtom } from "@sunrise/yallo-continue-watching";

import { recommendationsQueryResultAtom } from "./recommendations.legacy.query";

// Note: quick way to include/exclude continue watching items
const INCLUDE_CONTINUE_WATCHING = true;

export const continueWatchingDataAtom = atom<Promise<ProgramResult[]>>(
  async (get) => {
    const cwItems = get(continueWatchingKnownItemsAtom);
    const cwData: ProgramResult[] =
      cwItems.length > 0
        ? [
            {
              title: "recommendations_continue_watching", // translation key
              category: "continueWatching",
              kind: "normal",
              items: cwItems,
            },
          ]
        : [];

    return cwData;
  },
);

export const recommendationsDataLegacyAtom = atom<Promise<Recommendations>>(
  async (get) => {
    if (INCLUDE_CONTINUE_WATCHING) {
      const [cwData, recData] = await Promise.all([
        get(continueWatchingDataAtom),
        get(recommendationsQueryResultAtom),
      ]);
      return [...cwData, ...recData.data.result];
    } else {
      const { data } = await get(recommendationsQueryResultAtom);
      return data.result;
    }
  },
);
