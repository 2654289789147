import { useCallback } from "react";
import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue, useStore } from "jotai";

import type { ChannelGroupId } from "@sunrise/backend-types-core";
import {
  actionDialogClose,
  actionDialogOpen,
  dialogAtom,
} from "@sunrise/dialogs";
import { useTranslator } from "@sunrise/translator";
import { type Nullable } from "@sunrise/utils";
import {
  currentChannelGroupAtom,
  preferredChannelGroupIdAtom,
  selectableChannelGroupsAtom,
} from "@sunrise/yallo-channel-group";

/**
 * A hook depending on the dialogs module that will return the currently selected channel group's id and name + a function to trigger a dialog to change the channel group.
 *
 * @returns
 */
export function useChannelGroupSelector(): {
  current: Nullable<{
    name: string;
    id: ChannelGroupId;
  }>;
  askToChange: () => Promise<void>;
} {
  const current = useAtomValue(currentChannelGroupAtom);
  const store = useStore();
  const t = useTranslator();

  const askToChange = useCallback(async (): Promise<void> => {
    const options = await store.get(selectableChannelGroupsAtom);

    store.set(
      dialogAtom,
      actionDialogOpen({
        id: "channel-group-selector",
        type: "list",
        title: t("channelListExtendedTitle"),
        buttonTextAlign: "left",
        onButtonClick: (value) => {
          store.set(preferredChannelGroupIdAtom, value as ChannelGroupId);
          store.set(dialogAtom, actionDialogClose());
        },
        sections: [
          {
            options: options.map((group) => ({
              label: group.name,
              value: group.id,
            })),
          },
        ],
        activeOptions: current ? [current.id] : [],
        lastFocusKey: getCurrentFocusKey(),
      }),
    );
  }, [store, current, t]);

  return {
    current,
    askToChange,
  };
}
