import { useCallback, useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import { type Nullable } from "@sunrise/utils";

import { triggerNewPauseAd } from "../ads.init";
import {
  actionPauseAdsErrored,
  actionPauseAdsSetShowing,
  actionPauseAdsTerminate,
  pauseAdsAtom,
  selectPauseAdDimensions,
  selectPauseAdImage,
  selectPauseAdSkipTimeReached,
} from "../pause-ads.atom";

type UsePauseAdsResult = {
  url: Nullable<string>;
  width: Nullable<number>;
  height: Nullable<number>;
  onShown: () => void;
  onError: () => void;
  dismiss: () => void;
  shouldShowClose: boolean;
};

export function usePauseAds(): UsePauseAdsResult {
  const url = useAtomValue(selectPauseAdImage);
  const { width = null, height = null } = useAtomValue(selectPauseAdDimensions);
  const dispatchPauseAds = useSetAtom(pauseAdsAtom);
  const shouldShowClose = useAtomValue(selectPauseAdSkipTimeReached);

  useEffect(() => {
    triggerNewPauseAd();
    return () => dispatchPauseAds(actionPauseAdsTerminate());
  }, [dispatchPauseAds]);

  return {
    url,
    width,
    height,
    shouldShowClose,
    onShown: useCallback(() => {
      dispatchPauseAds(actionPauseAdsSetShowing());
    }, [dispatchPauseAds]),
    onError: useCallback(() => {
      dispatchPauseAds(actionPauseAdsErrored());
    }, [dispatchPauseAds]),
    dismiss: useCallback(() => {
      dispatchPauseAds(actionPauseAdsTerminate());
    }, [dispatchPauseAds]),
  };
}
