/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AssetFilterSchema */
export interface AssetFilterSchema {
  /**
   * FilterChannelSchemas
   * List of FilterChannelSchema to filter to
   */
  channels: FilterChannelSchema[];
  /**
   * Season schemas
   * List of season schemas to filter to
   */
  seasons: FilterSeasonSchema[];
  /**
   * list of FilterSeasonSchema
   * List of FilterSeasonSchema to filter to
   */
  availability: FilterAvailabilitySchema[];
}

/** AssetType */
export enum AssetType {
  Series = "series",
  Seriesepisode = "seriesepisode",
  Seriesseason = "seriesseason",
  Movie = "movie",
  Program = "program",
  Unknown = "unknown",
}

/** Availability */
export enum Availability {
  Available = "available",
  Upcoming = "upcoming",
  All = "all",
}

/** ChannelStreamProvider */
export interface ChannelStreamProvider {
  /**
   * Stream Provider ID
   * The stream provider id of a channel
   */
  provider_id: string;
  /**
   * Stream Provider Name
   * The stream provider name of a channel
   */
  provider_name: string;
}

/** EpgEntrySchema */
export interface EpgEntrySchema {
  /**
   * EPG ID
   * The id of the EPG
   */
  id: string;
  /**
   * Start
   * The start time of the epg
   * @format date-time
   */
  start: TimeISOString;
  /**
   * End
   * The end time of the epg
   * @format date-time
   */
  end: TimeISOString;
  asset: ReducedAssetSchema;
  channel: ReducedChannelSchema;
  /**
   * Replay Stream Service URL
   * The replay URL of the stream service
   */
  readonly stream_service_url: string;
}

/** EpgEpisodeSortingOption */
export enum EpgEpisodeSortingOption {
  AiringDateAscending = "airing_date_ascending",
  AiringDateDescending = "airing_date_descending",
  SubtitleAscending = "subtitle_ascending",
  SubtitleDescending = "subtitle_descending",
}

/** FilterAvailabilitySchema */
export interface FilterAvailabilitySchema {
  /**
   * type
   * type
   */
  type: Availability;
  /**
   * amount number
   * amount
   */
  count: number;
}

/** FilterChannelSchema */
export interface FilterChannelSchema {
  /**
   * Channel id
   * channel id
   */
  id: string;
  /**
   * Logo
   * Logo of the channel
   */
  logo: string;
  /**
   * Name
   * Name of the channel
   */
  name: string;
}

/** FilterSeasonSchema */
export interface FilterSeasonSchema {
  /**
   * season id
   * season id
   */
  id: string;
  /**
   * season number
   * Season number
   */
  season_number: number;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** LiveNextSchema */
export interface LiveNextSchema {
  live: EpgEntrySchema;
  next: EpgEntrySchema;
}

/** Page[EpgEntrySchema] */
export interface PageEpgEntrySchema {
  /** Items */
  items: EpgEntrySchema[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** ReducedAssetSchema */
export interface ReducedAssetSchema {
  /**
   * Asset ID
   * The id of the asset
   */
  id: string;
  /**
   * Asset Title
   * The title of the asset
   */
  title: string;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   * @default ""
   */
  subtitle?: string | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url: string;
  /**
   * Season Number
   * The season number of the series episode
   */
  season_number?: number | null;
  /**
   * Episode Number
   * The episode number of the series episode
   */
  episode_number?: number | null;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Type
   * The type of the asset (movie, series episode...)
   * @default "unknown"
   */
  type?: AssetType | null;
  /**
   * Asset Recording Available
   * Is it possible to record this asset as a series?
   */
  readonly asset_recording_available: boolean;
}

/** ReducedChannelSchema */
export interface ReducedChannelSchema {
  /**
   * Channel ID
   * The id of the channel
   */
  id: string;
  /**
   * Channel Name
   * The name of the channel
   */
  name: string;
  /**
   * Channel Logo
   * The logo of the channel
   */
  logo: string;
  /**
   * Live Stream Service URL
   * The live URL of the stream service
   */
  readonly stream_service_url: string;
}

/** ValidLanguages */
export enum ValidLanguages {
  En = "en",
  De = "de",
  Fr = "fr",
  It = "it",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";
import type { TimeISOString } from "@sunrise/backend-types-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  epg = {
    /**
     * No description
     *
     * @tags epg-entries
     * @name EpgEntryListEpgV1EpgEntriesGet
     * @summary Epg Entry List
     * @request GET:/epg/v1/epg-entries
     * @secure
     */
    epgEntryListEpgV1EpgEntriesGet: (
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageEpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/epg-entries`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags epg-entries
     * @name EpgEntryDetailEpgV1EpgEntriesEpgEntryIdGet
     * @summary Epg Entry Detail
     * @request GET:/epg/v1/epg-entries/{epg_entry_id}
     * @secure
     */
    epgEntryDetailEpgV1EpgEntriesEpgEntryIdGet: (epgEntryId: string, params: RequestParams = {}) =>
      this.http.request<EpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/epg-entries/${epgEntryId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags epg-entries
     * @name NextEpgEntryDetailEpgV1EpgEntriesEpgEntryIdNextGet
     * @summary Next Epg Entry Detail
     * @request GET:/epg/v1/epg-entries/{epg_entry_id}/next
     * @secure
     */
    nextEpgEntryDetailEpgV1EpgEntriesEpgEntryIdNextGet: (epgEntryId: string, params: RequestParams = {}) =>
      this.http.request<EpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/epg-entries/${epgEntryId}/next`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags epg-entries
     * @name PreviousEpgEntryDetailEpgV1EpgEntriesEpgEntryIdPreviousGet
     * @summary Previous Epg Entry Detail
     * @request GET:/epg/v1/epg-entries/{epg_entry_id}/previous
     * @secure
     */
    previousEpgEntryDetailEpgV1EpgEntriesEpgEntryIdPreviousGet: (epgEntryId: string, params: RequestParams = {}) =>
      this.http.request<EpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/epg-entries/${epgEntryId}/previous`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns other airings of the provided epg entry id
     *
     * @tags epg-entries
     * @name GetEpgEntryByAssetEpgV1EpgEntriesEpgEntryIdAiringsGet
     * @summary Get Epg Entry By Asset
     * @request GET:/epg/v1/epg-entries/{epg_entry_id}/airings
     * @secure
     */
    getEpgEntryByAssetEpgV1EpgEntriesEpgEntryIdAiringsGet: (
      epgEntryId: string,
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageEpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/epg-entries/${epgEntryId}/airings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is deprecated. Please use /epg-entries/<id>/airings instead
     *
     * @tags assets
     * @name GetEpgEntriesByAssetEpgV1AssetsAssetIdAiringsGet
     * @summary Get Epg Entries By Asset
     * @request GET:/epg/v1/assets/{asset_id}/airings
     * @deprecated
     * @secure
     */
    getEpgEntriesByAssetEpgV1AssetsAssetIdAiringsGet: (
      assetId: string,
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageEpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/assets/${assetId}/airings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description It's possible to provide either a Series asset id or a SeriesSeason asset id. If an asset id of another type is passed, then the response will be empty. * Availability (available, upcoming, all)  and start and end are mutually exclusive * airing_date_ascending == Oldest aired, airing_date_descending == Newest aired
     *
     * @tags assets
     * @name GetSeriesAssetEpisodesEpgV1AssetsAssetIdEpisodesGet
     * @summary Get Series Asset Episodes
     * @request GET:/epg/v1/assets/{asset_id}/episodes
     * @secure
     */
    getSeriesAssetEpisodesEpgV1AssetsAssetIdEpisodesGet: (
      assetId: string,
      query?: {
        /**
         * Start
         * @format date-time
         */
        start?: TimeISOString;
        /**
         * End
         * @format date-time
         */
        end?: TimeISOString;
        /** Channel Id */
        channel_id?: string;
        /** Season Number */
        season_number?: number;
        /** Availability */
        availability?: Availability | null;
        /** @default "airing_date_ascending" */
        sort_by?: EpgEpisodeSortingOption;
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageEpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/assets/${assetId}/episodes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name GetFiltersBySeriesAssetEpgV1AssetsAssetIdFiltersGet
     * @summary Get Filters By Series Asset
     * @request GET:/epg/v1/assets/{asset_id}/filters
     * @secure
     */
    getFiltersBySeriesAssetEpgV1AssetsAssetIdFiltersGet: (assetId: string, params: RequestParams = {}) =>
      this.http.request<AssetFilterSchema, void | HTTPValidationError>({
        path: `/epg/v1/assets/${assetId}/filters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags channels
     * @name EpgEntryByChannelEpgV1ChannelsChannelIdEpgEntriesGet
     * @summary Epg Entry By Channel
     * @request GET:/epg/v1/channels/{channel_id}/epg-entries
     * @secure
     */
    epgEntryByChannelEpgV1ChannelsChannelIdEpgEntriesGet: (
      channelId: string,
      query?: {
        /**
         * Start
         * @format date-time
         */
        start?: TimeISOString;
        /**
         * End
         * @format date-time
         */
        end?: TimeISOString;
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageEpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/channels/${channelId}/epg-entries`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags channels
     * @name LiveNextEpgEntryByChannelEpgV1ChannelsChannelIdLiveAndNextEpgEntriesGet
     * @summary Live Next Epg Entry By Channel
     * @request GET:/epg/v1/channels/{channel_id}/live-and-next-epg-entries
     * @secure
     */
    liveNextEpgEntryByChannelEpgV1ChannelsChannelIdLiveAndNextEpgEntriesGet: (
      channelId: string,
      params: RequestParams = {},
    ) =>
      this.http.request<LiveNextSchema, void | HTTPValidationError>({
        path: `/epg/v1/channels/${channelId}/live-and-next-epg-entries`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthEpgV1HealthGet
     * @summary Health
     * @request GET:/epg/v1/health
     */
    healthEpgV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/epg/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootEpgV1Get
     * @summary Root
     * @request GET:/epg/v1
     */
    rootEpgV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/epg/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
