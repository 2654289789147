import deepEqual from "fast-deep-equal";
import { selectAtom } from "jotai/vanilla/utils";

import { scrollPositionAtom } from "./scroll-position.atom";

export const selectChannelListTask = selectAtom(
  scrollPositionAtom,
  (data) => (data.loading ? null : data.task),
  deepEqual,
);
