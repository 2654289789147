import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import axios from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry";

import {
  manualRetryAxiosInterceptor,
  RefreshTokenOnErrorInterceptor,
  type RefreshTokens,
  type RetryFunction,
} from "@sunrise/backend-core";
import type { BaseError } from "@sunrise/error";
import { type Nullable } from "@sunrise/utils";

import { isRetryableError } from "./helpers/is-retryable-error";

export class PrivateApiClient {
  private rt: RefreshTokenOnErrorInterceptor;

  constructor(
    protected readonly getAccessToken: () => Nullable<string>,
    protected readonly getRefreshToken: () => Nullable<string>,
    protected readonly setTokens: (
      at: string,
      rt: string,
      ws: string | null,
    ) => void,
    /**
     * Function is triggered when the privateApi deems it correct to reset the tokens and log out the user.
     * The error that caused the reset is still thrown.
     */
    protected readonly resetTokens: (error: BaseError) => void = () => {
      // noop
    },
    protected readonly refreshTokens: RefreshTokens,
    handleRetry?: RetryFunction,
    protected readonly getRetryDelayInSeconds?: () => Nullable<number>,
    protected readonly isNotKnownUserError?: (error: AxiosError) => boolean,
    protected readonly onRetry?: (error: AxiosError) => void,
  ) {
    this.client = axios.create();
    this.client.defaults.headers.common["X-Tenant"] = "yallo";
    this.client.defaults.headers.common["Content-Type"] = "application/json";

    // TODO: We may have a problem here when we are running hybrid (ng + legacy) since the refresh system is separated.
    this.rt = new RefreshTokenOnErrorInterceptor(
      this.client,
      getAccessToken,
      getRefreshToken,
      setTokens,
      refreshTokens,
      resetTokens,
      isNotKnownUserError,
      onRetry,
      false,
    );

    axiosRetry(this.client, {
      retries: 0,
      retryCondition: (error) => {
        return (
          isNetworkOrIdempotentRequestError(error) ||
          error.response?.status === 400 ||
          error.code === "ERR_BAD_REQUEST"
        );
      },
      onRetry: (_, err) => {
        onRetry?.(err);
      },
      retryDelay: (retryCount) => {
        return 1000 * retryCount * 5;
      },
    });

    if (handleRetry) {
      manualRetryAxiosInterceptor(
        this.client,
        handleRetry,
        isRetryableError,
        getRetryDelayInSeconds,
      );
    }
  }

  private client: AxiosInstance;

  async get<T, D = unknown>(
    url: string,
    axiosConfig?: AxiosRequestConfig<D> & { retries?: number },
  ): Promise<AxiosResponse<T, D>> {
    const { retries, ...config } = axiosConfig ?? {};
    const retryConfig = retries ? { "axios-retry": { retries } } : {};

    return this.client.get(url, {
      ...retryConfig,
      ...config,
    });
  }

  async post<T, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<AxiosResponse<T, D>> {
    return this.client.post(url, data, config);
  }

  async delete<T, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<AxiosResponse<T, D>> {
    return this.client.delete(url, config);
  }

  public refreshToken(): Promise<void> {
    return this.rt.refreshToken();
  }
}
