import type { ReactNode } from "react";
import type { ArrowPressHandler } from "@noriginmedia/norigin-spatial-navigation";

import { useTranslator } from "@sunrise/translator";
import { type Nullable } from "@sunrise/utils";

import { Button, FocusButton } from "@/components";

import { localWidgetFocusKey } from "./focus-keys";

type SkipButtonProps = CommonProps & {
  visible: Nullable<boolean>;
  skipAvailableAfter: number;
  onArrowPress?: ArrowPressHandler;
  onSkip: () => void;
};
export function SkipButton({
  visible,
  skipAvailableAfter,
  onSkip,
  className,
  onArrowPress,
  "data-testid": dataTestId = "skip-button",
}: SkipButtonProps): ReactNode {
  const t = useTranslator();

  // if skipAvailableAfter reached 0 or if ad is skippable but there is no skipAvailableAfter, show focused skip button
  if (visible) {
    return (
      <FocusButton
        className={className}
        data-testid={`${dataTestId}.skip-available`}
        focusKey={localWidgetFocusKey.skipButton}
        text={t("ads_skip")}
        variant={"outlined"}
        onArrowPress={onArrowPress}
        onEnterPress={onSkip}
      />
    );
  }

  // else show skip in X seconds button
  return (
    <Button
      className={className}
      data-testid={`${dataTestId}.skip-not-available`}
      text={t("ads_skip_in", [skipAvailableAfter])}
      variant="ghost"
    />
  );
}
