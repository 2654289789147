import { type ReactNode, useCallback } from "react";
import type { ArrowPressHandler } from "@noriginmedia/norigin-spatial-navigation";
import { minutesToSeconds } from "date-fns";
import { useAtomCallback } from "jotai/utils";

import { useTranslator } from "@sunrise/translator";
import { forceHideWhatIsNextSideBarAtom } from "@sunrise/what-is-next";
import {
  actionPlayerManagerPlayRecording,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";
import type { GenericSingleRecording } from "@sunrise/yallo-recordings";

import { FocusButton } from "@/components";

import { WhatIsNextRecording } from "../what-is-next-recording";
import * as styles from "./what-is-next-side-bar.css";

export type WhatIsNextSideBarContentWithProps = {
  next: GenericSingleRecording;
  onArrowPress: ArrowPressHandler;
};

export function WhatIsNextSideBarContentWith({
  next,
  onArrowPress,
}: WhatIsNextSideBarContentWithProps): ReactNode {
  const t = useTranslator();
  const doHide = useAtomCallback(
    useCallback((_, set) => {
      set(forceHideWhatIsNextSideBarAtom, true);
    }, []),
  );
  const doOpenNext = useAtomCallback(
    useCallback(
      (_get, set) => {
        set(forceHideWhatIsNextSideBarAtom, true);
        set(
          playerManagerAtom,
          actionPlayerManagerPlayRecording(
            next.id,
            // In principle, the next playing item will always be for the same channelId.
            // But it is also not the end of the world if we do not pass it.
            next.channelId ?? null,
            minutesToSeconds(next.paddingStartMinutes ?? 0),
          ),
        );
      },
      [next],
    ),
  );

  return (
    <>
      <WhatIsNextRecording
        className={styles.nextRecording}
        data={next}
        data-testid="what-is-next-side-bar.next_recording"
        focusKey="what-is-next-side-bar.next_recording"
        onArrowPress={onArrowPress}
        onEnterPress={doOpenNext}
      />
      <FocusButton
        className={styles.button}
        data-testid="what-is-next-side-bar.hide"
        focusKey="what-is-next-side-bar.hide"
        text={t("hide")}
        onArrowPress={onArrowPress}
        onEnterPress={doHide}
      />
    </>
  );
}
