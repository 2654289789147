/**
 * Extends a Promise with timeout
 * @param promise
 * @param timeoutMs
 */
export function promiseWithTimeout<T>(promise: Promise<T>, timeoutMs: number) {
  const timeout = new Promise<T>((_, reject) => {
    setTimeout(() => {
      reject(new Error(`Timed-out after ${timeoutMs}ms`));
    }, timeoutMs);
  });

  return Promise.race([promise, timeout]);
}
