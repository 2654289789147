import type { ReactNode } from "react";

import { Spinner } from "@/components";

type RecordingsNetworkIndicatorProps = {
  shouldShow: boolean;
} & CommonProps;

export function RecordingsNetworkIndicator({
  shouldShow,
  "data-testid": dataTestId = "recordings-network-indicator",
  className,
  style,
}: RecordingsNetworkIndicatorProps): ReactNode {
  return shouldShow ? (
    <Spinner className={className} data-testid={dataTestId} style={style} />
  ) : null;
}
