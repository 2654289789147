import areEqual from "fast-deep-equal";
import { type Atom, atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import type { PageableItems } from "@sunrise/types";
import {
  type GenericRecording,
  recordingsMarkedForDeletionAtom,
  recordingStatusSocketAtom,
} from "@sunrise/yallo-recordings";
import { removeUnwantedItemsFromList } from "@sunrise/yallo-recordings-list";

import { pagedGenericRecordingsForRecordingGroupIdAtom } from "./data/paged-generic-recordings-for-recording-group-id.atom";

/**
 * Returns the generic recordings that can be shown on the UI for the current recording group.
 *
 * The filtering & sorting is handled inside the pagedGenericRecordingsForRecordingGroupIdAtom.
 */
export const recordingItemsWithPagingForRecordingGroupIdAtom = atomFamily<
  {
    recordingGroupId: RecordingGroupId;
    /**
     * TODO: For NG this is not known.
     */
    seriesAssetId: AssetId;
  },
  Atom<Promise<PageableItems<GenericRecording>>>
>(({ recordingGroupId, seriesAssetId }) => {
  return atom(async (get) => {
    const recordingStatuses = get(recordingStatusSocketAtom);
    const markedForDeletion = get(recordingsMarkedForDeletionAtom);

    const data = await get(
      pagedGenericRecordingsForRecordingGroupIdAtom({
        recordingGroupId,
        seriesAssetId,
      }),
    );

    return {
      ...data,
      items: removeUnwantedItemsFromList(
        data.items,
        recordingStatuses,
        markedForDeletion,
      ),
    };
  });
}, areEqual);
