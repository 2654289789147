import { useAtomValue } from "jotai";

import { playerCurrentEpgItemAtom } from "@sunrise/player";
import { nowAtom } from "@sunrise/time";
import { programIsPlayingAtTime } from "@sunrise/yallo-epg";
import {
  type GuideProgram,
  selectorGuideSelection,
  selectorIsGridFocused,
} from "@sunrise/yallo-guide";

import { GuideProgramItem } from "@/features/guide/guide-program-item";

type GuideProgramRowProps = {
  programs: GuideProgram[];
  offsetForHour: number;
  isChannelSelected: boolean;
  channelBarCutoff?: number;
  channelHeightInPx: number;
  shouldDisableAnimations: boolean;
  fullWidth: number;
  oneMinuteWidthInPx: number;
  timeOffset: Date;
};

export function GuideProgramRow({
  programs,
  offsetForHour,
  isChannelSelected,
  channelBarCutoff,
  channelHeightInPx,
  shouldDisableAnimations,
  fullWidth,
  timeOffset,
  oneMinuteWidthInPx,
}: GuideProgramRowProps) {
  const playingEpgItem = useAtomValue(playerCurrentEpgItemAtom);
  const isProgramFocused = useAtomValue(selectorIsGridFocused);
  const now = useAtomValue(nowAtom);
  const selection = useAtomValue(selectorGuideSelection);

  return programs.map((program) => {
    const isLive = programIsPlayingAtTime(program, now);
    const isFocused =
      isProgramFocused && isChannelSelected && program.id === selection?.epgId;

    return (
      <GuideProgramItem
        key={program.id}
        channelBarCutoffInPx={channelBarCutoff}
        channelHeightInPx={channelHeightInPx}
        disableAnimations={shouldDisableAnimations}
        fullGuideWidth={fullWidth}
        isActive={playingEpgItem?.id === program.id}
        isFocused={isFocused}
        isLive={isLive}
        navId={program.id}
        offset={offsetForHour}
        oneMinuteWidthInPx={oneMinuteWidthInPx}
        program={program}
        timeOffset={timeOffset}
      />
    );
  });
}
